import React, { useEffect, useRef, useState } from "react";
import { createChart, ColorType } from 'lightweight-charts';

const initialData: any = [
    { time: 1701590400, value: 32.51 },
    { time: 1701594000, value: 31.11 },
    { time: 1701597600, value: 27.02 },
    { time: 1701601200, value: 27.32 },
    { time: 1701604800, value: 25.17 },
    { time: 1701608400, value: 28.89 },
    { time: 1701612000, value: 25.46 },
    { time: 1701615600, value: 23.92 },
    { time: 1701619200, value: 22.68 },
    { time: 1701622800, value: 22.67 },
];

const ChartNft = ({
    data = initialData,
    colors: {
        backgroundColor = '#100c18',
        textColor = '#ffffff75',
        gridColor = '#ffffff12',
    } = {},
    backgroundImage = '',
}) => {
    const chartContainerRef: any = useRef();
    const [tooltip, setTooltip]: any = useState({ time: '', value: '' });

    useEffect(() => {
        const handleResize = () => {
            chart.applyOptions({ width: chartContainerRef.current.clientWidth });
        };

        const lastIndex = data.length - 1;
        const penultimateValue = data[lastIndex - 1]?.value;
        const lastValue = data[lastIndex]?.value;

        const isGreen = penultimateValue < lastValue;

        const lineColor = isGreen ? '#33d778' : '#ff4d4f';
        const areaTopColor = isGreen ? '#00d41540' : '#ff4d4f40';
        const areaBottomColor = isGreen ? '#00d4151a' : '#ff4d4f1a';

        const chart = createChart(chartContainerRef.current, {
            layout: {
                background: { type: ColorType.Solid, color: 'transparent' },
                textColor,
            },
            width: chartContainerRef.current.clientWidth,
            height: 500,
            grid: {
                vertLines: {
                    visible: false,
                },
                horzLines: {
                    visible: true,
                    color: gridColor,
                },
            },
            timeScale: {
                timeVisible: true,
                secondsVisible: true,

            },
            handleScroll: false,
            crosshair: {
                mode: 0,
            },
        });
        chart.timeScale().fitContent();

        const newSeries = chart.addAreaSeries({
            lineColor,
            topColor: areaTopColor,
            bottomColor: areaBottomColor,
        });
        newSeries.setData(data);

        chart.subscribeCrosshairMove((param: any) => {
            if (param.time && param.seriesData.size) {
                const value = param.seriesData.get(newSeries)?.value || '';
                const time = new Date(param.time * 1000).toLocaleString();
                setTooltip({ time, value });
            } else {
                setTooltip({ time: '', value: '' });
            }
        });

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            chart.remove();
        };
    }, [data, backgroundColor, textColor, gridColor]);

    return (
        <div
            style={{
                position: 'relative',
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                padding: '5px 5px',
                borderRadius: '10px',
                width: '100%',
                height: '500px',
                resize: "none"
            }}
        >
            <div
                ref={chartContainerRef}
                style={{
                    position: 'relative',
                }}
            />
            {tooltip.time && tooltip.value && (
                <div
                    style={{
                        position: 'absolute',
                        top: '10px',
                        left: '10px',
                        backgroundColor: '#000',
                        color: '#fff',
                        padding: '10px 10px',
                        borderRadius: '5px',
                        fontSize: '14px',
                    }}
                >
                    <div><strong style={{ color: "#00BCD4" }}>Time:</strong> {tooltip.time}</div>
                    <div><strong style={{ color: "#00BCD4" }}>Price:</strong> {new Intl.NumberFormat('en-US').format(tooltip.value)}$</div>
                </div>
            )}
        </div>
    );
};

export default ChartNft;
