import React, { useEffect, useState } from "react";
import { Tabs, Segmented, Skeleton, Spin, Progress, Empty } from 'antd';
import Countdown from "react-countdown";
import { rendererCountDown2 } from "./utils/render-countdown";
import { useWeb3Modal } from '@web3modal/wagmi/react'
import Web3 from 'web3';
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import "./style.scss"
import { AppHome } from "../../../api/appHome";
import { useContract } from "../../../hooks/useContract";
import UserContractMapping from "./utils/UserContractMapping.json"
import { RPC_BASE } from "../../../constants";
import abiLending from "../p2p-lending/utils/abiLending.json"
import { AbiItem } from "web3-utils";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { ethers } from "ethers";

declare const window: Window & typeof globalThis & { ethereum: any };

const Item = ({ item }: any) => {
    let web3: any = new Web3(RPC_BASE);
    const naigation = useNavigate()
    let timeNowStam = new Date().getTime()
    let scAddress = item?.contractAddress
    const [startTimePool, setStartTimePool]: any = useState(0)
    const [endTimePool, setEndTimePool]: any = useState(0)
    const [totalLenderPool, setTotalLenderPool] = useState(0)
    const [apyBasic, setApyBasic] = useState(0)
    const [fundingCap, setFundingCap] = useState(0)
    const [numberLendbyUser, setNumberLendbyUser] = useState(0)
    const [upcomingPool, setUpcomingPool]: any = useState(0)
    const timeInvestmentPeriod = Number(endTimePool) - Number(startTimePool);
    const secondsInAMinute = 60;
    const secondsInAnHour = 60 * secondsInAMinute;
    const secondsInADay = 24 * secondsInAnHour;
    const secondsInAMonth = 30 * secondsInADay;
    let period;

    if (timeInvestmentPeriod >= secondsInAMonth) {
        const monthsPeriod = timeInvestmentPeriod / secondsInAMonth;
        period = `${monthsPeriod} Month`;
    } else {
        const days = Math.floor(timeInvestmentPeriod / secondsInADay);
        const hours = Math.floor((timeInvestmentPeriod % secondsInADay) / secondsInAnHour);
        const minutes = Math.floor((timeInvestmentPeriod % secondsInAnHour) / secondsInAMinute);
        period = `${days}d:${hours}h:${minutes}m`;
    }

    const contractLendingNotAddress = new web3.eth.Contract(
        abiLending as unknown as AbiItem,
        scAddress
    );

    const getInfoContract = async () => {
        if (contractLendingNotAddress && scAddress) {
            let infoContract = await contractLendingNotAddress?.methods?.getContractInfo().call()
            setApyBasic(Number(infoContract?._baseAPY) / 100)
            setEndTimePool(Number(infoContract?._endTime))
            setStartTimePool(Number(infoContract?._startTime))
            setFundingCap(Number(web3.utils.fromWei(infoContract?._fundingCap, "picoether")))
            setNumberLendbyUser(Number(web3.utils.fromWei(infoContract?._totalLentAmount, "picoether")))
            setTotalLenderPool(Number(infoContract?._totalLenders))
            setUpcomingPool(Number(infoContract?._fundingOpenTime))
        }
    }

    useEffect(() => {
        getInfoContract()
    }, [contractLendingNotAddress, scAddress]);

    let percentProcess: any = Number(numberLendbyUser) / Number(fundingCap) * 100

    const handleOpenDetails = () => {
        naigation(`/apps/p2p-lending/details?id=${item?.code}`)
    }

    return (
        <>
            <div className="colum w-4">
                {!apyBasic ?
                    <>
                        <Skeleton paragraph={{ rows: 6 }} active />
                    </>
                    :
                    <>
                        <div className="guide-item" onClick={handleOpenDetails}>
                            <div className="name-type">
                                <div className="type-status">
                                    <img src="/images/location.png" alt="" /> {item?.country}
                                </div>
                                {(upcomingPool * 1000) > timeNowStam ?
                                    <>
                                        <div className="type-process">
                                            Upcoming
                                        </div>
                                    </>
                                    :
                                    <>
                                        {(upcomingPool * 1000) < timeNowStam && timeNowStam < (startTimePool * 1000) ?
                                            <>
                                                <div className="type-process">
                                                    In Progress
                                                </div>
                                            </>
                                            :
                                            <>
                                                {timeNowStam > (startTimePool * 1000) && Number(numberLendbyUser) < Number(fundingCap) ?
                                                    <>
                                                        <div className="type-process sold-out">
                                                            Partially filled
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="type-process sold-out">
                                                            Sold Out
                                                        </div>
                                                    </>
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </div>
                            <div className="box-img">
                                <img src={item?.thumbnail} alt="" />
                            </div>
                            <div className="bottom-item">
                                {(upcomingPool * 1000) > timeNowStam ?
                                    <>
                                        <div className="time-countdown">
                                            <div className="time-l">
                                                <img src="/images/lending/clock.png" alt="" /> Time Start:
                                            </div>
                                            <div className="time-r">
                                                <Countdown
                                                    date={upcomingPool * 1000}
                                                    renderer={rendererCountDown2}
                                                />
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        {(upcomingPool * 1000) < timeNowStam && timeNowStam < (startTimePool * 1000) ?
                                            <>
                                                {startTimePool &&
                                                    <>
                                                        <div className="time-countdown">
                                                            <div className="time-l">
                                                                <img src="/images/lending/clock.png" alt="" /> Time Left:
                                                            </div>
                                                            <div className="time-r">
                                                                <Countdown
                                                                    date={startTimePool * 1000}
                                                                    renderer={rendererCountDown2}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </>
                                            :
                                            <>
                                                {endTimePool &&
                                                    <>
                                                        <div className="time-countdown">
                                                            <div className="time-l">
                                                                <img src="/images/lending/clock.png" alt="" /> Time Left:
                                                            </div>
                                                            <div className="time-r">
                                                                Finished
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </>
                                        }
                                    </>
                                }
                                <div className="location-row">
                                    <div className="title-row">
                                        {item?.name}
                                    </div>
                                </div>
                                <div className="list-type-manual res">
                                    <div className="item">
                                        <div className="item-l">
                                            <img src="/images/logo-user.png" alt="" /> Unit:
                                        </div>
                                        <div className="item-r">
                                            {totalLenderPool}
                                        </div>
                                    </div>
                                </div>
                                <div className="list-type-manual res">
                                    <div className="item">
                                        <div className="item-l">
                                            <img src="/images/lending/type-le.png" alt="" /> Type:
                                        </div>
                                        <div className="item-r">
                                            {item?.type}
                                        </div>
                                    </div>
                                </div>
                                <div className="list-type-manual res">
                                    <div className="item">
                                        <div className="item-l">
                                            <img src="/images/cal.png" alt="" /> Loan ends at:
                                        </div>
                                        <div className="item-r">
                                            {moment(endTimePool * 1000).utc().format('hh:mm A [UTC], MMM Do YYYY')}
                                        </div>
                                    </div>
                                </div>
                                <div className="list-type-manual res">
                                    <div className="item">
                                        <div className="item-l">
                                            <img src="/images/cak.png" alt="" /> Annual Asset Appreciation:
                                        </div>
                                        <div className="item-r">
                                            {item?.annualAsset}%
                                        </div>
                                    </div>
                                </div>
                                <div className="poniter-box">
                                    <div className="raise-profit">
                                        <div className="box-riase">
                                            <div className="txt">Funding Taget</div>
                                            <div className="number-raise">
                                                <span className="number">${new Intl.NumberFormat('en-US').format(fundingCap)}</span>
                                            </div>
                                        </div>
                                        <div className="apy-profit">
                                            <div className="txt-top">
                                                APY
                                            </div>
                                            <div className="txt-bottom">
                                                {apyBasic}%
                                            </div>
                                        </div>
                                    </div>
                                    <div className="resize-invesment">
                                        <div className="total-inves">
                                            <div className="text-l">
                                                Total Investment
                                            </div>
                                            <div className="text-r">
                                                ${new Intl.NumberFormat('en-US').format(numberLendbyUser)} USDC
                                            </div>
                                        </div>
                                        <div className="bottom-process">
                                            <Progress percent={percentProcess.toFixed(0)} status="active" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }

            </div>
        </>
    )
}

const ItemUser = ({ item, index, onLenderBalanceChange }: any) => {
    const { address } = useAccount();
    let web3: any = new Web3(RPC_BASE);
    const naigation = useNavigate()
    let scAddress = item?.contractAddress
    const contractLending: any = useContract(scAddress, abiLending)
    const [lenderTotalBalanceByUser, setLenderTotalBalanceByUser] = useState(0)
    const [apyBasic, setApyBasic] = useState(0)

    const contractLendingNotAddress = new web3.eth.Contract(
        abiLending as unknown as AbiItem,
        scAddress
    );

    const getLenderTotalBalanceByUser = async () => {
        if (contractLending && address) {
            let totalBalance = await contractLending.lenderTotalBalance(address);
            const formattedBalance = Number(totalBalance) / 1e6;
            setLenderTotalBalanceByUser(formattedBalance);
            onLenderBalanceChange(formattedBalance);
        }
    };

    useEffect(() => {
        getLenderTotalBalanceByUser();
    }, [address, scAddress]);

    const getInfoContract = async () => {
        if (contractLendingNotAddress && scAddress) {
            let infoContract = await contractLendingNotAddress?.methods?.getContractInfo().call()
            setApyBasic(Number(infoContract?._baseAPY) / 100)
        }
    }

    useEffect(() => {
        getInfoContract()
    }, [contractLendingNotAddress, scAddress]);

    const handleOpenDetails = () => {
        naigation(`/apps/p2p-lending/details?id=${item?.code}`)
    }

    return (
        <>
            <div className="item-properties" onClick={handleOpenDetails}>
                <div className="left-pro">
                    <div className="stt">
                        {index}
                    </div>
                    <div className="box-img">
                        <img src={item?.thumbnail} alt="" />
                    </div>
                    <div className="text">
                        <div className="top">
                            {item?.fullName}
                        </div>
                        <div className="bottom">
                            {apyBasic}% APY
                        </div>
                    </div>
                </div>
                <div className="right-pro">
                    <div className="number">
                        {Number(lenderTotalBalanceByUser) > 0 ?
                            <>
                                ${new Intl.NumberFormat('en-US').format(lenderTotalBalanceByUser)}
                            </>
                            :
                            <>
                                {""}
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

const P2pLending = () => {
    const { address } = useAccount();
    const { open } = useWeb3Modal()
    const [dataContract, setdataContract]: any = useState()
    const [data, setData]: any = useState()
    const [keyTabs, setKeyTabs] = useState(0)
    const [keyFilter, setKeyFilter] = useState("All Time")
    let scContract = dataContract && dataContract?.p2p_sum_transaction_contract
    let contractProfile: any = useContract(scContract, UserContractMapping)
    const [arrParam, setArrParam] = useState([])
    const [dataProperties, setDataProperties] = useState([])
    const [userContractCount, setUserContractCount]: any = useState()
    const [hideBalance, setHideBalance]: any = useState(false)
    const [loadingData, setLoadingData] = useState(false)
    const [loadingPro, setLoadingPro] = useState(false)
    const [dataAccount, setDataAccount]: any = useState()
    const [totalLenderBalance, setTotalLenderBalance] = useState(0);
    const [balanceETH, setBalanceETH]: any = useState(0)

    const handleLenderBalanceChange = (balance: any) => {
        setTotalLenderBalance(prevBalance => prevBalance + balance);
    };

    const SettingContract = async () => {
        try {
            let res = await AppHome.getSettingContract();
            if (res.data.success === true) {
                setdataContract(res.data.data);
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    useEffect(() => {
        SettingContract()
    }, []);

    const handleConnect = () => {
        open();
    }

    const AccountInfo = async () => {
        try {
            let res = await AppHome.AccountInfo(
                address
            );
            if (res.data.success === true) {
                setDataAccount(res.data.data);
                localStorage.setItem('userAccount', JSON.stringify(res.data.data.dataAccount));
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    useEffect(() => {
        if (address) {
            AccountInfo()
        }
    }, [address]);


    const onChange = async (key: any) => {
        setLoadingData(true)
        const renderKey = (key: any) => {
            switch (key) {
                case "All Time":
                    return 0;
                case "24h":
                    return 1;
                case "7 days":
                    return 2;
                default:
                    return 'NA';
            }
        }
        setKeyFilter(key)
        let params = {
            pageSize: 6,
            pageIndex: 0,
            filterTime: Number(renderKey(key)),
            p2pStatus: Number(keyTabs)
        }
        try {
            let res = await AppHome.getListP2p(
                params
            );
            if (res.data.success === true) {
                setData(res.data.data);
                setLoadingData(false)
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    }

    const getInfoP2p = async () => {
        setLoadingData(true)
        let params = {
            pageSize: 6,
            pageIndex: 0,
            filterTime: 0,
            p2pStatus: 0
        }
        try {
            let res = await AppHome.getListP2p(
                params
            );
            if (res.data.success === true) {
                setData(res.data.data);
                setLoadingData(false)
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    useEffect(() => {
        getInfoP2p()
    }, []);

    const onChangeTabs = async (key: any) => {
        setLoadingData(true)
        const renderKey = (keyFilter: any) => {
            switch (keyFilter) {
                case "All Time":
                    return 0;
                case "24h":
                    return 1;
                case "7 days":
                    return 2;
                default:
                    return 'NA';
            }
        }
        let params = {
            pageSize: 6,
            pageIndex: 0,
            filterTime: Number(renderKey(keyFilter)),
            p2pStatus: Number(key)
        }
        setKeyTabs(key)
        try {
            let res = await AppHome.getListP2p(
                params
            );
            if (res.data.success === true) {
                setData(res.data.data);
                setLoadingData(false)
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    }

    const getUserContract = async () => {
        if (contractProfile && address) {
            let userContract = await contractProfile.getUserContractCount(address)
            setUserContractCount(Number(userContract))
        }
    }

    useEffect(() => {
        getUserContract()
    }, [address, contractProfile]);

    const getHistoryByUser = async () => {
        if (contractProfile && address && userContractCount) {
            let loan = await contractProfile.getUserContracts(address);
            setArrParam(loan);
        }
    };

    useEffect(() => {
        if (address) {
            getHistoryByUser()
        }
    }, [address, userContractCount]);

    const getInfoP2pProperties = async () => {
        setLoadingPro(true)
        let params = {
            contracts: arrParam
        }
        try {
            let res = await AppHome.getP2pProperties(
                params
            );
            if (res.data.success === true) {
                setDataProperties(res.data.data);
                setTimeout(() => {
                    setLoadingPro(false)
                }, 2000);
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    useEffect(() => {
        getInfoP2pProperties()
    }, [arrParam]);

    const handleHideBalance = () => {
        setHideBalance(!hideBalance)
        localStorage.setItem("keyBalance", hideBalance)
    }

    let dataHide = localStorage.getItem("keyBalance") || false

    const getBalance = async () => {
        if (!address) {
            // alert("Please enter a valid address");
            return;
        }

        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const balance = await provider.getBalance(address);
            setBalanceETH(ethers.utils.formatEther(balance));
        } catch (error) {
            console.error("Error fetching balance:", error);
            // alert("Error fetching balance");
        }
    };

    useEffect(() => {
        if (address) {
            getBalance()
        }
    }, [address]);

    const tabAll = (
        <>
            <div className="title-tabs">
                All
            </div>
        </>
    )

    const tabJust = (
        <>
            <div className="title-tabs">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.6" d="M14.9941 13.4699L14.0931 5.40459C14.0665 5.15785 13.9494 4.92974 13.7643 4.76445C13.5792 4.59915 13.3394 4.50842 13.0912 4.50984H10.9804C10.8591 3.80733 10.4935 3.17026 9.9482 2.71104C9.4029 2.25183 8.71291 2 8 2C7.28709 2 6.5971 2.25183 6.0518 2.71104C5.50649 3.17026 5.14093 3.80733 5.01962 4.50984H2.90878C2.66063 4.50842 2.42078 4.59915 2.23569 4.76445C2.0506 4.92974 1.93345 5.15785 1.90692 5.40459L1.00588 13.4699C0.990616 13.6115 1.00516 13.7547 1.04856 13.8904C1.09197 14.026 1.16328 14.1511 1.25792 14.2575C1.35224 14.363 1.46782 14.4472 1.59706 14.5048C1.7263 14.5624 1.86626 14.5919 2.00774 14.5915H13.9923C14.1337 14.5919 14.2737 14.5624 14.4029 14.5048C14.5322 14.4472 14.6478 14.363 14.7421 14.2575C14.8367 14.1511 14.908 14.026 14.9514 13.8904C14.9948 13.7547 15.0094 13.6115 14.9941 13.4699ZM5.98368 7.03025C5.98368 7.16394 5.93057 7.29215 5.83603 7.38669C5.7415 7.48122 5.61329 7.53433 5.4796 7.53433C5.3459 7.53433 5.21769 7.48122 5.12316 7.38669C5.02862 7.29215 4.97551 7.16394 4.97551 7.03025V6.02209C4.97551 5.8884 5.02862 5.76018 5.12316 5.66565C5.21769 5.57111 5.3459 5.51801 5.4796 5.51801C5.61329 5.51801 5.7415 5.57111 5.83603 5.66565C5.93057 5.76018 5.98368 5.8884 5.98368 6.02209V7.03025ZM6.04669 4.50984C6.15774 4.07612 6.40998 3.6917 6.76365 3.41718C7.11731 3.14265 7.55229 2.99365 8 2.99365C8.44771 2.99365 8.88269 3.14265 9.23635 3.41718C9.59002 3.6917 9.84226 4.07612 9.95331 4.50984H6.04669ZM11.0245 7.03025C11.0245 7.16394 10.9714 7.29215 10.8768 7.38669C10.7823 7.48122 10.6541 7.53433 10.5204 7.53433C10.3867 7.53433 10.2585 7.48122 10.164 7.38669C10.0694 7.29215 10.0163 7.16394 10.0163 7.03025V6.02209C10.0163 5.8884 10.0694 5.76018 10.164 5.66565C10.2585 5.57111 10.3867 5.51801 10.5204 5.51801C10.6541 5.51801 10.7823 5.57111 10.8768 5.66565C10.9714 5.76018 11.0245 5.8884 11.0245 6.02209V7.03025Z" fill="white" />
                </svg>

                Sold Out
            </div>
        </>
    )

    const tabTrending = (
        <>
            <div className="title-tabs">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.0001 8.43125L8.46887 1.9C8.35326 1.78337 8.21054 1.6972 8.05349 1.64921C7.89643 1.60122 7.72993 1.5929 7.56887 1.625L2.52512 2.6375C2.42948 2.6566 2.34164 2.70359 2.27268 2.77256C2.20372 2.84152 2.15672 2.92936 2.13762 3.025L1.12512 8.06875C1.09303 8.22981 1.10134 8.39631 1.14933 8.55337C1.19732 8.71042 1.28349 8.85314 1.40012 8.96875L7.93137 15.5C8.02378 15.5934 8.13377 15.6675 8.255 15.718C8.37622 15.7686 8.50627 15.7946 8.63762 15.7946C8.76897 15.7946 8.89902 15.7686 9.02025 15.718C9.14147 15.6675 9.25147 15.5934 9.34387 15.5L15.0001 9.84375C15.0935 9.75134 15.1676 9.64135 15.2182 9.52012C15.2687 9.3989 15.2948 9.26885 15.2948 9.1375C15.2948 9.00615 15.2687 8.8761 15.2182 8.75488C15.1676 8.63365 15.0935 8.52366 15.0001 8.43125ZM5.25012 6.5C5.10179 6.5 4.95678 6.45601 4.83344 6.3736C4.71011 6.29119 4.61398 6.17406 4.55721 6.03701C4.50045 5.89997 4.48559 5.74917 4.51453 5.60368C4.54347 5.4582 4.6149 5.32456 4.71979 5.21967C4.82468 5.11478 4.95832 5.04335 5.1038 5.01441C5.24929 4.98547 5.40009 5.00032 5.53713 5.05709C5.67418 5.11386 5.79131 5.20999 5.87372 5.33332C5.95614 5.45666 6.00012 5.60166 6.00012 5.75C6.00012 5.94891 5.9211 6.13968 5.78045 6.28033C5.6398 6.42098 5.44903 6.5 5.25012 6.5Z" fill="#999999" />
                </svg>

                In progress
            </div>
        </>
    )

    const tabPartially = (
        <>
            <div className="title-tabs">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.875 3.48748C10.1024 2.59571 9.23476 1.79094 8.2875 1.08748C8.20297 1.02916 8.1027 0.997925 8 0.997925C7.8973 0.997925 7.79703 1.02916 7.7125 1.08748C6.76524 1.79094 5.8976 2.59571 5.125 3.48748C3.40625 5.45623 2.5 7.53748 2.5 9.49998C2.5 10.9587 3.07946 12.3576 4.11091 13.3891C5.14236 14.4205 6.54131 15 8 15C9.45869 15 10.8576 14.4205 11.8891 13.3891C12.9205 12.3576 13.5 10.9587 13.5 9.49998C13.5 7.53748 12.5937 5.45623 10.875 3.48748ZM8 2.12498C8.8875 2.81873 11.5563 5.11248 12.3 7.99998H3.7C4.44375 5.11248 7.1125 2.81873 8 2.12498Z" fill="#999999" />
                </svg>

                Partially Filled
            </div>
        </>
    )


    return (
        <>
            <div className="section-grow mar-b-100-p2p">
                {isMobile ?
                    <>
                        <div className="title-p2p-mobile">
                            P2P Lending
                        </div>
                    </>
                    :
                    <>
                        {""}
                    </>
                }
                <div className={isMobile ? "grow-wrap grap-mobile-p2p" : "grow-wrap"}>
                    <div className="wrap-left">
                        <div className="content-grow">
                            <div className="tabs-list">
                                <div className="filter-grow">
                                    <Segmented onChange={onChange} options={['All Time', '7 days', '24h']} />
                                </div>
                                <Tabs defaultActiveKey="0" onChange={onChangeTabs}>
                                    <Tabs.TabPane tab={tabAll} key="0">
                                        <div className="ant-item-wrap">
                                            <div className="columns">
                                                {loadingData ?
                                                    <>
                                                        {[...Array(2)].map((_, index) => (
                                                            <Skeleton active />
                                                        ))}
                                                    </>
                                                    :
                                                    <>
                                                        {data && data.length > 0 ? (
                                                            <>
                                                                {data.map((item: any, index: any) => (
                                                                    <Item key={index} item={item} index={index + 1} />
                                                                ))}
                                                                {data.length === 1 ?
                                                                    <>
                                                                        <div className="colum w-4">
                                                                            <div className="box-img-mey-black">
                                                                                <img src="/images/lending/mey-black.png" alt="" />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {""}
                                                                    </>
                                                                }
                                                            </>
                                                        ) : (
                                                            <div className="box-no-data-lending">
                                                                <img src="/images/empty1.png" alt="" />
                                                            </div>
                                                        )}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab={tabTrending} key="1">
                                        <div className="ant-item-wrap">
                                            <div className="columns">
                                                {loadingData ?
                                                    <>
                                                        {[...Array(2)].map((_, index) => (
                                                            <Skeleton active />
                                                        ))}
                                                    </>
                                                    :
                                                    <>
                                                        {data && data.length > 0 ? (
                                                            <>
                                                                {data.map((item: any, index: any) => (
                                                                    <Item key={index} item={item} index={index + 1} />
                                                                ))}
                                                                {data.length === 1 ?
                                                                    <>
                                                                        <div className="colum w-4">
                                                                            <div className="box-img-mey-black">
                                                                                <img src="/images/lending/mey-black.png" alt="" />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {""}
                                                                    </>
                                                                }
                                                            </>
                                                        ) : (
                                                            <div className="box-no-data-lending">
                                                                <img src="/images/empty1.png" alt="" />
                                                            </div>
                                                        )}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab={tabJust} key="2">
                                        <div className="ant-item-wrap">
                                            <div className="columns">
                                                {loadingData ?
                                                    <>
                                                        {[...Array(2)].map((_, index) => (
                                                            <Skeleton active />
                                                        ))}
                                                    </>
                                                    :
                                                    <>
                                                        {data && data.length > 0 ? (
                                                            <>
                                                                {data.map((item: any, index: any) => (
                                                                    <Item key={index} item={item} index={index + 1} />
                                                                ))}
                                                                {data.length === 1 ?
                                                                    <>
                                                                        <div className="colum w-4">
                                                                            <div className="box-img-mey-black">
                                                                                <img src="/images/lending/mey-black.png" alt="" />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {""}
                                                                    </>
                                                                }

                                                            </>
                                                        ) : (
                                                            <div className="box-no-data-lending">
                                                                <img src="/images/empty1.png" alt="" />
                                                            </div>
                                                        )}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab={tabPartially} key="3">
                                        <div className="ant-item-wrap">
                                            <div className="columns">
                                                {loadingData ?
                                                    <>
                                                        {[...Array(2)].map((_, index) => (
                                                            <Skeleton active />
                                                        ))}
                                                    </>
                                                    :
                                                    <>
                                                        {data && data.length > 0 ? (
                                                            <>
                                                                {data.map((item: any, index: any) => (
                                                                    <Item key={index} item={item} index={index + 1} />
                                                                ))}
                                                                {data.length === 1 ?
                                                                    <>
                                                                        <div className="colum w-4">
                                                                            <div className="box-img-mey-black">
                                                                                <img src="/images/lending/mey-black.png" alt="" />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {""}
                                                                    </>
                                                                }
                                                            </>
                                                        ) : (
                                                            <div className="box-no-data-lending">
                                                                <img src="/images/empty1.png" alt="" />
                                                            </div>
                                                        )}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </Tabs.TabPane>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                    <div className="wrap-right">
                        <div className="row-user-info">
                            <div className="box-user-info">
                                <div className="title-profile">
                                    <img src="/images/my-por.png" alt="" /> My Portfolio
                                </div>
                                {address ?
                                    <>
                                        <div className="balance-user">
                                            <div className="txt-l" onClick={handleHideBalance}>
                                                Total Balance <img src="/images/eye.png" alt="" />
                                            </div>
                                            <div className="txt-r">
                                                {dataHide === "true" ?
                                                    <>
                                                        <span>******</span>
                                                    </>
                                                    :
                                                    <>
                                                        {Number(balanceETH).toFixed(4)} ETH
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="different-connect">
                                            <button className="button-different" onClick={handleConnect}>
                                                Connect Wallet
                                            </button>
                                        </div>
                                    </>
                                }
                                <div className="row-membersip">
                                    <div className="bar-item">
                                        <div className="txt-l">
                                            <img src="/images/lending/user-octagon.png" alt="" /> Your membership:
                                        </div>
                                        <div className="txt-r">
                                            {address && dataAccount?.tier?.tier > 0 ? "Early contributor" : "---"}
                                        </div>
                                    </div>
                                </div>
                                <div className="box-income">
                                    <div className="item">
                                        <div className="top">
                                            Your loan
                                        </div>
                                        <div className="bottom">
                                            {address ? `$${new Intl.NumberFormat('en-US').format(totalLenderBalance)}` : "---"} <span>USDC</span>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="top">
                                            Total propertires
                                        </div>
                                        <div className="bottom">
                                            {address ? dataProperties?.length : "---"}
                                        </div>
                                    </div>
                                </div>
                                <div className="list-properties">
                                    <div className="title">
                                        Total propertires: {address ? <span>{dataProperties?.length}</span> : ""}
                                    </div>
                                    {address ?
                                        <>
                                            {loadingPro ?
                                                <>
                                                    {[...Array(2)].map((_, index) => (
                                                        <Skeleton active />
                                                    ))}
                                                </>
                                                :
                                                <>
                                                    {dataProperties && dataProperties.length > 0 ? (
                                                        dataProperties.map((item: any, index: any) => (
                                                            <ItemUser onLenderBalanceChange={handleLenderBalanceChange} key={index} item={item} index={index + 1} />
                                                        ))
                                                    ) : (
                                                        <div className="box-no-data-lending">
                                                            <img className='img-empty' src="/images/empty1.png" alt="" />
                                                        </div>
                                                    )}
                                                </>
                                            }
                                        </>
                                        :
                                        <>
                                            <div className="box-no-data-lending">
                                                <img className='img-empty' src="/images/empty1.png" alt="" />
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default P2pLending