import React, { useEffect, useState } from "react";
import { AppHome } from "../../../../api/appHome";
import { useAccount } from "wagmi";
import { Link } from "react-router-dom";
import { useContract } from "../../../../hooks/useContract";
import abi_StakingTierManager from "./utils/abi_StakingTierManager.json"
import Web3 from "web3";
import { RPC_BASE } from "../../../../constants";
import { Skeleton } from "antd";

const Ranked = () => {
    const { address } = useAccount();
    const [dataInfo, setDataInfo]: any = useState()
    const [dataContract, setdataContract]: any = useState()
    const [infoTierId, setInfoTierId]: any = useState()
    const [infoTierName, setInfoTierName]: any = useState()
    let web3: any = new Web3(RPC_BASE);
    let scContract: any = dataContract && dataContract?.stake_tier_manager_contract
    const contractReadTier: any = useContract(scContract, abi_StakingTierManager)
    const [balanceStake, setBalanceStake] = useState(0)

    const AccountInfo = async () => {
        try {
            let res = await AppHome.AccountInfo(
                address
            );
            if (res.data.success === true) {
                setDataInfo(res.data.data);
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    useEffect(() => {
        if (address) {
            AccountInfo()
        }
    }, [address]);

    const SettingContract = async () => {
        try {
            let res = await AppHome.getSettingContract();
            if (res.data.success === true) {
                setdataContract(res.data.data);
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    useEffect(() => {
        SettingContract()
    }, []);

    const getTierByUser = async () => {
        if (contractReadTier && address && scContract) {
            let tier = await contractReadTier?.getTierFromStaking(address)
            setInfoTierId(Number(tier?.tierId))
            setInfoTierName(tier?.tierName)

        }
    }

    const getTierByUserBalance = async () => {
        if (contractReadTier && address) {
            let tier = await contractReadTier?.getTotalStakingAmount(address);
            let balance: any = web3.utils.fromWei(tier, "ether");
            setBalanceStake(balance);
        }
    }

    useEffect(() => {
        if (address && contractReadTier && scContract) {
            getTierByUser()
            getTierByUserBalance()
        }
    }, [address, infoTierId, scContract, contractReadTier]);

    const imagesRender = [
        "/images/user/ti-1.png",
        "/images/user/ti-2.png",
        "/images/user/ti-3.png",
        "/images/user/ti-4.png",
        "/images/user/ti-5.png"
    ];

    const renderAmountByUser = (amount: any) => {
        switch (amount) {
            case 0:
                return 10000;
                break;
            case 1:
                return 25000;
                break;
            case 2:
                return 50000;
                break;
            case 3:
                return 100000;
                break;
            case 4:
                return 0;
                break;
        }
    }

    let dataNextRank = Number(renderAmountByUser(infoTierId)) - balanceStake

    return (
        <>
            <div className="section-grow mar-b-100-p2p">
                <div className="my-rank-by-user">
                    {infoTierId === undefined || infoTierName === undefined ?
                        <>
                            <Skeleton paragraph={{ rows: 10 }} active />
                        </>
                        :
                        <>
                            <Link to="/apps/portfolio">
                                <div className="go-back">
                                    <img src="/images/user/go-back.png" alt="" /> Go back
                                </div>
                            </Link>
                            <div className="top-rank-by-user">
                                <div className="left-contants">
                                    <div className="my-rank">
                                        <div className="icon-rank">
                                            <img src={imagesRender[infoTierId]} />
                                        </div>
                                        <div className="text-rank">
                                            Your Rank <span>{infoTierName}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="right-contants">
                                    <div className="stake-user-rank">
                                        <div className="row-item">
                                            <div className="text-rank">
                                                Your Stake <span>${new Intl.NumberFormat('en-US').format(balanceStake)} MEY</span>
                                            </div>
                                        </div>
                                        <div className="row-item">
                                            <div className="text-rank">
                                                $MEY Left to Next Rank <span>{infoTierId === 4 ? 0 : new Intl.NumberFormat('en-US').format(dataNextRank)} MEY</span>
                                            </div>
                                        </div>
                                        <div className="row-item">
                                            <Link to="/apps/staking">
                                                <button className="stake-more-btn">
                                                    Stake More
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bottom-by-user-ranked">
                                <div className="list-images-tier">
                                    <div className="item-control-images">
                                        <div className="icon-tier">
                                            <img src="/images/user/standard.gif" alt="" />
                                        </div>
                                        <div className="name-core">
                                            Standard
                                        </div>
                                    </div>
                                    <div className="item-control-images">
                                        <div className="icon-tier">
                                            <img src="/images/user/dolphin.gif" alt="" />
                                        </div>
                                        <div className="name-core">
                                            Dolphin
                                        </div>
                                    </div>
                                    <div className="item-control-images">
                                        <div className="icon-tier">
                                            <img src="/images/user/shark.gif" alt="" />
                                        </div>
                                        <div className="name-core">
                                            Shark
                                        </div>
                                    </div>
                                    <div className="item-control-images">
                                        <div className="icon-tier">
                                            <img src="/images/user/whale.gif" alt="" />
                                        </div>
                                        <div className="name-core">
                                            Whale
                                        </div>
                                    </div>
                                    <div className="item-control-images">
                                        <div className="icon-tier">
                                            <img src="/images/user/SVIP.gif" alt="" />
                                        </div>
                                        <div className="name-core">
                                            SVIP
                                        </div>
                                    </div>
                                </div>
                                {dataInfo?.myRank.map((item: any, index: any) => (
                                    <div className="list-item-by-rank">
                                        <div className="item-control">
                                            <div className="text-control">
                                                {item?.name}:
                                            </div>
                                        </div>
                                        <div className="item-control">
                                            {item?.standard}
                                        </div>
                                        <div className="item-control">
                                            {item?.dolphin}
                                        </div>
                                        <div className="item-control">
                                            {item?.shark}
                                        </div>
                                        <div className="item-control">
                                            {item?.whale}
                                        </div>
                                        <div className="item-control">
                                            {item?.svip}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    )
}
export default Ranked