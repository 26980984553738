import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { AppHome } from "../../../../api/appHome";
import { CustomTable } from "../../../../components/ui/CustomTable";
import clsx from "clsx";
import { Button, Checkbox, Input, InputNumber, Modal, Pagination, Popover, Slider, Spin, Tabs } from "antd";
import { FilterTransactionHistory } from "../../../../types/marketplace";
import { useAccount } from "wagmi";
import moment from "moment";
import { useContract } from "../../../../hooks/useContract";
import { LoadingOutlined } from '@ant-design/icons';
import nftMarketABI from "../utils/nftMarketABI.json"
import { Noti } from "../../../../components/Noti/toast";
import { ethers } from "ethers";
import { BASE_SCAN } from "../../../../constants";
import { isMobile } from "react-device-detect";

export const TransactionHistoryTab = (props: any) => {
    const { address } = useAccount();
    const dataDetail: any = props?.dataDetail
    const [dataTx, setDataTx]: any = useState([])
    const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);
    const [filterData, setFilterData] = useState<FilterTransactionHistory>({});
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize]: any = useState(10)
    const [dataSell, setDataSell]: any = useState([])
    const [totalItem, setTotalItem]: any = useState(0)
    const contractApi: any = dataDetail && dataDetail?.contractAddress
    const contractMarket: any = useContract(contractApi, nftMarketABI)
    const [loadingPid, setLoadingPid] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [itemCancel, setItemCancel]: any = useState()
    const [selectedOrders, setSelectedOrders]: any = useState([]);
    const [orderData, setOrderData] = useState([])

    const getDataMarket = async () => {
        try {
            let res = await AppHome.getDataSellMarket(dataDetail?.code);
            if (res.data.success === true) {
                setDataSell(res.data.data);
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    const showModal = (item: any) => {
        setItemCancel(item)
        setIsModalOpen(true);
        setSelectedOrders((prev: any) => {
            const orders = item?.orders.map((order: any) => order.orderId);
            return prev.filter((id: any) => !orders.includes(id)).concat(orders);
        });

        const tokenOrderPairs = item?.orders.map((order: any) => ({
            tokenId: order.tokenId,
            orderId: order.orderId,
        }));
        setOrderData(tokenOrderPairs);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const getDataTransaction = async () => {
        try {
            let res = await AppHome.getDataTransaction(dataDetail?.code, 10, 0, -1, address);
            if (res.data.success === true) {
                setDataTx(res.data.data);
                setTotalItem(res.data.totalCount)
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    useEffect(() => {
        if (address) {
            getDataTransaction()
        }
    }, [address]);

    const handleCancelPid = async () => {
        setLoadingPid(true)
        try {
            if (selectedOrders.length > 0) {
                await contractMarket?.cancelListingBatch(selectedOrders).then(async (res: any) => {
                    const params = {
                        ownerAddress: address,
                        code: dataDetail?.code,
                        orders: orderData,
                        txHash: res?.hash
                    };

                    try {
                        const resApi = await AppHome.postDataCancelMarket(params);
                    } catch (error) {
                        console.log("Error in API call: ", error);
                    }
                    res.wait().then(async (resWait: any) => {
                        if (resWait?.status === 1) {
                            Noti('success', "Cancel NFT successfully", 3000)
                            getDataMarket()
                            setLoadingPid(false)
                            setIsModalOpen(false);
                            window.location.reload()
                        } else {
                            Noti('error', "Unknown error (status hash fail)", 3000)
                            setLoadingPid(false)
                            setIsModalOpen(false);
                        }
                    })
                })
                    .catch((err: any) => {
                        console.log('err', err)
                        setLoadingPid(false)
                        setIsModalOpen(false);
                        Noti('error', err?.reason || err?.message || "Unknown error", 3000)
                    })
            } else {
                Noti('error', "orderId not found", 3000)
            }
        } catch (error: any) {
            setLoadingPid(false)
            setIsModalOpen(false);
            if (error.code === ethers.errors.CALL_EXCEPTION) {
                Noti('error', error.error?.data?.message || "Unknown error", 3000)
            } else {
                Noti('error', error || "Unknown error", 3000)
            }
        }
    }

    const onChange = async (page: any) => {
        setCurrent(page);
        try {
            let res = await AppHome.getDataTransaction(dataDetail?.code, 10, Number(page) - 1, -1, address);
            if (res.data.success === true) {
                setDataTx(res.data.data);
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    const FilterTransaction = ({
        setIsOpenFilter,
    }: {
        data: FilterTransactionHistory;
        setData: Dispatch<SetStateAction<FilterTransactionHistory>>;
        setIsOpenFilter: Dispatch<SetStateAction<boolean>>;
    }) => {
        const [data, setData] = useState({
            buysBroadcasted: false,
            sellOrdersPlaced: false,
            canceled: false,
        });
    
        const handleResetForm = async () => {
            setData({
                buysBroadcasted: false,
                sellOrdersPlaced: false,
                canceled: false,
            });
            let res = await AppHome.getDataTransaction(dataDetail?.code, 10, 0, -1, address);
            if (res.data.success === true) {
                setDataTx(res.data.data);
                setIsOpenFilter(false);
            }
        };
    
        const handleChangeCheckbox = (field: string) => {
            setData({
                buysBroadcasted: field === "buysBroadcasted",
                sellOrdersPlaced: field === "sellOrdersPlaced",
                canceled: field === "canceled",
            });
        };
    
        const handleSaveForm = async () => {
            try {
                if (address) {
                    if (data?.buysBroadcasted) {
                        let res = await AppHome.getDataTransaction(dataDetail?.code, 10, 0, 1, address);
                        if (res.data.success === true) {
                            setDataTx(res.data.data);
                        }
                    } else if (data?.sellOrdersPlaced) {
                        let res = await AppHome.getDataTransaction(dataDetail?.code, 10, 0, 0, address);
                        if (res.data.success === true) {
                            setDataTx(res.data.data);
                        }
                    } else if (data?.canceled) {
                        let res = await AppHome.getDataTransaction(dataDetail?.code, 10, 0, 2, address);
                        if (res.data.success === true) {
                            setDataTx(res.data.data);
                        }
                    }
                }
            } catch (error) {
                console.log("Error: ", error);
            }
            setIsOpenFilter(false);
        };
    
        return (
            <div className="w-[333px] font-radioCanada">
                <div className="p-3 flex gap-x-2.5 items-center border-b border-b-[#3B3F45]">
                    <img
                        width={16}
                        height={16}
                        src="/images/marketplace/filter.png"
                        alt=""
                    />
                    <div className="text-white">Filter By</div>
                </div>
                <div className="p-3">
                    <div className="flex flex-col">
                        <div className="p-2 flex gap-x-2.5 items-center">
                            <Checkbox
                                onChange={() => handleChangeCheckbox("buysBroadcasted")}
                                checked={data?.buysBroadcasted}
                            />
                            <div className="text-[14px] text-white font-medium">
                                Buys
                            </div>
                        </div>
                        <div className="p-2 flex gap-x-2.5 items-center">
                            <Checkbox
                                onChange={() => handleChangeCheckbox("sellOrdersPlaced")}
                                checked={data?.sellOrdersPlaced}
                            />
                            <div className="text-[14px] text-white font-medium">
                                Sell
                            </div>
                        </div>
                        <div className="p-2 flex gap-x-2.5 items-center">
                            <Checkbox
                                onChange={() => handleChangeCheckbox("canceled")}
                                checked={data?.canceled}
                            />
                            <div className="text-[14px] text-white font-medium">
                                Canceled
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 flex justify-end items-center gap-x-3">
                        <Button
                            onClick={handleResetForm}
                            size="small"
                            className="border border-primary !bg-transparent h-[31px] w-[91px] text-white font-radioCanada"
                        >
                            Reset
                        </Button>
                        <Button
                            onClick={handleSaveForm}
                            type="primary"
                            size="small"
                            className="h-[31px] w-[91px] text-white font-radioCanada"
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        );
    };
    

    const Item = ({ item }: any) => {
        const [isCanceled, setIsCanceled] = useState()
        const orders = item?.orders.map((order: any) => order.orderId);
        const getCancelPid = async () => {
            if (contractMarket && address && orders.length > 0) {
                let isCancel = await contractMarket.getListing(item?.orders[0]?.orderId)
                console.log('isCancel', isCancel)
                setIsCanceled(isCancel[2])
            }
        }

        
        console.log('item?.orders', item?.orders)

        useEffect(() => {
            getCancelPid()
        }, [address , contractMarket]);

        function gotoHash(txHash: any): void {
            window.open(`${BASE_SCAN}/tx/${txHash}`)
        }

        return (
            <>
                <tr>
                    <td>
                        {item?.time_ui}
                    </td>
                    <td>
                        <div
                            className={clsx(
                                "text-[16px] font-radioCanada font-medium",
                                item?.numType === 4 ? "text-[#00D415]" : item?.numType === 5 ? "text-[#a2a0a0]" : "text-[#B43C3C]",
                            )}
                        >
                            {item?.type}
                        </div>
                    </td>
                    <td>
                        {item?.amount}
                    </td>
                    <td>
                        {new Intl.NumberFormat('en-US').format(item?.price)}
                    </td>
                    <td>
                        {new Intl.NumberFormat('en-US').format(item?.totalPrice)}$
                    </td>
                    <td>
                        <div className="text-[16px] font-radioCanada font-medium text-[#3CB696]">
                            Success
                        </div>
                    </td>
                    <td>
                        {item?.orders?.length > 0 ?
                            <>
                                <div className="button-cancel">
                                    {!isCanceled ?
                                        <>
                                            <div className="img-share" onClick={() => gotoHash(item?.txHash)}>
                                                <img src="/images/Share.png" alt="" />
                                            </div>
                                        </>
                                        :
                                        <>
                                            <button className="btn-cancel" onClick={() => showModal(item)}>
                                                Cancel
                                            </button>
                                        </>
                                    }

                                </div>
                            </>
                            :
                            <>
                                <div className="img-share" onClick={() => gotoHash(item?.txHash)}>
                                    <img src="/images/Share.png" alt="" />
                                </div>
                            </>
                        }
                    </td>
                </tr>
            </>
        )
    }

    const ItemMobile = ({ item }: any) => {
        const [isCanceled, setIsCanceled] = useState()
        const orders = item?.orders.map((order: any) => order.orderId);

        const getCancelPid = async () => {
            if (contractMarket && address && orders.length > 0) {
                let isCancel = await contractMarket.getListing(orders)
                setIsCanceled(isCancel[2])
            }
        }

        useEffect(() => {
            getCancelPid()
        }, []);

        function gotoHash(txHash: any): void {
            window.open(`${BASE_SCAN}/tx/${txHash}`)
        }
        return (
            <>
                <div className="content-item-mobile-mk">
                    <div className="item">
                        <div className="txt-l">
                            Time (UTC)
                        </div>
                        <div className="txt-r">
                            {item?.time_ui}
                        </div>
                    </div>
                    <div className="item">
                        <div className="txt-l">
                            Type
                        </div>
                        <div className="txt-r">
                            <div
                                className={clsx(
                                    "text-[16px] font-radioCanada font-medium",
                                    item?.numType === 4 ? "text-[#00D415]" : item?.numType === 5 ? "text-[#a2a0a0]" : "text-[#B43C3C]",
                                )}
                            >
                                {item?.type}
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="txt-l">
                            Total NFTs
                        </div>
                        <div className="txt-r">
                            {item?.amount}
                        </div>
                    </div>
                    <div className="item">
                        <div className="txt-l">
                            Price (USDC)
                        </div>
                        <div className="txt-r">
                            {new Intl.NumberFormat('en-US').format(item?.price)}
                        </div>
                    </div>
                    <div className="item">
                        <div className="txt-l">
                            Total Price
                        </div>
                        <div className="txt-r">
                            {new Intl.NumberFormat('en-US').format(item?.totalPrice)}$
                        </div>
                    </div>
                    <div className="item">
                        <div className="txt-l">
                            Status
                        </div>
                        <div className="txt-r">
                            <div className="text-[16px] font-radioCanada font-medium text-[#3CB696]">
                                Success
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="txt-l">
                            Actions
                        </div>
                        <div className="txt-r">
                            {item?.orders?.length > 0 ?
                                <>
                                    <div className="button-cancel">
                                        {isCanceled === true ?
                                            <>
                                                <div className="img-share" onClick={() => gotoHash(item?.txHash)}>
                                                    <img src="/images/Share.png" alt="" />
                                                </div>
                                            </>
                                            :
                                            <>
                                                <button className="btn-cancel" onClick={() => showModal(item)}>
                                                    Cancel
                                                </button>
                                            </>
                                        }

                                    </div>
                                </>
                                :
                                <>
                                    <div className="img-share" onClick={() => gotoHash(item?.txHash)}>
                                        <img src="/images/Share.png" alt="" />
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <Popover
                trigger="click"
                placement="bottom"
                open={isOpenFilter}
                onOpenChange={setIsOpenFilter}
                content={
                    <FilterTransaction
                        setIsOpenFilter={setIsOpenFilter}
                        data={filterData}
                        setData={setFilterData}
                    />
                }
            >
                <div className="positon-page flex items-center rounded-[8px] px-4 py-1.5 bg-[#3B3F45] border border-[#3B3F45] text-sm text-white font-medium gap-x-2.5 hover:cursor-pointer transition hover:border-primary hover:bg-primary/20 text-[14px]">
                    <img
                        width={24}
                        height={24}
                        src="/images/marketplace/filter.png"
                        alt=""
                    />
                    <span>Filters</span>
                </div>
            </Popover>
            {isMobile ?
                <>
                    {dataTx.length > 0 ? (
                        <>
                            <div className="row-mobile-list-mk">
                                {dataTx.map((item: any, index: any) => (
                                    <ItemMobile item={item} />
                                ))}
                            </div>
                            {totalItem > 10 ?
                                <>
                                    <div className="pagination-his">
                                        <Pagination current={current} defaultPageSize={pageSize} onChange={onChange} total={totalItem} />
                                    </div>
                                </>
                                :
                                <>
                                    {""}
                                </>
                            }
                        </>
                    ) : (
                        <div className="box-no-data-stake">
                            <img className='img-empty' src="/images/empty1.png" alt="" />
                        </div>
                    )}
                </>
                :
                <>
                    <div className="main-table-row">
                        <table cellSpacing="0" className="main-table table-market">
                            <tr>
                                <th>
                                    Time (UTC)
                                </th>
                                <th>
                                    Type
                                </th>
                                <th>
                                    Total NFTs
                                </th>
                                <th>
                                    Price (USDC)
                                </th>
                                <th>
                                    Total Price
                                </th>
                                <th>
                                    Status
                                </th>
                                <th>
                                    Actions
                                </th>
                            </tr>
                            {dataTx.length > 0 ? (
                                <>
                                    {dataTx.map((item: any, index: any) => (
                                        <Item item={item} />
                                    ))}
                                </>
                            ) : (
                                <tr>
                                    <td className="nodata" colSpan={7}>
                                        <div className="box-no-data-stake">
                                            <img className='img-empty' src="/images/empty1.png" alt="" />
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </table>
                        {totalItem > 10 ?
                            <>
                                <div className="pagination-his">
                                    <Pagination current={current} defaultPageSize={pageSize} onChange={onChange} total={totalItem} />
                                </div>
                            </>
                            :
                            <>
                                {""}
                            </>
                        }
                    </div>
                </>
            }
            <Modal className="modal-purchase" maskClosable={false} title="Confirm Cancel" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div className="confirm-box">
                    <div className="box-img">
                        <img src="/images/lending/pur.png" alt="" />
                    </div>
                    <div className="title">
                        Confirm your cancel
                    </div>
                    <div className="guide-confirm-project">
                        <div className="name-pr">
                            {dataDetail?.name}
                        </div>
                        <div className="item">
                            <div className="txt-l">
                                Quantity:
                            </div>
                            <div className="txt-r">
                                {itemCancel?.amount}
                            </div>
                        </div>
                        <div className="item">
                            <div className="txt-l">
                                Price:
                            </div>
                            <div className="txt-r">
                                {new Intl.NumberFormat('en-US').format(itemCancel?.price)}$
                            </div>
                        </div>
                        <div className="item">
                            <div className="txt-l">
                                Total Price:
                            </div>
                            <div className="txt-r">
                                {new Intl.NumberFormat('en-US').format(itemCancel?.totalPrice)} USDC
                            </div>
                        </div>
                    </div>
                    <div className="group-button">
                        {loadingPid ?
                            <>
                                <button className="btn-actions dis">
                                    Confirm <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                                </button>
                            </>
                            :
                            <>
                                <button onClick={handleCancelPid} className="btn-actions">
                                    Confirm
                                </button>
                            </>
                        }
                    </div>
                </div>
            </Modal>
        </>
    );
};
