import React from "react";

export const NFTDetailTab = (data: any) => {

  const renderValue = (key: any) => {
    let checkLink = key.includes('http')
    if (checkLink) {
      return <a className="link-a-cus" href={key} target="_blank">
        {key.slice(0, 15)}...${key.slice(-10)}
      </a>

    } else {
      return key
    }

  }

  return (
    <div className="content-details cus-tab-pto">
      <div className="list-detail-info mar-top-20 res-page">
        <div className="row-info">
          {data?.data?.length > 0 ? (
            <>
              {data?.data?.map((item: any, index: any) => (
                <>
                  <div className="item">
                    <div className="row-left">
                      <div className="box-img-info">
                        <img src={item?.iconUrl} alt="" />
                      </div>
                    </div>
                    <div className="row-right">
                      <div className="txt-top">
                        {item?.name}
                      </div>
                      <div className="txt-bottom">
                        {renderValue(item?.value)}

                      </div>
                    </div>
                  </div>
                </>
              ))}
            </>
          ) : (
            <div className="box-no-data-pto">
              <img className='img-empty' src="/images/empty1.png" alt="" />
            </div>
          )}

        </div>
      </div>
    </div>
  );
};
