import React, { useCallback, useEffect, useState } from "react";
import { useAccount } from "wagmi";
import debounce from 'lodash.debounce';
import { Dropdown, Pagination, Menu } from 'antd';
import { BASE_SCAN } from "../../../constants";
import EmptyConnect from "../../../components/empty-connect";
import { useHookAccount } from "../porfolio/storeAccount";
import "../porfolio/style.scss"
import './style.css'
import "../p2p-lending/style.scss"
import { isMobile } from "react-device-detect";

const PENDING = 1
const SUCCESS = 2
const FAIL = 3

const HistoryPage = () => {
    const { address } = useAccount();
    const [state, actions] = useHookAccount()
    const [current, setCurrent] = useState(1);
    const [listHis, setListHis]: any = useState([])
    const [textType, settextType]: any = useState(0)
    const [textType1, settextType1]: any = useState(0)
    const [pageSize, setPageSize]: any = useState(10)
    const [totalItem, setTotalItem]: any = useState(0)
    const [valueSearch, setValueSearch]: any = useState("")
    const [loadingData, setloadingData]: any = useState(true)
    const [isClick, setIsClick]: any = useState(false)

    function gotoHash(txHash: any): void {
        window.open(`${BASE_SCAN}/tx/${txHash}`)
    }

    function renderText(status: any) {
        switch (status) {
            case PENDING:
                return "Pending"
            case SUCCESS:
                return "Success"
            case FAIL:
                return "Fail"
            default:
                return '--'
        }
    }

    function renderClass(status: any) {
        switch (status) {
            case PENDING:
                return 'status-pending'
            case SUCCESS:
                return 'status-success'
            case FAIL:
                return 'status-fail'
            default:
                return "status-pending"
        }

    }

    const onChange = (page: any) => {
        setloadingData(true)
        setCurrent(page);
    };

    const items: any = [
        {
            label: <div onClick={() => onClickType(0)} className="type-his all">All Status</div>,
            key: '0',
        },
        {
            label: <div onClick={() => onClickType(2)} className="type-his success">Success</div>,
            key: '1',
        },
        {
            label: <div onClick={() => onClickType(1)} className="type-his pending">Pending</div>,
            key: '2',
        },
        {
            label: <div onClick={() => onClickType(3)} className="type-his fail">Fail</div>,
            key: '3',
        },
    ];

    const onClickType = (type: any) => {
        setloadingData(true)
        settextType(type)
        setIsClick(!isClick)
        setCurrent(1)
    }
    const onClickType1 = (type: any) => {
        setloadingData(true)
        settextType1(type)
        setIsClick(!isClick)
        setCurrent(1)
    }

    const renderClassType = (value: any) => {
        switch (value) {
            case 0:
                return ""
            case 2:
                return "bg-success"
            case 1:
                return "bg-pending"
            case 3:
                return "bg-fail"
            default:
                return ""
        }
    }
    const renderType = (value: any) => {
        switch (value) {
            case 0:
                return "All Status"
            case 1:
                return "Pending"
            case 2:
                return "Success"
            case 3:
                return "Fail"
            default:
                return "All Status"
        }
    }

    const renderType1 = (value: any) => {
        switch (value) {
            case 0:
                return "All Type"
            case 1:
                return "Staking"
            case 2:
                return "Loans"
            case 3:
                return "Vesting"
            case 4:
                return "PTO"
            default:
                return "All Type"
        }
    }

    const menu = (
        <Menu>
            <Menu.Item>
                <div onClick={() => onClickType1(0)} className="type-his all">All type</div>
            </Menu.Item>
            <Menu.Item>
                <div onClick={() => onClickType1(1)} className="type-his all">Staking</div>
            </Menu.Item>
            <Menu.Item>
                <div onClick={() => onClickType1(2)} className="type-his all">Loans</div>
            </Menu.Item>
            <Menu.Item>
                <div onClick={() => onClickType1(3)} className="type-his all">Vesting</div>
            </Menu.Item>
            <Menu.Item>
                <div onClick={() => onClickType1(4)} className="type-his all">PTO</div>
            </Menu.Item>
        </Menu>
    );

    const getListHis = async (value: any) => {
        try {
            let params = {
                ownerAddress: address || '',
                pageSize: pageSize,
                pageIndex: current - 1,
                keySearch: value,
                type: textType1,
                status: textType
            }

            await actions.getHistory(params).then((res: any) => {
                setListHis(res?.data)
                setTotalItem(res?.totalCount)
                setloadingData(false)
            })
                .catch((err: any) => {
                    console.log('err', err)
                    setListHis([])
                    setloadingData(false)
                })
        } catch (error) {
            console.log('error', error)
            setListHis([])
            setloadingData(false)
        }
    }

    useEffect(() => {
        if (address) {
            getListHis(valueSearch)
        } else {
            setloadingData(false)
            setListHis([])
        }
    }, [address, current, isClick, textType1, textType])

    function handleSearch(e: any) {
        setloadingData(true)
        setValueSearch(e?.target?.value)
        debouncedSearch(e?.target?.value);
    }
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (address) {
                getListHis(valueSearch)
            } else {
                setloadingData(false)
                setListHis([])
            }
        }
    };

    const handleSearchCall = (value: any) => {
        if (address) {
            getListHis(value)
        } else {
            setloadingData(false)
            setListHis([])
        }
    };

    const debouncedSearch: any = useCallback(debounce(handleSearchCall, 1000), [address]);

    useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);
    
    return (
        <>
            <div className="section-grow mar-b-100-p2p position-relative">
                {address === null || !address ?
                    <>
                        <EmptyConnect />
                    </>
                    :
                    <>
                        <div className="grow-wrap">
                            <div className="wrap-left his">
                                {isMobile ? <div className="title-mobile-respage">History</div> : ""}
                                <div className="list-filter-profile res res-mobile">
                                    <div className="row-right">
                                        <div className="row-search">
                                            <input onKeyDown={handleKeyDown} value={valueSearch} onChange={(e) => handleSearch(e)} placeholder="Search by TxID" type="text" />
                                            <img src="/images/user/search.png" alt="" />
                                        </div>
                                        <div className="group-his-left">
                                            <div className="status-filter">
                                                <Dropdown
                                                    className={`btn-status`}
                                                    overlayClassName="dropdown-type"
                                                    overlay={menu}
                                                    trigger={['click']}
                                                    placement="bottomRight"
                                                >
                                                    <a onClick={(e) => e.preventDefault()}>
                                                        {renderType1(textType1)}
                                                    </a>
                                                </Dropdown>
                                            </div>
                                            <div className="status-filter">
                                                <Dropdown
                                                    className={`btn-status ${renderClassType(textType)}`}
                                                    overlayClassName="dropdown-type"
                                                    menu={{
                                                        items
                                                    }}
                                                    placement="bottomRight"
                                                    trigger={['click']}
                                                >
                                                    <a onClick={(e) => e.preventDefault()}>
                                                        {renderType(textType)}
                                                    </a>
                                                </Dropdown>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                {isMobile ?
                                    <>
                                        <div className="list-mobile-history">
                                            {listHis.length > 0 ? (
                                                <>
                                                    {listHis.map((item: any, index: any) => (
                                                        <>
                                                            <div className="row-item-mobile">
                                                                <div className="item">
                                                                    <div className="txt-l">
                                                                        Time
                                                                    </div>
                                                                    <div className="txt-right">
                                                                        {item?.date}
                                                                    </div>
                                                                </div>
                                                                <div className="item">
                                                                    <div className="txt-l">
                                                                        Type
                                                                    </div>
                                                                    <div className="txt-right">
                                                                        {item?.type}
                                                                    </div>
                                                                </div>
                                                                <div className="item">
                                                                    <div className="txt-l">
                                                                        Amount
                                                                    </div>
                                                                    <div className="txt-right">
                                                                        {item?.amountType === 1 ? (
                                                                            <span className="main-color-amount">+{item?.amount}</span>
                                                                        ) : item?.amountType === -1 ? (
                                                                            <span className="main-color-negative">-{item?.amount}</span>
                                                                        ) : (
                                                                            <>--</>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="item">
                                                                    <div className="txt-l">
                                                                        Asset
                                                                    </div>
                                                                    <div className="txt-right">
                                                                        {item?.asset}
                                                                    </div>
                                                                </div>
                                                                <div className="item">
                                                                    <div className="txt-l">
                                                                        TxID
                                                                    </div>
                                                                    <div className="txt-right">
                                                                        <div className="row-link" onClick={() => gotoHash(item?.txHash)}>
                                                                            {item?.txHash.slice(0, 5)}...{item?.txHash.slice(-5)} <img src="/images/user/link.png" alt="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="item">
                                                                    <div className="txt-l">
                                                                        Status
                                                                    </div>
                                                                    <div className="txt-right">
                                                                        <div className={renderClass(item?.status)}>
                                                                            {renderText(item?.status)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))}
                                                    {totalItem > pageSize ? (
                                                        <tr>
                                                            <td colSpan={6}>
                                                                <div className="pagination-his">
                                                                    <Pagination current={current} defaultPageSize={pageSize} onChange={onChange} total={totalItem} />
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    ) : (
                                                        ''
                                                    )}
                                                </>
                                            ) : (
                                                <div className="box-no-data-stake">
                                                    <img className='img-empty' src="/images/no-data-cus.png" alt="" />
                                                </div>
                                            )}
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="main-table-row">
                                            <table cellSpacing="0" className="main-table">
                                                <tr>
                                                    <th>
                                                        Time
                                                    </th>
                                                    <th>
                                                        Type
                                                    </th>
                                                    <th>
                                                        Amount
                                                    </th>
                                                    <th>
                                                        Asset
                                                    </th>

                                                    <th>
                                                        TxID
                                                    </th>
                                                    <th>
                                                        Status
                                                    </th>
                                                </tr>
                                                {loadingData ? (
                                                    <tr>
                                                        <td className="nodata-loading" colSpan={6}>
                                                            <div className="load-3">
                                                                <div className="line"></div>
                                                                <div className="line"></div>
                                                                <div className="line"></div>
                                                            </div>
                                                        </td>

                                                    </tr>
                                                ) : (
                                                    <>
                                                        {listHis.length > 0 ? (
                                                            <>
                                                                {listHis.map((item: any, index: any) => (
                                                                    <tr>
                                                                        <td>
                                                                            {item?.date}
                                                                        </td>
                                                                        <td>
                                                                            {item?.type}
                                                                        </td>
                                                                        <td>
                                                                            {item?.amountType === 1 ? (
                                                                                <span className="main-color-amount">+{item?.amount}</span>
                                                                            ) : item?.amountType === -1 ? (
                                                                                <span className="main-color-negative">-{item?.amount}</span>
                                                                            ) : (
                                                                                <>--</>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {item?.asset}
                                                                        </td>

                                                                        <td>
                                                                            <div className="row-link" onClick={() => gotoHash(item?.txHash)}>
                                                                                {item?.txHash.slice(0, 5)}...{item?.txHash.slice(-5)} <img src="/images/user/link.png" alt="" />
                                                                            </div>
                                                                        </td>
                                                                        <td>

                                                                            <div className={renderClass(item?.status)}>
                                                                                {renderText(item?.status)}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                                {totalItem > pageSize ? (
                                                                    <tr>
                                                                        <td colSpan={6}>
                                                                            <div className="pagination-his">
                                                                                <Pagination current={current} defaultPageSize={pageSize} onChange={onChange} total={totalItem} />
                                                                            </div>
                                                                        </td>

                                                                    </tr>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </>
                                                        ) : (
                                                            <tr>
                                                                <td className="nodata" colSpan={6}>
                                                                    <div className="box-no-data-stake">
                                                                        <img className='img-empty' src="/images/no-data-cus.png" alt="" />
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        )}
                                                    </>
                                                )}
                                            </table>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
}
export default HistoryPage