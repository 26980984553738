import React, { Dispatch, SetStateAction, useState } from "react";
import { isMobile } from "react-device-detect";

export const OverviewTab = (props: any) => {
    const dataDetail = props?.dataDetail
    
    return (
        <>
            <div className="font-medium text-white">Description</div>
            <div className="mt-3 text-white/40 text-[14px]">
                {dataDetail?.description}
                
            </div>
            <div className="mt-5">
                <div className={isMobile ? "left-details res-mobile-content" : "left-details"}>
                    <div className="content-details">
                        <div className="list-detail-info">
                            <div className="row-info">
                                {dataDetail && dataDetail?.properties?.map((item: any, index: any) => (
                                    <>
                                        <div className="item">
                                            <div className="row-left">
                                                <div className="box-img-info">
                                                    <img src={item?.iconUrl} alt="" />
                                                </div>
                                            </div>
                                            <div className="row-right">
                                                <div className="txt-top">
                                                    {item?.name}
                                                </div>
                                                <div className="txt-bottom">
                                                    {item?.value}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
