import { useNavigate } from "react-router-dom";

const Offer = () => {
  const naigation = useNavigate();

  const gotoStaking = () => {
    naigation("/apps/staking");
  };
  const gotoP2P = () => {
    naigation("/apps/p2p-lending");
  };
  const gotoPTO = () => {
    naigation("/apps/pto");
  };
  const gotoPortfolio = () => {
    naigation("/marketplace");
  };

  return (
    <div className="wrap-offer" id="feature">
      <div className="container">
        <div
          className="offer-top"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <div className="img-offer">
            <img
              className="op-offer"
              src="./images/landing/img-offer.png"
              alt=""
            />
            <div className="content-offer-top">
              <div className="icon-offer">
                <img src="./images/landing/icon-offer.png" alt="" />
              </div>
              <div className="title-offer">What We Offer</div>
              <div className="dec-offer">
                Invest in real estate with ease and flexibility. MeyFi harnesses
                blockchain technology to make property investment accessible,
                liquid, and secure for everyone.
              </div>
            </div>
          </div>
        </div>
        <div className="flow-body">
          <div className="list-flow">
            <div
              className="item-flow"
              data-aos="zoom-in"
              data-aos-duration="1200"
              onClick={gotoStaking}
            >
              <div className="item-flow-left">
                <div className="flow-num">
                  <img src="./images/landing/offer-item-img11.png" alt="" />
                </div>
                <div className="flow-title">Mey Staking</div>
                <div className="flow-dec">
                  Earn rewards by staking your Mey tokens. Receive exclusive
                  NFTs and enjoy premium benefits across the MeyFi ecosystem.
                </div>
                <div className="flow-button" onClick={gotoStaking}>
                  <span>Get started</span>
                  <img
                    className="icon-boost"
                    src="./images/landing/c-r.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="item-flow-right">
                <img
                  lazy-src="/images/landing/flow-connect1.png"
                  src="/images/landing/flow-connect1.png"
                  alt=""
                />
                {/* <img src="/images/landing/flow-connect.png" alt="" /> */}
              </div>
            </div>

            <div
              className="item-flow cus"
              data-aos="zoom-in"
              data-aos-duration="1400"
              onClick={gotoP2P}
            >
              <div className="item-flow-left">
                <div className="flow-num">
                  <img src="./images/landing/o-item-img.png" alt="" />
                </div>
                <div className="flow-title">P2p Lending</div>
                <div className="flow-dec">
                  Tokenize your real estate and use it as collateral. Lend or
                  borrow securely with attractive yields.
                </div>
                <div className="flow-button" onClick={gotoP2P}>
                  <span>Get started</span>
                  <img
                    className="icon-boost"
                    src="./images/landing/c-r.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="item-flow-right">
                <img src="/images/landing/offer-item-img12.png" alt="" />
              </div>
            </div>
            <div
              className="item-flow"
              data-aos="zoom-in"
              data-aos-duration="1600"
              onClick={gotoPTO}
            >
              <div className="item-flow-left">
                <div className="flow-num">
                  <img src="./images/landing/o-item-img1.png" alt="" />
                </div>
                <div className="flow-title">Prop Token Offering (PTO)</div>
                <div className="flow-dec">
                  Invest in fractional ownership of real estate. Start with
                  small amounts and grow your portfolio.{" "}
                </div>
                <div className="flow-button" onClick={gotoPTO}>
                  <span>Get started</span>
                  <img
                    className="icon-boost"
                    src="./images/landing/c-r.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="item-flow-right">
                <img src="/images/landing/offer-item-img13.png" alt="" />
              </div>
            </div>
            <div
              className="item-flow cus"
              data-aos="zoom-in"
              data-aos-duration="1800"
              onClick={gotoPortfolio}
            >
              <div className="item-flow-left">
                <div className="flow-num">
                  <img src="./images/landing/o-item-img2.png" alt="" />
                </div>
                <div className="flow-title">MeyFi Marketplace</div>
                <div className="flow-dec">
                  Trade tokenized real estate assets with ease. Our marketplace
                  offers real-time pricing and continuous liquidity.{" "}
                </div>
                <div className="flow-button" onClick={gotoPortfolio}>
                  <span>Get started</span>
                  <img
                    className="icon-boost"
                    src="./images/landing/c-r.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="item-flow-right">
                <img src="/images/landing/offer-item-img14.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="offer-list">
                    <div className="offer-list1">
                        <div className="offer-item-big">
                            <div className="offer-item-name">Mey Staking</div>
                            <div className="offer-item-dec">
                                Earn rewards by staking your Mey tokens. Receive exclusive NFTs and enjoy <br /> premium benefits across the MeyFi ecosystem.
                            </div>
                            <div className="offer-item-logo" >
                                <img src="./images/landing/offer-item-img.png" alt="" />
                            </div>
                            <img className="offer-item-bg" src="./images/landing/offer-item-bg.png" alt="" />
                        </div>

                        <div className="offer-item-small">
                            <div className="offer-item-logo1">
                                <img src="./images/landing/offer-item-img1.png" alt="" />
                            </div>
                            <div className="offer-item-name">P2P Lending</div>
                            <div className="offer-item-dec">
                                Tokenize your real estate and use it as collateral.<br /> Lend or borrow securely with attractive yields.
                            </div>
                        </div>
                    </div>
                    <div className="offer-list1">
                        <div className="offer-item-small new">
                            <div className="offer-item-logo1">
                                <img src="./images/landing/offer-item-img2.png" alt="" />
                            </div>
                            <div className="offer-item-name">Prop Token Offering (PTO)</div>
                            <div className="offer-item-dec">
                                Invest in fractional ownership of real estate. Start with small amounts and grow your portfolio.
                            </div>

                        </div>
                        <div className="offer-item-big">
                            <div className="offer-item-name">MeyFi Marketplace</div>
                            <div className="offer-item-dec">
                                Trade tokenized real estate assets with ease. Our marketplace offers real-time pricing and continuous liquidity.
                            </div>
                            <div className="offer-item-logo">
                                <img src="./images/landing/offer-item-bg1.png" alt="" />
                            </div>
                            <img className="offer-item-bg" src="./images/landing/offer-item-bg.png" alt="" />
                        </div>

                    </div>
                </div> */}
      </div>
    </div>
  );
};
export default Offer;
