import React from "react";
import { Col, Collapse, Row } from 'antd';

const Faqs = () => {

    const { Panel } = Collapse;

    return (
        <>
            <div className="collapse-faq faq-mobie">
                <div className="title faq-pto">
                    Frequently Asked Questions
                </div>
                <Row gutter={16}>
                    <Col span={12}>
                        <Collapse accordion>
                            <Panel header="What is MeyFi ?" key="1">
                                <p>
                                    MeyFi is the decentralized finance (DeFi) platform within the Mey Network, empowering users to unlock the potential of real-world assets (RWAs). By leveraging blockchain technology, MeyFi enables you to stake, lend, and trade tokenized assets, providing seamless access to new investment opportunities.
                                    <br />
                                    Features:<br />
                                    -Staking: Stake tokenized real-world assets, such as fractionalized real estate, to earn rewards in Meycoin (MEY).
                                    <br />
                                    -P2P Lending: Participate in peer-to-peer lending where borrowers can use tokenized real estate as collateral, and lenders can earn interest on loans.
                                    <br />
                                    -PTO (Property Token Offering): Purchase fractional ownership of tokenized real estate through property token offerings, giving investors the opportunity to invest in real estate at lower costs.
                                    <br />
                                    -Asset Trading: Buy, sell, and trade fractional ownership of tokenized real estate and other RWAs, improving liquidity and access to global markets.
                                </p>
                            </Panel>
                            <Panel header="Can I Change My Wallet Address?" key="2">
                                <p>
                                    No, you cannot directly change your wallet address. However, MeyFi can offer assistance if you manually submit a request. After your request is submitted, MeyFi will see what could be done.
                                </p>
                            </Panel>
                            <Panel header="What is the Minimum Investment?" key="3">
                                <p>
                                    The minimum investment required is $100, which corresponds to one property token. This token represents a fraction of the property’s value, and your yield will be proportional to the total property value.
                                </p>
                            </Panel>
                            <Panel header="How is the Value of Property Tokens Determined?" key="4">
                                <p>The value of the property tokens is based on dividing the total property value into $100 increments, with an equal number of NFTs minted. The number of NFTs remains fixed, and no tokens can be burned. As the property's value appreciates or demand for the tokens rises on the secondary market, the price of the property NFT increases.

                                </p>
                            </Panel>
                            <Panel header="Are There Any Holding Periods or Trading Restrictions?" key="5">
                                <p>
                                    There is no mandatory holding period before trading property tokens once the title deed has been transferred. However, trading on the MeyFi Marketplace requires a secondary marketplace, which is still under development. Trading will commence once the marketplace is live, although this timeline may be adjusted if additional development time is needed after the title deed transfer.
                                </p>
                            </Panel>
                            <Panel header="What Happens if I Need to Liquidate My Investment ?" key="6">
                                <p>
                                    MeyFi will purchase back property tokens at any time and for any reason for the original invested amount, less a 1% processing fee. The process requires 10 working days to complete from the initial request being made. The request can be made on the user dashboard.
                                </p>
                            </Panel>
                        </Collapse>
                    </Col>
                    <Col span={12}>
                        <Collapse accordion>
                            <Panel header="Who Manages the Property ?" key="7">
                                <p>
                                    Meey Finance manages the property.
                                </p>
                            </Panel>
                            <Panel header="Are Property Tokens Securities or Utility Tokens ?" key="8">
                                <p>
                                    While MeyFi generally considers property tokens as securities because they represent fractional ownership in real estate assets, the regulatory framework of DAO LLCs does not specifically classify them as such. This is because governance participation by token holders is required. In a DAO LLC, token holders actively participate in governance decisions, such as voting on proposals and changes to the organization. This active participation distinguishes DAO LLC tokens from traditional securities, where investors typically have a more passive role.
                                    DAO LLCs, which manage these tokens, are less regulated compared to traditional corporate structures. This provides more flexibility in the management and operation of the tokenized properties while still ensuring that ownership and transactions are transparently recorded on the blockchain. This structure offers a balanced approach, combining the benefits of decentralized ownership with the necessary regulatory compliance to protect investors.
                                </p>
                            </Panel>
                            <Panel header="What Legal Protections are in Place for Property Token Investors?" key="9">
                                <p>
                                    Investments on MeyFi are structured to provide robust legal protections for property token investors. Here are some key legal safeguards:
                                    <br />
                                    -Limited Liability : As investors hold property tokens through a DAO LLC structure, their liability is limited. This means investors are not personally responsible for any liabilities or legal issues related to the asset.
                                    <br />
                                    -Insurance Coverage : Properties listed on MeyFi typically have insurance coverage to protect against damages, losses, or liability claims. This ensures that any potential financial burdens due to property-related issues are covered by the insurance, not the investors.
                                    <br />
                                    -DAO LLC Structure : Investments are structured through a Decentralized Autonomous Organization (DAO) LLC. This innovative structure ensures that ownership is transparently and securely managed on the blockchain, with the smart contract and property token holders representing the legal ownership.
                                    <br />
                                    -Articles of Organization : The smart contract governing the ownership and transactions of the property tokens is explicitly stated in the articles of organization of the DAO LLC. This legal document provides a clear framework for ownership and governance.
                                    <br />
                                    -Immutable Records : The title deed, property management contracts, and supporting documentation are securely stored on the IPFS blockchain. The hash of these documents is embedded in the property token contract itself, ensuring immutability and verifiability of all critical documents.
                                    <br />
                                    -Compliance with Securities Laws : While property tokens are not specifically designed as securities, they are managed in a manner that aligns with securities regulations to ensure investor protection. This includes transparent reporting and adherence to relevant legal standards.
                                    <br />
                                    -KYC Procedures : Investors are required to complete Know Your Customer (KYC) procedures. This ensures that all participants are verified and that the platform complies with regulatory requirements.
                                    <br />
                                    -Rental Income Distribution: The MeyFi platform is responsible for the distribution of rental income to all token holders, ensuring that investors receive their share of the income generated by the property.
                                    <br />
                                    -Buyback Option : MeyFi offers a buyback option, allowing investors to liquidate their property tokens at any time for the original invested amount, less a 1% processing fee. This process requires 10 working days to complete from the initial request being made, providing liquidity and flexibility for investors.
                                    <br />
                                    These legal protections ensure that investments on MeyFi are secure, transparent, and compliant with relevant regulations, providing confidence and peace of mind to property token investors.
                                </p>
                            </Panel>
                            <Panel header="What Should I Consider Before Making an Investment?" key="10">
                                <p>
                                    Before investing on MeyFi, it is essential to carefully review and fully understand the MeyFi Terms and Conditions, AML (Anti-Money Laundering) Policy, and Privacy Policy. These documents outline key information regarding your rights, responsibilities, and the platform’s operational procedures. Additionally, seeking advice from a qualified financial advisor is strongly recommended. This will help ensure that you are well-informed about the investment process, the regulatory environment MeyFi operates in, and that your investment strategy aligns with your financial goals and risk tolerance.
                                </p>
                            </Panel>
                            <Panel header="How Do NFT Tiers Work?" key="11">
                                <p>
                                    As an early contributor or staker, you gain priority access to many PTO (Property Token Offering) and P2P lending rounds. Early participants not only get the first opportunity to invest when sales begin but also enjoy more favorable APY (Annual Percentage Yields), giving them a distinct advantage over later participants.
                                </p>
                            </Panel>
                            {/* <Panel header="What if I'm unable to return the borrowed funds within the loan period?" key="12">
                                <p>If you fail to repay your loan on time, your loan will automatically be placed into monitoring and your collateral may be liquidated. We have a dedicated department to take care of and help borrowers who have payment problems.</p>
                            </Panel> */}
                        </Collapse>
                    </Col>
                </Row>
            </div>
        </>
    )
}
export default Faqs