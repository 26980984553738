/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return, no-param-reassign */
import axios from "axios";
import { API_URL } from "../../../constants/index";
import {
    createStore,
    createHook,
    createContainer,
    createSubscriber,
} from "react-sweet-state";

const StorePTO = createStore({
    initialState: {

    },
    actions: {
        listPTO:
            (params) =>
                ({ getState, setState }) => {
                    return new Promise((resolve, reject) => {
                        axios.post(`${API_URL}tokenize/list`, { ...params })
                            .then((res) => {
                                resolve(res?.data)
                            })

                    });
                },
        detailPTO:
            (params) =>
                ({ getState, setState }) => {
                    return new Promise((resolve, reject) => {
                        axios.post(`${API_URL}tokenize/detail`, { ...params })
                            .then((res) => {
                                resolve(res?.data?.data || null)
                            })
                            .catch((err) => {
                                reject(null)
                            })
                    });
                },



    },
    name: "StorePTO",
});

export const useHookPTO = createHook(StorePTO);
export const Container = createContainer(StorePTO, {
    onInit:
        () =>
            ({ setState }, props) => {
                setState({ ...props });
            },
});
export const Subscriber = createSubscriber(StorePTO);
