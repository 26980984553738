import { convertDateToStringSecond } from "../../porfolio/utils"
import { STATUS_PTO, NUM_RANK, TEXT_RANK } from "../index.d";

let textUpgrade = 'Upgrade to Tier Membership to Access This Exclusive Sale Round!'
export const renderDec = (value: any) => {
  let valueLow = value?.toLowerCase()
  switch (valueLow) {
    case TEXT_RANK.SVIP:
      return textUpgrade
    case TEXT_RANK.WHALE:
      return textUpgrade
    case TEXT_RANK.DOLPHIN:
      return textUpgrade
    case TEXT_RANK.SHARK:
      return textUpgrade
    case TEXT_RANK.NEXT3_RANK:
      return textUpgrade
    case TEXT_RANK.NEXT32_RANK:
      return textUpgrade
    case TEXT_RANK.NEXT21_RANK:
      return textUpgrade
    default:
      return ''
  }
}

export const renderMinStaked = (value: any) => {
  let valueLow = value?.toLowerCase()
  switch (valueLow) {
    case TEXT_RANK.SVIP:
      return NUM_RANK.SVIP
    case TEXT_RANK.WHALE:
      return NUM_RANK.WHALE
    case TEXT_RANK.DOLPHIN:
      return NUM_RANK.DOLPHIN
    case TEXT_RANK.SHARK:
      return NUM_RANK.SHARK
    case TEXT_RANK.NEXT3_RANK:
      return NUM_RANK.DOLPHIN
    case TEXT_RANK.NEXT32_RANK:
      return NUM_RANK.SHARK
    case TEXT_RANK.NEXT21_RANK:
      return NUM_RANK.DOLPHIN
    default:
      return 0
  }
}

export const renderTimeUI = (valueStart: any, valueEnd: any, tierName: any) => (

  <span>
    {tierName?.toLowerCase() !== TEXT_RANK.UPCOMING ? (
      <>
        {valueStart === valueEnd || valueStart + 1000 === valueEnd ? (
          <>
            Start on: {convertDateToStringSecond(valueStart)}
          </>

        ) : (
          <>
            {tierName?.toLowerCase() === TEXT_RANK.END ? (
              <>
                Start on: {convertDateToStringSecond(valueStart)} <br />
                End on: {convertDateToStringSecond(valueEnd)}<br />
                Calculating....
              </>
            ) : (
              <>
                {tierName?.toLowerCase() === TEXT_RANK.ACTION ? (
                  <>
                    Start on: {convertDateToStringSecond(valueStart)}
                  </>
                ) : (
                  <>
                    Start on: {convertDateToStringSecond(valueStart)} <br />
                    End on: {convertDateToStringSecond(valueEnd)}
                  </>
                )}

              </>
            )}

          </>
        )}

      </>
    ) : (
      <>

        End on: {convertDateToStringSecond(valueEnd)}
      </>
    )}
  </span>
)
export const rendererCountDownDetailNew = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
}: any) => {
  if (completed) {
    setTimeout(() => {
      window.location.reload()
    }, 300);
    return <span>Finished</span>
  }
  if (days > 1) {
    return (
      <span className="boc-time-pto1">
        <span className="tec-tem">{days}<span className="tec-time">Days</span></span>
        <span className="dot-time">:</span>
        <span className="tec-tem">{hours}<span className="tec-time">Hours</span></span>
        <span className="dot-time">:</span>
        <span className="tec-tem">{minutes}<span className="tec-time">Minutes</span></span>
        <span className="dot-time">:</span>
        <span className="tec-tem">{seconds}<span className="tec-time">Seconds</span></span>
      </span>
    );
  } else if (days === 1) {
    return (
      <span className="boc-time-pto1">
        <span className="tec-tem">{days}<span className="tec-time">Day</span></span>
        <span className="dot-time">:</span>
        <span className="tec-tem">{hours}<span className="tec-time">Hours</span></span>
        <span className="dot-time">:</span>
        <span className="tec-tem">{minutes}<span className="tec-time">Minutes</span></span>
        <span className="dot-time">:</span>
        <span className="tec-tem">{seconds}<span className="tec-time">Seconds</span></span>
      </span>
    );
  } else {
    return (
      <span className="boc-time-pto1">
        <span className="tec-tem">{hours}<span className="tec-time">Hours</span></span>
        <span className="dot-time">:</span>
        <span className="tec-tem">{minutes}<span className="tec-time">Minutes</span></span>
        <span className="dot-time">:</span>
        <span className="tec-tem">{seconds}<span className="tec-time">Seconds</span></span>
      </span>
    );
  }
};

export const rendererCountDownDetail = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
}: any) => {
  if (completed) {
    setTimeout(() => {
      window.location.reload()
    }, 300);
    return <span>Finished</span>
  }
  if (days > 1) {
    return (
      <span className="boc-time-pto">
        <span className="tec-tem">{days}<span className="tec-time">D</span></span>
        {" "}:{" "}
        <span className="tec-tem">{hours}<span className="tec-time">H</span></span>
        {" "}:{" "}
        <span className="tec-tem">{minutes}<span className="tec-time">M</span></span>
        {" "}:{" "}
        <span className="tec-tem">{seconds}<span className="tec-time">S</span></span>
      </span>
    );
  } else if (days === 1) {
    return (
      <span className="boc-time-pto">
        <span className="tec-tem">{days}<span className="tec-time">D</span></span>
        {" "}:{" "}
        <span className="tec-tem">{hours}<span className="tec-time">H</span></span>
        {" "}:{" "}
        <span className="tec-tem">{minutes}<span className="tec-time">M</span></span>
        {" "}:{" "}
        <span className="tec-tem">{seconds}<span className="tec-time">S</span></span>
      </span>
    );
  } else {
    return (
      <span className="boc-time-pto">
        <span className="tec-tem">{hours}<span className="tec-time">H</span></span>
        {" "}:{" "}
        <span className="tec-tem">{minutes}<span className="tec-time">M</span></span>
        {" "}:{" "}
        <span className="tec-tem">{seconds}<span className="tec-time">S</span></span>
      </span>
    );
  }
};
export const rendererCountDownDetailAll = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
}: any) => {
  if (completed) {
    // setTimeout(() => {
    //   window.location.reload()
    // }, 300);
    return <span>Finished</span>
  }
  if (days > 1) {
    return (
      <span className="boc-time-pto-all">
        <span className="tec-tem">{days}<span className="tec-time">D</span></span>
        {" "}:{" "}
        <span className="tec-tem">{hours}<span className="tec-time">H</span></span>
        {" "}:{" "}
        <span className="tec-tem">{minutes}<span className="tec-time">M</span></span>
        {" "}:{" "}
        <span className="tec-tem">{seconds}<span className="tec-time">S</span></span>
      </span>
    );
  } else if (days === 1) {
    return (
      <span className="boc-time-pto-all">
        <span className="tec-tem">{days}<span className="tec-time">D</span></span>
        {" "}:{" "}
        <span className="tec-tem">{hours}<span className="tec-time">H</span></span>
        {" "}:{" "}
        <span className="tec-tem">{minutes}<span className="tec-time">M</span></span>
        {" "}:{" "}
        <span className="tec-tem">{seconds}<span className="tec-time">S</span></span>
      </span>
    );
  } else {
    return (
      <span className="boc-time-pto-all">
        <span className="tec-tem">{hours}<span className="tec-time">H</span></span>
        {" "}:{" "}
        <span className="tec-tem">{minutes}<span className="tec-time">M</span></span>
        {" "}:{" "}
        <span className="tec-tem">{seconds}<span className="tec-time">S</span></span>
      </span>
    );
  }
};



export const tabProperty = (
  <>
    <div className="title-tabs">
      Property Details
    </div>
  </>
)

export const tabNFTDetail = (
  <>
    <div className="title-tabs">
      NFT Details
    </div>
  </>
)
export const tabTimeLine = (
  <>
    <div className="title-tabs">
      PTO Timeline
    </div>
  </>
)
export const tabDocument = (
  <>
    <div className="title-tabs">
      Document Detail
    </div>
  </>
)
export const tabMarket = (
  <>
    <div className="title-tabs">
      Market
    </div>
  </>
)
export const tabMap = (
  <>
    <div className="title-tabs">
      Map
    </div>
  </>
)


export const caculaTimeLine = (arrT: any) => {
  let temp: any = []
  let cuurentTime = Date.now()
  for (let i = 0; i < arrT?.length; i++) {
    let itemArr = arrT[i]
    let startTimeNumber = Number(itemArr.startTime) * 1000
    let endTimeNumber = Number(itemArr.endTime) * 1000
    let obj = {
      isActive: cuurentTime >= startTimeNumber ? true : false,
      online: (cuurentTime > startTimeNumber
        && cuurentTime < endTimeNumber)
        || ((i + 1 === arrT?.length) && cuurentTime > (endTimeNumber))
        ? true : false,
      endTime: endTimeNumber,
      startTime: startTimeNumber,
      name: itemArr.name,
      desc: renderDec(itemArr.name),
      minStaked: renderMinStaked(itemArr.name),
      time_ui: renderTimeUI(startTimeNumber, endTimeNumber, itemArr.name),
      maxNFTsAllowed: Number(itemArr.maxNFTsAllowed),
      nftsMinted: Number(itemArr.nftsMinted)
    }
    temp.push(obj)
  }
  return temp
}

export const renderTextTime = (numStt: any, name: any) => {
  switch (numStt) {
    case STATUS_PTO.OPEN:
      return `${name} End in:`
    case STATUS_PTO.COMING:
      return `${name} End in:`
    case STATUS_PTO.SOLD_OUT:
      return `Sold out`
    case STATUS_PTO.END:
      return "Finished"
    default:
      return ""
  }
}
export const renderStatus = (numStt: any) => {
  switch (numStt) {
    case STATUS_PTO.OPEN:
      return "In Progress"
    case STATUS_PTO.COMING:
      return "Upcoming"
    case STATUS_PTO.SOLD_OUT:
      return "Sold out"
    case STATUS_PTO.END:
      return "Partially filled"
    default:
      return "TBA"
  }
}
export const renderStatusList = (value: any, isSoldOut: any) => {

  switch (value) {
    case STATUS_PTO.OPEN:
      return "In Progress"
    case STATUS_PTO.COMING:
      return "Upcoming"
    case STATUS_PTO.SOLD_OUT:
      if (isSoldOut) {
        return "Sold out"
      } else {
        return "Partially filled"
      }
    default:
      return "TBA"
  }


}
export const renderClassStatusType = (value: any, isSoldOut: any) => {
  switch (value) {
    case 1:
      return "type-status open"
    case 2:
      return "type-status coming"
    case 3:
      if (isSoldOut) {
        return "type-status"
      } else {
        return "type-status par"
      }

    default:
      return "type-status tba"
  }
}
export const renderClassStatus = (numStt: any) => {
  switch (numStt) {
    case STATUS_PTO.OPEN:
      return "detail-status open"
    case STATUS_PTO.COMING:
      return "detail-status coming"
    case STATUS_PTO.SOLD_OUT:
      return "detail-status end"
    case STATUS_PTO.END:
      return "detail-status ends"
    default:
      return "detail-status tba"
  }
}

export const renderNumTimeAll = (value: any, startTime: any, endTime: any) => {

  if (value === STATUS_PTO.OPEN) {
    return endTime
  } else if (value === STATUS_PTO.COMING) {
    return startTime
  }
  else return 0
}