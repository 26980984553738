/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return, no-param-reassign */
import axios from "axios";
import { API_URL } from "../../../../constants/index";
import {
    createStore,
    createHook,
    createContainer,
    createSubscriber,
} from "react-sweet-state";

const StoreVesing = createStore({
    initialState: {},
    actions: {
        addHistory:
            (params) =>
                ({ getState, setState }) => {
                    return new Promise((resolve, reject) => {
                        axios.post(`${API_URL}account/add-history`, { ...params }).then((res) => {
                            resolve(res)
                        });
                    });
                },
        getHistory:
            (params) =>
                ({ getState, setState }) => {
                    return new Promise((resolve, reject) => {
                        axios.post(`${API_URL}account/your-vesting`, { ...params })
                            .then((res) => {
                                resolve(res?.data?.data)
                            })

                    });
                },
    },
    name: "StoreVesing",
});

export const useHookVesting = createHook(StoreVesing);
export const Container = createContainer(StoreVesing, {
    onInit:
        () =>
            ({ setState }, props) => {
                setState({ ...props });
            },
});
export const Subscriber = createSubscriber(StoreVesing);
