/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Skeleton, Tabs } from "antd";
import { useLocation, Link } from "react-router-dom";
import './style.css'
import Stake from "./Stake";
import UnStake from "./Unstake";
import Withdraw from "./Withdraw";
import abiStake from "./utils/abiStake.json"
import { RPC_BASE, NUM_FORMAT } from "../../../../constants";
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import { useAccount } from "wagmi";
import { useHookStaked } from "../storeStaked";
import { formatNumber } from "./utils";
import Faqs from "../faqs";
import { isMobile } from "react-device-detect";
import Action from "./action";



const StakeDetail = () => {
    let location = useLocation();
    const { TabPane } = Tabs;
    const [defaultKey, setDefaultKey] = useState("stake")
    const [objDetail, setObjDetail]: any = useState(1)
    const pathId = location.search?.split("=")[1];
    const { address } = useAccount();

    let web3: any = new Web3(RPC_BASE);

    const contractStake1 = new web3.eth.Contract(
        abiStake as unknown as AbiItem,
        objDetail?.poolContract
    );

    const [state, actions] = useHookStaked()

    const [totalStaked, setTotalStaked]: any = useState(0)
    const [maxCapPool, setMaxCapPool]: any = useState(0)
    const [endTime, setendTime]: any = useState(false)

    const [numberStaked, setNumberStaked]: any = useState(0)
    const [numberAPY, setNumberAPY]: any = useState(0)
    const [isCheckAction, setIsCheckAction]: any = useState(false)

    function callback(key: any) {
        setDefaultKey(key);
    }

    const getMaxCap = async () => {
        await contractStake1.methods.maxCap().call()
            .then((res: any) => {
                let cap: any = web3.utils.fromWei(res, "ether");
                setMaxCapPool(Number(cap))
            })
            .catch((err: any) => {
                console.log('err', err)
                setMaxCapPool(0)
            })
    }

    const getTotalStaked = async () => {
        await contractStake1.methods.tokenTotalStaked().call()
            .then((res: any) => {
                let balance: any = web3.utils.fromWei(res, "ether");
                setTotalStaked(Number(balance))

            })
            .catch((err: any) => {
                setTotalStaked(0)
            })
    }

    const getNumberStaked = async () => {
        try {
            await contractStake1.methods.totalStakerCount().call()
                .then((res: any) => {
                    setNumberStaked(Number(res))

                })
                .catch((err: any) => {
                    setNumberStaked(0)
                })

        } catch (error) {
            setNumberStaked(0)
        }
    }

    const getNumberAPY = async () => {
        try {
            await contractStake1.methods.stakingAPY().call()
                .then((res: any) => {

                    setNumberAPY(Number(res) / 100)

                })
                .catch((err: any) => {
                    setNumberAPY(0)
                })

        } catch (error) {
            setNumberAPY(0)
        }
    }


    const getTimeEnd = async () => {
        try {
            await contractStake1.methods.stakeRewardEndTime().call()
                .then((res: any) => {
                    if (res) {
                        if (Number(res) * 1000 > Date.now()) {
                            setendTime(false)
                        } else {
                            setendTime(true)
                        }
                    }
                }
                )
                .catch((err: any) => {
                })
        } catch (error) {

        }
    }

    useEffect(() => {
        if (contractStake1?._address && objDetail?.poolContract) {
            getNumberAPY()
            getTimeEnd()
        }
    }, [contractStake1?._address, objDetail, isCheckAction]);

    useEffect(() => {
        if (contractStake1?._address && objDetail?.poolContract) {
            getNumberStaked()
        }
    }, [contractStake1?._address, objDetail, isCheckAction]);

    useEffect(() => {
        if (contractStake1?._address && objDetail?.poolContract) {
            getTotalStaked()
            getMaxCap()
        }
    }, [contractStake1?._address, objDetail, isCheckAction]);


    useEffect(() => {
        if (contractStake1?._address && objDetail?.poolContract) {
            getMaxCap()
        }
    }, [contractStake1?._address, objDetail]);


    const getDetailPool = async () => {
        try {
            await actions.detailPool(pathId).then((res: any) => {
                setObjDetail(res)
            })
                .catch((err: any) => {
                    setObjDetail(null)
                })
        } catch (error) {
            setObjDetail(null)
        }
    }

    useEffect(() => {
        if (pathId) {
            getDetailPool()
        }
    }, [pathId])


    const saveLog = async () => {
        try {
            let paramsLog = {
                contractAddress: objDetail?.poolContract,
                ownerAddress: address
            }
            await actions.logStaking(paramsLog)
        } catch (error) {

        }
    }

    useEffect(() => {
        if (address && objDetail?.poolContract) {
            saveLog()
        }
    }, [address, objDetail, objDetail?.poolContract])



    return (
        <>
            <div className="main-stake-pool-v2">
                {objDetail === 1 ? (
                    <>
                        <div className="pool-empty">
                            <Skeleton paragraph={{ rows: 6 }} />
                            <Skeleton paragraph={{ rows: 6 }} />
                            <Skeleton paragraph={{ rows: 6 }} />
                            <Skeleton paragraph={{ rows: 6 }} />
                        </div>
                    </>
                ) : (
                    <>
                        {objDetail ? (
                            <>
                                <div className="container-stake">
                                    {isMobile ? (
                                        <div className="breadcrum-line">
                                            <div className="item-bread">
                                                <Link to="/apps/staking">
                                                    <img src="/images/menu/stake-2.png" alt="" />{" "}
                                                    <span>Staking</span>
                                                </Link>
                                            </div>
                                            <div className="item-bread">
                                                <div className="vector-r">
                                                    <img src="/images/vector-s.png" alt="" />
                                                </div>
                                            </div>
                                            <div className="item-bread active">
                                                <span>Details</span>
                                            </div>
                                        </div>
                                    ) : ('')}

                                    <div className="wrap-item-info">
                                        <div className="item-info">
                                            {!isMobile ? ('') : (
                                                <div className="icon-right-stake-mo">
                                                    <img src="/images/staking/icon-right-stake.png" alt="" />
                                                </div>
                                            )}
                                            <div className="item-1">
                                                <div className="item-1-logo">
                                                    <img src="/images/staking/total-stake-img.png" alt="" />
                                                </div>
                                                <div className="item-1-text">
                                                    <div className="title-item">Total Staked</div>
                                                    <div className="num-item">{formatNumber(totalStaked, NUM_FORMAT)} <span>{objDetail?.symbol}</span></div>
                                                </div>
                                            </div>
                                            <div className="item-1">
                                                <div className="item-1-logo">
                                                    <img src="/images/staking/apy.png" alt="" />
                                                </div>
                                                <div className="item-1-text">
                                                    <div className="title-item">APR</div>
                                                    <div className="num-item apr">{formatNumber(numberAPY, NUM_FORMAT)}%</div>
                                                </div>
                                            </div>
                                            <div className="item-1">
                                                <div className="item-1-logo">
                                                    <img src="/images/staking/bume.png" alt="" />
                                                </div>
                                                <div className="item-1-text">
                                                    <div className="title-item">Number of Stakers</div>
                                                    <div className="num-item">{formatNumber(numberStaked, NUM_FORMAT)}</div>
                                                </div>
                                            </div>

                                        </div>
                                        {isMobile ? ('') : (
                                            <div className="icon-right-stake">
                                                <img src="/images/staking/icon-right-stake.png" alt="" />
                                            </div>
                                        )}
                                    </div>

                                    <div className="wrap-tab">
                                        <div className="tabs-stake-pool v2">
                                            <Tabs activeKey={defaultKey} onChange={callback}>
                                                <TabPane tab="Stake" key="stake">
                                                    <Stake
                                                        poolContract={objDetail?.poolContract}
                                                        tokenContract={objDetail?.tokenContract}
                                                        tabBox={defaultKey}
                                                        setIsCheckAction={setIsCheckAction}
                                                        isCheckAction={isCheckAction}
                                                        endTime={endTime}
                                                        maxStake={maxCapPool - totalStaked}
                                                    />
                                                </TabPane>
                                                <TabPane tab="Unstake" key="unstake">
                                                    <UnStake
                                                        poolContract={objDetail?.poolContract}
                                                        tokenContract={objDetail?.tokenContract}
                                                        tabBox={defaultKey}
                                                        setIsCheckAction={setIsCheckAction}
                                                        isCheckAction={isCheckAction}
                                                        endTime={endTime}
                                                    />
                                                </TabPane>
                                                <TabPane tab="Withdraw" key="withdraw">
                                                    <Withdraw
                                                        poolContract={objDetail?.poolContract}
                                                        tokenContract={objDetail?.tokenContract}
                                                        tabBox={defaultKey}
                                                        setIsCheckAction={setIsCheckAction}
                                                        isCheckAction={isCheckAction}
                                                        endTime={endTime}
                                                    />
                                                </TabPane>
                                            </Tabs>
                                        </div>
                                    </div>

                                    <div className="stake-faq">
                                        <Faqs />
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="pool-empty">
                                    <div className="text-empty">
                                        <img src="/images/empty.png" alt="" />
                                        <div>
                                            Pool not found
                                        </div>
                                    </div>

                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    )
}

export default StakeDetail;
