import { useEffect, useState } from "react";
import { AppHome } from "../../../../api/appHome";
import { CustomTable } from "../../../../components/ui/CustomTable";
import clsx from "clsx";
import moment from "moment";
import { Dropdown, Menu, Pagination } from "antd";
import { isMobile } from "react-device-detect";

export const ActivityTab = (props: any) => {
    const dataDetail: any = props?.dataDetail
    const [data, setData]: any = useState([])
    const [textType1, settextType1]: any = useState(-1)
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize]: any = useState(10)
    const [totalItem, setTotalItem]: any = useState(0)

    const getDataActivity = async () => {
        try {
            let res = await AppHome.getDataActivity(dataDetail?.code, 10, 0, -1);
            if (res.data.success === true) {
                setData(res.data.data);
                setTotalItem(res.data.totalCount)
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    useEffect(() => {
        getDataActivity()
    }, []);

    const onClickType1 = async (type: any) => {
        settextType1(type);
        try {
            let res = await AppHome.getDataActivity(dataDetail?.code, 10, 0, type);
            if (res.data.success === true) {
                setData(res.data.data);
                setTotalItem(res.data.totalCount);
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    const renderType1 = (value: any) => {
        switch (value) {
            case -1:
                return "All Type"
            case 1:
                return "Buy"
            case 0:
                return "Sell"
            case 2:
                return "Canceled"
            default:
                return "All Type"
        }
    }

    const menu = (
        <Menu>
            <Menu.Item disabled={textType1 === -1}>
                <div onClick={() => onClickType1(-1)} className="type-his all">All type</div>
            </Menu.Item>
            <Menu.Item disabled={textType1 === 1}>
                <div onClick={() => onClickType1(1)} className="type-his all">Buy</div>
            </Menu.Item>
            <Menu.Item disabled={textType1 === 0}>
                <div onClick={() => onClickType1(0)} className="type-his all">Sell</div>
            </Menu.Item>
            <Menu.Item disabled={textType1 === 2}>
                <div onClick={() => onClickType1(2)} className="type-his all">Canceled</div>
            </Menu.Item>
        </Menu>
    );

    const onChange = async (page: any) => {
        setCurrent(page);
        try {
            let res = await AppHome.getDataActivity(dataDetail?.code, 10, Number(page) - 1, textType1);
            if (res.data.success === true) {
                setData(res.data.data);
                setTotalItem(res.data.totalCount)
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    const Item = ({ item }: any) => {
        return (
            <>
                <tr>
                    <td>
                        {item?.time_ui}
                    </td>
                    <td>
                        <div
                            className={clsx(
                                "text-[16px] font-radioCanada font-medium",
                                item?.numType === 4 ? "text-[#00D415]" : item?.numType === 5 ? "text-[#a2a0a0]" : "text-[#B43C3C]",
                            )}
                        >
                            {item?.type}
                        </div>
                    </td>
                    <td>
                        {item?.amount}
                    </td>
                    <td>
                        {new Intl.NumberFormat('en-US').format(item?.price)}
                    </td>
                    <td>
                        {new Intl.NumberFormat('en-US').format(item?.totalPrice)}$
                    </td>
                </tr>
            </>
        )
    }

    const ItemMobile = ({ item }: any) => {
        return (
            <>
                <div className="content-item-mobile-mk">
                    <div className="item">
                        <div className="txt-l">
                            Time (UTC)
                        </div>
                        <div className="txt-r">
                            {item?.time_ui}
                        </div>
                    </div>
                    <div className="item">
                        <div className="txt-l">
                            Type
                        </div>
                        <div className="txt-r">
                            <div
                                className={clsx(
                                    "text-[16px] font-radioCanada font-medium",
                                    item?.numType === 4 ? "text-[#00D415]" : item?.numType === 5 ? "text-[#a2a0a0]" : "text-[#B43C3C]",
                                )}
                            >
                                {item?.type}
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="txt-l">
                            Total NFTs
                        </div>
                        <div className="txt-r">
                            {item?.amount}
                        </div>
                    </div>
                    <div className="item">
                        <div className="txt-l">
                            Price (USDC)
                        </div>
                        <div className="txt-r">
                            {new Intl.NumberFormat('en-US').format(item?.price)}
                        </div>
                    </div>
                    <div className="item">
                        <div className="txt-l">
                            Total Price
                        </div>
                        <div className="txt-r">
                            {new Intl.NumberFormat('en-US').format(item?.totalPrice)}$
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <div className="list-filter-profile res positon-page-row">
                <div className="row-right">
                    <div className="status-filter rssss">
                        <span>Sort by</span>
                        <Dropdown
                            className={`btn-status`}
                            overlayClassName="dropdown-type"
                            overlay={menu}
                            trigger={['click']}
                            placement="bottomRight"
                        >
                            <a onClick={(e) => e.preventDefault()}>
                                {renderType1(textType1)}
                            </a>
                        </Dropdown>
                    </div>
                </div>

            </div>
            {isMobile ?
                <>
                    {data.length > 0 ? (
                        <>
                            <div className="row-mobile-list-mk">
                                {data.map((item: any, index: any) => (
                                    <ItemMobile item={item} />
                                ))}
                            </div>

                        </>
                    ) : (
                        <div className="box-no-data-stake">
                            <img className='img-empty' src="/images/empty1.png" alt="" />
                        </div>
                    )}
                </>
                :
                <>
                    <div className="main-table-row">
                        <table cellSpacing="0" className="main-table table-market">
                            <tr>
                                <th>
                                    Time (UTC)
                                </th>
                                <th>
                                    Type
                                </th>
                                <th>
                                    Total NFTs
                                </th>
                                <th>
                                    Price (USDC)
                                </th>
                                <th>
                                    Total Price
                                </th>
                            </tr>
                            {data.length > 0 ? (
                                <>
                                    {data.map((item: any, index: any) => (
                                        <Item item={item} />
                                    ))}
                                </>
                            ) : (
                                <tr>
                                    <td className="nodata" colSpan={7}>
                                        <div className="box-no-data-stake">
                                            <img className='img-empty' src="/images/empty1.png" alt="" />
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </table>
                    </div>
                </>
            }
            {data?.length > 0 ?
                <>
                    <div className="pagination-his">
                        <Pagination current={current} defaultPageSize={pageSize} onChange={onChange} total={totalItem} />
                    </div>
                </>
                :
                <>
                    {""}
                </>
            }
        </>
    );
};
