import React from "react";

export const PropertyDetailTab = (data: any) => {
  return (
    <div className="content-details">
      <div className="title">
        Description
      </div>
      <div className="desc">
        {data?.des}
      </div>
      <div className="list-detail-info">
        <div className="row-info">
          {data && data?.data?.map((item: any, index: any) => (
            <>
              <div className="item item-pto-pd">
                <div className="row-left">
                  <div className="box-img-info">
                    <img src={item?.iconUrl} alt="" />
                  </div>
                </div>
                <div className="row-right">
                  <div className="txt-top">
                    {item?.name}
                  </div>
                  <div className="txt-bottom">
                    {item?.value}
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};
