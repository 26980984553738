/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from "react";
import { Progress, Steps, Checkbox, Radio, Modal, Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import Action from "./action";
import { useContract } from "../../../../hooks/useContract";
import { BASE_SCAN, NUM_FORMAT, RPC_BASE, WORK_CHAIN } from "../../../../constants";
import abiTokenStake from "./utils/abiTokenStake.json";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import abiStake from "./utils/abiStake.json"
import { isMobile } from "react-device-detect";
import { useHookStaked } from '../storeStaked'
import { useAccount, useChainId } from "wagmi";
import { formatNumber } from "./utils";
import { Noti } from "../../../../components/Noti/toast";
import { ethers } from "ethers";

const steps = [
    {
        title: '1',
        content: 'First-content',
    },
    {
        title: '2',
        content: '2-content',
    },
    {
        title: '3',
        content: '3-content',
    },
    {
        title: '4',
        content: '4-content',
    },
    {
        title: '5',
        content: 'Last-content',
    },
];


declare const window: Window & typeof globalThis & { ethereum: any };
const web3: any = new Web3(RPC_BASE);

const Stake = (props: any) => {
    const { address } = useAccount();
    const { selectedNetworkId }: any = useWeb3ModalState();
    const chainId = useChainId()


    let endTime = props?.endTime
    let maxStake = props?.maxStake



    let scStake = props?.poolContract
    let tokenStake = props?.tokenContract


    const tokenStakeContract: any = useContract(tokenStake, abiTokenStake);

    const stakeContract: any = useContract(scStake, abiStake)
    const contractStakeNotAddress = new web3.eth.Contract(
        abiStake as unknown as AbiItem,
        scStake,
    );

    // window.ethereum ? new web3(window.ethereum) : new web3(RPC_BASE);
    const [ethBalance, setethBalance] = useState(0);
    const [state, actions] = useHookStaked()

    const [isSuccess, setIsSuccess]: any = useState(false)
    const [amountStake, setAmountStake]: any = useState()
    const [amountStakeFormat, setAmountStakeFormat]: any = useState('')
    const [current, setCurrent] = useState(0);
    const [amountApproved, setAmountApproved]: any = useState(0);
    const [amountTokenStake, setAmountTokenStake]: any = useState(0);
    const [isCheck, setIsCheck] = useState(false);
    const [isCheckRule, setIsCheckRule] = useState(false);
    const [showNext, setShowNext] = useState(false);
    const [hashStake, setHashStake]: any = useState('');
    const [unstakeAmount, setUnstakeAmount] = useState(0);
    const [childData, setchildData]: any = useState(false)
    const [numberFee, setNumberFee]: any = useState(0);

    const { Step } = Steps;


    const getNumberFee = async () => {
        try {
            await contractStakeNotAddress.methods
                .earlyWithdrawFee()
                .call()
                .then((res: any) => {
                    setNumberFee(Number(res) / 100);
                })
                .catch((err: any) => {
                    setNumberFee(0);
                });
        } catch (error) {
            setNumberFee(0);
        }
    };

    useEffect(() => {
        if (contractStakeNotAddress?._address && scStake && props.tabBox === "stake") {
            getNumberFee()
        }
    }, [contractStakeNotAddress?._address, scStake, props.tabBox])


    useEffect(() => {
        if (tokenStakeContract?.address && tokenStake && props.tabBox === "stake" && selectedNetworkId === WORK_CHAIN) {
            getAmountApprove()
        }

    }, [address, selectedNetworkId, props.tabBox, current, childData, tokenStakeContract?.address, tokenStake])

    const next = () => {
        if (current === 2) {
            if (amountApproved < amountStake) {
                handleApprove()
            } else {
                setCurrent(current + 1);
            }
        } else {
            if (current === 3) {
                handleStaking()
            } else {
                setCurrent(current + 1);
            }

        }

    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const renderImg1 = () => {
        return (
            <img className="img-step-stake" src="/images/staking/dot-stake-ac.png" alt="" />
        )
    }
    const renderImg2 = () => {
        if (current < 1) {
            return <img className="img-step-stake" src="/images/staking/dot-stake.png" alt="" />
        } else {
            return <img className="img-step-stake" src="/images/staking/dot-stake-ac.png" alt="" />
        }
    }
    const renderImg3 = () => {
        if (current < 2) {
            return <img className="img-step-stake" src="/images/staking/dot-stake.png" alt="" />
        } else {
            return <img className="img-step-stake" src="/images/staking/dot-stake-ac.png" alt="" />
        }
    }
    const renderImg4 = () => {
        if (current < 3) {
            return <img className="img-step-stake" src="/images/staking/dot-stake.png" alt="" />
        } else {
            return <img className="img-step-stake" src="/images/staking/dot-stake-ac.png" alt="" />
        }
    }
    const renderImg5 = () => {
        if (current < 4) {
            return <img className="img-step-stake" src="/images/staking/dot-stake.png" alt="" />
        } else {
            return <img className="img-step-stake" src="/images/staking/dot-stake-ac.png" alt="" />
        }
    }

    const renderText1 = () => {
        return <span className="text-ac">Checkpoints</span>
    }
    const renderText2 = () => {
        if (current < 1) {
            return "Amount to Stake"
        } else {
            return <span className="text-ac">Amount to Stake</span>
        }

    }

    const renderText3 = () => {
        if (current < 2) {
            return "Pre-authorization"
        } else {
            return <span className="text-ac">Pre-authorization</span>
        }

    }
    const renderText4 = () => {
        if (current < 3) {
            return "Confirm"
        } else {
            return <span className="text-ac">Confirm</span>
        }

    }
    const renderText5 = () => {
        if (current < 4) {
            return "Confirmation"
        } else {
            return <span className="text-ac">Confirmation</span>
        }
    }

    const onChange = (e: any) => {
        setIsCheck(e.target.checked)
    };
    const onChangeAll = () => {
        setIsCheck(!isCheck)
    };

    const onChangeRule = (e: any) => {
        setIsCheckRule(e.target.checked)
    }
    const onChangeRuleAll = () => {
        setIsCheckRule(!isCheckRule)
    }

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const getBscs = async () => {
        if (tokenStakeContract) {
            const amount = await tokenStakeContract.balanceOf(address);
            let balance: any = web3.utils.fromWei(amount, "ether");
            if (Number(balance) !== 0) {
                setAmountTokenStake(balance);
            } else {
                setAmountTokenStake(Number(balance));
            }

        }
    };

    useEffect(() => {
        if (address && selectedNetworkId === WORK_CHAIN && props.tabBox === "stake") {
            getBscs();
        } else {
            setAmountTokenStake(0)
        }
    }, [address, selectedNetworkId, props.tabBox, current, childData, tokenStakeContract?.address]);

    useEffect(() => {
        if (address && selectedNetworkId === WORK_CHAIN && props.tabBox === "stake") {
            web3.eth.getBalance(address).then((res: any) => {
                // if (res) {
                let _balance: any = web3.utils.fromWei(res, "ether");
                // let ethBl = Math.floor(parseFloat(_balance) * 10000000) / 10000000;
                // console.log('ethBl', ethBl)
                setethBalance(_balance);
                // } else {
                //     console.log('3', 3)
                //     setethBalance(0)
                // }
            })
        } else {
            setethBalance(0)
        }
    }, [address, selectedNetworkId, chainId, props.tabBox, current, childData]);



    const getAmountApprove = async () => {

        try {
            await tokenStakeContract.allowance(address, scStake).then((res: any) => {
                let amount = web3.utils.fromWei(res, "ether");
                setAmountApproved(amount)
            })
                .catch((err: any) => {
                    console.log('err', err)
                    setAmountApproved(0)
                })
        } catch (error) {
            console.log('error', error)
            setAmountApproved(0)
        }
    }

    const handleApprove = async () => {
        try {
            if (!tokenStakeContract) {
                Noti('error', "Can't reading contract token staking", 3000)
                return;
            }
            setIsModalOpen(true);
            let amount = web3.utils.toWei(amountStake.toString(), "ether");
            await tokenStakeContract.approve(scStake, ethers.constants.MaxUint256).then((res: any) => {
                res.wait()
                    .then((resWait: any) => {
                        if (resWait?.status === 1) {
                            let tem = web3.utils.fromWei(resWait?.logs[0]?.data.toString(), "ether");
                            if (Number(tem) < amountStake) {
                                Noti('warn', "Please increase the spending cap larger than the amount of tokens you want to stake", 3000)
                                setIsModalOpen(false);
                            } else {
                                Noti('success', "Approved successfully", 3000)

                                setIsModalOpen(false);
                                setCurrent(current + 1);
                            }
                        } else {
                            Noti('error', "Unknown error (status hash fail)", 3000)
                            setIsModalOpen(false);
                        }
                    })
                    .catch((errWait: any) => {
                        console.log('errWait', errWait)
                    })
            })
                .catch((err: any) => {
                    console.log('err', err)
                    setIsModalOpen(false);
                    Noti('error', err?.reason || err?.message || "Unknown error", 3000)

                    // let rejected = err?.toString()?.includes('rejected')
                    // if (rejected) {
                    //     message.error({
                    //         type: "error",
                    //         content: "User rejected transaction",
                    //         className: "custom-class",
                    //         duration: 3,
                    //     });
                    // } else {
                    //     message.error({
                    //         type: "error",
                    //         content: err?.message || "Unknown error",
                    //         className: "custom-class",
                    //         duration: 3,
                    //     });
                    // }

                })
        } catch (error) {
            console.log('error', error)
            Noti('error', error || "Unknown error", 3000)
            setIsModalOpen(false);
        }
    }

    const saveLog = async (hash: any, amount: any, type: any) => {
        try {
            let params = {
                ownerAddress: address,
                contractAddress: scStake,
                txHash: hash,
                amount: amount,
                type: type,
                assetToken: 'Mey',
            }
            await actions.addHistory(params)
        } catch (error) {

        }
    }


    const handleStaking = async () => {
        try {
            setIsModalOpen(true);
            if (selectedNetworkId !== WORK_CHAIN) {
                setIsModalOpen(false);
                Noti('error', "Wrong network", 3000)
                return
            }
            if (!stakeContract) {
                setIsModalOpen(false);
                Noti('error', "Stake contract not found", 3000)
                return
            }
            let amount = web3.utils.toWei(amountStake.toString(), "ether");
            await stakeContract.stake(amount)
                .then(async (res: any) => {
                    saveLog(res.hash, amountStake, 1)
                    // const timestamp = new Date().getTime();
                    // const date1 = new Date(timestamp);
                    // let timeStake = date1.toLocaleString('en-GB')
                    // let paramNoti = {
                    //     projectName: "BSCS",
                    //     amount: amountStake,
                    //     actionType: 1,
                    //     userAddress: address,
                    //     tokenName: "BSCS",
                    //     actionTime: timeStake,
                    //     hash: res.hash,
                    //     status: "Waiting"
                    // }
                    // await actions.callNoti(paramNoti)

                    // let params = {
                    //     ownerAddress: address,
                    //     poolContract: scStake
                    // }

                    // let resapi = await actions.updateStaked(params)
                    // if (resapi.data.data) {
                    res.wait()
                        .then(async (resWait: any) => {
                            // console.log('resWait222', resWait)
                            if (resWait?.status === 1) {
                                // const timestamp = new Date().getTime();
                                // const date1 = new Date(timestamp);
                                // let timeStake = date1.toLocaleString('en-GB')
                                // let paramNoti = {
                                //     projectName: "BSCS",
                                //     amount: amountStake,
                                //     actionType: 1,
                                //     userAddress: address,
                                //     tokenName: "BSCS",
                                //     actionTime: timeStake,
                                //     hash: resWait.transactionHash,
                                //     status: "Success"
                                // }
                                // await actions.callNoti(paramNoti)

                                setHashStake(resWait.transactionHash)
                                Noti('success', "Stake successfully", 3000)

                                props.setIsCheckAction(!props.isCheckAction)
                                setIsSuccess(!isSuccess)
                                setIsModalOpen(false);
                                setCurrent(current + 1);
                            } else {
                                Noti('error', "Unknown error (status hash fail)", 3000)
                                setIsModalOpen(false);
                            }
                        })
                        .catch((err: any) => {
                            Noti('error', "Unknown error", 3000)
                            setIsModalOpen(false);
                        })
                    // } else {
                    //     message.error({
                    //         type: "error",
                    //         content: "API update status stake fail",
                    //         className: "custom-class",
                    //         duration: 3,
                    //     });
                    //     setIsModalOpen(false);
                    // }
                })
                .catch((err: any) => {
                    console.log('err', err)
                    Noti('error', err.reason || err?.message, 3000)

                    setIsModalOpen(false);
                })
        } catch (error: any) {
            console.log('err catch-->', error)
            Noti('error', error, 3000)
            setIsModalOpen(false);

        }
    }

    const checkFlag = async () => {
        try {
            await stakeContract.userMap(address).then((res: any) => {
                if (res) {
                    setUnstakeAmount(res.inUnStakeAmount / 1e18)
                } else {
                    setUnstakeAmount(0)
                }
            })
                .catch((err: any) => {
                    setUnstakeAmount(0)
                    console.log('err userMap', err)
                })
        } catch (error) {
            console.log('error userMap', error)
        }
    }

    useEffect(() => {
        if (stakeContract?.address && address && selectedNetworkId === WORK_CHAIN && props.tabBox === "stake") {
            checkFlag()
        } else {
            setUnstakeAmount(0)
        }

    }, [address, stakeContract?.address, selectedNetworkId, props.tabBox, childData])


    const handleShowNext = () => {
        if (current === 0) {
            // if (address && Number(amountTokenStake) > 0 && ethBalance > 0 && isCheck && unstakeAmount <= 0) {
            if (address && Number(amountTokenStake) > 0 && ethBalance > 0 && isCheck) {
                setShowNext(true)
            } else {
                setShowNext(false)
            }
        }
        if (current === 1) {
            if (Number(amountStake) > 0 && amountStake && Number(amountStake) <= Number(amountTokenStake) && isCheckRule) {
                // if (Number(amountStake) > 0 && amountStake && isCheckRule) {
                setShowNext(true)
            } else {
                setShowNext(false)
            }
        }
        if (current === 2) {

        }

    }

    useEffect(() => {
        handleShowNext()
    }, [address, selectedNetworkId, amountTokenStake, ethBalance, isCheck, current, amountStake, isCheckRule, unstakeAmount, props.tabBox, childData])

    const handleChangeInputStake = (event: any) => {

        let x = event.target.value.replace(/[^0-9.]/g, "");
        if (Number(x) <= amountTokenStake && Number(x) <= maxStake) {
            setAmountStake(x)
            //
            let inputValue = event.target.value;
            const filteredValue = inputValue.replace(/[^0-9.]/g, '');
            const parts = filteredValue.split('.');
            if (parts[0]) {
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }

            const formattedValue = parts.length > 1
                ? `${parts[0]}.${parts[1]}`
                : parts[0];
            setAmountStakeFormat(formattedValue);
        }

    }

    function isAllZeros(str: any) {
        return /^0+$/.test(str);
    }

    const handleAmountMax = () => {
        if (amountTokenStake < maxStake) {
            setAmountStake(amountTokenStake)
            const filteredValue = amountTokenStake.toString().replace(/[^0-9.]/g, '');
            const parts = filteredValue.split('.');
            if (parts[0]) {
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
            if (isAllZeros(parts[1]?.slice(0, 4))) {
                const formattedValue = parts.length > 1
                    ? parts[0]
                    //`${parts[0]}.${parts[1]?.slice(0, 4)}`
                    : parts[0];
                setAmountStakeFormat(formattedValue);
            } else {
                const formattedValue = parts.length > 1
                    ? `${parts[0]}.${parts[1]?.slice(0, 4)}`
                    : parts[0];
                setAmountStakeFormat(formattedValue);
            }
        } else {
            setAmountStake(maxStake - 0.0001)
            const filteredValue = maxStake.toString().replace(/[^0-9.]/g, '');
            const parts = filteredValue.split('.');
            if (parts[0]) {
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
            if (isAllZeros(parts[1]?.slice(0, 4))) {
                const formattedValue = parts.length > 1
                    ? parts[0]
                    //`${parts[0]}.${parts[1]?.slice(0, 4)}`
                    : parts[0];
                setAmountStakeFormat(formattedValue);
            } else {
                const formattedValue = parts.length > 1
                    ? `${parts[0]}.${parts[1]?.slice(0, 4)}`
                    : parts[0];
                setAmountStakeFormat(formattedValue);
            }
        }

    }

    const callbackFunction = (data: any) => {
        setchildData(data)
        props.setIsCheckAction(!props.isCheckAction)
    };
    const callbackFunction1 = (data: any) => {
    };

    const [numberLockTime, setNumberLockTime]: any = useState(0);
    const getNumberLockTime = async () => {
        try {
            await contractStakeNotAddress.methods
                .lockTimePeriod()
                .call()
                .then((res: any) => {
                    setNumberLockTime(Number(res) / (24 * 60 * 60));
                })
                .catch((err: any) => {
                    setNumberLockTime(0);
                });
        } catch (error) {
            setNumberLockTime(0);
        }
    };



    useEffect(() => {
        if (contractStakeNotAddress?._address && props.tabBox === "stake") {
            getNumberLockTime()
        }
    }, [contractStakeNotAddress?._address, props.tabBox])


    console.log('render stake')

    return (
        <div className="wrap-stake">
            {isMobile ? ('') : (
                <div className="line-status">
                    {/* @ts-ignore this lib is incompatible with react18  */}
                    <Steps
                        current={current}>
                        <Step
                            title={renderText1()}
                            icon={renderImg1()}
                        />
                        <Step
                            title={renderText2()}
                            icon={renderImg2()}
                        />
                        <Step
                            title={renderText3()}
                            icon={renderImg3()}
                        />
                        <Step
                            title={renderText4()}
                            icon={renderImg4()}
                        />
                        <Step
                            title={renderText5()}
                            icon={renderImg5()}
                        />
                    </Steps>
                </div>
            )}

            <div className="stake">
                <div className="wrap-all">
                    {endTime ? (
                        <div className="pool-expired">
                            <div className="expired-text">
                                Pool expired. You can't stake more.
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="content-stake">
                                {/* <div className="steps-content">{steps[current].content}</div> */}
                                {current === 0 ? (
                                    <div className="content-step1">
                                        <div className="title">Checkpoints</div>
                                        <div className="des">The following conditions must be met to proceed</div>
                                        <div className="item-step1">
                                            <div className="card-item">
                                                <div className={`${address ? 'check-card acive' : 'check-card'}`}>
                                                    <img src="/images/staking/check-a1.png" alt="" />
                                                    <img src="/images/staking/check-b1.png" alt="" />
                                                </div>
                                                <div className="title-card">Connected with Wallet</div>
                                                <div className="content-card">If not connected, click the "Connect Wallet" button in the top right corner</div>
                                            </div>
                                            <div className="card-item"
                                            >
                                                <div className={`${amountTokenStake > 0 ? 'check-card acive' : 'check-card'}`}>

                                                    <img src="/images/staking/check-a1.png" alt="" />
                                                    <img src="/images/staking/check-b1.png" alt="" />

                                                </div>
                                                <div className="title-card">MEY available to deposit</div>
                                                <div className="content-card cus">Current Balance: {formatNumber(amountTokenStake, NUM_FORMAT)}</div>
                                            </div>
                                            <div className="card-item">
                                                <div className={`${ethBalance > 0 ? 'check-card acive' : 'check-card'}`}>
                                                    <img src="/images/staking/check-a1.png" alt="" />
                                                    <img src="/images/staking/check-b1.png" alt="" />

                                                </div>
                                                <div className="title-card">ETH available in wallet</div>
                                                <div className="content-card">ETH is required to pay transaction fees on the Base Chain network.<br /><span>ETH Balance: {formatNumber(ethBalance, NUM_FORMAT)}</span></div>
                                            </div>
                                            <div className="card-item">
                                                {/* <div className={`${unstakeAmount <= 0 && address ? 'check-card acive' : 'check-card'}`}> */}
                                                <div className={`${address ? 'check-card acive' : 'check-card'}`}>
                                                    <img src="/images/staking/check-a1.png" alt="" />
                                                    <img src="/images/staking/check-b1.png" alt="" />

                                                </div>
                                                <div className="title-card">Eligible to stake</div>
                                                <div className="content-card">
                                                    There is no limit on the number of times and amount of MEY token stake, you can Stake many different times
                                                </div>
                                            </div>
                                        </div>
                                        <div className="check-real" onClick={onChangeAll}>
                                            <Checkbox checked={isCheck} onChange={onChange} /> <span >I have read the <a target="_blank" href="/Term" className="terms">Terms and Conditions</a></span>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {current === 1 ? (
                                            <div className="content-step2">
                                                <div className="title">Amount to Stake</div>
                                                <div className="des">The following conditions must be met to proceed:</div>
                                                <div className="content-input">
                                                    <div className="amount-stake-title">
                                                        <div className="title-left">Staking amount</div>
                                                        <div className="title-right"><span>{formatNumber(amountTokenStake, NUM_FORMAT)}</span> MEY available</div>
                                                    </div>
                                                    <div className="input-amount">
                                                        <input
                                                            onChange={handleChangeInputStake}
                                                            // value={amountStake}
                                                            value={amountStakeFormat}
                                                            placeholder="0"
                                                            className="amount-stake"

                                                        // type="text"
                                                        />
                                                        <div className="posi">
                                                            {/* <span className="palce-token">MEY</span> */}
                                                            <button className="btn-max-stake" onClick={handleAmountMax}>Max</button>
                                                        </div>

                                                    </div>
                                                    <div className="des-amount">
                                                        <div className="staking-rule">Staking rules:</div>
                                                        <ul>
                                                            {/* <li>
                                                        Stake token will be locked for 72 hours. After 72 hours, you can start Unstake, the amount of unstake is arbitrary.
                                                    </li> */}
                                                            <li>
                                                                If you stake in a pool and decide to unstake before the pool expires, a {numberFee}% unstake fee will be charged from the staked amount.

                                                            </li>
                                                            <li>
                                                                The unstake token will be locked for {numberLockTime} days then you can withdraw.
                                                            </li>
                                                            <li>
                                                                There is no limit on the number of times and amount of MEY token stake, you can Stake many different times.
                                                            </li>
                                                            <li>
                                                                Rewards can be claimed at any time and without waiting, returned to the user's wallet. Rewards can be used to continue staking or for other purposes.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="check-rule" onClick={onChangeRuleAll}>
                                                        <Checkbox checked={isCheckRule} onChange={onChangeRule} />
                                                        <span className="text">I understand the staking rules.</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                {current === 2 ? (
                                                    <div className="content-step3">
                                                        <div className="title">Pre-authorization</div>
                                                        <div className="des">Required transaction 1 of 2</div>
                                                        <div className="autho-content">
                                                            <div className="autho-title">In this step, you grant access to the staking smart contract to accept your MEY token</div>
                                                            <div className="autho-progress">
                                                                <Progress
                                                                    strokeColor={{
                                                                        "0%": "#00BCD4",
                                                                        "100%": "#0047FF",
                                                                    }}
                                                                    percent={50}
                                                                    status="active"
                                                                />
                                                            </div>
                                                            <div className="autho-please">Please wait for the transaction to confirm before proceeding.</div>
                                                            {isMobile ? (
                                                                <a className="autho-link" rel="noreferrer" target="_blank" href={`${BASE_SCAN}/address/${address}`}>
                                                                    {address ? (
                                                                        <>
                                                                            {address.slice(0, 10)}...{address.slice(-10)}
                                                                        </>
                                                                    ) : ''}
                                                                </a>
                                                            ) : (
                                                                <a className="autho-link" rel="noreferrer" target="_blank" href={`${BASE_SCAN}/address/${address}`}>{address ? address : ''}</a>
                                                            )}

                                                        </div>
                                                    </div>
                                                ) : (
                                                    <>
                                                        {current === 3 ? (
                                                            <div className="content-step4">
                                                                <div className="title">Confirm</div>
                                                                <div className="des">Required transaction 2 of 2</div>
                                                                <div className="confirm-content">
                                                                    <div className="cf-content1">
                                                                        In this step, you will deposit the tokens into the staking smart contract.
                                                                    </div>
                                                                    <div className="cf-content1">
                                                                        After this step, your tokens will be successfully staked.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                {current === 4 ? (
                                                                    <div className="content-step5">
                                                                        <div className="title">Confirmation</div>
                                                                        <div className="des">Congratulations! Your tokens are now staked.</div>
                                                                        <div className="success-content">
                                                                            <div className="img-success">
                                                                                <img src="/images/staking/stake-success.png" alt="" />
                                                                            </div>
                                                                            <div className="mid-content">Success</div>
                                                                            <div className="mid-content1">Congratulations! Your tokens are now staked.</div>
                                                                            <div className="mid-content2">You can check on Basescan to confirm the transaction.</div>
                                                                            {isMobile ? (
                                                                                <a className="mid-content3" target="_blank" rel="noreferrer" href={`${BASE_SCAN}/tx/${hashStake}`}>
                                                                                    Txn Hash: {hashStake.slice(0, 10)}...{hashStake.slice(-10)}
                                                                                </a>
                                                                            ) : (
                                                                                <a className="mid-content3" target="_blank" rel="noreferrer" href={`${BASE_SCAN}/tx/${hashStake}`}>
                                                                                    <span> Txn Hash:</span> {hashStake.slice(0, 20)}.....{hashStake.slice(-20)}
                                                                                </a>
                                                                            )}

                                                                        </div>
                                                                    </div>
                                                                ) : ('')}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="steps-action">
                                {current > 0 && current !== steps.length - 1 && (
                                    <button className="btn-control" onClick={() => prev()}>
                                        <img src="/images/staking/icon-pre.png" alt="" />
                                    </button>
                                )}
                                {current < steps.length - 1 && (
                                    <>
                                        {showNext ? (
                                            <button className="btn-control" onClick={() => next()}>
                                                <img src="/images/staking/icon-next.png" alt="" />
                                            </button>
                                        ) : (
                                            <button className="btn-control dis">
                                                <img src="/images/staking/icon-next.png" alt="" />
                                            </button>
                                        )}
                                    </>
                                )}
                                {current === steps.length - 1 && (
                                    <button className="btn-control done" onClick={() => window.location.reload()}> Done</button>
                                )}
                            </div>
                        </>
                    )}

                </div>
                {!isMobile ? ('') : (
                    <>
                        <div className="line-status">
                            {/* @ts-ignore this lib is incompatible with react18  */}
                            <Steps
                                current={current}>
                                <Step
                                    title={renderText1()}
                                    icon={renderImg1()}
                                />
                                <Step
                                    title={renderText2()}
                                    icon={renderImg2()}
                                />
                                <Step
                                    title={renderText3()}
                                    icon={renderImg3()}
                                />
                                <Step
                                    title={renderText4()}
                                    icon={renderImg4()}
                                />
                                <Step
                                    title={renderText5()}
                                    icon={renderImg5()}
                                />
                            </Steps>
                        </div>
                        <div className="line-mo"></div>
                    </>

                )}

                {/* <Action
                    scStake={scStake}
                    tokenStake={tokenStake}
                    isSuccess={isSuccess}
                    tabBox={props.tabBox}
                    parentCallback1={callbackFunction1}
                    parentCallback={callbackFunction}
                    endTime={endTime}
                /> */}

                <Action
                    stakeSc={scStake}
                    tokenStake={tokenStake}
                    isSuccess={isSuccess}
                    tabBox={props.tabBox}
                    parentCallback1={callbackFunction1}
                    parentCallback={callbackFunction}
                    time={endTime}
                />

            </div>

            {/* @ts-ignore this lib is incompatible with react18  */}
            <Modal
                title={current === 2 ? "Approving" : "Staking"}
                visible={isModalOpen}
                // onOk={handleOk}
                // onCancel={handleCancel}
                footer={false}
                wrapClassName="modal-confirm"
            >
                <p className="spin-stake">
                    <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 60 }} spin />} />
                </p>
                <p className="modal-text1">Your request is being processed. Please wait...</p>


            </Modal>
            {/* <Button type="primary" onClick={showModal}>
                Open Modal
            </Button> */}
        </div>


    )
}

export default Stake;