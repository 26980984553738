import React, { useEffect, useState } from "react";
import { Input, Dropdown, Drawer } from "antd";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import {
    useAccount,
    useDisconnect,
} from "wagmi";
import "./style.scss";
import { Link, useLocation } from "react-router-dom";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import { WORK_CHAIN } from "../../constants";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppHome } from "../../api/appHome";
import { KYC_STATUS } from "./index.d"
import { isMobile } from "react-device-detect";
import SideBar from "../sidebar";

const HeaderApp = (props: any) => {
    const { address } = useAccount();
    const [dataInfo, setDataInfo]: any = useState();
    const { open } = useWeb3Modal();
    const { selectedNetworkId }: any = useWeb3ModalState();
    const { disconnect } = useDisconnect();
    const [showInput, setShowInput]: any = useState(false);
    const location = useLocation();
    let pathUrl: any = location?.pathname.split("/");
    let pathTitle = pathUrl[2];
    let pathBreadcrum = pathUrl[3];
    const [dataKyc, setDataKyc]: any = useState()
    const [dataKycInit, setDataKycInit]: any = useState()

    const [dataFromSidebar, setDataFromSidebar] = useState()

    const callbackFunction = (childData: any) => {
        setDataFromSidebar(childData)
    }

    const [openDr, setOpenDr] = useState(false);

    const showDrawer = () => {
        setOpenDr(true);
    };

    const onClose = () => {
        setOpenDr(false);
    };

    const handleConnect = () => {
        open();
    };

    const handleDisConnect = () => {
        disconnect();
    };

    const renderTitle = (pathTitle: any) => {
        if (pathTitle) {
            switch (pathTitle) {
                case "home-page":
                    return "Dashboard";
                case "staking":
                    return "Staking";
                case "p2p-lending":
                    return "P2P Lending";
                case "portfolio":
                    return "Welcome to MeyFi";
                case "edit-profile":
                    return "Edit Profile";
                case "vesting":
                    return "Vesting";
                case "pto":
                    return "Property Token Offering";
                case "marketplace":
                    return "Marketplace";
                case "history":
                    return "History";
                case "affiliate":
                    return "Mey Affiliate";
                case "rank":
                    return "My Rank";
                case "verify-email":
                    return "Email Verify";
                case "airdrop":
                    return "Mey Airdrop";
                default:
                    return "NA";
            }
        } else {
            return "Home";
        }
    };

    const AccountInfo = async () => {
        try {
            let res = await AppHome.AccountInfo(address);
            if (res.data.success === true) {
                setDataInfo(res.data.data);
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    useEffect(() => {
        if (address) {
            AccountInfo();
        }
    }, [address]);

    const GetDataKycInit = async () => {
        let params = {
            ownerAddress: address
        }
        try {
            let res = await AppHome.getDataKycInit(
                params
            );
            if (res.data.success === true) {
                setDataKycInit(res.data.data);
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };


    const GetDataKyc = async () => {
        let params = {
            ownerAddress: address
        }
        try {
            let res = await AppHome.getDataKyc(
                params
            );
            if (res.data.success === true) {
                setDataKyc(res.data.data);
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };



    useEffect(() => {
        if (address) {
            if (dataKyc === null || dataKyc === undefined) {
                GetDataKycInit()
            }
        }
    }, [dataKyc, address]);

    useEffect(() => {
        if (address) {
            GetDataKyc()
        }
    }, [address]);

    const renderStatus = (numStt: any) => {
        switch (numStt) {
            case KYC_STATUS.SUBMISSION_REQUIRED:
                return "KYC-Please complete verification"
            case KYC_STATUS.PENDING:
                return "Pending"
            case KYC_STATUS.VERIFIED:
                return "Verified"
            case KYC_STATUS.CANCELLED:
                return "Cancel"
            default:
                return "KYC-Please complete verification"
        }
    }

    const userNameCut = dataInfo?.dataAccount?.firstName.length > 10
        ? `${dataInfo.dataAccount.firstName.slice(0, 4)}...${dataInfo.dataAccount.firstName.slice(-4)}`
        : dataInfo?.dataAccount?.firstName;

    const rowUserInfo = () => {
        return (
            <>
                <div className="wrap-user-info">
                    <div className="top-wrap">
                        <div className="box-img">
                            <img src={dataInfo?.dataAccount?.avatar} alt="" />
                        </div>
                        <div className="name-user">
                            <div className="line-name">
                                {userNameCut}{" "}
                                {dataInfo?.dataAccount?.isKYC ? (
                                    <img src="/images/lending/very.png" alt="" />
                                ) : (
                                    <img src="/images/lending/very-false.png" alt="" />
                                )}
                            </div>
                            <div className="line-address">
                                {address?.slice(0, 4)}...{address?.slice(-4)}
                                <CopyToClipboard
                                    text={`${address}`}
                                    onCopy={() => {
                                        toast.success("Copied success", {
                                            position: "top-right",
                                            autoClose: 2000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                            theme: "light",
                                        });
                                    }}
                                >
                                    <span>
                                        <img src="/images/copy-user.png" alt="" />
                                    </span>
                                </CopyToClipboard>
                            </div>
                        </div>
                    </div>
                    <div className="kyc-row">
                        {dataKyc?.status === 2 ? "" : <img src="/images/warning.png" alt="" />}  {renderStatus(dataKyc?.status)}
                    </div>
                    <div className="menu-center">
                        <ul className="list-user-menu">
                            <div onClick={() => handleSetkey(7)}>
                                <Link to="/apps/portfolio">
                                    <li>
                                        <img src="/images/user/set-res.png" alt="" /> Account settings
                                    </li>
                                </Link>
                            </div>
                            <Link to="/apps/rank">
                                <li>
                                    <img src="/images/user/ranked.png" alt="" /> My Rank
                                </li>
                            </Link>
                            <Link to="/apps/edit-profile">
                                <li>
                                    <img src="/images/user/kyc-icon.png" alt="" /> KYC
                                </li>
                            </Link>
                            <a href={`https://basescan.org/address/${address}`} target="blank">
                                <li>
                                    <img src="/images/user/share-link.png" alt="" /> View on BaseScan
                                </li>
                            </a>
                        </ul>
                    </div>
                    <div className="menu-bottom">
                        <ul className="list-user-menu">
                            <li onClick={handleDisConnect}>
                                <img src="/images/user/logout.png" alt="" /> Logout
                            </li>
                        </ul>
                    </div>
                </div>
            </>
        );
    };

    const handleSetkey = (key: any) => {
        sessionStorage.setItem("keyActiveMenu", key)
        window.location.reload()
    }

    return (
        <>
            <div className="main-header-app header-mobile">
                <div className="left-app">
                    <div className="title">
                        <div className="text-2xl font-normal text-white font-radioCanada">
                            {pathBreadcrum === "details" ? (
                                <>
                                    {pathTitle === "staking" ? (
                                        <>
                                            <div className="breadcrum-line">
                                                <div className="item-bread">
                                                    <Link to="/apps/staking">
                                                        <img src="/images/staking.png" alt="" />{" "}
                                                        <span>Staking</span>
                                                    </Link>
                                                </div>
                                                <div className="item-bread">
                                                    <div className="vector-r">
                                                        <img src="/images/vector-s.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="item-bread active">
                                                    <span>Details</span>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {pathTitle === "p2p-lending" ? (
                                                <>
                                                    <div className="breadcrum-line">
                                                        <div className="item-bread">
                                                            <Link to="/apps/p2p-lending">
                                                                <img src="/images/laning.png" alt="" />{" "}
                                                                <span>P2P Lending</span>
                                                            </Link>
                                                        </div>
                                                        <div className="item-bread">
                                                            <div className="vector-r">
                                                                <img src="/images/vector-s.png" alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="item-bread active">
                                                            <span>Property Details</span>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    {pathTitle === "pto" ? (
                                                        <>
                                                            <div className="breadcrum-line">
                                                                <div className="item-bread">
                                                                    <Link to="/apps/pto">
                                                                        <img src="/images/menu/pto-1.png" alt="" />{" "}
                                                                        <span>PTO</span>
                                                                    </Link>
                                                                </div>
                                                                <div className="item-bread">
                                                                    <div className="vector-r">
                                                                        <img src="/images/vector-s.png" alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="item-bread active">
                                                                    <span>Details</span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="breadcrum-line">
                                                                <div className="item-bread">
                                                                    <Link to="/apps/home-page">
                                                                        <img src="/images/home.png" alt="" />{" "}
                                                                        <span>Home</span>
                                                                    </Link>
                                                                </div>
                                                                <div className="item-bread">
                                                                    <div className="vector-r">
                                                                        <img src="/images/vector-s.png" alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="item-bread active">
                                                                    <span>Property Details</span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            ) : (
                                <>{renderTitle(pathTitle)}</>
                            )}
                        </div>
                    </div>
                </div>
                <div className="right-app">
                    <div className="row-item">
                        <div className="live-on">
                            <div className="dot-live">🟢</div>
                            <div className="text-live">Live on BASE Mainnet</div>
                        </div>
                        <div className="onclick" onClick={() => handleSetkey(2)}>
                            <Link to="/apps/staking">
                                <div className="item res">
                                    <img src="/images/icon-earn.png" alt="" />
                                </div>
                            </Link>
                        </div>
                        {isMobile ?
                            <>
                                <Link to="/apps">
                                    <div className="box-logo-mobile">
                                        <img src="/images/logo.png" alt="" />
                                    </div>
                                </Link>
                            </>
                            :
                            <>
                                {""}
                            </>
                        }
                        {selectedNetworkId === WORK_CHAIN ? (
                            <>
                                <div className="item mobile-left">
                                    <img src="/images/base.png" alt="" />
                                </div>
                            </>
                        ) : (
                            <>{""}</>
                        )}
                        {address ? (
                            <>
                                <Dropdown overlay={rowUserInfo} placement="bottom">
                                    <div className="item row">
                                        {isMobile ?
                                            <>
                                                <img src="/images/wallet-s.png" alt="" />
                                            </>
                                            :
                                            <>
                                                <img src="/images/wallet-s.png" alt="" /> <span className="text-wallet">{address?.slice(0, 4)}...{address?.slice(-4)}</span>
                                            </>
                                        }
                                    </div>
                                </Dropdown>
                            </>
                        ) : (
                            <>
                                {isMobile ?
                                    <>
                                        <div className="ant-dropdown-trigger  mobile-left item" onClick={handleConnect}>
                                            <button className="btn btn-connect" onClick={handleConnect}>
                                                Connect Wallet
                                            </button>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="connect-wallet mar-l-15">
                                            <button className="btn btn-connect" onClick={handleConnect}>
                                                Connect Wallet
                                            </button>
                                        </div>
                                    </>
                                }
                            </>
                        )}
                        {isMobile ?
                            <>
                                <div className="bar-mobile">
                                    <div className="box-img">
                                        {openDr ?
                                            <>
                                                <img src="/images/bar-mobile-non-active.png" alt="" onClick={onClose} />
                                            </>
                                            :
                                            <>
                                                <img src="/images/bar-mobile-active.png" alt="" onClick={showDrawer} />
                                            </>
                                        }
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                {""}
                            </>
                        }
                    </div>
                </div>
            </div>
            <Drawer title="Basic Drawer" className="drawer-mobile" onClose={onClose} open={openDr}>
                <SideBar callbackFunction={callbackFunction} />
            </Drawer>
        </>
    );
};
export default HeaderApp;
