import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import clsx from "clsx";

export interface CustomTableProps {
  dataSource: Array<object>;
  hiddenHeader?: boolean;
  isLoading?: boolean;
  columns: Array<any>;
  bordered?: boolean;
  id?: string;
  isStriped?: boolean;
  rowClassName?: (record: any) => string;
  className?: string;
  scroll?: any;
}

export const CustomTable = ({
  dataSource,
  hiddenHeader,
  isLoading,
  columns,
  bordered,
  id,
  isStriped,
  rowClassName,
  className,
  scroll,
}: CustomTableProps) => {
  return (
    <Table
      rowClassName={(record, index) =>
        clsx(
          "hover:bg-primary/20 transitions",
          isStriped
            ? index % 2 === 0
              ? "bg-[rgba(59,63,69,0.2)]"
              : "bg-transparent"
            : "",
          rowClassName ? rowClassName?.(record) : "!bg-transparent",
        )
      }
      id={id}
      className={clsx("!mt-0", className)}
      dataSource={dataSource}
      showHeader={!hiddenHeader}
      loading={isLoading}
      scroll={scroll}
      columns={columns}
      pagination={false}
      tableLayout="fixed"
      size="small"
      bordered={bordered}
    />
  );
};
