import React from "react";

export const Map = (data: any) => {
    const latitude = data?.data?.latitude;
    const longitude = data?.data?.longitude;
    return (
        <>
            <div className="map-api-box">
                <iframe
                    src={`https://meeyatlas.com/map-pb/#z15/${latitude}}/${longitude}`}
                    style={{ width: "100%", height: "450px", border: "none" }}
                    allowFullScreen
                    loading="lazy"
                    title="Meey Atlas Map"
                ></iframe>
            </div>
        </>
    )
};
