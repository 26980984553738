import React, { useEffect, useState } from "react";
import { SellFormMarketplace } from "../../../../../types/marketplace";
import { Input, Modal, Spin } from "antd";
import IconUSDT from "../../../../../assets/icons/svg/icon-usdt.svg";
import { LoadingOutlined } from '@ant-design/icons';
import ptoNftABI from "../../utils/ptoNftABI.json"
import nftMarketABI from "../../utils/nftMarketABI.json"
import { useContract } from "../../../../../hooks/useContract";
import { RPC_BASE } from "../../../../../constants";
import { Noti } from "../../../../../components/Noti/toast";
import { useAccount } from "wagmi";
import { ethers } from "ethers";
import { AppHome } from "../../../../../api/appHome";
import web3 from "web3";

export const SellTab = (props: any) => {
    const dataDetail = props?.dataDetail
    const { address } = useAccount();
    const [sellForm, setSellForm]: any = useState<SellFormMarketplace>({ percent: 0, price: 0 });
    const [loading, setLoading]: any = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const contractPto = dataDetail?.ptoContract
    const contractNft: any = useContract(contractPto, ptoNftABI)
    const contractApi: any = dataDetail && dataDetail?.contractAddress
    const contractMarket: any = useContract(contractApi, nftMarketABI)
    const [lenderTotalBalanceByUser, setLenderTotalBalanceByUser]: any = useState(0)
    const [valueAmount, setValueAmount]: any = useState("")
    const [valueAmountUI, setValueAmountUI]: any = useState("")
    const valuePrice = Number(sellForm.quantity) * Number(valueAmount)
    const [loadingApprove, setLoadingApprove] = useState(false)
    const [isApprove, setIsApprove] = useState(false)
    const [notified, setNotified]: any = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleClickMax = () => {
        setSellForm((oldValue: any) => {
            return {
                ...oldValue,
                quantity: lenderTotalBalanceByUser,
            };
        })
    };

    const handleChangeForm = (value: string, field: string) => {
        const floatRegExp = new RegExp("^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$");
        if (floatRegExp.test(value) || value === "") {
            if (value >= lenderTotalBalanceByUser) {
                setSellForm((oldValue: any) => {
                    return {
                        ...oldValue,
                        [field]: lenderTotalBalanceByUser,
                    };
                });
            } else {
                setSellForm((oldValue: any) => {
                    return {
                        ...oldValue,
                        [field]: value ? Number(value) : 0,
                    };
                });
            }
        }
    };

    const handleChangeFormAmount = (e: any) => {
        const newValue = e.target.value;
        const cleanedValue = newValue.replace(/[^0-9.]/g, '');
        const numericValue = cleanedValue.includes('.')
            ? cleanedValue.split('.').slice(0, 2).join('.')
            : cleanedValue;
        const [integerPart, decimalPart] = numericValue.split('.');
        const formattedInteger = integerPart ? integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
        const formattedValue = decimalPart !== undefined
            ? `${formattedInteger}.${decimalPart}`
            : formattedInteger;

        setValueAmount(numericValue);
        setValueAmountUI(formattedValue);
    };

    const handleChangeQuantity = (type: string) => {
        if (type === "minus") {
            setSellForm((oldValue: any) => {
                if (sellForm.quantity <= 0) {
                    return {
                        ...oldValue,
                        quantity: 0,
                    };
                } else {
                    return {
                        ...oldValue,
                        quantity: (oldValue.quantity || 0) - 1,
                    };
                }

            });
        } else {
            setSellForm((oldValue: any) => {
                if (sellForm.quantity >= lenderTotalBalanceByUser) {
                    return {
                        ...oldValue,
                        quantity: lenderTotalBalanceByUser,
                    };
                } else {
                    return {
                        ...oldValue,
                        quantity: (oldValue.quantity || 0) + 1,
                    };
                }

            });
        }
    };

    const handleApprove = async () => {
        setLoadingApprove(true)
        try {
            await contractNft.setApprovalForAll(contractApi, true).then((res: any) => {
                res.wait()
                    .then((resWait: any) => {
                        if (resWait?.status === 1) {
                            Noti('success', "Approved successfully", 3000)
                            setLoadingApprove(false)
                            setIsApprove(true)
                        } else {
                            Noti('error', "Unknown error (status hash fail)", 3000)
                            setLoadingApprove(false)
                        }
                    })
                    .catch((errWait: any) => {
                        console.log('errWait', errWait)
                        setLoadingApprove(false)
                    })
            })
                .catch((err: any) => {
                    console.log('err', err)
                    setLoadingApprove(false)
                    Noti('error', err?.reason || err?.message || "Unknown error", 3000)
                })
        } catch (error: any) {
            console.log('error', error)
            if (error.code === ethers.errors.CALL_EXCEPTION) {
                Noti('error', error.error?.data?.message || "Unknown error", 3000)
            } else {
                Noti('error', error || "Unknown error", 3000)
            }
            setLoadingApprove(false)
        }
    }

    const getAmountApprove = async () => {
        await contractNft?.isApprovedForAll(address, contractApi).then((res: any) => {
            if (res) {
                setIsApprove(true)
            } else {
                setIsApprove(false)
            }
        })
    }

    useEffect(() => {
        if (address && contractNft && contractApi) {
            getAmountApprove()
        }
    }, [contractNft, address, contractApi]);

    const handleSell = async () => {
        setLoading(true)
        let amount = web3.utils.toWei(valueAmount.toString(), "picoether");
        console.log('sellForm.quantity, amount', sellForm.quantity, valueAmount)
        try {
            await contractMarket.listNFTBatch(contractPto, sellForm.quantity, amount).then(async (res: any) => {
                let params = {
                    quantity: sellForm.quantity,
                    price: valueAmount,
                    code: dataDetail?.code,
                    txHash: res?.hash
                }
                try {
                    let resApi = await AppHome.postDataSellMarket(
                        params
                    );
                    console.log('resApi', resApi)
                } catch (error) {
                    console.log("Error: ", error);
                }
                res.wait().then(async (resWait: any) => {
                    if (resWait?.status === 1) {
                        Noti('success', "Sell NFT successfully", 3000)
                        setLoading(false)
                        setIsModalOpen(false);
                        setValueAmount(0)
                        setSellForm(0)
                        setValueAmountUI(0)
                    } else {
                        Noti('error', "Unknown error (status hash fail)", 3000)
                        setLoading(false)
                        setIsModalOpen(false);
                    }
                })
            })
                .catch((err: any) => {
                    console.log('err', err)
                    setLoading(false)
                    setIsModalOpen(false);
                    Noti('error', err?.reason || err?.message || "Unknown error", 3000)
                })
        } catch (error: any) {
            console.log('error', error)
            setLoading(false)
            setIsModalOpen(false);
            if (error.code === ethers.errors.CALL_EXCEPTION) {
                Noti('error', error.error?.data?.message || "Unknown error", 3000)
            } else {
                console.log('error', error)
                Noti('error', error || "Unknown error", 3000)
            }
        }
    }

    const getLenderTotalBalanceByUser = async () => {
        if (contractNft && address) {
            let totalBalance = await contractNft?.getAllTokensOfOwner(address)
            setLenderTotalBalanceByUser(Number(totalBalance.length) || 0)
        }
    }

    useEffect(() => {
        getLenderTotalBalanceByUser()
    }, [address, contractNft]);

    return (
        <>
            <div className="p-2 flex flex-col gap-y-3 mt-3">
                <div className="flex justify-between items-center text-sm">
                    <div className="text-white/60">Quantity</div>
                    <div className="text-white/40">
                        Balance: <span className="text-white">{lenderTotalBalanceByUser ? lenderTotalBalanceByUser : 0} NFT </span>
                        <span
                            onClick={handleClickMax}
                            className="hover:cursor-pointer text-primary"
                        >
                            Max
                        </span>
                    </div>
                </div>
                <Input
                    onChange={(e) => handleChangeForm(e.target.value, "quantity")}
                    value={sellForm.quantity ? sellForm.quantity : ""}
                    placeholder={"0.00"}
                    suffix={
                        <div className="flex gap-x-1.5 items-center z-999">
                            <div
                                onClick={() => handleChangeQuantity("minus")}
                                className="rounded-[8px] bg-[#222528] w-9 h-9 flex justify-center items-center border-transparent border hover:border-primary hover:bg-primary/20 transition"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="2"
                                    viewBox="0 0 14 2"
                                    fill="none"
                                >
                                    <path
                                        d="M13 1L1 0.999999"
                                        stroke="white"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                    />
                                </svg>
                            </div>
                            <div
                                onClick={() => handleChangeQuantity("plus")}
                                className="rounded-[8px] bg-[#222528] w-9 h-9 flex justify-center items-center border-transparent border hover:border-primary hover:bg-primary/20 transition"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                >
                                    <path
                                        d="M7 1V13"
                                        stroke="white"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                    />
                                    <path
                                        d="M13 7L1 7"
                                        stroke="white"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                    />
                                </svg>
                            </div>
                        </div>
                    }
                    className="row-input-css sell-input h-12 w-full p-1.5 !bg-[rgba(3,3,3,0.4)] rounded-[12px] border-none outline-0 text-white placeholder:text-white/40 font-medium text-[18px]"
                />
                <div className="text-white/60 text-start">Price</div>
                <Input
                    onChange={handleChangeFormAmount}
                    value={valueAmountUI}
                    placeholder={"0.00"}
                    suffix={
                        <div
                            onClick={() => handleChangeQuantity("minus")}
                            className="rounded-[8px] bg-[#222528] px-2.5 h-9 flex gap-x-2.5 items-center border-transparent border hover:border-primary hover:bg-primary/20 transition"
                        >
                            <img width={20} height={20} src="/images/lending/usd-coin.png" alt="" />
                            <div className="text-white font-medium">USDC</div>
                        </div>
                    }
                    className="row-input-css sell-input h-12 w-full p-1.5 !bg-[rgba(3,3,3,0.4)] rounded-[12px] border-none outline-0 text-white placeholder:text-white/40 font-medium text-[18px]"
                />
            </div>
            <div className="flex-shrink-0 p-3 bg-[rgba(59,63,69,0.4)] rounded-b-[8px] backdrop-blur-[25px] mat-top-40x">
                <div className="flex flex-col gap-y-2.5">
                    <div className="flex justify-between items-center">
                        <div className="flex gap-x-2.5 items-center">
                            <div className="text-[16px] font-medium text-white">
                                You Receive
                            </div>
                        </div>
                        <div className="flex gap-x-1.5 items-center">
                            <div className="text-sm text-white font-medium">{valuePrice ? new Intl.NumberFormat('en-US').format(valuePrice) : 0} USDC</div>
                            {valuePrice.toLocaleString().length > 9 ?
                                <>
                                    {""}
                                </>
                                :
                                <>
                                    <div className="text-sm text-white/40">~${valuePrice ? new Intl.NumberFormat('en-US').format(valuePrice) : 0}</div>
                                </>
                            }
                        </div>
                    </div>
                    <div className="flex justify-between items-center">
                        <div className="text-white/60 text-md">Average Price</div>
                        <div className="text-white font-medium text-md">${valuePrice ? new Intl.NumberFormat('en-US').format(valuePrice) : 0} USDC</div>
                    </div>
                    {Number(sellForm.quantity) > 0 && Number(valueAmount) > 0 ?
                        <>
                            {isApprove ?
                                <>
                                    <button className="btn-buy-market" onClick={showModal}>
                                        Sell
                                    </button>
                                </>
                                :
                                <>
                                    {loadingApprove ?
                                        <>
                                            <button className="btn-buy-market dis">
                                                Approve <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                                            </button>
                                        </>
                                        :
                                        <>
                                            <button className="btn-buy-market" onClick={handleApprove}>
                                                Approve
                                            </button>
                                        </>
                                    }
                                </>
                            }
                        </>
                        :
                        <>
                            <button className="btn-buy-market dis">
                                Sell
                            </button>
                        </>
                    }
                    <div className="text-xs text-white/60 font-medium -mx-2.5 padd-l-10px">
                        Buy clicking "Sell", you agree to the MeyFi Term of Service.
                    </div>
                </div>
                <Modal className="modal-purchase" maskClosable={false} title="Confirm Sell" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    <div className="confirm-box">
                        <div className="box-img">
                            <img src="/images/lending/pur.png" alt="" />
                        </div>
                        <div className="title">
                            Confirm your sell
                        </div>
                        <div className="guide-confirm-project">
                            <div className="name-pr">
                                {dataDetail?.name}
                            </div>
                            <div className="item">
                                <div className="txt-l">
                                    Quantity:
                                </div>
                                <div className="txt-r">
                                    {sellForm?.quantity}
                                </div>
                            </div>
                            <div className="item">
                                <div className="txt-l">
                                    Price:
                                </div>
                                <div className="txt-r">
                                    {new Intl.NumberFormat('en-US').format(valueAmount)} USDC
                                </div>
                            </div>
                            <div className="item">
                                <div className="txt-l">
                                    Total Price:
                                </div>
                                <div className="txt-r">
                                    {new Intl.NumberFormat('en-US').format(valuePrice)} USDC
                                </div>
                            </div>
                        </div>
                        <div className="group-button">
                            {loading ?
                                <>
                                    <button className="btn-actions dis">
                                        Confirm <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                                    </button>
                                </>
                                :
                                <>
                                    <button onClick={handleSell} className="btn-actions">
                                        Confirm
                                    </button>
                                </>
                            }
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
};
