/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { AppHome } from "../../../api/appHome";
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { useLocation } from "react-router-dom";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { isMobile } from "react-device-detect";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

const ChartMarket = (props: any) => {
    const code = props?.code
    const [dataChart, setDataChart]: any = useState()

    const location = useLocation()
    let pathUrl: any = location?.pathname.split("/");
    let pathTitle = pathUrl[2];

    const SettingDataChart = async () => {
        try {
            if (pathTitle === 'p2p-lending') {
                let res = await AppHome.chartMarket(code);
                if (res.data.success === true) {
                    setDataChart(res.data.data);
                }
            } else if (pathTitle === 'pto') {

                let res = await AppHome.chartMarketPTO(code);
                if (res.data.success === true) {
                    setDataChart(res.data.data);
                }
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };


    useEffect(() => {
        SettingDataChart()
    }, [pathTitle]);


    const data: any = {
        labels: dataChart?.labels,
        datasets: [
            {
                label: pathTitle === 'p2p-lending' ? 'Property value' : 'Property value',
                type: 'bar',
                data: dataChart && dataChart?.datasets[0]?.data[0].data,
                backgroundColor: '#2965ffb5',
                borderColor: '#2965ffb5',
                borderWidth: 1,
                barThickness: isMobile ? 20 : 35,
            },
            {
                label: pathTitle === 'p2p-lending' ? 'Investment value' : 'Investment value',
                type: 'line',
                data: dataChart && dataChart?.datasets[1]?.data[0].data,
                fill: false,
                borderColor: '#fff',
                backgroundColor: '#fff',
                borderWidth: 2,
                tension: 0.4,
                pointRadius: 4,
            },
        ],
    };

    const options: any = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: pathTitle === 'p2p-lending' ? false : true,
                position: "top",
            },
            title: {
                display: true,
                enabled: true,
            },
            tooltip: {
                enabled: false,
                backgroundColor: "#3B3F45",
                titleFont: {
                    size: 16,
                    weight: "bold",
                    family: "Inter",
                },
                bodyFont: {
                    size: 14,
                    family: "Inter",
                    weight: "bold",
                },
                padding: 15,
                displayColors: false,
                callbacks: {
                    title: function (tooltipItems: any) {
                        return `${tooltipItems[0].label}`;
                    },
                    label: function (tooltipItem: any) {
                        return `Property Value`;
                    },
                    afterLabel: function (tooltipItem: any) {
                        return `${tooltipItem.formattedValue}$`;
                    },
                },
            },
            datalabels: {
                opacity: isMobile ? 0 : 1,
                anchor: "end",
                align: "top",
                color: "#fff",
                font: {
                    size: 12,
                    weight: "bold",
                },
                formatter: (value: any) => {
                    return `${new Intl.NumberFormat('en-US').format(value)}$`;
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: "white",
                },
            },
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: "white",
                },
            },
        },
    };

    return (
        <>
            <div className="grow-chart-market">
                {!dataChart ? (
                    <div className="row-info">
                        <div className="box-no-data-pto">
                            <img className='img-empty' src="/images/empty1.png" alt="" />
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="title">
                            Research by Meey Value AI & Big Data
                        </div>
                        <div className="chart-market-box">
                            <Bar data={data} options={options} />
                        </div>
                        <div className="linked-value">
                            <a href="https://meeyvalue.com" target="blank">
                                Research by Meey Value AI & Big Data: <img src="/images/logo-mey-value.svg" alt="" />
                            </a>
                        </div>
                    </>
                )}

            </div>
        </>
    );
}
export default ChartMarket