import React, { useEffect, useState } from "react";
import { Modal, Slider, Spin } from "antd";
import IconCheckFilled from "../../../../../assets/icons/svg/icon-check-filled.svg";
import IconPlusSquare from "../../../../../assets/icons/svg/icon-plus-square.svg";
import { useAccount } from "wagmi";
import Web3 from "web3"
import abiTokenStake from "../../utils/abiTokenStake.json"
import { useWeb3Modal } from "@web3modal/wagmi/react";
import nftMarketABI from "../../utils/nftMarketABI.json"
import { useContract } from "../../../../../hooks/useContract";
import { Noti } from "../../../../../components/Noti/toast";
import { LoadingOutlined } from '@ant-design/icons';
import { ethers } from "ethers";
import { RPC_BASE } from "../../../../../constants";
import "../../style.scss"
import { AppHome } from "../../../../../api/appHome";
import ptoNftABI from "../../utils/ptoNftABI.json"
import { AbiItem } from "web3-utils";

export const BuyTab = (props: any) => {
    const { open } = useWeb3Modal();
    const { address } = useAccount();
    const dataDetail = props?.dataDetail;
    const [dataContract, setdataContract]: any = useState()
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [sliderTotalPrice, setSliderTotalPrice] = useState(0);
    const [totalQuantity, setTotalQuantity]: any = useState(0)
    const contractApi: any = dataDetail && dataDetail?.contractAddress
    const contractMarket: any = useContract(contractApi && contractApi, nftMarketABI)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading]: any = useState(false)
    const [feeData, setFeeData]: any = useState(null);
    const [dataSell, setDataSell]: any = useState([])
    const [tokenIds, setTokenIds]: any = useState([]);
    const [loadingApprove, setLoadingApprove] = useState(false)
    const [isApprove, setIsApprove] = useState(false)
    const contractPto = dataDetail?.ptoContract
    const contractNft: any = useContract(contractPto, ptoNftABI)
    const amountTotal = sliderTotalPrice
    let scUsdt = dataContract && dataContract?.p2p_deposit_token_address
    const contractUsdt: any = useContract(scUsdt, abiTokenStake);
    let web3: any = new Web3(RPC_BASE);
    const [OrderData, setOrderData] = useState([])
    const priceOneQuantity = Number(sliderTotalPrice) / totalQuantity

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleConnect = () => {
        open();
    };

    const calculateTotalPrice = (items: any[]) => {
        return items.reduce((acc, item) => acc + item.total, 0);
    };

    const calculateTotalQuantity = (items: any[]) => {
        return items.reduce((acc, item) => acc + item.quantity, 0);
    };

    const SettingContract = async () => {
        try {
            let res = await AppHome.getSettingContract();
            if (res.data.success === true) {
                setdataContract(res.data.data);
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    useEffect(() => {
        SettingContract()
    }, []);

    const handleSliderChange = (value: any) => {
        setSliderTotalPrice(value);

        let currentTotal = 0;
        const closestItems: any = [];

        for (const item of dataSell) {
            const itemTotal: any = item.total ?? 0;
            if (currentTotal + itemTotal <= value) {
                closestItems.push(item);
                currentTotal += itemTotal;
            } else {
                break;
            }
        }

        setSelectedItems(closestItems);
        setTotalQuantity(calculateTotalQuantity(closestItems));

        const tokenIds = closestItems.flatMap((item: any) =>
            item.orderIds.map((order: any) => order.orderId)
        );
        setTokenIds(tokenIds);

        const tokenOrderPairs = closestItems.flatMap((item: any) =>
            item.orderIds.map((order: any) => ({
                tokenId: order.tokenId,
                orderId: order.orderId
            }))
        );

        setOrderData(tokenOrderPairs);
    };

    const handleItemClick = (item: any) => {
        let updatedItems: any;
        if (selectedItems.includes(item)) {
            updatedItems = selectedItems.filter((i) => i !== item);
        } else {
            updatedItems = [...selectedItems, item];
        }

        setSelectedItems(updatedItems);
        const total = calculateTotalPrice(updatedItems);
        setSliderTotalPrice(total);
        setTotalQuantity(calculateTotalQuantity(updatedItems));

        const tokenIds = updatedItems.flatMap((item: any) =>
            item.orderIds.map((order: any) => order.orderId)
        );

        setTokenIds(tokenIds);

        const tokenOrderPairs = updatedItems.flatMap((item: any) =>
            item.orderIds.map((order: any) => ({
                tokenId: order.tokenId,
                orderId: order.orderId
            }))
        );

        setOrderData(tokenOrderPairs);
    };

    const handleApprove = async () => {
        setLoadingApprove(true)
        try {
            await contractUsdt.approve(contractApi, ethers.constants.MaxUint256).then((res: any) => {
                res.wait()
                    .then((resWait: any) => {
                        if (resWait?.status === 1) {
                            Noti('success', "Approved successfully", 3000)
                            setLoadingApprove(false)
                            setIsApprove(true)
                        } else {
                            Noti('error', "Unknown error (status hash fail)", 3000)
                            setLoadingApprove(false)
                        }
                    })
                    .catch((errWait: any) => {
                        console.log('errWait', errWait)
                        setLoadingApprove(false)
                    })
            })
                .catch((err: any) => {
                    console.log('err', err)
                    setLoadingApprove(false)
                    Noti('error', err?.reason || err?.message || "Unknown error", 3000)
                })
        } catch (error: any) {
            console.log('error', error)
            if (error.code === ethers.errors.CALL_EXCEPTION) {
                Noti('error', error.error?.data?.message || "Unknown error", 3000)
            } else {
                Noti('error', error || "Unknown error", 3000)
            }
            setLoadingApprove(false)
        }
    }

    const getAmountApprove = async () => {
        await contractUsdt?.allowance(address, contractApi).then((res: any) => {
            if (res) {
                let balance: any = web3.utils.fromWei(res, "picoether");
                if (Number(balance) >= Number(amountTotal)) {
                    setIsApprove(true)
                } else {
                    setIsApprove(false)
                }
            }
        })
    }

    useEffect(() => {
        if (address && contractNft && contractApi) {
            getAmountApprove()
        }
    }, [contractNft, address, contractApi]);

    const contractLendingNotAddress: any = new web3.eth.Contract(
        nftMarketABI as unknown as AbiItem,
        contractApi
    );

    const handleBuyNft = async () => {
        setLoading(true);
        if (!address || !contractLendingNotAddress || !contractMarket) {
            Noti('error', "Missing necessary contract or address information", 3000);
            setLoading(false);
            return;
        }

        try {
            const gasPrice = BigInt(await web3.eth.getGasPrice());
            let gasEstimate;

            try {
                gasEstimate = await contractLendingNotAddress.methods.buyNFTBatch(tokenIds).estimateGas({
                    from: address,
                });
            } catch (estimateError) {
                console.error("Failed to estimate gas:", estimateError);
                Noti('error', "Failed to estimate gas. Please check the contract or your input.", 3000);
                setLoading(false);
                return;
            }

            let res;
            try {
                res = await contractMarket.buyNFTBatch(tokenIds, {
                    gasLimit: gasEstimate.toString(),
                    gasPrice: gasPrice.toString(),
                });
            } catch (txError: any) {
                console.error("Transaction failed:", txError);
                Noti('error', txError?.reason || txError?.message || "Transaction failed. Please try again.", 3000);
                setLoading(false);
                return;
            }

            const params = {
                code: dataDetail?.code,
                orders: OrderData,
                txHash: res?.hash,
            };

            try {
                await AppHome.postDataBuyMarket(params);
            } catch (apiError) {
                console.error("Error in API call:", apiError);
                Noti('error', "Failed to send data to the server. Please try again later.", 3000);
            }

            const resWait = await res.wait();
            if (resWait?.status === 1) {
                try {
                    const res = await AppHome.getDataSellMarket(dataDetail?.code);
                    console.log("Data Sell Response:", res);
                    if (res.data.success) {
                        setDataSell(res.data.data);
                    } else {
                        console.error("Failed to fetch sell data. Response:", res.data);
                    }
                } catch (dataSellError) {
                    console.error("Error fetching sell data:", dataSellError);
                    Noti('error', "Failed to fetch sell data. Please refresh the page.", 3000);
                }

                Noti('success', "Purchase successful", 3000);
                setSliderTotalPrice(0);
                window.location.reload();
            } else {
                Noti('error', "Transaction failed. Status hash not successful.", 3000);
            }

            setIsModalOpen(false);
        } catch (error: any) {
            console.error("Unexpected error:", error);
            Noti('error', error?.reason || error?.message || "An unexpected error occurred. Please try again.", 3000);
        } finally {
            setLoading(false);
        }
    };



    const fetchNetworkFee = async () => {
        try {
            const provider = new ethers.providers.JsonRpcProvider(RPC_BASE);
            const fee: any = await provider.getFeeData();
            setFeeData(ethers.utils.formatUnits(fee?.gasPrice, "gwei"));
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchNetworkFee();
    }, []);

    const getDataMarket = async () => {
        try {
            let res = await AppHome.getDataSellMarket(dataDetail?.code);
            if (res.data.success === true) {
                setDataSell(res.data.data);
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    useEffect(() => {
        if (dataDetail?.code) {
            getDataMarket()
        }
    }, [dataDetail]);

    const ItemsBuy = ({ item }: { item: any }) => {
        const isActive = selectedItems.includes(item);

        return (
            <div
                className="px-2 h-[50px] grid grid-cols-3 border-b border-b-[rgba(174,171,255,0.2)] items-center"
                onClick={() => handleItemClick(item)}
            >
                <div className="flex gap-x-2 justify-start">
                    <div className="hover:cursor-pointer transition">
                        {isActive ? (
                            <img alt="" src={IconCheckFilled} />
                        ) : (
                            <img src={IconPlusSquare} alt="" />
                        )}
                    </div>
                    <div className="text-white font-medium">{item.quantity}</div>
                </div>
                <div className="text-end text-white font-medium">${new Intl.NumberFormat('en-US').format(item.price)}</div>
                <div className="text-end text-white font-medium">${new Intl.NumberFormat('en-US').format(item.quantity * item?.price)}</div>
            </div>
        );
    };

    return (
        <>
            <div className="p-2 flex flex-col gap-y-3 mt-3">
                <Slider
                    min={0}
                    max={dataSell.reduce((total: any, item: any) => total + (item.total ?? 0), 0)}
                    value={sliderTotalPrice}
                    onChange={handleSliderChange}
                    className="!mt-0"
                />
                <div className="grid grid-cols-3 py-2 text-white/80 text-sm">
                    <div className="text-start">Quantity</div>
                    <div className="text-end">Price (USDC)</div>
                    <div className="text-end">Total (USDC)</div>
                </div>
                <div className="px-2">
                    {dataSell.length > 0 ? (
                        dataSell?.map((item: any, index: any) => (
                            <ItemsBuy
                                item={item}
                                key={index}
                            />
                        ))
                    ) : (
                        <div className="box-no-data-lending">
                            <img src="/images/empty1.png" alt="" />
                        </div>
                    )}
                </div>
            </div>
            <div className="flex-shrink-0 p-3 bg-[rgba(59,63,69,0.4)] rounded-b-[8px] backdrop-blur-[25px] mat-top-40x">
                <div className="flex flex-col gap-y-2.5">
                    <div className="flex justify-between items-center">
                        <div className="flex gap-x-2.5 items-center">
                            <div className="text-[16px] font-medium text-white">You Pay</div>
                        </div>
                        <div className="flex gap-x-1.5 items-center">
                            <div className="text-xl text-white font-medium">{new Intl.NumberFormat('en-US').format(amountTotal)} USDC</div>
                            <div className="text-sm text-white/40">~${new Intl.NumberFormat('en-US').format(amountTotal)}</div>
                        </div>
                    </div>
                    <div className="flex justify-between items-center">
                        <div className="text-white/60 text-md">Average Price</div>
                        <div className="text-white font-medium text-md">{new Intl.NumberFormat('en-US').format(amountTotal)} USDC</div>
                    </div>
                    <div className="flex justify-between items-center">
                        <div className="text-white/60 text-md">Network Fee (Standard)</div>
                        <div className="text-white font-medium text-md">{totalQuantity > 0 ? Number(feeData).toFixed(5) : 0} USDC</div>
                    </div>
                    {address ?
                        <>
                            {totalQuantity > 0 ?
                                <>
                                    {isApprove ?
                                        <>
                                            <button className="btn-buy-market" onClick={showModal}>
                                                Buy
                                            </button>
                                        </>
                                        :
                                        <>
                                            {loadingApprove ?
                                                <>
                                                    <button className="btn-buy-market dis">
                                                        Approve <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                                                    </button>
                                                </>
                                                :
                                                <>
                                                    <button className="btn-buy-market" onClick={handleApprove}>
                                                        Approve
                                                    </button>
                                                </>
                                            }
                                        </>
                                    }
                                </>
                                :
                                <>
                                    <button className="btn-buy-market dis">
                                        Buy
                                    </button>
                                </>
                            }
                            <div className="text-xs text-white/60 font-medium -mx-2.5 padd-l-10px">
                                Buy clicking "Buy", you agree to the MeyFi Term of Service.
                            </div>
                        </>
                        :
                        <>
                            <button className="btn-buy-market" onClick={handleConnect}>
                                Connect wallet
                            </button>
                        </>
                    }
                </div>
            </div>
            <Modal className="modal-purchase" maskClosable={false} title="Confirm Buy" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div className="confirm-box">
                    <div className="box-img">
                        <img src="/images/lending/pur.png" alt="" />
                    </div>
                    <div className="title">
                        Confirm your buy
                    </div>
                    <div className="guide-confirm-project">
                        <div className="name-pr">
                            {dataDetail?.name}
                        </div>
                        <div className="item">
                            <div className="txt-l">
                                Quantity:
                            </div>
                            <div className="txt-r">
                                {totalQuantity}
                            </div>
                        </div>
                        <div className="item">
                            <div className="txt-l">
                                Price:
                            </div>
                            <div className="txt-r">
                                {new Intl.NumberFormat('en-US').format(priceOneQuantity)} USDC
                            </div>
                        </div>
                        <div className="item">
                            <div className="txt-l">
                                Total Price:
                            </div>
                            <div className="txt-r">
                                {new Intl.NumberFormat('en-US').format(sliderTotalPrice)} USDC
                            </div>
                        </div>
                    </div>
                    <div className="group-button">
                        {loading ?
                            <>
                                <button className="btn-actions dis">
                                    Confirm <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                                </button>
                            </>
                            :
                            <>
                                <button className="btn-actions" onClick={handleBuyNft}>
                                    Confirm
                                </button>
                            </>
                        }
                    </div>
                </div>
            </Modal>
        </>
    );
};
