import { useEffect, useState } from "react";
import "./style.scss"
import { Pagination, Skeleton, Spin, Tabs } from "antd";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";
import Web3 from "web3";
import Faqs from "./faqs";
import { isMobile } from "react-device-detect";
import { useContract } from "../../../hooks/useContract";
import abiAirdrop from "./utils/abiAirdrop.json"
import { AppHome } from "../../../api/appHome";
import { RPC_BASE } from "../../../constants";
import { LoadingOutlined } from '@ant-design/icons';
import { Noti } from "../../../components/Noti/toast";

const Item = ({ item, resKey }: { item: any; resKey: any }) => {
    const { address } = useAccount();
    const { open } = useWeb3Modal();
    const web3: any = new Web3(RPC_BASE);
    const scPool = item?.contractAddress
    const contractClaim: any = useContract(scPool && scPool, abiAirdrop)
    const [amountClaimed, setAmountClaimed]: any = useState(0)
    const [loading, setLoading] = useState(false)
    let timeNowStam = new Date().getTime()
    let timeStart = Number(item?.startTime) * 1000
    let timeEnd = Number(item?.endTime) * 1000

    const handleConnect = () => {
        open();
    };

    const handleClaim = async () => {
        setLoading(true)
        try {
            await contractClaim.claimTokens()
                .then((res: any) => {
                    res.wait()
                        .then(async (resWait: any) => {
                            if (resWait?.status === 1) {
                                Noti('success', "Claim Success", 3000)
                                setLoading(false)
                            } else {
                                Noti('error', "Unknown error (status hash fail)", 3000)
                                setLoading(false)
                            }
                        })
                        .catch((err: any) => {
                            Noti('error', err?.reason || err?.message || 'Unknown error', 3000)
                            setLoading(false)
                        })
                })
                .catch((err: any) => {
                    Noti('error', err?.reason || err?.message || 'Unknown error', 3000)
                    setLoading(false)
                })
        } catch (error: any) {
            Noti('error', error?.reason || error?.message || error || 'Unknown error', 3000)
            setLoading(false)
        }
    }

    const formatToWei = (v: any, d: any) => {
        if (d === 6)
            return web3.utils.toWei(v, "picoether");
        else if (d === 9)
          return web3.utils.toWei(v, "nanoether");
        else if (d === 12)
          return web3.utils.toWei(v, "microether");
        else
          return web3.utils.toWei(v, "ether");
    };

    const checkClaimed = async () => {
        try {    
            await contractClaim.userInfo(address).then((res: any) => {
                const formattedAmount = res.tokensClaimed.toString().replace(',', '.');
                let amountTemp = formatToWei(formattedAmount, item?.tokenDecimal);
                setAmountClaimed(Number(amountTemp))
            })
                .catch((err: any) => {
                    console.log('err', err)
                    setAmountClaimed(0)
                })
        } catch (error) {
            console.log('error', error)
            setAmountClaimed(0)
        }
    }

    useEffect(() => {
        if (address && contractClaim) {
            checkClaimed()
        }
    }, [address, contractClaim])

    return (
        <>
            <div className="colum w-4">
                <div className="frow-content">
                    {Number(resKey) === 1 ?
                        <>
                            <div className="status-type progress">
                                In Progress
                            </div>
                        </>
                        :
                        <>
                            {Number(resKey) === 0 ?
                                <>
                                    <div className="status-type upcoming">
                                        Up coming
                                    </div>
                                </>
                                :
                                <>
                                    <div className="status-type complete">
                                        Completed
                                    </div>
                                </>
                            }
                        </>
                    }
                    <div className="box-img">
                        <img src={item?.bannerUrl} alt="" />
                    </div>
                    <div className="frow-box">
                        <div className="name-type">
                            {item?.name}
                        </div>
                        <ul className="list-type-air">
                            <li>
                                <div className="txt-l">
                                    Reward:
                                </div>
                                <div className="txt-r">
                                    {new Intl.NumberFormat('en-US').format(item?.userClaim?.tokenAmount) || 0} {item?.tokenSymbol}
                                </div>
                            </li>
                            <li>
                                <div className="txt-l">
                                    Duration start:
                                </div>
                                <div className="txt-r">
                                    {item?.startClaimTime_ui}
                                </div>
                            </li>
                            <li>
                                <div className="txt-l">
                                    Duration end:
                                </div>
                                <div className="txt-r">
                                    {item?.endClaimTime_ui}
                                </div>
                            </li>
                            <li>
                                <div className="txt-l">
                                    Network:
                                </div>
                                <div className="txt-r">
                                    <img src="/images/base-air.png" alt="" /> BASE
                                </div>
                            </li>
                        </ul>
                        <div className="gr-button">
                            {address ?
                                <>
                                    {item?.userClaim === null ?
                                        <>
                                            <button className="btn-claim dis">
                                                You’re not whitelisted
                                            </button>
                                        </>
                                        :
                                        <>
                                            {timeNowStam < timeStart ?
                                                <>
                                                    <button className="btn-claim dis">
                                                        Upcoming
                                                    </button>
                                                </>
                                                :
                                                <>
                                                    {timeNowStam > timeEnd ?
                                                        <>
                                                            <button className="btn-claim dis">
                                                                Ended
                                                            </button>
                                                        </>
                                                        :
                                                        <>
                                                            {loading ?
                                                                <>
                                                                    <button className="btn-claim dis">
                                                                        Claim <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                                                                    </button>
                                                                </>
                                                                :
                                                                <>
                                                                    {Number(amountClaimed) === Number(item?.userClaim?.tokenAmount) ?
                                                                        <>
                                                                            <button className="btn-claim dis">
                                                                                Claimed
                                                                            </button>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <button className="btn-claim" onClick={handleClaim}>
                                                                                Claim
                                                                            </button>
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            }

                                        </>
                                    }
                                </>
                                :
                                <>
                                    <button className="btn-claim" onClick={handleConnect}>
                                        Connect Wallet
                                    </button>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const Airdrop = () => {
    const { address } = useAccount();
    const [keyTabs, setKeyTabs]: any = useState("1")
    const [data, setData]: any = useState()
    const [loadingData, setLoadingData] = useState(false)
    const [current, setCurrent] = useState(1);
    const [totalItem, setTotalItem]: any = useState(0)

    const GetListAirdrop = async () => {
        setLoadingData(true)
        try {
            let res = await AppHome.getAirdropList(6, 0, keyTabs, address);
            if (res.data.success === true) {
                setData(res.data.data);
                setTotalItem(res?.data?.totalCount)
                setTimeout(() => {
                    setLoadingData(false)
                }, 1000);
            }
        } catch (error) {
            console.log("Error: ", error);
            setLoadingData(false)
        }
    };

    useEffect(() => {
        if (address) {
            GetListAirdrop()
        }
    }, [address]);

    const onChangeTabs = async (key: any) => {
        setKeyTabs(key)
        setLoadingData(true)
        try {
            let res = await AppHome.getAirdropList(6, 0, key, address);
            if (res.data.success === true) {
                setData(res.data.data);
                setTimeout(() => {
                    setLoadingData(false)
                }, 1000);
            }
        } catch (error) {
            console.log("Error: ", error);
            setLoadingData(false)
        }
    }

    const onChange = async (page: any) => {
        setCurrent(page);
        try {
            let res = await AppHome.getAirdropList(6, Number(page) - 1, keyTabs, address);
            if (res.data.success === true) {
                setData(res.data.data);
                setTimeout(() => {
                    setLoadingData(false)
                }, 1000);
            }
        } catch (error) {
            console.log("Error: ", error);
            setLoadingData(false)
        }
    };

    return (
        <>
            <div className="section-grow position-relative">
                <div className="wrap-airdrop">
                    <div className="box-img">
                        {isMobile ?
                            <>
                                <img src="/images/air-mobile.png" alt="" />
                            </>
                            :
                            <>
                                <img src="/images/banner-air-drop.png" alt="" />
                            </>
                        }
                    </div>
                    <div className="wrap-tabs-air">
                        <Tabs className="tabs-airdrop" activeKey={keyTabs} onChange={onChangeTabs}>
                            <Tabs.TabPane tab="Opening" key="1">
                                {data?.length === 0 ?
                                    <>
                                        <div className="box-no-data-air">
                                            <img src="/images/no-data-air.png" alt="" />
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="list-airdrop-page">
                                            <div className="columns">
                                                {loadingData ?
                                                    <>
                                                        {[...Array(3)].map((_, index) => (
                                                            <div className="colum w-4">
                                                                <Skeleton paragraph={{ rows: 10 }} active />
                                                            </div>
                                                        ))}
                                                    </>
                                                    :
                                                    <>
                                                        {data && data.length > 0 && data.map((item: any, index: any) => (
                                                            <Item resKey="1" item={item} />
                                                        ))}
                                                    </>
                                                }
                                            </div>
                                            {data?.length > 6 ?
                                                <>
                                                    <div className="pagination-table">
                                                        <Pagination current={current} defaultPageSize={6} onChange={onChange} total={totalItem} />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    {""}
                                                </>
                                            }
                                        </div>
                                    </>
                                }
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Upcoming" key="0">
                                {data?.length === 0 ?
                                    <>
                                        <div className="box-no-data-air">
                                            <img src="/images/no-data-air.png" alt="" />
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="list-airdrop-page">
                                            <div className="columns">
                                                {loadingData ?
                                                    <>
                                                        {[...Array(3)].map((_, index) => (
                                                            <div className="colum w-4">
                                                                <Skeleton paragraph={{ rows: 10 }} active />
                                                            </div>
                                                        ))}
                                                    </>
                                                    :
                                                    <>
                                                        {data && data.length > 0 && data.map((item: any, index: any) => (
                                                            <Item resKey="0" item={item} />
                                                        ))}
                                                    </>
                                                }
                                            </div>
                                            {data?.length > 6 ?
                                                <>
                                                    <div className="pagination-table">
                                                        <Pagination current={current} defaultPageSize={6} onChange={onChange} total={totalItem} />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    {""}
                                                </>
                                            }
                                        </div>
                                    </>
                                }
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Ended" key="2">
                                {data?.length === 0 ?
                                    <>
                                        <div className="box-no-data-air">
                                            <img src="/images/no-data-air.png" alt="" />
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="list-airdrop-page">
                                            <div className="columns">
                                                {loadingData ?
                                                    <>
                                                        {[...Array(3)].map((_, index) => (
                                                            <div className="colum w-4">
                                                                <Skeleton paragraph={{ rows: 10 }} active />
                                                            </div>
                                                        ))}
                                                    </>
                                                    :
                                                    <>
                                                        {data && data.length > 0 && data.map((item: any, index: any) => (
                                                            <Item resKey="2" item={item} />
                                                        ))}
                                                    </>
                                                }
                                            </div>
                                            {data?.length > 6 ?
                                                <>
                                                    <div className="pagination-table">
                                                        <Pagination current={current} defaultPageSize={6} onChange={onChange} total={totalItem} />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    {""}
                                                </>
                                            }
                                        </div>
                                    </>
                                }
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                </div>
                {/* <Faqs /> */}
            </div>
        </>
    )
}
export default Airdrop