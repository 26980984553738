import React from "react";

export const TimeLineTab = (data: any) => {
  const dataTimelineSlice = data && data?.data[0]?.desc?.split(".")
  const FilterArr = dataTimelineSlice && dataTimelineSlice.filter((item: any) => item !== "");
  return (
    <>
      <div className="content-details">
        <div className="title">
          Offering & Tokenization Timeline
        </div>
        {data && data?.data?.map((item: any, index: any) => (
          <>
            <div className="per-rewait-item">
              <div className="number-per">
                0{index + 1}
              </div>
              <div className="content-per">
                <div className="time-line-per">
                  {item?.time}
                </div>
                <div className="text-per">
                  {item?.title}
                </div>
                <ul className="list-per">
                  {index === 0 ?
                    <>
                      {FilterArr && FilterArr?.map((itemS: any, indexS: any) => (
                        <>
                          <li>
                            {itemS}
                          </li>
                        </>
                      ))}
                    </>
                    :
                    <>
                      <li>
                        {item?.desc}
                      </li>
                    </>
                  }
                </ul>
                {index === 0 ?
                  <>
                    <div className="note-per">
                      Note: It takes you from 24 to 48 hours to have your submission approved.
                    </div>
                  </>
                  :
                  <>
                    {""}
                  </>
                }
              </div>
            </div>
          </>
        ))}
      </div>
      {/* <div className="content-details">
        <div className="list-detail-info mar-top-20 res-page">
          <div className="row-info">
            <div className="box-no-data-pto">
              <img className='img-empty' src="/images/empty1.png" alt="" />
            </div>
          </div>
        </div>
      </div> */}
    </>
  )
};
