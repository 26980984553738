/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useContract } from "../../../../hooks/useContract";
import web3 from "web3";
import './style.css'
import { BASE_SCAN, WORK_CHAIN } from "../../../../constants";
import abiVesting from "../utils/abiVesting.json"
import { Noti } from "../../../../components/Noti/toast";
import { formatNumber } from "../../staking/details/utils";
import Countdown from "react-countdown";
import { useAccount } from "wagmi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import { useHookVesting } from './storeVesting'
import { convertDateToString } from "../utils";
import { AppHome } from "../../../../api/appHome";
import { Skeleton } from "antd";


const Vesting = () => {
    const { selectedNetworkId }: any = useWeb3ModalState();
    const { address } = useAccount();
    const [state, actions] = useHookVesting()
    const [loading, setLoading] = useState(false)
    const [loading1, setLoading1] = useState(true)
    const [isClaimed, setIsClaimed] = useState(false)
    const [unlockedToken, setUnlockedToken]: any = useState(0)
    const [totalToken, setTotalToken]: any = useState(0)
    const [totalClaimed, setTotalClaimed]: any = useState(0)
    const [dataContract, setdataContract]: any = useState()
    const [startClaimTemp, setStartClaimTemp]: any = useState(0)
    const [cliffTemp, setCliffTemp]: any = useState(0)
    const [timePerPeriod, setTimePerPeriod]: any = useState(0)
    const [disableWithdraw, setDisableWithdraw]: any = useState(true)
    const [totalPeriods, setTotalPeriods]: any = useState(0)
    const [reCheck, setReCheck]: any = useState(false)
    const [timeNextClaim, setTimeNextClaim]: any = useState(0)
    // const [listHis, setListHis]: any = useState([])
    let sc_vesting = dataContract && dataContract?.user_vesting_may_contract
    const vestingContract: any = useContract(sc_vesting, abiVesting)

    const rendererCountDownVesting = ({
        days,
        hours,
        minutes,
        seconds,
        completed,
    }: any) => {
        // Render a countdown
        if (completed) {
            setTimeout(() => {
                window.location.reload()
                return <span className="text-cd-vest">Withdrawable</span>
            }, 1000);
        }
        if (days > 1) {
            return (
                <span className="text-cd-vest">
                    {days}d : {hours}h : {minutes}m
                </span>
            );
        } else if (days === 1) {
            return (
                <span className="text-cd-vest">
                    {days}d : {hours}h : {minutes}m
                </span>
            );
        } else {
            return (
                <span className="text-cd-vest">
                    {hours}h : {minutes}m : {seconds}s
                </span>
            );
        }
    };

    const SettingContract = async () => {
        try {
            let res = await AppHome.getSettingContract();
            if (res.data.success === true) {
                setdataContract(res.data.data);
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    useEffect(() => {
        SettingContract()
    }, []);

    const getUnlockedToken = async () => {
        try {
            await vestingContract.claimAble(address).then((res: any) => {
                let temp: any = web3.utils.fromWei(res, "ether");
                setUnlockedToken(Number(temp))
            })
                .catch((err: any) => {
                    setUnlockedToken(0)
                    console.log('err claimAble', err)
                })
        } catch (error) {
            setUnlockedToken(0)
        }
    }

    const [firstRelease, setfirstRelease]: any = useState()
    const getFirstRelease = async () => {
        try {
            await vestingContract.firstRelease().then((res: any) => {
                setfirstRelease(Number(res))

            })
                .catch((err: any) => {
                    setfirstRelease(0)
                    console.log('err firstRelease', err)
                })
        } catch (error) {
            setfirstRelease(0)
            console.log('error firstRelease', error)

        }
    }

    useEffect(() => {
        if (address && selectedNetworkId === WORK_CHAIN && vestingContract !== null) {
            getFirstRelease()
        } else {
            setfirstRelease(0)
        }

    }, [address, selectedNetworkId, vestingContract])




    const getUserInfo = async () => {
        try {
            await vestingContract.userInfo(address).then((res: any) => {
                let amount: any = web3.utils.fromWei(res[0], "ether");
                let tokensClaimed: any = web3.utils.fromWei(res[1], "ether");
                setTotalToken(Number(amount))
                setTotalClaimed(tokensClaimed)
                setLoading1(false)
            })
                .catch((err: any) => {
                    setTotalToken(0)
                    setTotalClaimed(0)
                    console.log('err userInfo', err)
                    setLoading1(false)
                })
        } catch (error) {
            console.log('error userInfo', error)
            setTotalToken(0)
            setTotalClaimed(0)
            setLoading1(false)
        }
    }

    useEffect(() => {
        let timer = setTimeout(() => {
            if (!address) {
                setLoading1(false)
            }
        }, 3000);
        return () => {
            clearTimeout(timer)
        };
    }, [address])

    useEffect(() => {
        if (address && selectedNetworkId === WORK_CHAIN && vestingContract !== null) {
            getUnlockedToken()
            getUserInfo()
        } else {
            setTotalToken(0)
            setTotalClaimed(0)
            setUnlockedToken(0)
        }
    }, [address, selectedNetworkId, vestingContract])

    const getStartTime = async () => {
        try {
            await vestingContract.startTime().then((res: any) => {
                setStartClaimTemp(res?.toString())
            })
                .catch((err: any) => {
                    setStartClaimTemp(0)
                    console.log('err startTime', err)
                })
        } catch (error) {
            setStartClaimTemp(0)
            console.log('error startTime', error)
        }
    }

    const getCliff = async () => {
        try {
            await vestingContract.cliff().then((res: any) => {
                setCliffTemp(res?.toString())
            })
                .catch((err: any) => {
                    setCliffTemp(0)
                    console.log('err cliffTemp', err)
                })
        } catch (error) {
            setCliffTemp(0)
            console.log('error cliffTemp', error)
        }
    }

    const getTimePerPeriod = async () => {
        try {
            await vestingContract.timePerPeriod().then((res: any) => {
                setTimePerPeriod(res?.toString())
            })
                .catch((err: any) => {
                    setTimePerPeriod(0)
                    console.log('err cliffTemp', err)
                })
        } catch (error) {
            setTimePerPeriod(0)
            console.log('error cliffTemp', error)
        }
    }

    const getTotalPeriods = async () => {
        try {
            await vestingContract.totalPeriods().then((res: any) => {
                setTotalPeriods(res?.toString())
            })
                .catch((err: any) => {
                    setTotalPeriods(0)
                    console.log('err cliffTemp', err)
                })
        } catch (error) {
            setTotalPeriods(0)
            console.log('error cliffTemp', error)
        }
    }


    useEffect(() => {

        if (selectedNetworkId === WORK_CHAIN && vestingContract !== null) {
            getStartTime()
            getCliff()
            getTimePerPeriod()
            getTotalPeriods()
        }

    }, [selectedNetworkId, reCheck, vestingContract])


    useEffect(() => {
        if (startClaimTemp * 1000 > Date.now()) {
            setDisableWithdraw(true)
        } else {
            setDisableWithdraw(false)
        }
    }, [startClaimTemp])

    const btnWithdrawAll = () => {
        const obj = {
            className: `ac-wd`,
            disabled: loading || isClaimed || disableWithdraw || unlockedToken <= 0
        };
        return obj;
    };

    const viewContract = () => {
        window.open(`${BASE_SCAN}/address/${sc_vesting}`)
    }

    let currentTime = Date.now()
    let startClaimMilis = startClaimTemp * 1000
    let perPeriodMilis = timePerPeriod * 1000
    let totalStartCliff = startClaimTemp * 1000 + cliffTemp * 1000
    let numberLoop = 1000

    const caculaCountDown = () => {
        for (let i = 0; i <= numberLoop; i++) {
            if (currentTime >= (totalStartCliff + perPeriodMilis * i)
                && currentTime < (totalStartCliff + perPeriodMilis * (i + 1))) {
                setTimeNextClaim((totalStartCliff + perPeriodMilis * (i + 1)))
                break;
            } else {
                if (i === numberLoop) {
                    setTimeNextClaim(0)
                }
            }

        }

    }


    useEffect(() => {
        if (Number(totalToken) === Number(totalClaimed) ||
            currentTime >= (totalStartCliff + perPeriodMilis * Number(totalPeriods - 1))) {
            setTimeNextClaim(0)
        } else {
            // chưa tới giờ claim ở TGE
            if (startClaimMilis > currentTime) {
                setTimeNextClaim(startClaimMilis)
            } else
                // đã claim lần đầu ở TGE. chờ tới lần claim vesting lần thứ 1
                if (startClaimMilis <= currentTime && currentTime < (totalStartCliff)) {
                    setTimeNextClaim(totalStartCliff)

                } else {
                    caculaCountDown()
                }


            // // đã claim vesting lần 1,chờ tới vesting lần 2
            // if (currentTime >= (totalStartCliff)
            //     && currentTime < (totalStartCliff + perPeriodMilis)) {
            //     setTimeNextClaim((totalStartCliff + perPeriodMilis))
            // }
            // else
            //     // đã claim vesting lần 2,chờ tới vesting lần 3
            //     if (currentTime >= (totalStartCliff + perPeriodMilis)
            //         && currentTime < (totalStartCliff + perPeriodMilis * 2)) {
            //         setTimeNextClaim((totalStartCliff + perPeriodMilis * 2))
            //     }
            //     else
            //         // đã claim vesting lần 3,chờ tới vesting lần 4
            //         if (currentTime >= (totalStartCliff + perPeriodMilis * 2)
            //             && currentTime < (totalStartCliff + perPeriodMilis * 3)) {
            //             setTimeNextClaim((totalStartCliff + perPeriodMilis * 3))
            //         }
            //         else
            //             // đã claim vesting lần 4,chờ tới vesting lần 5
            //             if (currentTime >= (totalStartCliff + perPeriodMilis * 3)
            //                 && currentTime < (totalStartCliff + perPeriodMilis * 4)) {
            //                 setTimeNextClaim((totalStartCliff + perPeriodMilis * 4))
            //             }
            //             else
            //                 // đã claim vesting lần 5,chờ tới vesting lần 6
            //                 if (currentTime >= (totalStartCliff + perPeriodMilis * 4)
            //                     && currentTime < (totalStartCliff + perPeriodMilis * 5)) {
            //                     setTimeNextClaim((totalStartCliff + perPeriodMilis * 5))
            //                 }
            //                 else
            //                     // đã claim vesting lần 6,chờ tới vesting lần 7
            //                     if (currentTime >= (totalStartCliff + perPeriodMilis * 5)
            //                         && currentTime < (totalStartCliff + perPeriodMilis * 6)) {
            //                         setTimeNextClaim((totalStartCliff + perPeriodMilis * 6))
            //                     }
            //                     else
            //                         // đã claim vesting lần 7,chờ tới vesting lần 8
            //                         if (currentTime >= (totalStartCliff + perPeriodMilis * 6)
            //                             && currentTime < (totalStartCliff + perPeriodMilis * 7)) {
            //                             setTimeNextClaim((totalStartCliff + perPeriodMilis * 7))
            //                         }
            //                         else
            //                             // đã claim vesting lần 8,chờ tới vesting lần 9
            //                             if (currentTime >= (totalStartCliff + perPeriodMilis * 7)
            //                                 && currentTime < (totalStartCliff + perPeriodMilis * 8)) {
            //                                 setTimeNextClaim((totalStartCliff + perPeriodMilis * 8))
            //                             }
            //                             else
            //                                 // đã claim vesting lần 9,chờ tới vesting lần 10
            //                                 if (currentTime >= (totalStartCliff + perPeriodMilis * 8)
            //                                     && currentTime < (totalStartCliff + perPeriodMilis * 9)) {
            //                                     setTimeNextClaim((totalStartCliff + perPeriodMilis * 9))
            //                                 }
            //                                 else
            //                                     // đã claim vesting lần 10,chờ tới vesting lần 11
            //                                     if (currentTime >= (totalStartCliff + perPeriodMilis * 9)
            //                                         && currentTime < (totalStartCliff + perPeriodMilis * 10)) {
            //                                         setTimeNextClaim((totalStartCliff + perPeriodMilis * 10))
            //                                     }
            //                                     else
            //                                         // đã claim vesting lần 11,chờ tới vesting lần 12
            //                                         if (currentTime >= (totalStartCliff + perPeriodMilis * 10)
            //                                             && currentTime < (totalStartCliff + perPeriodMilis * 11)) {
            //                                             setTimeNextClaim((totalStartCliff + perPeriodMilis * 11))
            //                                         }
            //                                         else
            //                                             // đã claim vesting lần 12,chờ tới vesting lần 13
            //                                             if (currentTime >= (totalStartCliff + perPeriodMilis * 11)
            //                                                 && currentTime < (totalStartCliff + perPeriodMilis * 12)) {
            //                                                 setTimeNextClaim((totalStartCliff + perPeriodMilis * 12))
            //                                             }
            //                                             else
            //                                                 // đã claim vesting lần 13,chờ tới vesting lần 14
            //                                                 if (currentTime >= (totalStartCliff + perPeriodMilis * 12)
            //                                                     && currentTime < (totalStartCliff + perPeriodMilis * 13)) {
            //                                                     setTimeNextClaim((totalStartCliff + perPeriodMilis * 13))
            //                                                 }
            //                                                 else
            //                                                     // đã claim vesting lần 15,chờ tới vesting lần 16
            //                                                     if (currentTime >= (totalStartCliff + perPeriodMilis * 13)
            //                                                         && currentTime < (totalStartCliff + perPeriodMilis * 14)) {
            //                                                         setTimeNextClaim((totalStartCliff + perPeriodMilis * 14))
            //                                                     }
            //                                                     else
            //                                                         // đã claim vesting lần 16,chờ tới vesting lần 17
            //                                                         if (currentTime >= (totalStartCliff + perPeriodMilis * 14)
            //                                                             && currentTime < (totalStartCliff + perPeriodMilis * 15)) {
            //                                                             setTimeNextClaim((totalStartCliff + perPeriodMilis * 15))
            //                                                         }
            //                                                         else
            //                                                             // đã claim vesting lần 17,chờ tới vesting lần 18
            //                                                             if (currentTime >= (totalStartCliff + perPeriodMilis * 15)
            //                                                                 && currentTime < (totalStartCliff + perPeriodMilis * 16)) {
            //                                                                 setTimeNextClaim((totalStartCliff + perPeriodMilis * 16))
            //                                                             }
            //                                                             else
            //                                                                 // đã claim vesting lần 18,chờ tới vesting lần 19
            //                                                                 if (currentTime >= (totalStartCliff + perPeriodMilis * 17)
            //                                                                     && currentTime < (totalStartCliff + perPeriodMilis * 18)) {
            //                                                                     setTimeNextClaim((totalStartCliff + perPeriodMilis * 18))
            //                                                                 }
            //                                                                 else
            //                                                                     // đã claim vesting lần 19,chờ tới vesting lần 20
            //                                                                     if (currentTime >= (totalStartCliff + perPeriodMilis * 18)
            //                                                                         && currentTime < (totalStartCliff + perPeriodMilis * 19)) {
            //                                                                         setTimeNextClaim((totalStartCliff + perPeriodMilis * 19))
            //                                                                     }
            //                                                                     else
            //                                                                         // đã claim vesting lần 20,chờ tới vesting lần 21
            //                                                                         if (currentTime >= (totalStartCliff + perPeriodMilis * 19)
            //                                                                             && currentTime < (totalStartCliff + perPeriodMilis * 20)) {
            //                                                                             setTimeNextClaim((totalStartCliff + perPeriodMilis * 20))
            //                                                                         }
            //                                                                         else
            //                                                                             // đã claim vesting lần 21,chờ tới vesting lần 22
            //                                                                             if (currentTime >= (totalStartCliff + perPeriodMilis * 20)
            //                                                                                 && currentTime < (totalStartCliff + perPeriodMilis * 21)) {
            //                                                                                 setTimeNextClaim((totalStartCliff + perPeriodMilis * 21))
            //                                                                             }
            //                                                                             else
            //                                                                                 // đã claim vesting lần 22,chờ tới vesting lần 23
            //                                                                                 if (currentTime >= (totalStartCliff + perPeriodMilis * 21)
            //                                                                                     && currentTime < (totalStartCliff + perPeriodMilis * 22)) {
            //                                                                                     setTimeNextClaim((totalStartCliff + perPeriodMilis * 22))
            //                                                                                 }
            //                                                                                 else
            //                                                                                     // đã claim vesting lần 23,chờ tới vesting lần 24
            //                                                                                     if (currentTime >= (totalStartCliff + perPeriodMilis * 22)
            //                                                                                         && currentTime < (totalStartCliff + perPeriodMilis * 23)) {
            //                                                                                         setTimeNextClaim((totalStartCliff + perPeriodMilis * 23))
            //                                                                                     }
            //                                                                                     else
            //                                                                                         // đã claim vesting lần 24,chờ tới vesting lần 25
            //                                                                                         if (currentTime >= (totalStartCliff + perPeriodMilis * 23)
            //                                                                                             && currentTime < (totalStartCliff + perPeriodMilis * 24)) {
            //                                                                                             setTimeNextClaim((totalStartCliff + perPeriodMilis * 24))
            //                                                                                         }
            //                                                                                         else
            //                                                                                             // đã claim vesting lần 25,chờ tới vesting lần 26
            //                                                                                             if (currentTime >= (totalStartCliff + perPeriodMilis * 24)
            //                                                                                                 && currentTime < (totalStartCliff + perPeriodMilis * 25)) {
            //                                                                                                 setTimeNextClaim((totalStartCliff + perPeriodMilis * 25))
            //                                                                                             }
            //                                                                                             else
            //                                                                                                 // đã claim vesting lần 26,chờ tới vesting lần 27
            //                                                                                                 if (currentTime >= (totalStartCliff + perPeriodMilis * 25)
            //                                                                                                     && currentTime < (totalStartCliff + perPeriodMilis * 26)) {
            //                                                                                                     setTimeNextClaim((totalStartCliff + perPeriodMilis * 26))
            //                                                                                                 }
            // else {
            //     setTimeNextClaim(0)
            // }
        }

    }, [startClaimTemp, reCheck, totalClaimed, totalPeriods, totalToken, cliffTemp, timePerPeriod])

    const addHis = async (hash: any, amount: any, type: any) => {
        try {
            let params = {
                ownerAddress: address,
                contractAddress: sc_vesting,
                txHash: hash,
                amount: amount,
                type: type,
                assetToken: 'Mey',
            }
            await actions.addHistory(params)
        } catch (error) {

        }
    }

    const claim = async () => {
        try {
            setLoading(true)
            await vestingContract.claimTokens().then((res: any) => {
                res.wait()
                    .then(async (resWait: any) => {
                        if (resWait?.status === 1) {
                            await addHis(resWait?.transactionHash, unlockedToken, 5)
                            setTimeout(() => {
                                setReCheck(!reCheck)
                                setLoading(false)
                                setIsClaimed(true)
                                Noti('success', "Withdraw successfully", 3000)
                            }, 1000);
                        } else {
                            Noti('error', "Hash fail", 3000)
                            setLoading(false)
                        }
                    })
            })
                .catch((error: any) => {
                    // console.log('error1', error)
                    console.log('message', error?.reason)
                    setLoading(false)
                    if (error?.code) {
                        Noti('error', error?.reason || error.code || "Unknown error", 3000)
                    } else {
                        Noti('error', error || "Unknown error", 3000)
                    }
                })
        } catch (error: any) {
            console.log('error222', error)
            setLoading(false)
            if (error.code) {
                Noti('error', error.code || "Unknown error", 3000)
            } else {
                Noti('error', error || "Unknown error", 3000)
            }
        }
    }


    // const getHis = async () => {
    //     try {
    //         let params = {
    //             ownerAddress: address,
    //             pageSize: 10,
    //             pageIndex: 0
    //         }
    //         await actions.getHistory(params).then((res: any) => {
    //             setListHis(res)
    //         })
    //             .catch((err: any) => {
    //                 console.log('err', err)
    //                 setListHis([])
    //             })
    //     } catch (error) {
    //         setListHis([])
    //     }
    // }

    // useEffect(() => {
    //     if (address) {
    //         getHis()
    //     }
    // }, [address, reCheck])

    // let firstRe = (firstRelease * totalToken) / 10000

    let firstRe = (firstRelease / 100)

    let arr = new Array(Number(totalPeriods) + 1).fill(0);
    let arrvesting: any = []

    arr.map((item: any, index: any) => {
        if (index === 0) {
            let checkClaim: any
            if (Date.now() >= Number(startClaimTemp) * 1000) {
                if (Number(totalClaimed) >= (totalToken * firstRe / 100)) {
                    checkClaim = 'Withdrawn'
                } else {
                    checkClaim = 'UnLocked'
                }
            } else {
                checkClaim = 'Locked'
            }
            let obj = {
                day: convertDateToString(startClaimTemp),
                por: formatNumber(firstRe, 4),
                /// 100 / (Number(totalPeriods) + 1),
                amount: formatNumber(totalToken * firstRe / 100, 4),
                // totalToken / (Number(totalPeriods) + 1),
                asset: 'MEY',
                status: checkClaim
            }

            arrvesting.push(obj)
        } else
            if (index === 1) {
                let checkClaim: any
                if (Date.now() >= Number(startClaimTemp) * 1000) {
                    if (Number(totalClaimed) >= ((totalToken * ((100 - firstRe) / (Number(totalPeriods))) / 100) + (totalToken * firstRe / 100))) {
                        checkClaim = 'Withdrawn'
                    } else {
                        if (Date.now() >= ((Number(startClaimTemp) * 1000) + Number(cliffTemp) * 1000)) {
                            checkClaim = 'UnLocked'
                        } else {
                            checkClaim = "Locked"
                        }
                    }
                } else {
                    checkClaim = "Locked"
                }
                let obj = {
                    day: convertDateToString(Number(startClaimTemp) + Number(cliffTemp)),
                    por: formatNumber(((100 - firstRe) / (Number(totalPeriods))), 4),
                    amount: formatNumber((totalToken * ((100 - firstRe) / (Number(totalPeriods))) / 100), 4),
                    // totalToken / (Number(totalPeriods) + 1),
                    asset: 'MEY',
                    status: checkClaim
                }
                arrvesting.push(obj)
            } else {
                let checkClaim: any
                if (Date.now() >= Number(startClaimTemp) * 1000) {
                    if (Number(totalClaimed) >= (((totalToken * ((100 - firstRe) / (Number(totalPeriods))) / 100)) * (index + 1))) {
                        checkClaim = 'Withdrawn'
                    } else {
                        if (Date.now() >= ((Number(startClaimTemp) * 1000) + Number(cliffTemp) * 1000 + Number(timePerPeriod * (index - 1)) * 1000)) {
                            checkClaim = 'UnLocked'
                        } else {
                            checkClaim = "Locked"
                        }
                    }
                } else {
                    checkClaim = "Locked"
                }
                let obj = {
                    day: convertDateToString(Number(startClaimTemp) + Number(cliffTemp) + Number(timePerPeriod * (index - 1))),
                    por: formatNumber(((100 - firstRe) / (Number(totalPeriods))), 4),
                    amount: formatNumber((totalToken * ((100 - firstRe) / (Number(totalPeriods))) / 100), 4),
                    // totalToken / (Number(totalPeriods) + 1),
                    asset: 'MEY',
                    status: checkClaim
                }
                arrvesting.push(obj)
            }
    });



    return (
        <>
            <div className="section-grow">
                <div className="container-vesting">
                    <div className="content-vest">
                        <div className="title-vest">
                            MEY Token Vesting Contract
                        </div>
                        <div className="dec-vest">
                            Withdraw your MEY tokens tokens from this vesting smart contract
                        </div>
                        <div className="list-item-vest">
                            <div className="wrap-item-vest">
                                <div className="item-vest">
                                    <div className="item-vest1">{formatNumber(totalToken, 5)} <span>MEY</span></div>
                                    <div className="item-vest2">Total</div>
                                </div>
                            </div>
                            <div className="wrap-item-vest">
                                <div className="item-vest">
                                    <div className="item-vest1">{formatNumber(totalToken - totalClaimed, 5)} <span>MEY</span></div>
                                    <div className="item-vest2">Locked</div>
                                </div>
                            </div>
                            <div className="wrap-item-vest">
                                <div className="item-vest">
                                    {timeNextClaim === 0 ? (
                                        <>
                                            <div className="item-vest1">Finished</div>
                                        </>
                                    ) : (
                                        <>
                                            {timeNextClaim && (
                                                <div className="item-vest1">
                                                    <Countdown
                                                        date={timeNextClaim + 30000}
                                                        renderer={rendererCountDownVesting}
                                                    />
                                                    <div className="item-vest2">Time until the next release</div>
                                                </div>
                                            )}

                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="wrap-item-vest">
                                <div className="item-vest">
                                    <div className="item-vest1">{formatNumber(totalClaimed, 5)} <span>MEY</span></div>
                                    <div className="item-vest2">Claimed</div>
                                </div>
                            </div>
                        </div>
                        <div className="text-unlock">Unlocked Tokens</div>
                        <div className="num-unlock">{formatNumber(unlockedToken, 5)} <span>MEY</span></div>
                        <div className="action-vest">
                            <div className="view-sc" onClick={viewContract}>
                                View vesting contract
                            </div>

                            {loading ? (
                                <div
                                    {...btnWithdrawAll()}
                                >
                                    Waiting{" "}
                                    <div className="loader"></div>
                                </div>
                            ) : (
                                <div
                                    {...btnWithdrawAll()}
                                    onClick={claim}
                                >
                                    Withdraw
                                </div>
                            )}

                        </div>
                    </div>
                    <div className="content-vest">
                        <div className="title-vest">
                            Vesting Epochs
                        </div>
                        <div className="table-vest">
                            {Number(totalToken) === 0 ? (
                                <div className="box-no-data-stake">
                                    <img className='img-empty' src="/images/no-data-cus.png" alt="" />
                                </div>
                            ) : (
                                <>
                                    <table cellSpacing="0" className='tb-vest'>
                                        <thead>
                                            <tr className='tb-header'>
                                                <th>#</th>
                                                <th>Date</th>
                                                <th>Portion</th>
                                                <th>Amount</th>
                                                <th>Asset</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading1 ? (
                                                <>
                                                    <tr className='tb-body'>
                                                        <td className="nodata" colSpan={6}>
                                                            <Skeleton paragraph={{ rows: 6 }} active />
                                                        </td>
                                                    </tr>
                                                </>
                                            ) : (
                                                <>
                                                    {Number(totalToken) === 0 ? (

                                                        <tr className='tb-body'>
                                                            <td colSpan={6}>
                                                                <div className="box-no-data-stake">
                                                                    <img className='img-empty' src="/images/no-data-cus.png" alt="" />
                                                                </div>
                                                            </td>
                                                        </tr>

                                                    ) : (
                                                        <>
                                                            {arrvesting.length > 0 ? (
                                                                <>
                                                                    {arrvesting.map((item: any, index: any) => (
                                                                        <tr className='tb-body'>
                                                                            <td>{index + 1}</td>
                                                                            <td>{item?.day}</td>
                                                                            <td className='text-blu'>{item?.por}%</td>
                                                                            <td>{item?.amount}</td>
                                                                            <td>{item?.asset}</td>
                                                                            {item?.status === 'UnLocked' ? (
                                                                                <td className="unlocked">{item?.status}</td>
                                                                            ) : (
                                                                                <td>{item?.status}</td>
                                                                            )}

                                                                        </tr>
                                                                    ))}
                                                                </>
                                                            ) : (

                                                                <tr className='tb-body'>
                                                                    <td colSpan={6}>
                                                                        <div className="box-no-data-stake">
                                                                            <img className='img-empty' src="/images/no-data-cus.png" alt="" />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </tbody>
                                    </table>
                                </>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Vesting;