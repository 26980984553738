
function isAllZeros(str :any) {
    return /^0+$/.test(str);
}

export const formatNumber = (number: any, max: any) => {
    if (Number(number) >= 1) {
        let [integerPart, decimalPart] = number.toString().split('.');
        if (integerPart !== '0') {
            integerPart = parseInt(integerPart).toLocaleString('en-US');
        }
        decimalPart = decimalPart ? decimalPart.substring(0, max) : '';
        if(isAllZeros(decimalPart)){
            decimalPart=''
        }
        return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
    } else  if (Number(number) < 1){
        const factor = Math.pow(10, max);
    return Math.floor(number * factor) / factor;
    }else{
        return 0
} 
}


export function calculateTimeRemaining(futureTimestampInSeconds :any) {
    const now = Math.floor(Date.now() / 1000); 
    const remainingSeconds = futureTimestampInSeconds - now; 

    if (remainingSeconds <= 0) {
        return "TBA";
    }

    const days = Math.floor(remainingSeconds / (24 * 60 * 60)); // Tính số ngày
    const hours = Math.floor((remainingSeconds % (24 * 60 * 60)) / (60 * 60)); // Tính số giờ
    const minutes = Math.floor((remainingSeconds % (60 * 60)) / 60); // Tính số phút

    if(days>1){
        return `${days} days, ${hours} hours, and ${minutes} minutes`;
    }else if(days<=1){
        return `${days} day, ${hours} hours, and ${minutes} minutes`;
    }
   
}