import React, { useEffect, useState } from "react";
import { Input, Radio, Modal, Space, Checkbox, Select } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CaretDownOutlined } from "@ant-design/icons";
import dataCountry from "./dataCountry";
import { toast } from "react-toastify";
import { useAccount } from "wagmi";
import "./style.scss"
import { AppHome } from "../../api/appHome";

const ModalDisclaimer = (props: any) => {
    const pathTitle = props?.pathTitle
    const { address } = useAccount();
    const { Search } = Input;
    const pathParam = props?.pathParam
    const { Option } = Select;
    const [loading, setLoading] = useState(false)
    const [valueOne, setValueOne]: any = useState()
    const [valueTwo, setValueTwo]: any = useState()
    const [valueThree, setValueThree]: any = useState()
    const [dataSelect, setDataSelect] = useState()

    const onChangeCheckOne = (e: CheckboxChangeEvent) => {
        setValueOne(e.target.checked)
    };

    const onChangeCheckTwo = (e: CheckboxChangeEvent) => {
        setValueTwo(e.target.checked)
    };

    const onChangeCheckThree = (e: CheckboxChangeEvent) => {
        setValueThree(e.target.checked)
    };

    const handleChange = (value: any) => {
        setDataSelect(value);
    };

    const handleConfirm = async () => {
        setLoading(true)
        let params = {
            ownerAddress: address,
            code: pathParam,
            type: pathTitle === "p2p-lending" ? 1 : 2,
            countryCode: dataSelect
        }
        try {
            let res = await AppHome.postDisClaimer(
                params
            );
            if (res.data.success === true) {
                setLoading(false)
                props?.setIsModalOpenDis(false)
                toast.success('Success', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } catch (error) {
            toast.error('error', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            props?.setIsModalOpenDis(false)
        }
    }

    const [filteredCountries, setFilteredCountries]: any = useState(dataCountry);
    const [isDropdownOpen, setIsDropdownOpen]: any = useState(false);

    const onSearchChange = (e:any) => {
        const input = e.target.value;
        const filtered:any = dataCountry.filter(
          item =>
            item.value.toLowerCase().includes(input.toLowerCase()) ||
            item.code.toLowerCase().includes(input.toLowerCase())
        );
        setFilteredCountries(filtered);
        setIsDropdownOpen(true);
        setDataSelect(filtered[0]?.code)
      };

    const handleSearchInputKeyDown = (event:any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        } 
    };

    return (
        <>
            <Modal className="modal-purchase modal-dispage" maskClosable={false} title="Just A Sec!" open={props?.isModalOpenDis} onOk={props?.handleOkDis} onCancel={props?.handleCancelDis}>
                <div className="group-disclaimer-box">
                    <div className="content-over">
                        <div className="title-page">
                            By entering to this website and/or using our Services, you hereby agree, represent and warrant that:
                        </div>
                        <ul className="list-content">
                            <li>
                                You will be solely responsible to proceed at your own discretion.
                            </li>
                            <li>
                                You are not using, and will not in the future use, a VPN to mask your physical location from a restricted location.
                            </li>
                            <li>
                                You are responsible for ensuring compliance with the laws of your jurisdiction in connections with your use of the our Services.
                            </li>
                            <li>
                                You understand that Meyfi is not liable for your compliance or non-compliance with any such laws
                            </li>
                        </ul>
                        <div className="title-page">
                            Please confirm that you are not a Prohibited Person.
                        </div>
                        <p className="desc">
                            "Prohibited Person” shall mean any individual or legal entity that is (i) a national or resident of, or legal entity formed or incorporated within or subject to the laws of any United States embargoed or restricted country; (ii) solely with respect to the non-government sanctioned trading, mining, minting of digital assets or cryptocurrency, or support of the foregoing, a national or resident of, or legal entity formed or incorporated within, or subject to the laws of the People’s Republic of China; (iii) a national or resident of, or legal entity formed or incorporated within or subject to the laws of the Republic of Cuba, Democratic People’s Republic of North Korea, Venezuela, Islamic Republic of Iran, Libya, Republic of South Sudan, Republic of Sudan, Syrian Arab Republic, the Crimea, or any other jurisdiction in which it is prohibited from using the Website (the ”Prohibited Jurisdictions”); (iv) included on, or affiliated with any Person on, the United States Commerce Department’s Denied Persons List, Entities List, or Unverified List; the U.S. Department of the Treasury’s Specially Designated Nationals and Blocked Persons List, Specially Designated Narcotics Traffickers or Specially Designated Terrorists, or the Annex to Executive Order No. 13224; the Department of State’s Debarred List; or UN Sanctions; (v) a Person with whom business transactions, including exports and re-exports, are restricted by a United States Governmental Authority, including each item listed in the foregoing clauses (i), (ii), (iii), (iv) and (v) and any updates or revisions thereto and any newly published rules therefore; or (vi) a subject or target of any other economic sanctions administered or enforced by the United Nations, the European Union, the United States of America, or the United Kingdom."
                        </p>
                    </div>
                    <div className="checkbox-page">
                        <Checkbox onChange={onChangeCheckOne}>I declare that I am NOT a resident of the prohibited territories or possessions as listed above.</Checkbox>
                        <Checkbox onChange={onChangeCheckTwo}>I have read, Understood , and agree with the Privacy Policy and the Term of Service</Checkbox>
                        <Checkbox onChange={onChangeCheckThree}>I declare that, I am the resident of</Checkbox>
                    </div>
                    <div className="select-page">
                        <div className="ant-select-filter">
                            <Select
                                open={isDropdownOpen}
                                value={dataSelect ? dataSelect : "Choose from a list"}
                                onDropdownVisibleChange={(open) => setIsDropdownOpen(open)}
                                suffixIcon={<CaretDownOutlined rev={undefined} />}
                                onChange={handleChange}
                                dropdownRender={menu => (
                                    <>
                                        <Search
                                            placeholder="Input symbol"
                                            onChange={onSearchChange}
                                            onKeyDown={handleSearchInputKeyDown} 
                                            style={{ marginBottom: 8, width: '100%' }}
                                        />
                                        {menu}
                                    </>
                                )}
                            >
                                {filteredCountries.map((item: any, index: any) => (
                                    <Option key={index} value={item?.code}>
                                        <span className="text-chain">{item?.map} {item?.value}</span>
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                    {valueOne !== undefined && valueTwo !== undefined && valueThree !== undefined && dataSelect ?
                        <>
                            {loading ?
                                <>
                                    <button className="btn-action-confirm" onClick={handleConfirm}>
                                        <SyncOutlined spin />
                                    </button>
                                </>
                                :
                                <>
                                    <button className="btn-action-confirm" onClick={handleConfirm}>
                                        Accept
                                    </button>
                                </>
                            }
                        </>
                        :
                        <>
                            <button className="btn-action-confirm dis">
                                Accept
                            </button>
                        </>
                    }
                </div>
            </Modal>
        </>
    )
}
export default ModalDisclaimer