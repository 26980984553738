/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import type { MenuProps } from "antd";
import { Button, Menu } from "antd";
import "./style.scss";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import { isMobile } from "react-device-detect";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group",
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

const items = [
    getItem(
        <Link to="/apps">Home <div className="icon-mobile"><img src="/images/arr-mb.png" alt="" /></div></Link>,
        "1",
        <>
            <Link to="/apps">
                <img src="/images/menu/home.png" alt="" />
                <img src="/images/menu/home-1.png" alt="" />
            </Link>
        </>,
    ),
    getItem(
        <Link to="/apps/staking">Staking <div className="icon-mobile"><img src="/images/arr-mb.png" alt="" /></div></Link>,
        "2",
        <>
            <Link to="/apps/staking">
                <img src="/images/menu/stake.png" alt="" />
                <img src="/images/menu/stake-2.png" alt="" />
            </Link>
        </>,
    ),
    getItem(
        <Link to="/apps/p2p-lending">P2P Lending <div className="icon-mobile"><img src="/images/arr-mb.png" alt="" /></div></Link>,
        "4",
        <>
            <Link to="/apps/p2p-lending">
                <img src="/images/menu/lending.png" alt="" />
                <img src="/images/menu/lending-1.png" alt="" />
            </Link>
        </>,
    ),
    getItem(
        <Link to="/apps/pto">PTO <div className="icon-mobile"><img src="/images/arr-mb.png" alt="" /></div></Link>,
        "5",
        <>
            <Link to="/apps/pto">
                <img src="/images/menu/pto-1.png" alt="" />
                <img src="/images/menu/pto.png" alt="" />
            </Link>
        </>,
    ),
    getItem(
        <Link to="/apps/marketplace">Marketplace <div className="icon-mobile"><img src="/images/arr-mb.png" alt="" /></div></Link>,
        "6",
        <>
            <Link to="/apps/marketplace">
                <img src="/images/menu/store.png" alt="" />
                <img src="/images/menu/store-1.png" alt="" />
            </Link>
        </>,
    ),
    getItem(
        <Link to="/apps/history">History <div className="icon-mobile"><img src="/images/arr-mb.png" alt="" /></div></Link>,
        "8",
        <>
            <Link to="/apps/history">
                <img src="/images/menu/his.png" alt="" />
                <img src="/images/menu/his-1.png" alt="" />
            </Link>
        </>,
    ),
    getItem(
        <Link to="/apps/affiliate">Affiliate <div className="icon-mobile"><img src="/images/arr-mb.png" alt="" /></div></Link>,
        "affiliate",
        <>
            <Link to="/apps/affiliate">
                <img src="/images/menu/affiliate.png" alt="" />
                <img src="/images/menu/affiliate-1.png" alt="" />
            </Link>
        </>,
    ),
    getItem(
        <Link to="/apps/airdrop">Airdrop <div className="icon-mobile"><img src="/images/arr-mb.png" alt="" /></div></Link>,
        "9",
        <>
            <Link to="/apps/history">
                <img src="/images/air-non-active.png" alt="" />
                <img src="/images/air-active.png" alt="" />
            </Link>
        </>,
    ),
    getItem(
        <Link to="/apps/portfolio">My Account <div className="icon-mobile"><img src="/images/arr-mb.png" alt="" /></div></Link>,
        "7",
        <>
            <Link to="/apps/portfolio">
                <img src="/images/menu/por.png" alt="" />
                <img src="/images/menu/por-1.png" alt="" />
            </Link>
        </>,
    ),
];
const SideBar = (props: any) => {
    const [collapsed, setCollapsed] = useState(false);
    const [current, setCurrent] = useState<any>("1");

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const location = useLocation();

    const mapPathToKey: { [key: string]: string } = {
        "/apps": "1",
        "/apps/staking": "2",
        "/apps/staking/details": "2",
        "/apps/p2p-lending": "4",
        "/apps/p2p-lending/details": "4",
        "/apps/pto": "5",
        "/apps/pto/details": "5",
        "/apps/marketplace": "6",
        "/apps/marketplace/detail": "6",
        "/apps/history": "8",
        "/apps/affiliate": "affiliate",
        "/apps/airdrop": "9",
        "/apps/portfolio": "7",
        "/apps/verify-email": "7",
        "/apps/vesting": "7",
        "/apps/edit-profile": "7",
        "/apps/rank": "7",
    };

    const activeKey = mapPathToKey[location.pathname] || "1";


    const onClick: MenuProps["onClick"] = (e) => {
        setCurrent(e.key);
        sessionStorage.setItem("keyActiveMenu", e.key);
        if (isMobile) {
            window.location.reload()
        }
    };

    let keyActiveDrop: any;
    if (
        typeof window !== "undefined" &&
        window.sessionStorage.getItem("keyActiveMenu") !== undefined
    ) {
        keyActiveDrop = window.sessionStorage.getItem("keyActiveMenu");
    }
    function gotoDocs() {
        window.open("https://docs.mey.network/mey-network");
    }

    const sendData = () => {
        props?.callbackFunction(collapsed);
    }

    useEffect(() => {
        sendData()
    }, [collapsed]);

    return (
        <>
            <div className="menu-wrap">
                <div className={clsx("menu-sidebar", collapsed && "collapse-menu")}>
                    {isMobile ?
                        <>
                            <div className="sidebar-top sidebar-mobile">
                                <Link className={clsx(collapsed && "flex justify-center")} to="/">
                                    <img
                                        className="h-7"
                                        src={
                                            collapsed ? "/images/logo-collapse.png" : "/images/logo.png"
                                        }
                                        alt=""
                                    />
                                </Link>
                                <div
                                    className={clsx(
                                        "sidebar-icon transition",
                                        !collapsed && "rotate-180",
                                    )}
                                    onClick={toggleCollapsed}
                                >
                                    <img src="/images/icon-sidebar.png" alt="" />
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="sidebar-top">
                                <Link className={clsx(collapsed && "flex justify-center")} to="/">
                                    <img
                                        className="h-7"
                                        src={
                                            collapsed ? "/images/logo-collapse.png" : "/images/logo.png"
                                        }
                                        alt=""
                                    />
                                </Link>
                                <div
                                    className={clsx(
                                        "sidebar-icon transition",
                                        !collapsed && "rotate-180",
                                    )}
                                    onClick={toggleCollapsed}
                                >
                                    <img src="/images/icon-sidebar.png" alt="" />
                                </div>
                            </div>
                        </>
                    }
                    <div className="sidebar-center">
                        <Menu
                            selectedKeys={[activeKey]}
                            onClick={onClick}
                            mode="inline"
                            theme="dark"
                            items={items}
                        />
                    </div>
                    <div className="sidebar-bottom">
                        <div className="row-contact">
                            {/* <Link to="/apps/p2p-lending"> */}
                            {/* <a href="http://portal-meyfi-group-dev.testproduct.site/" target="_blank">
                                <div className="item">
                                    <img src="/images/user/link.png" alt="" /> <span>Portal</span>
                                </div>
                            </a> */}

                            {/* </Link> */}
                            {/* {isMobile ?
                                <>
                                    {""}
                                </>
                                :
                                <>
                                    <div className="item">
                                        <img src="/images/headphone.png" alt="" />{" "}
                                        <span>Help Center</span>
                                    </div>
                                </>
                            } */}
                            <div className="item" onClick={gotoDocs}>
                                <img src="/images/question.png" alt="" /> <span>Docs</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SideBar;
