
export const convertDateToString = (value :any)=> {
  const date = new Date(value * 1000);

// Lấy giờ và phút
let hours = date.getUTCHours();
const minutes = date.getUTCMinutes().toString().padStart(2, '0');

// Xác định AM hay PM
const ampm = hours >= 12 ? 'PM' : 'AM';
hours = hours % 12;
hours = hours ? hours : 12; // Nếu giờ = 0 thì đổi thành 12

// Lấy tháng (dạng viết tắt)
const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const month = monthNames[date.getUTCMonth()];

// Lấy thứ tự ngày (thêm 'st', 'nd', 'rd', 'th')
const day = date.getUTCDate();
const daySuffix = (day % 10 === 1 && day !== 11) ? 'st' :
                  (day % 10 === 2 && day !== 12) ? 'nd' :
                  (day % 10 === 3 && day !== 13) ? 'rd' : 'th';

// Lấy năm
const year = date.getUTCFullYear();

// Tạo chuỗi định dạng
const formattedDate = `${hours}:${minutes} ${ampm} UTC, ${month} ${day}${daySuffix} ${year}`;
return formattedDate
}
export const convertDateToStringSecond = (value :any)=> {
  const date = new Date(value);

// Lấy giờ và phút
let hours = date.getUTCHours();
const minutes = date.getUTCMinutes().toString().padStart(2, '0');

// Xác định AM hay PM
const ampm = hours >= 12 ? 'PM' : 'AM';
hours = hours % 12;
hours = hours ? hours : 12; // Nếu giờ = 0 thì đổi thành 12

// Lấy tháng (dạng viết tắt)
const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const month = monthNames[date.getUTCMonth()];

// Lấy thứ tự ngày (thêm 'st', 'nd', 'rd', 'th')
const day = date.getUTCDate();
const daySuffix = (day % 10 === 1 && day !== 11) ? 'st' :
                  (day % 10 === 2 && day !== 12) ? 'nd' :
                  (day % 10 === 3 && day !== 13) ? 'rd' : 'th';

// Lấy năm
const year = date.getUTCFullYear();


// Tạo chuỗi định dạng
const formattedDate = `${hours}:${minutes} ${ampm} UTC, ${month} ${day}${daySuffix} ${year}`;

return formattedDate
}