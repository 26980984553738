import { Segmented, Skeleton, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { STATUS_PTO } from "../pto/index.d";

const AssetComponent = (props: any) => {
    const loadingAsset: any = props?.loadingAsset
    const dataAsset: any = props?.dataAsset
    const naigation = useNavigate()

    const ItemProperti = ({ item }: any) => {
        const handleOpenDetails = () => {
            naigation(`/apps/pto/details?id=${item?.code}`)
        }

        const renderStatus = (numStt: any) => {
            switch (numStt) {
                case STATUS_PTO.OPEN:
                    return "In Progress"
                case STATUS_PTO.COMING:
                    return "Upcoming"
                case STATUS_PTO.SOLD_OUT:
                    return "Sold out"
                case STATUS_PTO.END:
                    return "Ends"
                default:
                    return "TBA"
            }
        }

        const renderClassStatus = (numStt: any) => {
            switch (numStt) {
                case STATUS_PTO.OPEN:
                    return "detail-status open"
                case STATUS_PTO.COMING:
                    return "detail-status coming"
                case STATUS_PTO.SOLD_OUT:
                    return "detail-status end"
                case STATUS_PTO.END:
                    return "detail-status ends"
                default:
                    return "detail-status tba"
            }
        }

        return (
            <>
                <div className="colum w-4">
                    <div className="guide-item" onClick={handleOpenDetails}>
                        <div className="name-type">
                            <div className={renderClassStatus(item?.status)}>
                                {renderStatus(item?.status)}
                            </div>
                        </div>
                        <div className="box-img">
                            <img src={item?.thumbnail} alt="" />
                        </div>
                        <div className="bottom-item">
                            <div className="commercial-type">
                                <div className="item">
                                    <img src="/images/user/lo.png" alt="" /> {item?.country}
                                </div>
                                <div className="item">
                                    <img src="/images/user/me.png" alt="" /> <span>Type: </span> {item?.type}
                                </div>
                            </div>
                            <div className="location-row">
                                <div className="title-row">
                                    {item?.name}
                                </div>
                            </div>
                            <div className="property-price">
                                Property Price <span>${item?.tokenIssued * item?.pricePerTokenIssued}</span>
                            </div>
                            <div className="list-assigment">
                                <div className="item">
                                    <div className="icon">
                                        <img src="/images/user/si-1.png" alt="" />
                                    </div>
                                    <div className="text">
                                        <div className="txt-top">
                                            Total Projected Gross Yield
                                        </div>
                                        <div className="txt-bot">
                                            {item?.projectedGrossYield}
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="icon">
                                        <img src="/images/user/si-2.png" alt="" />
                                    </div>
                                    <div className="text">
                                        <div className="txt-top">
                                            Annual Asset Appreciation
                                        </div>
                                        <div className="txt-bot">
                                            {item?.annualAssetAppreciation}
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="icon">
                                        <img src="/images/user/si-1.png" alt="" />
                                    </div>
                                    <div className="text">
                                        <div className="txt-top">
                                            Annual Gross Rental Yield
                                        </div>
                                        <div className="txt-bot">
                                            {item?.annualGrossRentalYield}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <div className="content-field">
                <div className="ant-item-wrap">
                    <div className="list-filter-profile">
                        <div className="row-left">
                            Total Properties ({dataAsset?.length})
                        </div>
                        {/* <div className="row-right">
                            <div className="row-search">
                                <input placeholder="Search name" type="text" />
                                <img src="/images/user/search.png" alt="" />
                            </div>
                            <div className="status-filter">
                                <button className="btn-status">
                                    All Status
                                </button>
                            </div>
                        </div> */}
                    </div>
                    <div className="columns">
                        {loadingAsset ?
                            <>
                                {[...Array(3)].map((_, index) => (
                                    <Skeleton key={index} active />
                                ))}
                            </>
                            :
                            <>
                                {dataAsset && dataAsset.length > 0 ? (
                                    dataAsset.map((item: any, index: any) => (
                                        <ItemProperti key={index} item={item} index={index + 1} />
                                    ))
                                ) : (
                                    <>
                                        <div className="box-no-data-lending">
                                            <img className='img-empty' src="/images/no-data-cus.png" alt="" />
                                        </div>
                                    </>
                                )}
                            </>
                        }
                    </div>
                </div>
            </div >
        </>
    )
}
export default AssetComponent