import React, { useEffect, useState } from "react";
import { Progress, Skeleton } from "antd";
import { useNavigate } from "react-router-dom";
import Countdown from "react-countdown";
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import { rendererCountDown2 } from "../p2p-lending/utils/render-countdown";
import { RPC_BASE } from "../../../constants";
import abiLending from "../p2p-lending/utils/abiLending.json"

const Item = ({ item }: any) => {
    let web3: any = new Web3(RPC_BASE);
    const naigation = useNavigate()
    let timeNowStam = new Date().getTime()
    let scAddress = item?.contractAddress
    const [startTimePool, setStartTimePool]: any = useState(0)
    const [endTimePool, setEndTimePool]: any = useState(0)
    const [totalLenderPool, setTotalLenderPool] = useState(0)
    const [apyBasic, setApyBasic] = useState(0)
    const [fundingCap, setFundingCap] = useState(0)
    const [numberLendbyUser, setNumberLendbyUser] = useState(0)
    const [upcomingPool, setUpcomingPool]: any = useState(0)

    const contractLendingNotAddress = new web3.eth.Contract(
        abiLending as unknown as AbiItem,
        scAddress
    );

    const getInfoContract = async () => {
        if (contractLendingNotAddress && scAddress) {
            let infoContract = await contractLendingNotAddress?.methods?.getContractInfo().call()
            setApyBasic(Number(infoContract?._baseAPY) / 100)
            setEndTimePool(Number(infoContract?._endTime))
            setStartTimePool(Number(infoContract?._startTime))
            setFundingCap(Number(web3.utils.fromWei(infoContract?._fundingCap, "picoether")))
            setNumberLendbyUser(Number(web3.utils.fromWei(infoContract?._totalLentAmount, "picoether")))
            setTotalLenderPool(Number(infoContract?._totalLenders))
            setUpcomingPool(Number(infoContract?._fundingOpenTime))
        }
    }

    useEffect(() => {
        getInfoContract()
    }, [contractLendingNotAddress, scAddress]);

    let percentProcess: any = Number(numberLendbyUser) / Number(fundingCap) * 100

    const handleOpenDetails = () => {
        naigation(`/apps/p2p-lending/details?id=${item?.code}`)
    }

    return (
        <>
            <div className="colum w-4">
                <div className="guide-item" onClick={handleOpenDetails}>
                    <div className="name-type">
                        {(upcomingPool * 1000) > timeNowStam ?
                            <>
                                <div className="type-process">
                                    Upcoming
                                </div>
                            </>
                            :
                            <>
                                {(upcomingPool * 1000) < timeNowStam && timeNowStam < (startTimePool * 1000) ?
                                    <>
                                        <div className="type-process">
                                            In Progress
                                        </div>
                                    </>
                                    :
                                    <>
                                        {timeNowStam > (startTimePool * 1000) && Number(numberLendbyUser) < Number(fundingCap) ?
                                            <>
                                                <div className="type-process sold-out">
                                                    Partially filled
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="type-process sold-out">
                                                    Sold Out
                                                </div>
                                            </>
                                        }
                                    </>
                                }
                            </>
                        }
                    </div>
                    <div className="box-img">
                        <img src={item?.thumbnail} alt="" />
                    </div>
                    <div className="bottom-item">
                        <div className="commercial-type">
                            <div className="item time-clock">
                                {(upcomingPool * 1000) > timeNowStam ?
                                    <>
                                        {upcomingPool &&
                                            <>
                                                <img src="/images/lending/clock.png" alt="" />
                                                <Countdown
                                                    date={Number(upcomingPool) * 1000}
                                                    renderer={rendererCountDown2}
                                                />
                                            </>
                                        }
                                    </>
                                    :
                                    <>
                                        {(upcomingPool * 1000) < timeNowStam && timeNowStam < (startTimePool * 1000) ?
                                            <>
                                                {startTimePool &&
                                                    <>
                                                        <img src="/images/lending/clock.png" alt="" />
                                                        <Countdown
                                                            date={Number(startTimePool * 1000)}
                                                            renderer={rendererCountDown2}
                                                        />
                                                    </>

                                                }
                                            </>
                                            :
                                            <>
                                                {endTimePool &&
                                                    <>
                                                        Finished
                                                    </>
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </div>
                            <div className="item">
                                <img src="/images/user/lo.png" alt="" /> {item?.country}
                            </div>
                        </div>
                        <div className="location-row">
                            <div className="title-row">
                                {item?.name}
                            </div>
                            <div className="item res">
                                <img src="/images/logo-user.png" alt="" /> {totalLenderPool}
                            </div>
                        </div>
                        <div className="bottom-process">
                            <Progress percent={percentProcess.toFixed(0)} status="active" />
                        </div>
                        <div className="raise-profit">
                            <div className="box-riase">
                                <div className="txt">Funding Taget</div>
                                <div className="number-raise">
                                    <span className="number">${new Intl.NumberFormat('en-US').format(fundingCap)}</span>
                                </div>
                            </div>
                            <div className="apy-profit">
                                <div className="txt-top">
                                    APY
                                </div>
                                <div className="txt-bottom">
                                    {apyBasic}%
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const LoansComponent = (props: any) => {
    let dataLoans = props?.dataLoans

    return (
        <>
            <div className="content-field">
                <div className="ant-item-wrap">
                    <div className="list-filter-profile">
                        <div className="row-left">
                            Total Properties ({dataLoans?.length})
                        </div>
                        {/* <div className="row-right">
                            <div className="row-search">
                                <input placeholder="Search name" type="text" />
                                <img src="/images/user/search.png" alt="" />
                            </div>
                            <div className="status-filter">
                                <button className="btn-status">
                                    All Status
                                </button>
                            </div>
                        </div> */}
                    </div>
                    <div className="columns">
                        {props?.loadingLoans ?
                            <>
                                {[...Array(3)].map((_, index) => (
                                    <Skeleton key={index} active />
                                ))}
                            </>
                            :
                            <>
                                {dataLoans && dataLoans.length > 0 ? (
                                    dataLoans.map((item: any, index: any) => (
                                        <Item key={index} item={item} index={index + 1} />
                                    ))
                                ) : (
                                    <>
                                        <div className="box-no-data-lending">
                                            <img className='img-empty' src="/images/no-data-cus.png" alt="" />
                                        </div>
                                    </>
                                )}
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default LoansComponent