import React, { useEffect, useRef, useState } from "react";
import { Progress, Skeleton, Tabs, Select, Modal, Carousel, Spin, Tooltip } from "antd";
import { ethers } from "ethers";
import abiLending from "./utils/abiLending.json"
import abiTokenStake from "./utils/abiTokenStake.json"
import boostedapyWhitelist from "./utils/boostedapyWhitelist.json"
import Faqs from "./faqs";
import { AppHome } from "../../../api/appHome";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { rendererCountDown2 } from "./utils/render-countdown";
import { useContract } from "../../../hooks/useContract";
import Countdown from "react-countdown";
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import { useAccount } from "wagmi";
import { Noti } from "../../../components/Noti/toast";
import { LoadingOutlined } from '@ant-design/icons';
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { useWeb3Modal } from '@web3modal/wagmi/react'
import moment from "moment";
import { BASE_SCAN, RPC_BASE } from "../../../constants";
import ModalDisclaimer from "../../../components/modal-disclaimer";
import { isMobile } from "react-device-detect";
import ChartMarket from "./chartJs";

const DetailsP2pLending = () => {
    const { address } = useAccount();
    const inputRef: any = useRef(null);
    let web3: any = new Web3(RPC_BASE);
    const [valueAmount, setValueAmount] = useState<any>("");
    const [valueAmountUI, setValueAmountUI] = useState<any>("");
    const [dataDetail, setDataDetail]: any = useState()
    const location = useLocation()
    const pathUrl: any = location?.search?.split("?")
    const pathSearch = pathUrl[1]
    const pathId = pathSearch?.split("=")
    const pathParam = pathId[1]
    let pathUrlDis: any = location?.pathname.split("/");
    let pathTitle = pathUrlDis[2];
    const [activeSummary, setActiveSummary] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenDis, setIsModalOpenDis] = useState(false);
    const [isModalOpenApprove, setIsModalOpenApprove] = useState(false);
    const [isModalOpenClaim, setIsModalOpenClaim] = useState(false);
    const [amountUsdt, setAmountUsdt] = useState(0)
    const [dataContract, setdataContract]: any = useState()
    let scUsdt = dataContract && dataContract?.p2p_deposit_token_address
    let scCheckWl = dataContract && dataContract?.user_hold_mey_token_contract
    let scPool: any = dataDetail?.contractAddress
    const contractLending: any = useContract(scPool, abiLending)
    const contractWl: any = useContract(scCheckWl, boostedapyWhitelist)
    const contractUsdt: any = useContract(scUsdt, abiTokenStake);
    const [isApprove, setIsApprove] = useState(false)
    const [loadingApprove, setLoadingApprove] = useState(false)
    const [loadingPurchase, setLoadingPurchase] = useState(false)
    const [loadingClaim, setLoadingClaim] = useState(false)
    const [loadingArr, setLoadingArr] = useState(false)
    const [totalLenderPool, setTotalLenderPool] = useState(0)
    const [apyBasic, setApyBasic] = useState(0)
    const [boostedCallSc, setBoostedCallSc] = useState(0)
    const [fundingCap, setFundingCap] = useState(0)
    const [numberLendbyUser, setNumberLendbyUser] = useState(0)
    const [startTimePool, setStartTimePool]: any = useState(0)
    const [upcomingPool, setUpcomingPool]: any = useState(0)
    const [endTimePool, setEndTimePool]: any = useState(0)
    const [lenderTotalBalanceByUser, setLenderTotalBalanceByUser] = useState(0)
    const [historyByuser, setHistoryByUser]: any = useState([])
    const [hashLend, setHashLend]: any = useState("")
    const [isClaimed, setIsClaimed] = useState(0)
    const [checkWl, setCheckWl] = useState(false)
    const timeInvestmentPeriod = Number(endTimePool) - Number(startTimePool);
    const secondsInAMinute = 60 * 1000;
    const secondsInAnHour = 60 * secondsInAMinute;
    const secondsInADay = 24 * secondsInAnHour;
    const secondsInAMonth = 30 * secondsInADay;
    let timeNowStam = new Date().getTime()
    const [dataAccount, setDataAccount]: any = useState()
    const [rewardOneUserAfter, setRewardOneUserAfter] = useState(0)
    const [isReady, setIsReady] = useState(false);
    const [boosted, setBoosted] = useState(0)
    const [estimatedReward, setEstimatedReward] = useState(0)
    const [notified, setNotified]: any = useState(false);
    const [dataHistoryFromSc, setDataHistoryFromSc]: any = useState([]);
    const [rewardByApi, setRewardByApi]: any = useState(0)

    let period;

    if (timeInvestmentPeriod >= secondsInAMonth) {
        const monthsPeriod = timeInvestmentPeriod / secondsInAMonth;
        period = `${monthsPeriod} Month`;
    } else {
        const days = Math.floor(timeInvestmentPeriod / secondsInADay);
        const hours = Math.floor((timeInvestmentPeriod % secondsInADay) / secondsInAnHour);
        const minutes = Math.floor((timeInvestmentPeriod % secondsInAnHour) / secondsInAMinute);
        period = `${days}d:${hours}h:${minutes}m`;
    }

    const contractLendingNotAddress = new web3.eth.Contract(
        abiLending as unknown as AbiItem,
        scPool
    );

    const SettingContract = async () => {
        try {
            let res = await AppHome.getSettingContract();
            if (res.data.success === true) {
                setdataContract(res.data.data);
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    useEffect(() => {
        SettingContract()
    }, []);

    const { open } = useWeb3Modal()

    const handleConnect = () => {
        open();
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const showModalDis = () => {
        setIsModalOpenDis(true);
    };

    const handleOkDis = () => {
        setIsModalOpenDis(false);
    };

    const handleCancelDis = () => {
        setIsModalOpenDis(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setHashLend("")
        setValueAmount(0)
        setValueAmountUI(0)
    };

    const showModalApprove = () => {
        setIsModalOpenApprove(true);
    };

    const handleOkApprove = () => {
        setIsModalOpenApprove(false);
    };

    const handleCancelApprove = () => {
        setIsModalOpenApprove(false);
        setValueAmount(0)
        setValueAmountUI(0)
    };

    const showModalClaim = () => {
        setIsModalOpenClaim(true);
    };

    const handleOkClaim = () => {
        setIsModalOpenClaim(false);
    };

    const handleCancelClaim = () => {
        setIsModalOpenClaim(false);
    };

    const getInfoP2pDetail = async () => {
        try {
            let res = await AppHome.getListP2pDetail(
                pathParam, address || ""
            );
            if (res.data.success === true) {
                setDataDetail(res.data.data);
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    useEffect(() => {
        getInfoP2pDetail();
    }, [address]);

    useEffect(() => {
        if (dataDetail && address !== undefined) {
            setIsReady(true);
        }
    }, [dataDetail, address]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (isReady && address && dataDetail) {
                if (dataDetail?.userDetail?.isDisclaimer === false) {
                    showModalDis();
                }
            }
        }, 2000);
        return () => clearTimeout(timer);

    }, [isReady, dataDetail, address]);

    const handleAmount = (e: any) => {
        const newValue = e.target.value;

        const cleanedValue = newValue.replace(/[^0-9,]/g, '');

        const validValue = cleanedValue.replace(/,{2,}/g, ',');
        const numericValue = validValue.replace(/,/g, '');
        const formattedValue = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        setValueAmount(numericValue);
        setValueAmountUI(formattedValue);

        const numericNumber = Number(numericValue);
        if (numericNumber > fundingCap - numberLendbyUser && !notified) {
            Noti('error', "Amount has exceeded the pool limit", 3000);
            setNotified(true);
        } else if (numericNumber <= fundingCap - numberLendbyUser && notified) {
            setNotified(false);
        }
    };


    const tabJust = (
        <>
            <div className="title-tabs">
                Property Details
            </div>
        </>
    )

    const tabTrending = (
        <>
            <div className="title-tabs">
                Loans Details
            </div>
        </>
    )

    const tabTimeline = (
        <>
            <div className="title-tabs">
                P2P Timeline
            </div>
        </>
    )

    const tabFinancials = (
        <>
            <div className="title-tabs">
                Financials
            </div>
        </>
    )

    const tabDocument = (
        <>
            <div className="title-tabs">
                Document Details
            </div>
        </>
    )

    const tabMarket = (
        <>
            <div className="title-tabs">
                Market
            </div>
        </>
    )

    const tabMap = (
        <>
            <div className="title-tabs">
                Map
            </div>
        </>
    )

    const getHistoryLendByUser = async () => {
        setLoadingArr(true)
        let params = {
            ownerAddress: address,
            contract: scPool,
            pageSize: 5,
            pageIndex: 0
        }

        try {
            let res = await AppHome.getHistoryLend(
                params
            );
            if (res.data.message === "Success") {
                setLoadingArr(false)
                setHistoryByUser(res.data.data)
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    useEffect(() => {
        if (address) {
            getHistoryLendByUser()
        }
    }, [address, scPool]);

    const AccountInfo = async () => {
        try {
            let res = await AppHome.AccountInfo(
                address
            );
            if (res.data.success === true) {
                setDataAccount(res.data.data);
                localStorage.setItem('userAccount', JSON.stringify(res.data.data.dataAccount));
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    useEffect(() => {
        if (address) {
            AccountInfo()
        }
    }, [address]);

    const getBalanceUsdt = async () => {
        if (contractUsdt) {
            const amount: any = await contractUsdt.balanceOf(address);
            let balance: any = web3.utils.fromWei(amount, "picoether");
            setAmountUsdt(Number(balance));
        }
    };

    useEffect(() => {
        if (address) {
            getBalanceUsdt()
        }
    }, [address, contractUsdt]);

    const formatNumberWithCommas = (value: number) => {
        return value.toLocaleString('en-US');
    };

    const handleMaxPercent = () => {
        const maxValue = Number(fundingCap - numberLendbyUser);
        const amountValue = Number(amountUsdt);

        if (amountValue > maxValue) {
            setValueAmount(maxValue);
            setValueAmountUI(formatNumberWithCommas(maxValue));
        } else {
            setValueAmount(amountValue);
            setValueAmountUI(formatNumberWithCommas(amountValue));
        }
    };

    const handle50Percent = () => {
        const maxValue = Number(fundingCap - numberLendbyUser);
        const amountValue = Number(amountUsdt);

        if (amountValue > maxValue) {
            const halfValue = maxValue / 2;
            setValueAmount(halfValue);
            setValueAmountUI(formatNumberWithCommas(halfValue));
        } else {
            const halfValue = amountValue / 2;
            setValueAmount(halfValue);
            setValueAmountUI(formatNumberWithCommas(halfValue));
        }
    };

    const handleApprove = async () => {
        setLoadingApprove(true)
        try {
            let amount = web3.utils.toWei(valueAmount.toString(), "picoether");
            await contractUsdt.approve(scPool, amount).then((res: any) => {
                res.wait()
                    .then((resWait: any) => {
                        if (resWait?.status === 1) {
                            Noti('success', "Approved successfully", 3000)
                            setLoadingApprove(false)
                            setIsModalOpenApprove(false);
                            setIsApprove(true)
                        } else {
                            Noti('error', "Unknown error (status hash fail)", 3000)
                            setLoadingApprove(false)
                            setIsModalOpenApprove(false);
                        }
                    })
                    .catch((errWait: any) => {
                        console.log('errWait', errWait)
                        setLoadingApprove(false)
                        setIsModalOpenApprove(false);
                    })
            })
                .catch((err: any) => {
                    console.log('err', err)
                    setLoadingApprove(false)
                    setIsModalOpenApprove(false);
                    Noti('error', err?.reason || err?.message || "Unknown error", 3000)
                })
        } catch (error: any) {
            console.log('error', error)
            if (error.code === ethers.errors.CALL_EXCEPTION) {
                Noti('error', error.error?.data?.message || "Unknown error", 3000)
            } else {
                Noti('error', error || "Unknown error", 3000)
            }
            setLoadingApprove(false)
            setIsModalOpenApprove(false);
        }
    }

    const handlePurchase = async () => {
        setLoadingPurchase(true)
        try {
            let amount = web3.utils.toWei(valueAmount.toString(), "picoether");
            await contractLending.fundLoan(amount).then(async (res: any) => {
                let params = {
                    ownerAddress: address,
                    contractAddress: scPool,
                    txHash: res?.hash,
                    amount: Number(valueAmount),
                    type: 6,
                    assetToken: "USDC"
                }
                try {
                    let resApi = await AppHome.postHistoryLend(
                        params
                    );
                    console.log('resApi', resApi)
                } catch (error) {
                    console.log("Error: ", error);
                }
                res.wait().then(async (resWait: any) => {
                    if (resWait?.status === 1) {
                        getHistoryLendByUser()
                        getEstimateRewardByUser()
                        setHashLend(res?.hash)
                        Noti('success', "Lend successfully", 3000)
                        setLoadingPurchase(false)
                    } else {
                        Noti('error', "Unknown error (status hash fail)", 3000)
                        setLoadingPurchase(false)
                        setIsModalOpen(false)
                    }
                })
            })
                .catch((err: any) => {
                    setLoadingPurchase(false)
                    setIsModalOpen(false)
                    Noti('error', err?.reason || err?.message || "Unknown error", 3000)
                })
        } catch (error: any) {
            setLoadingPurchase(false)
            if (error.code === ethers.errors.CALL_EXCEPTION) {
                Noti('error', error.error?.data?.message || "Unknown error", 3000)
            } else {
                Noti('error', error || "Unknown error", 3000)
            }
        }
    }

    const getAmountApprove = async () => {
        await contractUsdt?.allowance(address, scPool).then((res: any) => {
            if (res) {
                let balance: any = web3.utils.fromWei(res, "picoether");
                if (Number(balance) >= Number(valueAmount)) {
                    setIsApprove(true)
                } else {
                    setIsApprove(false)
                }
            }
        })
    }

    useEffect(() => {
        if (address && contractUsdt && scPool) {
            getAmountApprove()
        }
    }, [contractUsdt, address, scPool]);

    const getInfoContract = async () => {
        if (contractLendingNotAddress && scPool) {
            let infoContract = await contractLendingNotAddress?.methods?.getContractInfo().call()
            setApyBasic(Number(infoContract?._baseAPY) / 100)
            setEndTimePool(Number(infoContract?._endTime) * 1000)
            setStartTimePool(Number(infoContract?._startTime) * 1000)
            setFundingCap(Number(web3.utils.fromWei(infoContract?._fundingCap, "picoether")))
            setNumberLendbyUser(Number(web3.utils.fromWei(infoContract?._totalLentAmount, "picoether")))
            setTotalLenderPool(Number(infoContract?._totalLenders))
            setUpcomingPool(Number(infoContract?._fundingOpenTime) * 1000)
        }
    }

    useEffect(() => {
        getInfoContract()
    }, [contractLendingNotAddress, scPool, dataDetail]);

    const getApyBoosted = async () => {
        if (contractLending && address) {
            let totalApy = await contractLending?.getBoostedAPY(address)
            setBoosted(Number(totalApy) / 100)
            setBoostedCallSc(Number(totalApy))
        }
    }

    let apyBoosted = boosted

    useEffect(() => {
        getApyBoosted()
    }, [address, contractLending]);

    const getLenderTotalBalanceByUser = async () => {
        if (contractLending && address) {
            let totalBalance = await contractLending?.lenderTotalBalance(address)
            let balance: any = web3.utils.fromWei(totalBalance, "picoether")
            setLenderTotalBalanceByUser(balance)
        }
    }

    const getEstimateRewardByUser = async () => {
        if (contractLending && address) {
            let totalBalance = await contractLending?.getEstimateReward(address)
            let balance: any = web3.utils.fromWei(totalBalance, "picoether")
            setEstimatedReward(balance)
        }
    }

    useEffect(() => {
        getEstimateRewardByUser()
    }, [address, contractLending, scPool]);

    const getUserByApyBoosted = async () => {
        if (contractWl && address) {
            let wlUser = await contractWl?.isWhitelisted(address)
            setCheckWl(wlUser)
        }
    }

    useEffect(() => {
        getUserByApyBoosted()
    }, [address, contractWl, scCheckWl]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
            inputRef.current.setSelectionRange(valueAmount.length, valueAmount.length);
        }
    }, [valueAmount]);

    useEffect(() => {
        getLenderTotalBalanceByUser()
    }, [address, contractLending, scPool]);

    let percentProcess: any = Number(numberLendbyUser) / Number(fundingCap) * 100
    let amountTotaReward = Number(rewardOneUserAfter) + Number(lenderTotalBalanceByUser)

    const handleWithdraw = async () => {
        setLoadingClaim(true)
        try {
            await contractLending.withdrawByLender().then(async (res: any) => {
                let params = {
                    ownerAddress: address,
                    contractAddress: scPool,
                    txHash: res?.hash,
                    amount: 0,
                    type: 8,
                    assetToken: "USDC"
                }
                try {
                    let resApi = await AppHome.postHistoryLend(
                        params
                    );
                } catch (error) {
                    console.log("Error: ", error);
                }
                res.wait().then(async (resWait: any) => {
                    if (resWait?.status === 1) {
                        Noti('success', "Withdraw successfully", 3000)
                        setLoadingClaim(false)
                        setIsModalOpenClaim(false);
                    } else {
                        Noti('error', "Unknown error (status hash fail)", 3000)
                        setIsModalOpenClaim(false);
                        setLoadingClaim(false)
                    }
                })
            })
                .catch((err: any) => {
                    console.log('err', err)
                    setLoadingClaim(false)
                    setIsModalOpenClaim(false);
                    Noti('error', err?.reason || err?.message || "Unknown error", 3000)
                })
        } catch (error: any) {
            console.log('error', error)
            setLoadingClaim(false)
            setIsModalOpenClaim(false);
            if (error.code === ethers.errors.CALL_EXCEPTION) {
                Noti('error', error.error?.data?.message || "Unknown error", 3000)
            } else {
                Noti('error', error || "Unknown error", 3000)
            }
        }
    }

    const setIsClaimedUser = async () => {
        if (contractLending && address) {
            let isClaim = await contractLending.lastClaimTime(address)
            setIsClaimed(Number(isClaim))
        }
    }

    useEffect(() => {
        if (address) {
            setIsClaimedUser()
        }
    }, [contractLending, address, scPool]);

    const PostSoldOut = async () => {
        let params = {
            code: dataDetail?.code
        }
        try {
            let res = await AppHome.postSoldOut(
                params
            );
            if (res.data.success === true) {
                console.log('res.data.data', res.data.data)
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    useEffect(() => {
        if (dataDetail?.soldOut === 0) {
            if (
                timeNowStam > startTimePool &&
                Number(numberLendbyUser) < Number(fundingCap)
            ) {
                PostSoldOut();
            }
            else if (timeNowStam > endTimePool) {
                PostSoldOut();
            }
        }
    }, [dataDetail, startTimePool, numberLendbyUser, fundingCap, endTimePool]);

    const [isFetched, setIsFetched] = useState(false);

    const getHistoryByUser = async () => {
        if (contractLending && address) {
            const history = [];
            const extractedData = [];

            for (let i = 0; i < 5; i++) {
                try {
                    let loan = await contractLending.lenderLoans(address, i);
                    console.log(`Loan ${i}:`, loan);
                    history.push(loan);
                    if (loan) {
                        extractedData.push({
                            lendTime: Number(loan.startTime),
                            lendAmount: Number(loan.loanAmount / 1e18),
                        });
                    }
                } catch (error) {
                    console.error(`Error fetching loan ${i}:`, error);
                }
            }
            setDataHistoryFromSc(extractedData)
        }
    };

    useEffect(() => {
        if (contractLending && address && !isFetched) {
            getHistoryByUser();
            setIsFetched(true);
        }
    }, [contractLending, address, isFetched, scPool]);

    const PostEstReward = async () => {
        let params = {
            apy: boosted,
            endTime: endTimePool / 1000,
            lendOjb: dataHistoryFromSc
        }
        try {
            let res = await AppHome.postEstReward(
                params
            );
            if (res.data.success === true) {
                setRewardByApi(Number(res.data.data.reward).toFixed(3))
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    const PostEstRewardAfter = async () => {
        let params = {
            apy: boosted,
            endTime: endTimePool / 1000,
            lendOjb: [
                {
                    lendTime: timeNowStam / 1000,
                    lendAmount: valueAmount,
                }
            ]
        }
        try {
            let res = await AppHome.postEstReward(
                params
            );
            if (res.data.success === true) {
                setRewardByApi(Number(res.data.data.reward).toFixed(3))
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    useEffect(() => {
        if (dataHistoryFromSc?.length > 0) {
            PostEstReward()
        } else {
            if (Number(valueAmount > 0)) {
                PostEstRewardAfter()
            }
        }
    }, [boosted, endTimePool, dataHistoryFromSc, valueAmount]);

    const dataTimelineSlice = dataDetail && dataDetail?.timeLines[0]?.desc?.split(".")
    const FilterArr = dataTimelineSlice && dataTimelineSlice.filter((item: any) => item !== "");

    const latitude = dataDetail?.geographicCoordinateSystem?.latitude;
    const longitude = dataDetail?.geographicCoordinateSystem?.longitude;

    const mapSrc = `https://www.google.com/maps?q=${latitude},${longitude}&hl=es;z=14&output=embed`;

    const naigation = useNavigate()

    function gotoKYC() {
        naigation(`/apps/edit-profile`)
        localStorage.setItem("openKYC", "1")
        window.location.reload()
    }

    const userNameCut = dataAccount?.dataAccount?.firstName.length > 10
        ? `${dataAccount.dataAccount.firstName.slice(0, 4)}...${dataAccount.dataAccount.firstName.slice(-4)}`
        : dataAccount?.dataAccount?.firstName;


    return (
        <>
            <div className={isMobile ? "section-grow grap-mobile" : "section-grow"}>
                <div className={isMobile ? "grow-wrap grap-mobile-p2p-detail" : "grow-wrap"}>
                    <div className="wrap-left">
                        {/* {isMobile ?
                            <>
                                {""}
                            </>
                            :
                            <>
                                <div className="porfolio-wrap">
                                    <div className="kyc-user">
                                        <div className="center-row">
                                            <div className="time-line-kyc">
                                                <div className={address ? "item active" : "item"}>
                                                    <div className="box-icon">
                                                        {address ? <img src="/images/user/line-active.png" alt="" /> : <img src="/images/user/line-no-active.png" alt="" />}
                                                    </div>
                                                    <div className="text">Connect Wallet</div>
                                                </div>
                                                <div className={dataAccount?.dataAccount?.isKYC ? "item active" : "item"}>
                                                    <div className="box-icon">
                                                        {dataAccount?.dataAccount?.isKYC ? <img src="/images/user/line-active.png" alt="" /> : <img src="/images/user/line-no-active.png" alt="" />}
                                                    </div>
                                                    <div className="text">Complete KYC</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        } */}
                        {dataDetail === undefined ?
                            <>
                                <Skeleton active paragraph={{ rows: 20 }} />
                            </>
                            :
                            <>
                                <div className="left-details">
                                    <div className="name-type-detail">
                                        <div className="year-common">
                                            {moment(endTimePool).utc().format('hh:mm A [UTC], MMM Do YYYY')}
                                        </div>
                                        <div className="apy-common">
                                            {apyBoosted}% APY
                                        </div>
                                    </div>
                                    <Carousel effect="fade" arrows={dataDetail?.thumbnails?.length > 1 ? true : false} autoplay infinite={true}>
                                        {dataDetail && dataDetail?.thumbnails?.map((item: any, index: any) => (
                                            <div className="box-img">
                                                <img src={item} alt="" />
                                            </div>
                                        ))}
                                    </Carousel>
                                    <div className="tabs-list res-tabs-p2p">
                                        <Tabs defaultActiveKey="1">
                                            <Tabs.TabPane tab={tabJust} key="1">
                                                <div className="content-details">
                                                    <div className="title">
                                                        Description
                                                    </div>
                                                    <div className="desc">
                                                        {dataDetail?.description}
                                                    </div>
                                                    <div className="list-detail-info">
                                                        <div className="row-info">
                                                            {dataDetail && dataDetail?.properties?.map((item: any, index: any) => (
                                                                <>
                                                                    <div className="item">
                                                                        <div className="row-left">
                                                                            <div className="box-img-info">
                                                                                <img src={item?.iconUrl} alt="" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="row-right">
                                                                            <div className="txt-top">
                                                                                {item?.name}
                                                                            </div>
                                                                            <div className="txt-bottom">
                                                                                {item?.value}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tabs.TabPane>
                                            <Tabs.TabPane tab={tabTimeline} key="2">
                                                <div className="content-details">
                                                    <div className="title">
                                                        P2P Process Timeline
                                                    </div>
                                                    {dataDetail && dataDetail?.timeLines?.map((item: any, index: any) => (
                                                        <>
                                                            <div className="per-rewait-item">
                                                                <div className="number-per">
                                                                    0{index + 1}
                                                                </div>
                                                                <div className="content-per">
                                                                    <div className="time-line-per">
                                                                        {item?.time}
                                                                    </div>
                                                                    <div className="text-per">
                                                                        {item?.title}
                                                                    </div>
                                                                    <ul className="list-per">
                                                                        {index === 0 ?
                                                                            <>
                                                                                {FilterArr && FilterArr?.map((itemS: any, indexS: any) => (
                                                                                    <>
                                                                                        <li>
                                                                                            {itemS}
                                                                                        </li>
                                                                                    </>
                                                                                ))}
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <li>
                                                                                    {item?.desc}
                                                                                </li>
                                                                            </>
                                                                        }
                                                                    </ul>
                                                                    {index === 0 ?
                                                                        <>
                                                                            <div className="note-per">
                                                                                Note: It takes you from 24 to 48 hours to have your submission approved.
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {""}
                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))}
                                                </div>
                                            </Tabs.TabPane>
                                            <Tabs.TabPane tab={tabTrending} key="3">
                                                <div className="content-details">
                                                    <div className="list-detail-info mar-top-20 res-page">
                                                        <div className="row-info">
                                                            <div className="item">
                                                                <div className="row-left">
                                                                    <div className="box-img-info">
                                                                        <img src="/images/lending/l-1.png" alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="row-right">
                                                                    <div className="txt-top">
                                                                        Total Loan Value
                                                                    </div>
                                                                    <div className="txt-bottom">
                                                                        ${new Intl.NumberFormat('en-US').format(numberLendbyUser)} USDC
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {dataDetail && dataDetail?.loansDetail?.map((item: any, index: any) => (
                                                                <>
                                                                    <div className="item">
                                                                        <div className="row-left">
                                                                            <div className="box-img-info">
                                                                                <img src={item?.iconUrl} alt="" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="row-right">
                                                                            <div className="txt-top">
                                                                                {item?.name}
                                                                            </div>
                                                                            <div className="txt-bottom">
                                                                                {item?.value}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tabs.TabPane>
                                            <Tabs.TabPane tab={tabFinancials} key="4">
                                                <div className="content-details">
                                                    <div className="list-detail-info">
                                                        <div className="row-info">
                                                            {dataDetail && dataDetail?.financialS?.map((item: any, index: any) => (
                                                                <>
                                                                    <div className="item">
                                                                        <div className="row-right">
                                                                            <div className="txt-top">
                                                                                {item?.name}
                                                                            </div>
                                                                            <div className="txt-bottom">
                                                                                {item?.value}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tabs.TabPane>
                                            <Tabs.TabPane tab={tabDocument} key="5">
                                                <div className="content-details">
                                                    {dataDetail && dataDetail?.documentDetail?.map((item: any, index: any) => (
                                                        <>
                                                            <div className="item-view">
                                                                <div className="title-view">
                                                                    {item?.name}
                                                                </div>
                                                                <div className="button-view">
                                                                    {item?.link === "" ?
                                                                        <>
                                                                            <Tooltip title="Coming soon">
                                                                                <button className="btn-view-page dis">
                                                                                    View
                                                                                </button>
                                                                            </Tooltip>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <button className="btn-view-page">
                                                                                <a href={item?.link} target="blank">
                                                                                    View
                                                                                </a>
                                                                            </button>
                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))}
                                                </div>
                                            </Tabs.TabPane>
                                            <Tabs.TabPane tab={tabMarket} key="6">
                                                <ChartMarket code={dataDetail?.code} />
                                            </Tabs.TabPane>
                                            <Tabs.TabPane tab={tabMap} key="7">
                                                <div className="map-api-box">
                                                    <iframe
                                                        src={`https://meeyatlas.com/map-pb/#z15/${latitude}}/${longitude}`}
                                                        style={{ width: "100%", height: "450px", border: "none" }}
                                                        allowFullScreen
                                                        loading="lazy"
                                                        title="Meey Atlas Map"
                                                    ></iframe>

                                                </div>
                                            </Tabs.TabPane>
                                        </Tabs>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <div className="wrap-right">
                        {dataDetail === null || !startTimePool || !endTimePool ?
                            <>
                                <div className="content-project">
                                    <Skeleton active paragraph={{ rows: 20 }} />
                                </div>
                            </>
                            :
                            <>
                                {isMobile ?
                                    <>
                                        <div className="breadcrum-line mobile-breadcrum">
                                            <div className="item-bread">
                                                <Link to="/apps/p2p-lending">
                                                    <img src="/images/laning.png" alt="" />{" "}
                                                    <span>P2P Lending</span>
                                                </Link>
                                            </div>
                                            <div className="item-bread">
                                                <div className="vector-r">
                                                    <img src="/images/vector-s.png" alt="" />
                                                </div>
                                            </div>
                                            <div className="item-bread active">
                                                <span>Property Details</span>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        {""}
                                    </>
                                }
                                {isMobile ?
                                    <>
                                        <div className="porfolio-wrap">
                                            <div className="kyc-user">
                                                <div className="center-row">
                                                    <div className="time-line-kyc">
                                                        <div className={address ? "item active" : "item"}>
                                                            <div className="box-icon">
                                                                {address ? <img src="/images/user/line-active.png" alt="" /> : <img src="/images/user/line-no-active.png" alt="" />}
                                                            </div>
                                                            <div className="text">Connect Wallet</div>
                                                        </div>
                                                        <div className={dataAccount?.dataAccount?.isKYC ? "item active" : "item"}>
                                                            <div className="box-icon">
                                                                {dataAccount?.dataAccount?.isKYC ? <img src="/images/user/line-active.png" alt="" /> : <img src="/images/user/line-no-active.png" alt="" />}
                                                            </div>
                                                            <div className="text">Complete KYC</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        {""}
                                    </>
                                }
                                <div className="content-project">
                                    <div className="location-page">
                                        {upcomingPool > timeNowStam ?
                                            <>
                                                <div className="status-detail">
                                                    Upcoming
                                                </div>
                                            </>
                                            :
                                            <>
                                                {upcomingPool < timeNowStam && timeNowStam < startTimePool ?
                                                    <>
                                                        <div className="status-detail">
                                                            In Progress
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        {timeNowStam > startTimePool && Number(numberLendbyUser) < Number(fundingCap) ?
                                                            <>
                                                                <div className="status-detail sold-out">
                                                                    Partially filled
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div className="status-detail sold-out">
                                                                    Sold Out
                                                                </div>
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </>
                                        }
                                        <div className="checkin-detail">
                                            <img src="/images/lending/loca.png" alt="" /> {dataDetail?.country}
                                        </div>
                                        <div className="user-detail">
                                            <img src="/images/logo-user.png" alt="" /> {totalLenderPool}
                                        </div>
                                    </div>
                                    <div className="name-detail">
                                        {dataDetail?.name}
                                    </div>
                                    {address ?
                                        <>
                                            <div className="owner-account">
                                                <div className="box-img">
                                                    <img src={dataAccount?.dataAccount?.avatar} alt="" />
                                                </div>
                                                <div className="name-account">
                                                    <div className="top">
                                                        User name
                                                    </div>
                                                    <div className="bot">
                                                        {userNameCut} {dataAccount?.dataAccount?.isKYC ? <img src="/images/lending/very.png" alt="" /> : <img src="/images/lending/very-false.png" alt="" />}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            {""}
                                        </>
                                    }
                                    <div className="funding-target">
                                        {upcomingPool > timeNowStam ?
                                            <>
                                                {upcomingPool &&
                                                    <div className="time-countdown">
                                                        <div className="time-l">
                                                            <img src="/images/lending/clock.png" alt="" /> Start Time:
                                                        </div>
                                                        <div className="time-r">
                                                            <Countdown
                                                                date={Number(upcomingPool)}
                                                                renderer={rendererCountDown2}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                            :
                                            <>
                                                {upcomingPool < timeNowStam && timeNowStam < startTimePool ?
                                                    <>
                                                        {startTimePool &&
                                                            <div className="time-countdown">
                                                                <div className="time-l">
                                                                    <img src="/images/lending/clock.png" alt="" /> Time Left:
                                                                </div>
                                                                <div className="time-r">
                                                                    <Countdown
                                                                        date={Number(startTimePool)}
                                                                        renderer={rendererCountDown2}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {Number(numberLendbyUser) < Number(fundingCap) ?
                                                            <>
                                                                <div className="warning-pool">
                                                                    <img src="/images/lending/warning-err.png" alt="" /> We are unable to complete this loan, your principal and interest will be refunded immediately.
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                {""}
                                                            </>
                                                        }
                                                        {endTimePool &&
                                                            <div className="time-countdown">
                                                                <div className="time-l">
                                                                    <img src="/images/lending/clock.png" alt="" /> Time Left:
                                                                </div>
                                                                <div className="time-r">
                                                                    Finished
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </>
                                        }
                                        <div className="apy-reward">
                                            <div className="item">
                                                <div className="title">
                                                    Basic APY:
                                                </div>
                                                <div className="number-apy-basic">
                                                    {apyBasic}%
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="title">
                                                    Boosted APY:
                                                </div>
                                                <div className="number-apy-boost">
                                                    {apyBoosted}%
                                                </div>
                                            </div>
                                        </div>
                                        <div className="funding-row">
                                            <div className="name">
                                                Funding Target: <span className="number"><span className="res-span">${new Intl.NumberFormat('en-US').format(numberLendbyUser)}</span> / ${new Intl.NumberFormat('en-US').format(fundingCap)}</span>
                                            </div>
                                            <div className="bottom-process">
                                                <Progress percent={percentProcess.toFixed(0)} status="active" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="investment-period">
                                        <div className="title">
                                            Loan ends at:
                                        </div>
                                        <div className="month">
                                            {moment(endTimePool).utc().format('hh:mm A [UTC], MMM Do YYYY')}
                                        </div>
                                    </div>
                                    {address ?
                                        <>
                                            {dataAccount && dataAccount?.dataAccount?.isKYC === false ?
                                                <>
                                                    <div className="group-button res-button">
                                                        <button className="btn-actions" onClick={gotoKYC}>
                                                            Start KYC
                                                        </button>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    {upcomingPool > timeNowStam ?
                                                        <>
                                                            <div className="group-button res-button">
                                                                <button className="btn-actions dis">
                                                                    Upcoming
                                                                </button>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            {upcomingPool < timeNowStam && timeNowStam < startTimePool ?
                                                                <>
                                                                    <div className="row-purchase">
                                                                        <div className="lends-page">
                                                                            <div className="top">
                                                                                <div className="left-lend">
                                                                                    <div className="text">
                                                                                        Lend
                                                                                    </div>
                                                                                </div>
                                                                                <div className="right-lend">
                                                                                    <div className="balance-wallet">
                                                                                        <img src="/images/lending/wallet.png" alt="" />
                                                                                        <div className="number-balance">
                                                                                            {new Intl.NumberFormat('en-US').format(amountUsdt)}
                                                                                        </div>
                                                                                        <img src="/images/lending/usd-coin.png" className="eth-child" alt="" />
                                                                                    </div>
                                                                                    <div className="button-percent">
                                                                                        {Number(numberLendbyUser) >= Number(fundingCap) ?
                                                                                            <>
                                                                                                <button className="btn-percent">
                                                                                                    Max
                                                                                                </button>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <button className="btn-percent" onClick={handleMaxPercent}>
                                                                                                    Max
                                                                                                </button>
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                    <div className="button-percent">
                                                                                        {Number(numberLendbyUser) >= Number(fundingCap) ?
                                                                                            <>
                                                                                                <button className="btn-percent" >
                                                                                                    50%
                                                                                                </button>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <button className="btn-percent" onClick={handle50Percent}>
                                                                                                    50%
                                                                                                </button>
                                                                                            </>
                                                                                        }

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="bottom">
                                                                                <div className="currentcy">
                                                                                    <img src="/images/lending/usd-coin.png" alt="" /> USDC
                                                                                </div>
                                                                                <div className="value-current">
                                                                                    <div className="value-top">
                                                                                        {Number(numberLendbyUser) >= Number(fundingCap) ?
                                                                                            <>
                                                                                                <input placeholder="0" ref={inputRef} disabled value={"0"} onChange={handleAmount} type="text" />
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <input placeholder="0" ref={inputRef} value={valueAmount > amountUsdt ? amountUsdt : valueAmountUI} onChange={handleAmount} type="text" />
                                                                                            </>
                                                                                        }

                                                                                    </div>
                                                                                    <div className="value-bot">
                                                                                        ~{Number(valueAmount) ? valueAmountUI : 0}$
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="group-button">
                                                                            {address ?
                                                                                <>
                                                                                    {valueAmount > 0 && amountUsdt > 0 ?
                                                                                        <>
                                                                                            {isApprove ?
                                                                                                <>
                                                                                                    <button className="btn-actions" onClick={showModal}>
                                                                                                        Lend
                                                                                                    </button>
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    <button className="btn-actions" onClick={showModalApprove}>
                                                                                                        Approve
                                                                                                    </button>
                                                                                                </>
                                                                                            }
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            {Number(numberLendbyUser) >= Number(fundingCap) ?
                                                                                                <>

                                                                                                    <button className="btn-actions dis">
                                                                                                        Max lends amount
                                                                                                    </button>
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    <button className="btn-actions">
                                                                                                        Enter an amount
                                                                                                    </button>
                                                                                                </>
                                                                                            }

                                                                                        </>
                                                                                    }
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <button className="btn-actions" onClick={handleConnect}>
                                                                                        Connect Wallet
                                                                                    </button>
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div className="group-button res-button">
                                                                        <button className="btn-actions dis">
                                                                            Expired
                                                                        </button>
                                                                    </div>
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            }
                                        </>
                                        :
                                        <>
                                            <div className="group-button res-button">
                                                <button className="btn-actions" onClick={handleConnect}>
                                                    Connect Wallet
                                                </button>
                                            </div>
                                        </>
                                    }
                                    {lenderTotalBalanceByUser && Number(lenderTotalBalanceByUser) > 0 ?
                                        <>
                                            <div className="wrap-summary">
                                                <div className="row-summary">
                                                    <div className="title" onClick={() => setActiveSummary(!activeSummary)}>
                                                        Summary
                                                        <span>
                                                            <img className={activeSummary ? "active" : ""} src="/images/lending/arrow-down.png" alt="" />
                                                        </span>
                                                    </div>
                                                    <div className={activeSummary ? "row-details-summary active" : "row-details-summary"}>
                                                        <ul className="list-sum">
                                                            <li>
                                                                <div className="txt-l">
                                                                    Loan ends at:
                                                                </div>
                                                                <div className="txt-r">
                                                                    {moment(endTimePool).utc().format('hh:mm A [UTC], MMM Do YYYY')}
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="txt-l">
                                                                    Boosted APY:
                                                                </div>
                                                                <div className="txt-r">
                                                                    {apyBoosted}%
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="txt-l">
                                                                    Total Amount:
                                                                </div>
                                                                <div className="txt-r">
                                                                    {new Intl.NumberFormat('en-US').format(lenderTotalBalanceByUser)} USDC
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="txt-l">
                                                                    Your Estimated Reward:
                                                                </div>
                                                                <div className="txt-r">
                                                                    ~{new Intl.NumberFormat('en-US').format(rewardByApi)} USDC
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="txt-l">
                                                                    Total Received:
                                                                </div>
                                                                <div className="txt-r">
                                                                    ~{new Intl.NumberFormat('en-US').format(Number(lenderTotalBalanceByUser) + Number(rewardByApi))} USDC
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            {""}
                                        </>
                                    }
                                    <div className="history-purchase">
                                        <div className="bottom-pur">
                                            {loadingArr ?
                                                <>
                                                    <Skeleton paragraph={{ rows: 5 }} />
                                                </>
                                                :
                                                <>
                                                    {historyByuser.length === 0 ?
                                                        <>
                                                            {""}
                                                        </>
                                                        :
                                                        <>
                                                            <div className="resige-history">
                                                                <div className="title-history">
                                                                    History
                                                                </div>
                                                                <div className="top-pur">
                                                                    <div className="item">
                                                                        Type
                                                                    </div>
                                                                    <div className="item">
                                                                        Amount
                                                                    </div>
                                                                    <div className="item">
                                                                        Date
                                                                    </div>
                                                                </div>
                                                                {historyByuser && historyByuser?.map((item: any, index: any) => (
                                                                    <>
                                                                        <div className="row-tsx">
                                                                            <div className="item">
                                                                                Lend
                                                                            </div>
                                                                            <div className="item">
                                                                                {item?.amount} USDC
                                                                            </div>
                                                                            <div className="item">
                                                                                {item?.claimDate}
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ))}
                                                            </div>
                                                        </>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                    {address ?
                                        <>
                                            {upcomingPool > timeNowStam ?
                                                <>
                                                    {""}
                                                </>
                                                :
                                                <>
                                                    {startTimePool < timeNowStam && timeNowStam < endTimePool ?
                                                        <>
                                                            {endTimePool && lenderTotalBalanceByUser && Number(lenderTotalBalanceByUser) > 0 ?
                                                                <>
                                                                    {Number(numberLendbyUser) < Number(fundingCap) ?
                                                                        <>
                                                                            <div className="age-claim-box">
                                                                                <div className="claimable-box">
                                                                                    <div className="left-box">
                                                                                        <div className="txt">
                                                                                            Principal and interest
                                                                                        </div>
                                                                                        {Number(lenderTotalBalanceByUser) > 0 ?
                                                                                            <>
                                                                                                {Number(numberLendbyUser) < Number(fundingCap) ?
                                                                                                    <>
                                                                                                        <div className="desc">
                                                                                                            <span>{new Intl.NumberFormat('en-US').format(Number(lenderTotalBalanceByUser))}</span> <img src="/images/lending/usd-coin.png" alt="" /> <span>USDC</span>
                                                                                                        </div>
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        <div className="desc">
                                                                                                            <span>{new Intl.NumberFormat('en-US').format(Number(amountTotaReward))}</span> <img src="/images/lending/usd-coin.png" alt="" /> <span>USDC</span>
                                                                                                        </div>
                                                                                                    </>
                                                                                                }
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                {""}
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                    <div className="right-box">
                                                                                        {isClaimed > 0 ?
                                                                                            <>
                                                                                                <button className="claim-able-box dis">
                                                                                                    Claimed
                                                                                                </button>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                {Number(lenderTotalBalanceByUser) > 0 ?
                                                                                                    <>
                                                                                                        <button className="claim-able-box" onClick={showModalClaim}>
                                                                                                            Claim
                                                                                                        </button>
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        <button className="claim-able-box dis">
                                                                                                            Claim
                                                                                                        </button>
                                                                                                    </>
                                                                                                }
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div className="age-claim-box">
                                                                                <div className="claim-detail-box">
                                                                                    <div className="text">
                                                                                        Time To Claim
                                                                                    </div>
                                                                                    <div className="time-box">
                                                                                        <Countdown
                                                                                            date={Number(endTimePool)}
                                                                                            renderer={rendererCountDown2}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    {isClaimed > 0 ?
                                                                        <>
                                                                            <div className="age-claim-box">
                                                                                <div className="claimable-box">
                                                                                    <div className="left-box">
                                                                                        <div className="txt">
                                                                                            Principal and interest
                                                                                        </div>
                                                                                        {Number(lenderTotalBalanceByUser) > 0 ?
                                                                                            <>
                                                                                                {Number(numberLendbyUser) < Number(fundingCap) ?
                                                                                                    <>
                                                                                                        <div className="desc">
                                                                                                            <span>{new Intl.NumberFormat('en-US').format(Number(lenderTotalBalanceByUser))}</span> <img src="/images/lending/usd-coin.png" alt="" /> <span>USDC</span>
                                                                                                        </div>
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        <div className="desc">
                                                                                                            <span>{new Intl.NumberFormat('en-US').format(Number(amountTotaReward))}</span> <img src="/images/lending/usd-coin.png" alt="" /> <span>USDC</span>
                                                                                                        </div>
                                                                                                    </>
                                                                                                }
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                {""}
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                    <div className="right-box">
                                                                                        {isClaimed > 0 ?
                                                                                            <>
                                                                                                <button className="claim-able-box dis">
                                                                                                    Claimed
                                                                                                </button>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                {Number(lenderTotalBalanceByUser) > 0 ?
                                                                                                    <>
                                                                                                        <button className="claim-able-box" onClick={showModalClaim}>
                                                                                                            Claim
                                                                                                        </button>
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        <button className="claim-able-box dis">
                                                                                                            Claim
                                                                                                        </button>
                                                                                                    </>
                                                                                                }
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {""}
                                                                        </>
                                                                    }

                                                                </>
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {endTimePool < timeNowStam ?
                                                                <>
                                                                    <div className="age-claim-box">
                                                                        <div className="claimable-box">
                                                                            <div className="left-box">
                                                                                <div className="txt">
                                                                                    Principal and interest
                                                                                </div>
                                                                                {Number(lenderTotalBalanceByUser) > 0 ?
                                                                                    <>
                                                                                        {Number(numberLendbyUser) < Number(fundingCap) ?
                                                                                            <>
                                                                                                <div className="desc">
                                                                                                    <span>{new Intl.NumberFormat('en-US').format(Number(lenderTotalBalanceByUser))}</span> <img src="/images/lending/usd-coin.png" alt="" /> <span>USDC</span>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <div className="desc">
                                                                                                    <span>{new Intl.NumberFormat('en-US').format(Number(amountTotaReward))}</span> <img src="/images/lending/usd-coin.png" alt="" /> <span>USDC</span>
                                                                                                </div>
                                                                                            </>
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        {""}
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                            <div className="right-box">
                                                                                {isClaimed > 0 ?
                                                                                    <>
                                                                                        <button className="claim-able-box dis">
                                                                                            Claimed
                                                                                        </button>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        {Number(lenderTotalBalanceByUser) > 0 ?
                                                                                            <>
                                                                                                <button className="claim-able-box" onClick={showModalClaim}>
                                                                                                    Claim
                                                                                                </button>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <button className="claim-able-box dis">
                                                                                                    Claim
                                                                                                </button>
                                                                                            </>
                                                                                        }
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    {""}
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            }
                                        </>
                                        :
                                        <>
                                            {""}
                                        </>
                                    }
                                </div>
                            </>
                        }
                    </div>
                </div>
                <Modal className="modal-purchase" maskClosable={false} title="Confirm Your Lend" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    <div className="confirm-box">
                        {hashLend !== "" ?
                            <>
                                <div className="box-img">
                                    <img src="/images/lending/hash-success.png" alt="" />
                                </div>
                                <div className="title res-title">
                                    Congrats! Your lend is successful!
                                </div>
                            </>
                            :
                            <>
                                <div className="box-img">
                                    <img src="/images/lending/pur.png" alt="" />
                                </div>
                                <div className="title">
                                    Confirm your lend
                                </div>
                            </>
                        }
                        <div className="guide-confirm-project">
                            <div className="name-pr">
                                {dataDetail?.name}
                            </div>
                            <div className="item">
                                <div className="txt-l">
                                    Loan ends at:
                                </div>
                                <div className="txt-r">
                                    {moment(endTimePool).utc().format('hh:mm A [UTC], MMM Do YYYY')}
                                </div>
                            </div>
                            <div className="item">
                                <div className="txt-l">
                                    Basic APY:
                                </div>
                                <div className="txt-r">
                                    {checkWl && checkWl ? apyBoosted : apyBasic}%
                                </div>
                            </div>
                            <div className="item">
                                <div className="txt-l">
                                    Your lend:
                                </div>
                                <div className="txt-r">
                                    {new Intl.NumberFormat('en-US').format(valueAmount)} USDC
                                </div>
                            </div>
                            {Number(estimatedReward) > 0 ?
                                <>
                                    <div className="item">
                                        <div className="txt-l">
                                            Your Estimated Reward:
                                        </div>
                                        <div className="txt-r">
                                            {new Intl.NumberFormat('en-US').format(rewardByApi)} USDC
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    {""}
                                </>
                            }
                            <div className="item">
                                <div className="txt-l">
                                    Total Received:
                                </div>
                                <div className="txt-r">
                                    {new Intl.NumberFormat('en-US').format(Number(valueAmount) + Number(rewardByApi))} USDC
                                </div>
                            </div>
                        </div>
                        {hashLend !== "" ?
                            <>
                                <div className="box-hash-modal">
                                    <div className="tsx-l">
                                        Transaction Hash:
                                    </div>
                                    <div className="tsx-r">
                                        <a className="mid-content3" target="_blank" rel="noreferrer" href={`${BASE_SCAN}/tx/${hashLend}`}>{hashLend?.slice(0, 4)}...{hashLend?.slice(-4)}</a>
                                        <CopyToClipboard
                                            text={`${hashLend}`}
                                            onCopy={() => {
                                                toast.success('Copied success', {
                                                    position: "top-right",
                                                    autoClose: 2000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined,
                                                    theme: "light",
                                                });
                                            }}
                                        >
                                            <img src="/images/user/copy-row.png" alt="" />
                                        </CopyToClipboard>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                {""}
                            </>
                        }
                        <div className="group-button">
                            {hashLend !== "" ?
                                <>
                                    <button className="btn-actions" onClick={handleCancel}>
                                        Back to lending
                                    </button>
                                </>
                                :
                                <>
                                    {loadingPurchase ?
                                        <>
                                            <button className="btn-actions dis">
                                                Confirm Lend <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                                            </button>
                                        </>
                                        :
                                        <>
                                            <button className="btn-actions" onClick={handlePurchase}>
                                                Confirm Lend
                                            </button>
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </Modal>
                <Modal className="modal-purchase" maskClosable={false} title="Confirm Approve" open={isModalOpenApprove} onOk={handleOkApprove} onCancel={handleCancelApprove}>
                    <div className="confirm-box">
                        <div className="box-img">
                            <img src="/images/lending/pur.png" alt="" />
                        </div>
                        <div className="title">
                            Confirm your approve
                        </div>
                        <div className="group-button">
                            {loadingApprove ?
                                <>
                                    <button className="btn-actions dis">
                                        Confirm <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                                    </button>
                                </>
                                :
                                <>
                                    <button className="btn-actions" onClick={handleApprove}>
                                        Confirm
                                    </button>
                                </>
                            }
                        </div>
                    </div>
                </Modal>
                <Modal className="modal-purchase" maskClosable={false} title="Principal and interest" open={isModalOpenClaim} onOk={handleOkClaim} onCancel={handleCancelClaim}>
                    <div className="confirm-box">
                        <div className="box-img">
                            <img src="/images/lending/pur.png" alt="" />
                        </div>
                        <div className="guide-confirm-project">
                            <div className="item">
                                <div className="txt-l">
                                    Total Received:
                                </div>
                                <div className="txt-r">
                                    {new Intl.NumberFormat('en-US').format(Number(lenderTotalBalanceByUser) + Number(rewardByApi))} USDC
                                </div>
                            </div>
                        </div>
                        <div className="group-button">
                            {loadingClaim ?
                                <>
                                    <button className="btn-actions dis">
                                        Confirm <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                                    </button>
                                </>
                                :
                                <>
                                    <button className="btn-actions" onClick={handleWithdraw}>
                                        Confirm
                                    </button>
                                </>
                            }
                        </div>
                    </div>
                </Modal>
                <ModalDisclaimer setIsModalOpenDis={setIsModalOpenDis} pathTitle={pathTitle} pathParam={pathParam} isModalOpenDis={isModalOpenDis} handleOkDis={handleOkDis} handleCancelDis={handleCancelDis} />
                <Faqs />
            </div>
        </>
    )
}
export default DetailsP2pLending