import React, { useCallback, useEffect, useRef, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useAccount } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { Modal } from "antd";
import { AppHome } from "../../../../api/appHome";
import { Noti } from "../../../../components/Noti/toast";
import { ethers } from 'ethers';
import { isMobile } from "react-device-detect";
import { useSignMessage } from 'wagmi';

declare const window: Window & typeof globalThis & { ethereum: any };

const VerifyEmail = () => {
    const { address } = useAccount();
    const { open } = useWeb3Modal();
    const [dataUser, setDataUser]: any = useState()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [valueEmail, setValueEmail]: any = useState();
    const [otpNumber, serOtpNumber]: any = useState()
    const [stepOtp, setStepOtp] = useState(1)
    const [isVerifyed, setIsVerifyed]: any = useState()
    const inputRef = useRef<HTMLInputElement>(null);
    const [disbleLoading, setDisbleLoading] = useState(false)
    const { signMessageAsync } = useSignMessage();

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleConnect = () => {
        open();
    };

    const onChangeEmail = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setValueEmail(e.target.value);
    }, []);

    const onChangeOtp = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value;
        const formattedInput = input.replace(/\s+/g, "");
        serOtpNumber(formattedInput);
    }, []);

    const getEmailuser = async () => {
        try {
            let res = await AppHome.getVerifyEmail(
                address
            );
            if (res.data.success === true) {
                setDataUser(res.data.data);
            }
        } catch (error: any) {
            console.log("Error: ", error);
            setIsVerifyed(error?.message)
        }
    };

    useEffect(() => {
        if (address) {
            getEmailuser()
        }
    }, [address]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [otpNumber]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [valueEmail]);

    const handleNextStepTow = async () => {
        setDisbleLoading(true)
        let params = {
            email: valueEmail,
            walletAddress: address
        }
        try {
            let res = await AppHome.postOtpUser(
                params
            );
            if (res.data.success === true) {
                if (res.data.message === "Email verified.") {
                    Noti('error', res.data.message, 3000)
                    setIsModalOpen(false);
                    setDisbleLoading(false)
                } else {
                    Noti('success', res.data.message, 3000)
                    setStepOtp(2)
                    setDisbleLoading(false)
                }
            }
        } catch (error: any) {
            Noti('error', error?.message, 3000)
            setIsModalOpen(false);
            setDisbleLoading(false)
        }
    }

    const handleNextStepThree = async () => {
        let params = {
            email: valueEmail,
            otp: otpNumber
        }
        try {
            let res = await AppHome.postCheckOtp(
                params
            );
            if (res.data.success === true) {
                Noti('success', res.data.message, 3000)
                setStepOtp(3)
            }
        } catch (error: any) {
            Noti('error', error?.message, 3000)
        }
    }

    const checkDoneVerify = async () => {
        try {
            let res = await AppHome.getVerifyEmail(
                address
            );
            if (res.data.success === true) {
                setDataUser(res.data.data);
                setIsModalOpen(false);
                window.location.reload()
            }
        } catch (error: any) {
            console.log("Error: ", error);
            setIsVerifyed(error?.message)
        }
    }

    const signMessage = async () => {
        try {
            if (!window.ethereum) {
                Noti('error', "Please install Metamask", 3000)
                return;
            }
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            await provider.send('eth_requestAccounts', []);
            const signer = provider.getSigner();
            const message = "Welcom to Meyfi";
            const signature = await signer.signMessage(message);

            if (signature) {
                try {
                    let params = {
                        email: dataUser?.email,
                        walletAddress: address,
                        sign: signature
                    }
                    let res = await AppHome.postSign(
                        params
                    );
                    if (res.data.success === true) {
                        getEmailuser()
                        setIsModalOpen(false);
                        Noti('success', "Comfirm wallet success", 3000)
                    }
                } catch (error: any) {
                    console.log("Error: ", error);
                }
            }
        } catch (error: any) {
            Noti('error', error?.message, 3000)
        }
    };


    const ComponentFirst = () => {
        return (
            <>
                <div className="confirm-box">
                    <div className="modal-verify-box">
                        <ul className="list-send-otp">
                            <li>
                                <img src="/images/done.png" alt="" /> Verify email
                            </li>
                            <li>
                                <img src="/images/line-modal.png" className="line-modal" alt="" />
                            </li>
                            <li>
                                <img src="/images/done.png" alt="" /> Send OTP
                            </li>
                        </ul>
                        <div className="box-img-modal">
                            <img src="/images/modal-email.png" alt="" />
                        </div>
                        <div className="title-email">
                            Verify Your Email Address in the Investor Mail List
                        </div>
                        <div className="input-modal">
                            <input type="text" ref={inputRef} value={valueEmail} onChange={onChangeEmail} placeholder="abc@gmail.com" />
                            {valueEmail ?
                                <>
                                    <img src="/images/check-em.png" alt="" />
                                </>
                                :
                                <>
                                    {""}
                                </>
                            }
                        </div>
                        <div className="button-click">
                            {valueEmail ?
                                <>
                                    {disbleLoading ?
                                        <>
                                            <button className="btn-click-email">
                                                Click to check verify
                                            </button>
                                        </>
                                        :
                                        <>
                                            <button className="btn-click-email" onClick={handleNextStepTow}>
                                                Click to check verify
                                            </button>
                                        </>
                                    }
                                </>
                                :
                                <>
                                    <button className="btn-click-email dis">
                                        Click to check verify
                                    </button>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const ComponentTwo = () => {
        return (
            <>
                <div className="confirm-box">
                    <div className="modal-verify-box">
                        <ul className="list-send-otp">
                            <li>
                                <img src="/images/doned.png" alt="" /> <span className="color-green">Verify email</span>
                            </li>
                            <li>
                                <img src="/images/line-modal.png" className="line-modal" alt="" />
                            </li>
                            <li>
                                <img src="/images/done.png" alt="" /> Send OTP
                            </li>
                        </ul>
                        <div className="box-img-modal">
                            <img src="/images/modal-email.png" alt="" />
                        </div>
                        <div className="title-email">
                            Get a verification code
                        </div>
                        <div className="desc-email">
                            We have sent you a verification code to: <span>{valueEmail}</span>
                        </div>
                        <div className="input-modal">
                            <input type="text" value={otpNumber} ref={inputRef} onChange={onChangeOtp} className="f-control" placeholder="123456" />
                        </div>
                        <div className="button-click">
                            <button className="btn-click-email" onClick={handleNextStepThree}>
                                Click to check verify
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const ComponentThree = () => {
        return (
            <>
                <div className="confirm-box">
                    <div className="modal-verify-box">
                        <ul className="list-send-otp">
                            <li>
                                <img src="/images/doned.png" alt="" /> <span className="color-green">Verify email</span>
                            </li>
                            <li>
                                <img src="/images/line-modal.png" className="line-modal" alt="" />
                            </li>
                            <li>
                                <img src="/images/doned.png" alt="" /> <span className="color-green">Send OTP</span>
                            </li>
                        </ul>
                        <div className="box-img-modal">
                            <img src="/images/otp-s.png" alt="" />
                        </div>
                        <div className="title-email">
                            Email Verified
                        </div>
                        <div className="desc-email">
                            Congratulations! You have successfully verified your email address.
                        </div>
                        <div className="button-click">
                            <button className="btn-click-email" onClick={checkDoneVerify}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const returnComponent = (stepOtp: any) => {
        switch (stepOtp) {
            case 1:
                return <ComponentFirst />
            case 2:
                return <ComponentTwo />
            case 3:
                return <ComponentThree />
            default:
                return 1
        }
    }

    return (
        <>
            <div className="section-grow mar-b-100-p2p">
                <div className="wrap-verify-email">
                    <Link to="/apps/portfolio">
                        <div className="go-back">
                            <img src="/images/user/go-back.png" alt="" /> Go back
                        </div>
                    </Link>
                    <div className="title">
                        Email Verify
                    </div>
                    <div className="desc">
                        Complete the 3-step process to unlock token rewards
                    </div>
                    <div className="group-verify-box">
                        <div className="box-img">
                            {isMobile ? "" : <img src="/images/line-verify.png" alt="" />}
                        </div>
                        <div className="daily-group-verify">
                            <div className="columns">
                                <div className="colum w-4">
                                    <div className="port-wallet">
                                        <div className="reder-box">
                                            <div className="left-render">
                                                <div className="top">
                                                    {address ? <img src="/images/v-1.png" alt="" /> : <img src="/images/v-1-non.png" alt="" />} Connect Your Wallet
                                                </div>
                                                {address ?
                                                    <>
                                                        <div className="bottom">
                                                            {address?.slice(0, 4)}...{address?.slice(-4)}
                                                            <CopyToClipboard
                                                                text={`${address}`}
                                                                onCopy={() => {
                                                                    toast.success('Copied success', {
                                                                        position: "top-right",
                                                                        autoClose: 2000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true,
                                                                        progress: undefined,
                                                                        theme: "light",

                                                                    });
                                                                }}
                                                            >
                                                                <img src="/images/s-copy.png" alt="" />
                                                            </CopyToClipboard>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        {""}
                                                    </>
                                                }
                                            </div>
                                            <div className="right-render">
                                                {address ?
                                                    <>
                                                        <button className="btn-checked">
                                                            <img src="/images/check-ss.png" alt="" /> Connected
                                                        </button>
                                                    </>
                                                    :
                                                    <>
                                                        <button className="btn-checked non-check" onClick={handleConnect}>
                                                            <img src="/images/check-ss.png" alt="" /> Connect
                                                        </button>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        {address ?
                                            <>
                                                <div className="mey-balance-wallet">
                                                    <div className="top-mey">
                                                        <img src="/images/mey-mey.png" alt="" /> $MEY Allocation: 
                                                        <span>
                                                            {dataUser?.isVerify === 1 && dataUser?.isSign === 1 && address ?
                                                                <>
                                                                    {new Intl.NumberFormat('en-US').format(dataUser?.allocation)}
                                                                </>
                                                                :
                                                                <>
                                                                    {"NA"}
                                                                </>
                                                            }
                                                        </span>
                                                    </div>
                                                    <div className="bottom-mey">
                                                        <img src="/images/war.png" alt="" /> This will be vested according to vesting schedule
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                {""}
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="colum w-4">
                                    <div className="reder-box">
                                        <div className="left-render">
                                            <div className="top">
                                                {isVerifyed === "User not found." || dataUser?.isVerify === 0 || dataUser?.isVerify === undefined ? <img src="/images/v-1-non.png" alt="" /> : <img src="/images/v-1.png" alt="" />} Verify Your Email
                                            </div>
                                            <div className="bottom">
                                                {isVerifyed === "User not found." || dataUser?.isVerify === 0 || dataUser?.isVerify === undefined ? "---" : dataUser?.email}
                                            </div>
                                        </div>
                                        <div className="right-render">
                                            {isVerifyed === "User not found." || dataUser?.isVerify === 0 || dataUser?.isVerify === undefined ?
                                                <>
                                                    <button className="btn-checked non-check" onClick={showModal}>
                                                        <img src="/images/check-ss.png" alt="" /> Verify
                                                    </button>
                                                </>
                                                :
                                                <>
                                                    <button className="btn-checked">
                                                        <img src="/images/check-ss.png" alt="" /> Verified
                                                    </button>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-4">
                                    <div className="reder-box">
                                        <div className="left-render">
                                            <div className="top">
                                                {isVerifyed === "User not found." ?
                                                    <>
                                                        <img src="/images/v-1-non.png" alt="" /> Confirm Wallet Address
                                                    </>
                                                    :
                                                    <>
                                                        {dataUser?.isVerify === 1 && dataUser?.isSign === 0 && address ?
                                                            <>
                                                                <img src="/images/v-1-non.png" alt="" /> Confirm Wallet Address
                                                            </>
                                                            :
                                                            <>
                                                                <img src="/images/v-1.png" alt="" /> Confirm Wallet Address
                                                            </>
                                                        }
                                                    </>
                                                }

                                            </div>
                                        </div>
                                        <div className="right-render">
                                            {isVerifyed === "User not found." ?
                                                <>
                                                    <button className="btn-checked non-check">
                                                        <img src="/images/check-ss.png" alt="" /> Confirm
                                                    </button>
                                                </>
                                                :
                                                <>
                                                    {dataUser?.isVerify === 1 && dataUser?.isSign === 0 && address ?
                                                        <>
                                                            <button className="btn-checked non-check" onClick={signMessage}>
                                                                <img src="/images/check-ss.png" alt="" /> Confirm
                                                            </button>
                                                        </>
                                                        :
                                                        <>
                                                            <button className="btn-checked">
                                                                <img src="/images/check-ss.png" alt="" /> Verified
                                                            </button>
                                                        </>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-purchase" maskClosable={false} title="Verify Your Email" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                {returnComponent(stepOtp)}
            </Modal>
        </>
    )
}
export default VerifyEmail