import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { CustomTab } from "../../../components/ui/CustomTab";
import { PTOFilterTypes } from "../../../types/pto";
import { COUNTRY } from "../../../constants/pto";
import {
    Button,
    Popover,
    Select,
    Slider,
    Table,
    Image,
    Pagination,
} from "antd";
import IconChevronDown from "../../../assets/icons/svg/icon-chevron-down.svg";
import { CustomTable } from "../../../components/ui/CustomTable";
import ComingSoon from "../../../components/comming-soon";
import { Link } from "react-router-dom";
import { AppHome } from "../../../api/appHome";
import "./style.scss"
import { isMobile } from "react-device-detect";

const DEFAULT_DATA_FILTER = {
    minValue: 0,
    maxValue: 1000000,
};

const MARKETPLACE_FILTER_OPTIONS = [
    {
        label: "All",
        value: "",
    }
];

const TYPE_FILTER = [
    { value: '1', label: 'Condo' },
    { value: '2', label: 'LandedHouse' },
    { value: '3', label: 'NewConstruction' },
    { value: '4', label: 'Hotels' },
    { value: '5', label: 'Resorts' },
    { value: '6', label: 'ShortTermRental' },
    { value: '7', label: 'Retail' },
    { value: '8', label: 'ServicedOffice' },
    { value: '9', label: 'Commercial' },
    { value: '10', label: 'PrivateREITs' },

]

const Marketplace = () => {
    const [activeTab, setActiveTab] = useState<string>("");
    const [dataMarket, setDataMarket]: any = useState([])
    const [filterData, setFilterData] =
        useState<PTOFilterTypes>(DEFAULT_DATA_FILTER);
    const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);
    const [minValue, setMinValue]: any = useState(0)
    const [maxValue, setMaxValue]: any = useState(1000000)
    const [type, setType]: any = useState("")
    const [country, setCountry]: any = useState("")
    const [status, setStatus]: any = useState("")
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize]: any = useState(10)
    const [totalItem, setTotalItem]: any = useState(0)
    const [isFilter, setisFilter] = useState(false);

    const getDataMarket = async () => {
        const renderKey = (activeTab: any) => {
            switch (activeTab) {
                case "":
                    return 0;
                case "gainers":
                    return 1;
                case "upcoming":
                    return 2;
                case "completed":
                    return 3;
            }
        }
        try {
            let res = await AppHome.getDataMarket(pageSize, 0, renderKey(activeTab), minValue, maxValue, type, country, status);
            if (res.data.success === true) {
                setDataMarket(res.data.data);
                setTotalItem(res.data.totalCount)
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    useEffect(() => {
        getDataMarket()
    }, [activeTab]);

    const handleChangeTabs = (key: any) => {
        setActiveTab(key)
    }

    const onChange = async (page: any) => {
        setCurrent(page);
        const renderKey = (activeTab: any) => {
            switch (activeTab) {
                case "":
                    return 0;
                case "gainers":
                    return 1;
                case "upcoming":
                    return 2;
                case "completed":
                    return 3;
            }
        }
        try {
            let res = await AppHome.getDataMarket(pageSize, Number(page) - 1, renderKey(activeTab), minValue, maxValue, type, country, status);
            if (res.data.success === true) {
                setDataMarket(res.data.data);
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    const FilterMarketplace = ({
        data,
        setData,
        setIsOpenFilter,
    }: {
        data: PTOFilterTypes;
        setData: Dispatch<SetStateAction<PTOFilterTypes>>;
        setIsOpenFilter: Dispatch<SetStateAction<boolean>>;
    }) => {
        const handleChangeSlider = (value: any) => {
            setMinValue(value?.[0])
            setMaxValue(value?.[1])
        };

        const checkIsFilter = () => {
            if (minValue === 0 && maxValue === 1000000
                && type === "" && status === ""
                && country === "") {
                setisFilter(false)
            } else {
                setisFilter(true)
            }
        }
        useEffect(() => {
            checkIsFilter()
        }, [data])

        const handleResetForm = () => {
            setData(DEFAULT_DATA_FILTER);
            setMinValue(0)
            setMaxValue(1000000)
            setType("")
            setCountry('')
            setStatus("")
        };

        const handleSaveForm = async () => {
            setIsOpenFilter(false);
            const renderKey = (activeTab: any) => {
                switch (activeTab) {
                    case "":
                        return 0;
                    case "gainers":
                        return 1;
                    case "upcoming":
                        return 2;
                    case "completed":
                        return 3;
                }
            }
            try {
                let res = await AppHome.getDataMarket(pageSize, Number(current) - 1, renderKey(activeTab), minValue, maxValue, type, country, status);
                if (res.data.success === true) {
                    setDataMarket(res.data.data);
                    setActiveTab(status)
                }
            } catch (error) {
                console.log("Error: ", error);
            }
        };


        function handleSelectFilterStatus(e: any) {
            setStatus(e)
        }
        function handleSelectFilterType(e: any) {
            setType(e)
            console.log('e', e)
        }
        function handleSelectFilterCountry(e: any) {
            setCountry(e)
        }

        console.log('TYPE_FILTER', TYPE_FILTER)

        return (
            <div className="w-[333px] font-radioCanada">
                <div className="p-3 flex gap-x-2.5 items-center border-b border-b-[#3B3F45]">
                    <img
                        width={16}
                        height={16}
                        src="/images/marketplace/filter.png"
                        alt=""
                    />
                    <div className="text-white">Filter By</div>
                </div>
                <div className="p-3">
                    <div className="flex flex-col gap-y-3">
                        <div className="flex flex-col gap-y-2">
                            <div className="text-white">Country</div>
                            <Select
                                suffixIcon={<img alt="" src={IconChevronDown} />}
                                placeholder="Select"
                                className="h-10 rounded-[8px]"
                                options={COUNTRY}
                                showSearch
                                value={country}
                                onSelect={(e) => handleSelectFilterCountry(e)}
                            />
                        </div>
                        <div className="flex flex-col gap-y-2">
                            <div className="text-white">Type</div>
                            <Select
                                suffixIcon={<img alt="" src={IconChevronDown} />}
                                placeholder="Select"
                                className="h-10 rounded-[8px]"
                                options={TYPE_FILTER}
                                value={type}
                                onSelect={(e) => handleSelectFilterType(e)}

                            />
                        </div>
                        <div className="flex flex-col gap-y-2">
                            <div className="text-white">Property Token Price Range</div>
                            <div className="p-2 bg-[#3b3f4566] flex flex-col gap-y-2.5 rounded-[8px]">
                                <div className="flex justify-between items-center text-[14px]">
                                    <div className="text-white/60">Price Range</div>
                                    <div className="text-white">
                                        ${data?.minValue} - ${data?.maxValue}
                                    </div>
                                </div>
                                <Slider
                                    onChange={handleChangeSlider}
                                    value={[minValue || 0, maxValue || 1000000]}
                                    className="!mt-0"
                                    range={true}
                                    min={0}
                                    max={1000000}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 flex justify-end items-center gap-x-3">
                        <Button
                            onClick={handleResetForm}
                            size="small"
                            className="border border-primary !bg-transparent h-[31px] w-[91px] text-white font-radioCanada"
                        >
                            Reset
                        </Button>
                        <Button
                            onClick={handleSaveForm}
                            type="primary"
                            size="small"
                            className="h-[31px] w-[91px] text-white font-radioCanada"
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    const columns = [
        {
            title: (
                <div className="text-white/60 font-radioCanada text-[14px]">#</div>
            ),
            render: (_: any, __: any, index: number) => (
                <div className="font-radioCanada">{index + 1}</div>
            ),
            width: 40,
        },
        {
            title: (
                <div className="text-white/60 font-radioCanada text-[14px]">
                    Property Name
                </div>
            ),
            width: 240,
            render: (_: any, item: any, index: number) => (
                <div className="font-radioCanada flex gap-x-2.5 items-center text-[16px]">
                    <img src={item?.thumbnail} className="img-thumb" alt="" />
                    <Link
                        to={`/apps/marketplace/detail?id=${item?.code}`}
                        className="hover:text-primary transition"
                    >
                        {item?.name}
                    </Link>
                </div>
            ),
        },
        {
            title: (
                <div className="text-white/60 font-radioCanada text-[14px]">
                    Property Token Value
                </div>
            ),
            width: 140,
            align: "end",
            render: (_: any, item: any) => (
                <div className="font-radioCanada">${new Intl.NumberFormat('en-US').format(item?.tokenIssued * item?.pricePerTokenIssued)}</div>
            ),
        },
        {
            title: (
                <div className="text-white/60 font-radioCanada text-[14px]">
                    Tokens Issued
                </div>
            ),
            width: 140,
            align: "end",
            render: (_: any, item: any) => (
                <div className="font-radioCanada">{item?.tokenIssued_ui}</div>
            ),
        },
        {
            title: (
                <div className="text-white/60 font-radioCanada text-[14px]">
                    Property Value
                </div>
            ),
            width: 100,
            align: "end",
            render: (_: any, item: any) => (
                <div className="font-radioCanada">{item.propertyValue}</div>
            ),
        },
        {
            title: (
                <div className="text-white/60 font-radioCanada text-[14px]">
                    Holders
                </div>
            ),
            width: 100,
            align: "end",
            render: (_: any, item: any) => (
                <div className="font-radioCanada">{item.holder}</div>
            ),
        },
        {
            title: (
                <div className="text-white/60 font-radioCanada text-[14px]">
                    Gross Annual Rental Yield
                </div>
            ),
            width: 168,
            align: "end",
            render: (_: any, item: any) => (
                <div className="font-radioCanada">{item.annualGrossRentalYield}</div>
            ),
        },
        {
            title: (
                <div className="text-white/60 font-radioCanada text-[14px]">
                    Annual Asset Appreciation
                </div>
            ),
            width: 195,
            align: "end",
            render: (_: any, item: any) => (
                <div className="font-radioCanada">{item.annualAssetAppreciation}</div>
            ),
        },
    ];

    const Item = ({ item, index }: any) => {
        const userNameCut = item?.name.length > 15
            ? `${item?.name.slice(0, 4)}...${item?.name.slice(-4)}`
            : item?.name;

        return (
            <>
                <Link to={`/apps/marketplace/detail?id=${item?.code}`}>
                    <div className="content-item-mobile-mk">
                        <div className="item">
                            <div className="txt-l">
                                STT
                            </div>
                            <div className="txt-r">
                                {index + 1}
                            </div>
                        </div>
                        <div className="item">
                            <div className="txt-l">
                                Property Name
                            </div>
                            <div className="txt-r name">
                                {userNameCut}
                            </div>
                        </div>
                        <div className="item">
                            <div className="txt-l">
                                Property Token Value
                            </div>
                            <div className="txt-r price">
                                ${new Intl.NumberFormat('en-US').format(item?.tokenIssued * item?.pricePerTokenIssued)}
                            </div>
                        </div>
                        <div className="item">
                            <div className="txt-l">
                                Tokens Issued
                            </div>
                            <div className="txt-r price">
                                {item?.tokenIssued_ui}
                            </div>
                        </div>
                        <div className="item">
                            <div className="txt-l">
                                Property Value
                            </div>
                            <div className="txt-r price">
                                {item.propertyValue}
                            </div>
                        </div>
                        <div className="item">
                            <div className="txt-l">
                                Holders
                            </div>
                            <div className="txt-r">
                                {item.holder}
                            </div>
                        </div>
                        <div className="item">
                            <div className="txt-l">
                                Gross Annual Rental Yield
                            </div>
                            <div className="txt-r">
                                {item.annualGrossRentalYield}
                            </div>
                        </div>
                        <div className="item">
                            <div className="txt-l">
                                Annual Asset Appreciation
                            </div>
                            <div className="txt-r">
                                {item.annualAssetAppreciation}
                            </div>
                        </div>
                    </div>
                </Link>
            </>
        )
    }

    return (
        <div className="py-4 px-6 font-radioCanada">
            <div className="flex items-center table-custome-market justify-end">
                <Popover
                    trigger="click"
                    placement="bottom"
                    open={isOpenFilter}
                    onOpenChange={setIsOpenFilter}
                    content={
                        <FilterMarketplace
                            setIsOpenFilter={setIsOpenFilter}
                            data={filterData}
                            setData={setFilterData}
                        />
                    }
                >
                    <div className={`${isFilter ? (
                        "flex items-center rounded-[8px] px-4 py-1.5 bg-[#3B3F45] border border-[#3B3F45] text-sm text-white font-medium gap-x-2.5 hover:cursor-pointer transition hover:border-primary hover:bg-primary/20 text-[14px] filter-active"
                    ) : (
                        "flex items-center rounded-[8px] px-4 py-1.5 bg-[#3B3F45] border border-[#3B3F45] text-sm text-white font-medium gap-x-2.5 hover:cursor-pointer transition hover:border-primary hover:bg-primary/20 text-[14px]"
                    )}`}
                    >
                        <img
                            width={24}
                            height={24}
                            src="/images/marketplace/filter.png"
                            alt=""
                        />
                        <span>Filters</span>
                    </div>
                </Popover>
            </div>
            {dataMarket.length === 0 ?
                <>
                    <div className="box-no-data-stake">
                        <img className='img-empty' src="/images/empty1.png" alt="" />
                    </div>
                </>
                :
                <>
                    {isMobile ?
                        <>
                            <div className="row-mobile-list-mk">
                                {dataMarket.map((item: any, index: any) => (
                                    <Item item={item} index={index} />
                                ))}
                            </div>
                        </>
                        :
                        <>
                            <div className="mt-5 p-3 rounded-[12px] bg-[#1A1C1E]">
                                <CustomTable
                                    scroll={{
                                        x: "max-content",
                                    }}
                                    id="table-striped"
                                    dataSource={dataMarket}
                                    isStriped
                                    columns={columns}
                                />
                            </div>
                        </>
                    }
                    {totalItem > 10 ?
                        <>
                            <div className="pagination-his">
                                <Pagination current={current} defaultPageSize={pageSize} onChange={onChange} total={totalItem} />
                            </div>
                        </>
                        :
                        <>
                            {""}
                        </>
                    }
                </>
            }
        </div>
    );
};

export default Marketplace;
