export const API_URL = process.env.REACT_APP_API_ENDPOINT
export const NUM_FORMAT = 4

// config TESTNET
// export const RPC_BASE = "https://sepolia.base.org"
// export const BASE_SCAN = "https://sepolia.basescan.org"
// export cont WORK_sCHAIN = 84532


// config MAINNET
export const RPC_BASE = "https://light-fluent-sound.base-mainnet.quiknode.pro/b3c14bfd234f7c92338dd0b260f1df387ee575ca/"
// "https://proud-silent-sky.base-mainnet.quiknode.pro/ff3f057f7dec2c8b28e9c15442aa037893b40969/"
export const BASE_SCAN = "https://basescan.org"
export const WORK_CHAIN = 8453


export const TELE_URL = "https://t.me/Mey_Network"
export const TELE_GROUP_URL = "https://t.me/MeyNetwork"
export const FB_URL = "https://www.facebook.com/MeyNetwork"
export const X_URL = "https://x.com/Mey_Network"
export const YT_URL = "https://www.youtube.com/@MeyNetwork"





