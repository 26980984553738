// import { PropertyDetailTab } from "../pages/apps/pto/details/PropertyDetailTab";
// import { TokenDetailTab } from "../pages/apps/pto/details/TokenDetailTab";
// import { FinancialsTab } from "../pages/apps/pto/details/FinancialsTab";
// import { BuyingProcessTab } from "../pages/apps/pto/details/BuyingProcessTab";

export const DEFAULT_DATA_FILTER = {
  minValue: 0,
  maxValue: 1000000,
  type: "",
  country: "",
  status: ""
};



export const STATUS_FILTER = [
  { value: '1', label: 'In Progress' },
  { value: '2', label: 'Upcoming' },
  { value: '3', label: 'Completed' },
  { value: "0", label: 'ALL' },
]

export const PTO_TABS = [
  {
    label: <div className="font-medium">All</div>,
    icon: (
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.4">
          <path
            d="M12.3188 4.66255C11.5063 3.36255 10.45 2.2438 9.53125 1.32505C9.47459 1.26889 9.4053 1.2271 9.32919 1.20318C9.25308 1.17926 9.17235 1.1739 9.09375 1.18755C9.01458 1.20001 8.93964 1.23157 8.87539 1.27948C8.81115 1.32739 8.75953 1.39022 8.725 1.46255L6.79375 5.51255L4.78125 4.1688C4.67086 4.09573 4.53625 4.0688 4.40625 4.0938C4.34176 4.10652 4.28041 4.13184 4.22572 4.16831C4.17102 4.20477 4.12405 4.25166 4.0875 4.3063C2.86875 6.1438 2.25 7.8063 2.25 9.25005C2.25 10.775 2.8558 12.2376 3.93414 13.3159C5.01247 14.3942 6.47501 15 8 15C9.52499 15 10.9875 14.3942 12.0659 13.3159C13.1442 12.2376 13.75 10.775 13.75 9.25005C13.75 7.6938 13.2812 6.1938 12.3188 4.66255ZM11.7063 9.82505C11.588 10.6133 11.2201 11.3429 10.6564 11.9065C10.0928 12.4701 9.36326 12.8381 8.575 12.9563H8.5C8.37455 12.9554 8.25404 12.9073 8.16237 12.8217C8.0707 12.736 8.01458 12.619 8.00514 12.4939C7.9957 12.3688 8.03363 12.2448 8.1114 12.1463C8.18918 12.0479 8.30111 11.9823 8.425 11.9625C8.99961 11.8691 9.53029 11.5974 9.94193 11.1857C10.3536 10.7741 10.6253 10.2434 10.7188 9.6688C10.7257 9.60145 10.7462 9.53621 10.7791 9.47703C10.8119 9.41785 10.8565 9.36597 10.91 9.32452C10.9636 9.28307 11.0249 9.25293 11.0905 9.23591C11.156 9.2189 11.2243 9.21537 11.2912 9.22553C11.3582 9.2357 11.4223 9.25935 11.4798 9.29506C11.5374 9.33077 11.587 9.37778 11.6258 9.43325C11.6646 9.48872 11.6918 9.5515 11.7056 9.61777C11.7194 9.68404 11.7196 9.75244 11.7063 9.8188V9.82505Z"
            fill="currentColor"
          />
        </g>
      </svg>
    ),
    value: '0',
  },
  {
    label: <div className="font-medium">Active</div>,
    icon: (
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M14.9375 13.3875L14.0437 5.38752C14.0174 5.14279 13.9012 4.91652 13.7176 4.75256C13.534 4.5886 13.2961 4.49861 13.05 4.50002H10.9562C10.8359 3.8032 10.4733 3.17128 9.9324 2.71578C9.39151 2.26029 8.7071 2.0105 7.99997 2.0105C7.29284 2.0105 6.60843 2.26029 6.06754 2.71578C5.52665 3.17128 5.16404 3.8032 5.04372 4.50002H2.94997C2.70383 4.49861 2.46592 4.5886 2.28233 4.75256C2.09874 4.91652 1.98253 5.14279 1.95622 5.38752L1.06247 13.3875C1.04733 13.528 1.06176 13.67 1.10481 13.8046C1.14787 13.9391 1.2186 14.0632 1.31247 14.1688C1.40602 14.2734 1.52067 14.357 1.64886 14.4141C1.77706 14.4712 1.91589 14.5005 2.05622 14.5H13.9437C14.0841 14.5005 14.2229 14.4712 14.3511 14.4141C14.4793 14.357 14.5939 14.2734 14.6875 14.1688C14.7813 14.0632 14.8521 13.9391 14.8951 13.8046C14.9382 13.67 14.9526 13.528 14.9375 13.3875ZM5.99997 7.00002C5.99997 7.13263 5.94729 7.25981 5.85352 7.35358C5.75976 7.44734 5.63258 7.50002 5.49997 7.50002C5.36736 7.50002 5.24018 7.44734 5.14642 7.35358C5.05265 7.25981 4.99997 7.13263 4.99997 7.00002V6.00002C4.99997 5.86741 5.05265 5.74024 5.14642 5.64647C5.24018 5.5527 5.36736 5.50002 5.49997 5.50002C5.63258 5.50002 5.75976 5.5527 5.85352 5.64647C5.94729 5.74024 5.99997 5.86741 5.99997 6.00002V7.00002ZM6.06247 4.50002C6.17262 4.06981 6.42282 3.6885 6.77362 3.4162C7.12443 3.1439 7.55588 2.9961 7.99997 2.9961C8.44406 2.9961 8.87551 3.1439 9.22631 3.4162C9.57712 3.6885 9.82732 4.06981 9.93747 4.50002H6.06247ZM11 7.00002C11 7.13263 10.9473 7.25981 10.8535 7.35358C10.7598 7.44734 10.6326 7.50002 10.5 7.50002C10.3674 7.50002 10.2402 7.44734 10.1464 7.35358C10.0526 7.25981 9.99997 7.13263 9.99997 7.00002V6.00002C9.99997 5.86741 10.0526 5.74024 10.1464 5.64647C10.2402 5.5527 10.3674 5.50002 10.5 5.50002C10.6326 5.50002 10.7598 5.5527 10.8535 5.64647C10.9473 5.74024 11 5.86741 11 6.00002V7.00002Z"
          fill="currentColor"
        />
      </svg>
    ),
    value: '1',
  },
  {
    label: <div className="font-medium">Upcoming</div>,
    icon: (
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M14.9375 13.3875L14.0437 5.38752C14.0174 5.14279 13.9012 4.91652 13.7176 4.75256C13.534 4.5886 13.2961 4.49861 13.05 4.50002H10.9562C10.8359 3.8032 10.4733 3.17128 9.9324 2.71578C9.39151 2.26029 8.7071 2.0105 7.99997 2.0105C7.29284 2.0105 6.60843 2.26029 6.06754 2.71578C5.52665 3.17128 5.16404 3.8032 5.04372 4.50002H2.94997C2.70383 4.49861 2.46592 4.5886 2.28233 4.75256C2.09874 4.91652 1.98253 5.14279 1.95622 5.38752L1.06247 13.3875C1.04733 13.528 1.06176 13.67 1.10481 13.8046C1.14787 13.9391 1.2186 14.0632 1.31247 14.1688C1.40602 14.2734 1.52067 14.357 1.64886 14.4141C1.77706 14.4712 1.91589 14.5005 2.05622 14.5H13.9437C14.0841 14.5005 14.2229 14.4712 14.3511 14.4141C14.4793 14.357 14.5939 14.2734 14.6875 14.1688C14.7813 14.0632 14.8521 13.9391 14.8951 13.8046C14.9382 13.67 14.9526 13.528 14.9375 13.3875ZM5.99997 7.00002C5.99997 7.13263 5.94729 7.25981 5.85352 7.35358C5.75976 7.44734 5.63258 7.50002 5.49997 7.50002C5.36736 7.50002 5.24018 7.44734 5.14642 7.35358C5.05265 7.25981 4.99997 7.13263 4.99997 7.00002V6.00002C4.99997 5.86741 5.05265 5.74024 5.14642 5.64647C5.24018 5.5527 5.36736 5.50002 5.49997 5.50002C5.63258 5.50002 5.75976 5.5527 5.85352 5.64647C5.94729 5.74024 5.99997 5.86741 5.99997 6.00002V7.00002ZM6.06247 4.50002C6.17262 4.06981 6.42282 3.6885 6.77362 3.4162C7.12443 3.1439 7.55588 2.9961 7.99997 2.9961C8.44406 2.9961 8.87551 3.1439 9.22631 3.4162C9.57712 3.6885 9.82732 4.06981 9.93747 4.50002H6.06247ZM11 7.00002C11 7.13263 10.9473 7.25981 10.8535 7.35358C10.7598 7.44734 10.6326 7.50002 10.5 7.50002C10.3674 7.50002 10.2402 7.44734 10.1464 7.35358C10.0526 7.25981 9.99997 7.13263 9.99997 7.00002V6.00002C9.99997 5.86741 10.0526 5.74024 10.1464 5.64647C10.2402 5.5527 10.3674 5.50002 10.5 5.50002C10.6326 5.50002 10.7598 5.5527 10.8535 5.64647C10.9473 5.74024 11 5.86741 11 6.00002V7.00002Z"
          fill="currentColor"
        />
      </svg>
    ),
    value: '2',
  },
  {
    label: <div className="font-medium">Completed</div>,
    icon: (
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M14.9375 13.3875L14.0437 5.38752C14.0174 5.14279 13.9012 4.91652 13.7176 4.75256C13.534 4.5886 13.2961 4.49861 13.05 4.50002H10.9562C10.8359 3.8032 10.4733 3.17128 9.9324 2.71578C9.39151 2.26029 8.7071 2.0105 7.99997 2.0105C7.29284 2.0105 6.60843 2.26029 6.06754 2.71578C5.52665 3.17128 5.16404 3.8032 5.04372 4.50002H2.94997C2.70383 4.49861 2.46592 4.5886 2.28233 4.75256C2.09874 4.91652 1.98253 5.14279 1.95622 5.38752L1.06247 13.3875C1.04733 13.528 1.06176 13.67 1.10481 13.8046C1.14787 13.9391 1.2186 14.0632 1.31247 14.1688C1.40602 14.2734 1.52067 14.357 1.64886 14.4141C1.77706 14.4712 1.91589 14.5005 2.05622 14.5H13.9437C14.0841 14.5005 14.2229 14.4712 14.3511 14.4141C14.4793 14.357 14.5939 14.2734 14.6875 14.1688C14.7813 14.0632 14.8521 13.9391 14.8951 13.8046C14.9382 13.67 14.9526 13.528 14.9375 13.3875ZM5.99997 7.00002C5.99997 7.13263 5.94729 7.25981 5.85352 7.35358C5.75976 7.44734 5.63258 7.50002 5.49997 7.50002C5.36736 7.50002 5.24018 7.44734 5.14642 7.35358C5.05265 7.25981 4.99997 7.13263 4.99997 7.00002V6.00002C4.99997 5.86741 5.05265 5.74024 5.14642 5.64647C5.24018 5.5527 5.36736 5.50002 5.49997 5.50002C5.63258 5.50002 5.75976 5.5527 5.85352 5.64647C5.94729 5.74024 5.99997 5.86741 5.99997 6.00002V7.00002ZM6.06247 4.50002C6.17262 4.06981 6.42282 3.6885 6.77362 3.4162C7.12443 3.1439 7.55588 2.9961 7.99997 2.9961C8.44406 2.9961 8.87551 3.1439 9.22631 3.4162C9.57712 3.6885 9.82732 4.06981 9.93747 4.50002H6.06247ZM11 7.00002C11 7.13263 10.9473 7.25981 10.8535 7.35358C10.7598 7.44734 10.6326 7.50002 10.5 7.50002C10.3674 7.50002 10.2402 7.44734 10.1464 7.35358C10.0526 7.25981 9.99997 7.13263 9.99997 7.00002V6.00002C9.99997 5.86741 10.0526 5.74024 10.1464 5.64647C10.2402 5.5527 10.3674 5.50002 10.5 5.50002C10.6326 5.50002 10.7598 5.5527 10.8535 5.64647C10.9473 5.74024 11 5.86741 11 6.00002V7.00002Z"
          fill="currentColor"
        />
      </svg>
    ),
    value: '3',
  },
];

// export const PTO_DETAILS_TABS = [
//   {
//     label: <div className="font-medium">Property Details</div>,
//     value: "property-details",
//     children: <PropertyDetailTab />,
//   },
//   {
//     label: <div className="font-medium">Token Details</div>,
//     value: "token-details",
//     children: <TokenDetailTab />,
//   },
//   {
//     label: <div className="font-medium">Financials</div>,
//     value: "financials",
//     children: <FinancialsTab />,
//   },
//   {
//     label: <div className="font-medium">Buying Process</div>,
//     value: "buying-process",
//     children: <BuyingProcessTab />,
//   },
// ];


export const TYPE_FILTER = [
  { value: '1', label: 'Condo' },
  { value: '2', label: 'LandedHouse' },
  { value: '3', label: 'NewConstruction' },
  { value: '4', label: 'Hotels' },
  { value: '5', label: 'Resorts' },
  { value: '6', label: 'ShortTermRental' },
  { value: '7', label: 'Retail' },
  { value: '8', label: 'ServicedOffice' },
  { value: '9', label: 'Commercial' },
  { value: '10', label: 'PrivateREITs' },

]

export const COUNTRY = [
  { value: 'AF', label: 'Afghanistan' },
  { value: 'AX', label: 'Aland Islands' },
  { value: 'AL', label: 'Albania' },
  { value: 'DZ', label: 'Algeria' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AO', label: 'Angola' },
  { value: 'AI', label: 'Anguilla' },
  { value: 'AQ', label: 'Antarctica' },
  { value: 'AG', label: 'Antigua and Barbuda' },
  { value: 'AR', label: 'Argentina' },
  { value: 'AM', label: 'Armenia' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AU', label: 'Australia' },
  { value: 'AT', label: 'Austria' },
  { value: 'AZ', label: 'Azerbaijan' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BH', label: 'Bahrain' },
  { value: 'BD', label: 'Bangladesh' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BE', label: 'Belgium' },
  { value: 'BZ', label: 'Belize' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'BT', label: 'Bhutan' },
  { value: 'BO', label: 'Bolivia}, Plurinational State of' },
  { value: 'BQ', label: 'Bonaire}, Sint Eustatius and Saba' },
  { value: 'BA', label: 'Bosnia and Herzegovina' },
  { value: 'BW', label: 'Botswana' },
  { value: 'BV', label: 'Bouvet Island' },
  { value: 'BR', label: 'Brazil' },
  { value: 'IO', label: 'British Indian Ocean Territory' },
  { value: 'BN', label: 'Brunei Darussalam' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BI', label: 'Burundi' },
  { value: 'KH', label: 'Cambodia' },
  { value: 'CM', label: 'Cameroon' },
  { value: 'CA', label: 'Canada' },
  { value: 'CV', label: 'Cape Verde' },
  { value: 'KY', label: 'Cayman Islands' },
  { value: 'CF', label: 'Central African Republic' },
  { value: 'TD', label: 'Chad' },
  { value: 'CL', label: 'Chile' },
  { value: 'CN', label: 'China' },
  { value: 'CX', label: 'Christmas Island' },
  { value: 'CC', label: 'Cocos (Keeling) Islands' },
  { value: 'CO', label: 'Colombia' },
  { value: 'KM', label: 'Comoros' },
  { value: 'CG', label: 'Congo' },
  { value: 'CD', label: 'Congo}, Democratic Republic of the' },
  { value: 'CK', label: 'Cook Islands' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'HR', label: 'Croatia' },
  { value: 'CW', label: 'Curacao' },
  { value: 'CY', label: 'Cyprus' },
  { value: 'CZ', label: 'Czech Republic' },
  { value: 'DK', label: 'Denmark' },
  { value: 'DJ', label: 'Djibouti' },
  { value: 'DM', label: 'Dominica' },
  { value: 'DO', label: 'Dominican Republic' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'EG', label: 'Egypt' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'GQ', label: 'Equatorial Guinea' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'EE', label: 'Estonia' },
  { value: 'ET', label: 'Ethiopia' },
  { value: 'FK', label: 'Falkland Islands (Malvinas)' },
  { value: 'FO', label: 'Faroe Islands' },
  { value: 'FJ', label: 'Fiji' },
  { value: 'FI', label: 'Finland' },
  { value: 'FR', label: 'France' },
  { value: 'GF', label: 'French Guiana' },
  { value: 'PF', label: 'French Polynesia' },
  { value: 'TF', label: 'French Southern Territories' },
  { value: 'GA', label: 'Gabon' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GE', label: 'Georgia' },
  { value: 'DE', label: 'Germany' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'GR', label: 'Greece' },
  { value: 'GL', label: 'Greenland' },
  { value: 'GD', label: 'Grenada' },
  { value: 'GP', label: 'Guadeloupe' },
  { value: 'GU', label: 'Guam' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GG', label: 'Guernsey' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HM', label: 'Heard Island and Mcdonald Islands' },
  { value: 'VA', label: 'Holy See (Vatican City State)' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HK', label: 'Hong Kong' },
  { value: 'HU', label: 'Hungary' },
  { value: 'IS', label: 'Iceland' },
  { value: 'IN', label: 'India' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'IQ', label: 'Iraq' },
  { value: 'IE', label: 'Ireland' },
  { value: 'IM', label: 'Isle of Man' },
  { value: 'IL', label: 'Israel' },
  { value: 'IT', label: 'Italy' },
  { value: 'JM', label: 'Jamaica' },
  { value: 'JP', label: 'Japan' },
  { value: 'JE', label: 'Jersey' },
  { value: 'JO', label: 'Jordan' },
  { value: 'KZ', label: 'Kazakhstan' },
  { value: 'KE', label: 'Kenya' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'KR', label: 'Korea}, Republic of' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'KG', label: 'Kyrgyzstan' },
  { value: 'LA', label: 'Lao People’s Democratic Republic' },
  { value: 'LV', label: 'Latvia' },
  { value: 'LB', label: 'Lebanon' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LY', label: 'Libya' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Lithuania' },
  { value: 'LU', label: 'Luxembourg' },
  { value: 'MO', label: 'Macao' },
  { value: 'MG', label: 'Madagascar' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'MV', label: 'Maldives' },
  { value: 'ML', label: 'Mali' },
  { value: 'MT', label: 'Malta' },
  { value: 'MH', label: 'Marshall Islands' },
  { value: 'MQ', label: 'Martinique' },
  { value: 'MR', label: 'Mauritania' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'MX', label: 'Mexico' },
  { value: 'FM', label: 'Micronesia (Federated States of)' },
  { value: 'MD', label: 'Moldova}, Republic of' },
  { value: 'MC', label: 'Monaco' },
  { value: 'MN', label: 'Mongolia' },
  { value: 'ME', label: 'Montenegro' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MA', label: 'Morocco' },
  { value: 'MZ', label: 'Mozambique' },
  { value: 'MM', label: 'Myanmar' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'NC', label: 'New Caledonia' },
  { value: 'NZ', label: 'New Zealand' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NE', label: 'Niger' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NU', label: 'Niue' },
  { value: 'NF', label: 'Norfolk Island' },
  { value: 'MK', label: 'North Macedonia' },
  { value: 'MP', label: 'Northern Mariana Islands' },
  { value: 'NO', label: 'Norway' },
  { value: 'OM', label: 'Oman' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PW', label: 'Palau' },
  { value: 'PS', label: 'Palestine}, State of' },
  { value: 'PA', label: 'Panama' },
  { value: 'PG', label: 'Papua New Guinea' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PE', label: 'Peru' },
  { value: 'PH', label: 'Philippines' },
  { value: 'PN', label: 'Pitcairn' },
  { value: 'PL', label: 'Poland' },
  { value: 'PT', label: 'Portugal' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'QA', label: 'Qatar' },
  { value: 'RE', label: 'Réunion' },
  { value: 'RO', label: 'Romania' },
  { value: 'RW', label: 'Rwanda' },
  { value: 'BL', label: 'Saint Barthélemy' },
  { value: 'SH', label: 'Saint Helena}, Ascension and Tristan da Cunha' },
  { value: 'KN', label: 'Saint Kitts and Nevis' },
  { value: 'LC', label: 'Saint Lucia' },
  { value: 'MF', label: 'Saint Martin (French part)' },
  { value: 'PM', label: 'Saint Pierre and Miquelon' },
  { value: 'VC', label: 'Saint Vincent and the Grenadines' },
  { value: 'WS', label: 'Samoa' },
  { value: 'SM', label: 'San Marino' },
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'SN', label: 'Senegal' },
  { value: 'RS', label: 'Serbia' },
  { value: 'SC', label: 'Seychelles' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'SG', label: 'Singapore' },
  { value: 'SX', label: 'Sint Maarten (Dutch part)' },
  { value: 'SK', label: 'Slovakia' },
  { value: 'SI', label: 'Slovenia' },
  { value: 'SB', label: 'Solomon Islands' },
  { value: 'SO', label: 'Somalia' },
  { value: 'ZA', label: 'South Africa' },
  { value: 'GS', label: 'South Georgia and the South Sandwich Islands' },
  { value: 'SS', label: 'South Sudan' },
  { value: 'ES', label: 'Spain' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'SR', label: 'Suriname' },
  { value: 'SJ', label: 'Svalbard and Jan Mayen' },
  { value: 'SE', label: 'Sweden' },
  { value: 'CH', label: 'Switzerland' },
  { value: 'ST', label: 'São Tomé and Príncipe' },
  { value: 'TW', label: 'Taiwan' },
  { value: 'TJ', label: 'Tajikistan' },
  { value: 'TZ', label: 'Tanzania}, United Republic of' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TG', label: 'Togo' },
  { value: 'TK', label: 'Tokelau' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TT', label: 'Trinidad and Tobago' },
  { value: 'TN', label: 'Tunisia' },
  { value: 'TR', label: 'Turkey' },
  { value: 'TM', label: 'Turkmenistan' },
  { value: 'TC', label: 'Turks and Caicos Islands' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'UG', label: 'Uganda' },
  { value: 'UA', label: 'Ukraine' },
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'GB', label: 'United Kingdom' },
  { value: 'US', label: 'United States' },
  { value: 'UM', label: 'United States Minor Outlying Islands' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Uzbekistan' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'VE', label: 'Venezuela' },
  { value: 'VN', label: 'Vietnam' },
  { value: 'VG', label: 'Virgin Islands}, British' },
  { value: 'VI', label: 'Virgin Islands}, U.S.' },
  { value: 'WF', label: 'Wallis and Futuna' },
  { value: 'EH', label: 'Western Sahara' },
  { value: 'YE', label: 'Yemen' },
  { value: 'ZM', label: 'Zambia' },
  { value: 'ZW', label: 'Zimbabwe' }
];