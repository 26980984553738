import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { useAccount } from "wagmi";
import clsx from "clsx";
import { IconClockwise } from "../../../../assets/icons/tsx/icon-clockwise";
import "./style.scss";
import { SellTab } from "./trade/SellTab";
import { BuyTab } from "./trade/BuyTab";
import { AppHome } from "../../../../api/appHome";
import { useLocation } from "react-router-dom";
import { TransactionHistoryTab } from "./TransactionHistoryTab";
import { ActivityTab } from "./ActivityTab";
import { OverviewTab } from "./OverviewTab";
import ChartNft from "./chartNft";
import { Noti } from "../../../../components/Noti/toast";
import { isMobile } from "react-device-detect";

const MarketplaceDetail = () => {
    const location = useLocation()
    const pathUrl: any = location?.search?.split("?")
    const pathSearch = pathUrl[1]
    const pathId = pathSearch?.split("=")
    const pathParam = pathId[1]
    const [dataDetail, setDataDetail]: any = useState([])

    const getDataMarket = async () => {
        try {
            let res = await AppHome.getDataMarketDetail(pathParam);
            if (res.data.success === true) {
                setDataDetail(res.data.data);
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    useEffect(() => {
        getDataMarket()
    }, []);

    return (
        <div className={isMobile ? "py-2 px-2 font-radioCanada" : "py-4 px-6 font-radioCanada"}>
            <div className="flex gap-x-5 items-start">
                <DetailSection dataDetail={dataDetail} />
                {isMobile ?
                    <>
                        {""}
                    </>
                    :
                    <>
                        <TradeSection dataDetail={dataDetail} />
                    </>
                }
            </div>
        </div>
    );
};

const DetailSection = (props: any) => {
    const { address } = useAccount();
    const dataDetail = props?.dataDetail
    const [activeTab, setActiveTab] = useState("overview");
    const [dataAccount, setDataAccount]: any = useState()
    const [dataChart, setDataChart]: any = useState()

    const onChangeTabs = async (key: any) => {
        setActiveTab(key)
    }

    const getDataChartMarket = async () => {
        try {
            let res = await AppHome.getDataChartMarket(dataDetail?.code);
            if (res.data.success === true) {
                setDataChart(res.data.data);
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    useEffect(() => {
        if (dataDetail?.code) {
            getDataChartMarket()
        }
    }, [dataDetail?.code]);

    const AccountInfo = async () => {
        try {
            let res = await AppHome.AccountInfo(
                address
            );
            if (res.data.success === true) {
                setDataAccount(res.data.data);
                localStorage.setItem('userAccount', JSON.stringify(res.data.data.dataAccount));
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    useEffect(() => {
        if (address) {
            AccountInfo()
        }
    }, [address]);

    const tabJust = (
        <>
            <div className="title-tabs">
                Overview
            </div>
        </>
    )

    const tabActive = (
        <>
            <div className="title-tabs">
                Activity
            </div>
        </>
    )

    const tabTran = (
        <>
            <div className="title-tabs">
                My Transaction
            </div>
        </>
    )

    return (
        <div className={isMobile ? "flex-grow text-start res-mobile-grow" : "flex-grow text-start overflow-y-auto"}>
            {/* <div className="porfolio-wrap">
                <div className="kyc-user">
                    <div className="center-row">
                        <div className="time-line-kyc">
                            <div className={address ? "item active" : "item"}>
                                <div className="box-icon">
                                    {address ? <img src="/images/user/line-active.png" alt="" /> : <img src="/images/user/line-no-active.png" alt="" />}
                                </div>
                                <div className="text">Connect Wallet</div>
                            </div>
                            <div className={dataAccount?.dataAccount?.isKYC ? "item active" : "item"}>
                                <div className="box-icon">
                                    {dataAccount?.dataAccount?.isKYC ? <img src="/images/user/line-active.png" alt="" /> : <img src="/images/user/line-no-active.png" alt="" />}
                                </div>
                                <div className="text">Complete KYC</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className={isMobile ? "text-white/60 text-[18px] font-medium" : "text-white/60 text-xl font-medium"}>{dataDetail?.name}</div>
            <div className={isMobile ? "mt-2.5 text-xl font-semibold text-white" : "mt-2.5 text-2xl font-semibold text-white"}>
                {new Intl.NumberFormat('en-US').format(Number(dataDetail?.currentPrice))} USDC
            </div>
            {dataDetail?.priceChange < 0 ?
                <>
                    <div className="mt-1 text-[#ff0000] text-[14px]">{new Intl.NumberFormat('en-US').format(dataDetail?.priceChange)}%</div>
                </>
                :
                <>
                    <div className="mt-1 text-[#00D415] text-[14px]">{new Intl.NumberFormat('en-US').format(dataDetail?.priceChange)}%</div>
                </>
            }
            <div className="my-2">
                <div className="my-chart">
                    <ChartNft
                        data={dataChart || []}
                        backgroundImage="/images/bg-chart.png"
                        colors={{
                            backgroundColor: '#100c18',
                            textColor: '#ffffff75',
                            gridColor: '#ffffff12',
                        }}
                    />
                </div>
            </div>
            {isMobile ?
                <>
                    <TradeSection dataDetail={dataDetail} />
                </>
                :
                <>
                    {""}
                </>
            }
            <div className="list-info-supply">
                <div className="item">
                    <div className="top-item">
                        Volume(1d)
                    </div>
                    <div className="bottom-item">
                        {dataDetail?.volume} USDC
                    </div>
                </div>
                <div className="line-item"></div>
                <div className="item">
                    <div className="top-item">
                        Property Value
                    </div>
                    <div className="bottom-item">
                        {dataDetail?.propertyValue}
                    </div>
                </div>
                <div className="line-item"></div>
                <div className="item">
                    <div className="top-item">
                        Supply
                    </div>
                    <div className="bottom-item">
                        {dataDetail?.supply}
                    </div>
                </div>
                <div className="line-item"></div>
                <div className="item">
                    <div className="top-item">
                        Trades (1d)
                    </div>
                    <div className="bottom-item">
                        {dataDetail?.trades}
                    </div>
                </div>
            </div>
            {/* <CustomSegmented
                options={MARKETPLACE_DETAIL_FILTER_OPTIONS}
                value={time}
                onChange={setTime}
                className="my-2.5"
            /> */}
            <div className={isMobile ? "mt-4 display-tab-mobile flex justify-between items-center" : "mt-4 flex justify-between items-center"}>
                <div className="tabs-list">
                    <Tabs defaultActiveKey="overview" onChange={onChangeTabs}>
                        <Tabs.TabPane tab={tabJust} key="overview">
                            <OverviewTab dataDetail={dataDetail} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={tabActive} key="activity">
                            <ActivityTab dataDetail={dataDetail} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={tabTran} key="transaction-history">
                            <TransactionHistoryTab dataDetail={dataDetail} />
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
            <div className="mt-5"></div>
        </div>
    );
};

const TradeSection = (props: any) => {
    const dataDetail = props?.dataDetail
    const [activeTab, setActiveTab] = useState<"buy" | "sell">("buy");
    const [dataSell, setDataSell]: any = useState([])

    const handleReloadData = async () => {
        try {
            let res = await AppHome.getDataSellMarket(dataDetail?.code);
            if (res.data.success === true) {
                setDataSell(res.data.data);
                Noti('success', "Reload success", 3000)
            }
        } catch (error) {
            Noti('error', error || "Unknown error", 3000)
        }
    }

    return (
        <>
            <div className="row-action-mk">
                <div className={isMobile ? "flex-shrink-0 w-100-cus rounded-[12px] border border-[rgba(59,63,69,0.4)]" : "flex-shrink-0 w-[363px] p-3 rounded-[12px] border border-[rgba(59,63,69,0.4)]"}>
                    <div className="flex flex-col h-full bg-[rgba(59,63,69,0.4)] rounded-[8px] border border-[rgba(59,63,69,0.2)]">
                        <div className="flex-grow">
                            <div className="px-4 py-2 flex justify-between items-center border-b border-b-[rgba(59,63,69,0.2)]">
                                <div className="flex gap-x-6 items-center">
                                    <div
                                        onClick={() => setActiveTab("buy")}
                                        className={clsx(
                                            "hover:text-primary hover:cursor-pointer transition font-semibold text-xl",
                                            activeTab === "buy" ? "text-primary" : "text-white/40",
                                        )}
                                    >
                                        BUY
                                    </div>
                                    <div
                                        onClick={() => setActiveTab("sell")}
                                        className={clsx(
                                            "hover:text-primary hover:cursor-pointer transition font-semibold text-xl",
                                            activeTab === "sell" ? "text-primary" : "text-white/40",
                                        )}
                                    >
                                        SELL
                                    </div>
                                </div>
                                {/* <button className="text-white/40 hover:text-primary transition" onClick={handleReloadData}>
                                    <IconClockwise />
                                </button> */}
                            </div>
                            {activeTab === "buy" ? <BuyTab dataDetail={dataDetail} /> : <SellTab dataDetail={dataDetail} />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MarketplaceDetail;
