import clsx from "clsx";
import { motion } from "framer-motion";
import { useMemo } from "react";

export interface CustomTabProps {
    value?: string;
    onChange?: (value: string) => void;
    className?: string;
    tabs: Array<{
        title?: string | React.ReactNode;
        value: string;
        label?: string | React.ReactNode;
        icon?: string | React.ReactNode;
    }>;
    width?: "full" | "auto";
    centeredTab?: boolean;
    hasBorderBottom?: boolean;
}

export const CustomTab = ({
    value,
    onChange,
    className,
    tabs,
    width,
    centeredTab,
    hasBorderBottom,
}: CustomTabProps) => {
    const generateLayoutId = useMemo(() => {
        return tabs.map((tab) => tab.value).join(",");
    }, [tabs]);

    const renderClassWidth = () => {
        switch (width) {
            case "full":
                return {
                    parent: "gap-x-0",
                    child: "flex-grow",
                };
            case "auto": {
                return {
                    parent: "gap-x-2",
                    child: "",
                };
            }
            default: {
                return {
                    parent: "gap-x-2",
                    child: "",
                };
            }
        }
    };

    return (
        <div
            className={clsx(
                "flex items-center font-radioCanada text-[16px]",
                className,
                renderClassWidth()?.parent,
                hasBorderBottom && "border-b border-b-white/10",
            )}
        >
            {tabs.map((tab) => {
                const isActive = tab.value === value;
                return (
                    <div
                        onClick={() => onChange?.(tab.value)}
                        className={clsx(
                            "flex res-class-item items-center gap-x-[6px] px-2.5 py-3 relative hover:cursor-pointer hover:text-primary hover:bg-primary/10 transition text-nowrap",
                            isActive ? "text-primary bg-primary/10" : "text-white/60",
                            renderClassWidth()?.child,
                            centeredTab && "justify-center",
                        )}
                    >
                        {isActive && (
                            <motion.div
                                className="absolute bottom-0 h-[2px] left-0 right-0 bg-primary"
                                layoutId={generateLayoutId}
                            ></motion.div>
                        )}
                        {tab.icon}
                        <div>{tab.title || tab.label}</div>
                    </div>
                );
            })}
        </div>
    );
};
