/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Progress, Spin, Modal, Carousel, Tabs, Steps, Collapse } from "antd";
import { useWeb3Modal, useWeb3ModalState } from "@web3modal/wagmi/react";
import { LoadingOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom'
import Web3 from "web3";
import { useLocation } from "react-router-dom";
import { AbiItem } from "web3-utils";
import { useAccount } from "wagmi";
import { useHookPTO } from "../storePTO";
import { Noti } from "../../../../components/Noti/toast";
import { useContract } from "../../../../hooks/useContract";
import { BASE_SCAN, RPC_BASE, WORK_CHAIN } from "../../../../constants";
import abiPTO from "../utils/abiPTO.json"
import abiTokenPTO from "../utils/abiTokenPTO.json"
import abi_StakingTierManager from "../../porfolio/rank/utils/abi_StakingTierManager.json"
import { formatNumber } from "../../staking/details/utils";
import { useHookStaked } from "../../staking/storeStaked";
import { PropertyDetailTab } from "./PropertyDetailTab";
import { NFTDetailTab } from "./NFTDetailTab";
import { TimeLineTab } from "./TimeLineTab";
import { DocumentDetail } from "./DocumentDetail";
import CopyToClipboard from "react-copy-to-clipboard";
import Countdown from "react-countdown";
import { STATUS_PTO, TEXT_RANK } from "../index.d";
import Faqs from "../faqs";
import { Market } from "./Market";
import { Map } from "./Map";
import { convertDateToString } from "../../porfolio/utils";
import {
  caculaTimeLine, renderClassStatus,
  rendererCountDownDetail,
  rendererCountDownDetailNew,
  rendererCountDownDetailAll,
  renderNumTimeAll,
  renderStatus, renderTextTime,
  tabDocument, tabTimeLine,
  tabMap, tabMarket,
  tabProperty, tabNFTDetail
} from "../utils/index";
import { isMobile } from "react-device-detect";
import { AppHome } from "../../../../api/appHome";



declare const window: Window & typeof globalThis & { ethereum: any };
const DetailsPto = () => {
  const { address } = useAccount();
  const navigation = useNavigate()
  const { selectedNetworkId }: any = useWeb3ModalState();
  const [state1, actions1] = useHookStaked()
  const [state, actions] = useHookPTO()
  const [priceNFT, setPriceNFT]: any = useState(0)
  const [valueAmount, setValueAmount] = useState<string>("0");
  const [dataDetail, setDataDetail]: any = useState(null);
  const location = useLocation();
  const pathUrl: any = location?.search?.split("?");
  const pathSearch = pathUrl[1];
  const pathId = pathSearch?.split("=");
  const pathParam = pathId[1];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenApprove, setIsModalOpenApprove] = useState(false);
  const [amountApproved, setAmountApproved]: any = useState(0)
  const [loadingPur, setLoadingPur]: any = useState(false)
  const [loadingApprove, setLoadingApprove]: any = useState(false)
  const [isApprove, setIsApprove]: any = useState(false)
  const [amountTokenPTO, setAmountTokenPTO]: any = useState(0)
  const [hashLend, sethashLend]: any = useState('')
  const [statusPurchase, setstatusPurchase]: any = useState(false)
  const [numStt, setnumStt]: any = useState(0)
  const [isActiveRound, setIsActiveRound]: any = useState('')
  const [totalInvestPool, settotalInvestPool]: any = useState(0)
  const [maxNFTSupply, setmaxNFTSupply]: any = useState(0)
  const [numSteps, setNumSteps]: any = useState(0)
  const [enough, setEnough]: any = useState(true)
  const [amountNFTUser, setAmountNFTUser]: any = useState(0)
  const [arrTimeline, setArrTimeline]: any = useState([])
  const [totalStakedFromSC, setTotalStakedFromSC]: any = useState(0)
  const [arrLine, setarrLine]: any = useState([])
  const [maxBuyTier, setmaxBuyTier]: any = useState(0)
  const [totalClaimedNFT, settotalClaimedNFT]: any = useState(0)
  const [arrHis, setarrHis]: any = useState([])
  const [curentTier, setcurentTier]: any = useState('')
  const [maxBuyOnRound, setmaxBuyOnRound]: any = useState(0)
  const [nftMintedOnRound, setnftMintedOnRound]: any = useState(0)
  const [amountRefunded, setAmountRefunded]: any = useState(0)
  const [amountClaimed, setAmountClaimed]: any = useState(0)
  const [totalLoanAmountRaised, setTotalLoanAmountRaised]: any = useState(0)

  let arrItemTimeLine: any = []

  //---connect wallet 
  const { open } = useWeb3Modal();
  const handleConnect = () => {
    open();
  };
  function goToKYC() {
    navigation("/apps/edit-profile")
    sessionStorage.setItem("keyActiveMenu", '7')
    window.location.reload()
  }
  function goToStake() {
    navigation("/apps/staking")
    sessionStorage.setItem("keyActiveMenu", '2')
    window.location.reload()
  }
  //------------------------------
  const [startTime, setstartTime]: any = useState(0)
  const [startTimeSvip, setstartTimeSvip]: any = useState(0)
  const [endTimeSvip, setendTimeSvip]: any = useState(0)

  const [startTimeDolphin, setstartTimeDolphin]: any = useState(0)
  const [endTimeDolphin, setendTimeDolphin]: any = useState(0)

  const [startTimeShark, setstartTimeShark]: any = useState(0)
  const [endTimeShark, setendTimeShark]: any = useState(0)

  const [startTimeWhale, setstartTimeWhale]: any = useState(0)
  const [endTimeWhale, setendTimeWhale]: any = useState(0)

  const [startTimeCom, setstartTimeCom]: any = useState(0)
  const [endTimeCom, setendTimeCom]: any = useState(0)

  const [startTimeEnd, setstartTimeEnd]: any = useState(0)
  const [endTimeEnd, setendTimeEnd]: any = useState(0)

  const [startTimeClaim_Refund, setstartTimeClaim_Refund]: any = useState(0)
  const [endTimeClaim_Refund, setendTimeClaim_Refund]: any = useState(0)

  const [endTime, setendTime]: any = useState(0)

  const [dataContract, setdataContract]: any = useState()
  const SettingContract = async () => {
    try {
      let res = await AppHome.getSettingContract();
      if (res.data.success === true) {
        setdataContract(res.data.data);
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    SettingContract()
  }, []);



  let scPTO = dataDetail?.contractAddress
  let scContractTier = dataContract?.stake_tier_manager_contract

  const contractPTO: any = useContract(scPTO, abiPTO);
  const contractReadTier: any = useContract(scContractTier, abi_StakingTierManager)

  const contractTokenPTO: any = useContract(dataContract?.pto_deposit_token_address, abiTokenPTO);
  let web3: any = new Web3(RPC_BASE);
  const { Panel } = Collapse;

  let timeNow = Date.now()

  const contractPTONotAddress = new web3.eth.Contract(
    abiPTO as unknown as AbiItem,
    scPTO
  );

  const getTierByUser = async () => {
    let tier = await contractReadTier?.getTotalStakingAmount(address)
    let balance: any = web3.utils.fromWei(tier, "picoether")
    setTotalStakedFromSC(Number(balance))
  }

  useEffect(() => {
    if (address && contractReadTier) {
      getTierByUser()
    } else {
      setTotalStakedFromSC(0)
    }
  }, [address, contractReadTier])


  const [iterID, setTierID]: any = useState(0)
  const getMaxBuyTier = async () => {
    try {
      let tierID = await contractReadTier.getTierFromStaking(address);
      setcurentTier(tierID?.tierName)
      let tierIdNumber = Number(tierID.tierId)

      setTierID(Number(tierID.tierId))

      await contractPTO.tiers(tierIdNumber).then((res: any) => {
        setmaxBuyTier(Number(res.maxNFTsAllowed))
      })
        .catch((err: any) => {
          console.log('err', err)
          setmaxBuyTier(0)
        })
    } catch (error) {
      console.log('error', error)
      setmaxBuyTier(0)
    }
  }

  useEffect(() => {
    if (contractReadTier && contractPTO && address) {
      getMaxBuyTier()
    } else {
      setmaxBuyTier(0)
    }
  }, [contractReadTier, contractPTO, address])

  const handleAmount = (e: any) => {
    const newValue = e.target.value;
    if (/^\d*$/.test(newValue)) {
      if (Number(newValue) >= 0) {
        setValueAmount(newValue);
      }
    }
  };

  const handleClickPlusAmount = () => {
    setValueAmount((oldValue) => String(Number(oldValue) + 1));
  };

  const handleClickMinusAmount = () => {
    setValueAmount((oldValue) => String((Number(oldValue) - 1) < 0 ? 0 : Number(oldValue) - 1));
  };

  const getDetailPTO = async () => {
    try {
      let params = {
        ownerAddress: address || "",
        code: pathParam || ""
      }
      await actions.detailPTO(params).then((res: any) => {
        setDataDetail(res)
      })
        .catch((err: any) => {
          console.log('err', err)
          setDataDetail(null)
        })
    } catch (error) {
      console.log('error', error)
      setDataDetail(null)
    }
  }

  useEffect(() => {
    getDetailPTO()
  }, [address])

  //---purchased

  const btnPurchased = () => {
    const obj = {
      className: `btn-actions-pto`,
      disabled: loadingPur
    };
    return obj;
  };
  const btnPurchasedDis = () => {
    const obj = {
      className: `btn-actions-pto`,
      disabled: true
    };
    return obj;
  };

  const btnApprove = () => {
    const obj = {
      className: `btn-actions-pto`,
      disabled: loadingApprove
    };
    return obj;
  };
  const btnClaim = () => {
    const obj = {
      className: `btn-claim`,
      disabled: loadingClaim || loadingRefund
    };
    return obj;
  };
  const btnClaimed = () => {
    const obj = {
      className: `btn-claim claimed`,
      disabled: true
    };
    return obj;
  };

  const btnClaimDis = () => {
    const obj = {
      className: `btn-claim`,
      disabled: true
    };
    return obj;
  };

  const getBalanceTokenInWallet = async () => {
    if (contractTokenPTO) {
      const amount = await contractTokenPTO.balanceOf(address);
      let balance: any = web3.utils.fromWei(amount, "picoether");
      setAmountTokenPTO(Number(balance));
    } else {
      setAmountTokenPTO(0)
    }
  };

  useEffect(() => {
    if (address && selectedNetworkId === WORK_CHAIN && contractTokenPTO) {
      getBalanceTokenInWallet()
    } else {
      setAmountTokenPTO(0)
    }
  }, [address, selectedNetworkId, contractTokenPTO])

  const getAmountApprove = async () => {
    try {
      await contractTokenPTO.allowance(address, scPTO).then((res: any) => {
        let balance: any = web3.utils.fromWei(res, "picoether");
        setAmountApproved(Number(balance))
      })
        .catch((err: any) => {
          console.log('err', err)
          setAmountApproved(0)
        })
    } catch (error) {
      console.log('error', error)
      setAmountApproved(0)
    }
  }
  useEffect(() => {
    if (address && selectedNetworkId === WORK_CHAIN && contractTokenPTO && scPTO) {
      getAmountApprove()
    } else {
      setAmountApproved(0)
    }
  }, [address, selectedNetworkId, scPTO, contractTokenPTO])

  useEffect(() => {
    if (dataDetail && priceNFT) {
      if (amountApproved < (Number(valueAmount) * priceNFT)) {
        setIsApprove(false)
      } else {
        setIsApprove(true)
      }
    } else {
      setIsApprove(false)
    }

  }, [amountApproved, valueAmount, priceNFT])


  const saveLog = async (hash: any, amount: any, type: any) => {
    try {
      let params = {
        ownerAddress: address,
        contractAddress: scPTO,
        txHash: hash,
        amount: amount,
        type: type,
        assetToken: 'USDC',
      }
      await actions1.addHistory(params)
    } catch (error) {
      console.log('error', error)
    }
  }

  const handlePurchased = async () => {
    try {
      setLoadingPur(true)
      if (selectedNetworkId !== WORK_CHAIN) {
        setLoadingPur(false);
        Noti('error', "Wrong network", 3000)
        return
      }
      if (Number(valueAmount) === 0) {
        setLoadingPur(false);
        Noti('error', "Invalid amount!", 3000)
        return
      }
      if ((Number(valueAmount) * priceNFT) > Number(amountApproved)) {
        setLoadingPur(false);
        Noti('error', "Invalid amount!", 3000)
        return
      }
      if (!scPTO) {
        setLoadingPur(false);
        Noti('error', "PTO contract not found", 3000)
        return
      }
      await contractPTO.fundLoan(Number(valueAmount)).then((res: any) => {
        saveLog(res.hash, Number(valueAmount), 9)
        res.wait().then((res1: any) => {
          if (res1?.status === 1) {
            sethashLend(res?.hash)
            setstatusPurchase(!statusPurchase)
            setLoadingPur(false)
            Noti('success', "Purchase successfully", 3000)
          } else {
            setstatusPurchase(!statusPurchase)
            sethashLend(res?.hash)
            setLoadingPur(false)
            Noti('error', "Unknown error (status hash fail)", 3000)
          }
        })
          .catch((err: any) => {
            console.log('err00', err)
            setstatusPurchase(!statusPurchase)
            sethashLend('')
            setLoadingPur(false)
            setIsModalOpen(false);
            Noti('error', err, 3000)
          })
      })
        .catch((err: any) => {
          console.log('err111', err)
          setstatusPurchase(!statusPurchase)
          sethashLend('')
          setLoadingPur(false)
          setIsModalOpen(false);
          Noti('error', err.reason || err?.message || err, 3000)
        })

    } catch (error) {
      console.log('error22', error)
      setstatusPurchase(!statusPurchase)
      sethashLend('')
      setLoadingPur(false)
      setIsModalOpen(false);
      Noti('error', error, 3000)
    }
  }

  const handleApprove = async () => {
    try {
      setLoadingApprove(true)
      if (selectedNetworkId !== WORK_CHAIN) {
        setLoadingApprove(false);
        Noti('error', "Wrong network", 3000)
        return
      }
      if (!scPTO) {
        Noti('error', "Can't reading contract address PTO", 3000)
        setLoadingApprove(false)
        return;
      }
      if (!contractTokenPTO) {
        Noti('error', "Can't reading contract token address", 3000)
        setLoadingApprove(false)
        return;
      }
      setIsModalOpenApprove(true);
      let amount = web3.utils.toWei((Number(valueAmount) * priceNFT).toString(), "picoether");
      await contractTokenPTO.approve(scPTO, amount).then((res: any) => {
        res.wait()
          .then((resWait: any) => {
            if (resWait?.status === 1) {
              let tem = web3.utils.fromWei(resWait?.logs[0]?.data.toString(), "picoether");
              if (Number(tem) < (Number(valueAmount) * priceNFT)) {
                Noti('warn', "Please increase the spending cap larger than the amount of tokens you want to purchase", 3000)
                setIsModalOpenApprove(false);
                setIsApprove(false)
                setLoadingApprove(false)
              } else {
                Noti('success', "Approved successfully", 3000)
                setIsModalOpenApprove(false);
                setIsApprove(true)
                setLoadingApprove(false)
              }
            } else {
              Noti('error', "Unknown error (status hash fail)", 3000)
              setIsModalOpenApprove(false);
              setIsApprove(false)
              setLoadingApprove(false)
            }
          })
          .catch((errWait: any) => {
            console.log('errWait', errWait)
            Noti('error', errWait, 3000)
            setIsModalOpenApprove(false);
            setIsApprove(false)
            setLoadingApprove(false)
          })
      })
        .catch((err: any) => {
          console.log('err', err)
          setIsModalOpenApprove(false);
          setIsApprove(false)
          setLoadingApprove(false)
          Noti('error', err?.reason || err?.message || "Unknown error", 3000)
        })

    } catch (error) {
      Noti('error', error, 3000)
      setLoadingApprove(false)
      setIsModalOpenApprove(false);
      setIsApprove(false)
    }
  }

  const setStatusPool = () => {
    if (timeNow < startTime) {
      setnumStt(STATUS_PTO.COMING)
    } else if (timeNow >= startTime && timeNow < endTime) {
      setnumStt(STATUS_PTO.OPEN)
    } else if (timeNow >= endTime && totalLoanAmountRaised >= (maxNFTSupply * priceNFT)) {
      setnumStt(STATUS_PTO.SOLD_OUT)
    } else if (timeNow >= endTime) {
      setnumStt(STATUS_PTO.END)
    }
  }


  useEffect(() => {
    setStatusPool()
  }, [startTime, totalLoanAmountRaised, endTime, maxNFTSupply])


  const renderNumTime = () => {
    let temp = isActiveRound?.toLowerCase()

    if (numStt === STATUS_PTO.OPEN) {
      if (temp === TEXT_RANK.UPCOMING) {
        return startTime
      } else if (temp === TEXT_RANK.SVIP) {
        return endTimeSvip
      } else if (temp === TEXT_RANK.WHALE) {
        return endTimeWhale
      } else if (temp === TEXT_RANK.SHARK) {
        return endTimeShark
      }
      else if (temp === TEXT_RANK.DOLPHIN) {
        return endTimeDolphin
      }
      else if (temp === TEXT_RANK.NEXT3_RANK || temp === "whale, dolphin, shark") {
        return endTimeShark
      }
      else if (temp === TEXT_RANK.NEXT32_RANK) {
        return endTimeShark
      }
      else if (temp === TEXT_RANK.NEXT21_RANK) {
        return endTimeShark
      }
      else if (temp === TEXT_RANK.COMMUNITY) {
        return endTimeCom
      }
      else return 0
    } else if (numStt === STATUS_PTO.COMING) {
      return startTime
    }
    else return 0
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    sethashLend("")
    setValueAmount('0')
  };


  const getAmountNftUserByAddress = async () => {
    try {
      await contractPTO.lenderTotalBalance(address)
        .then((res: any) => {
          let temp = Number(web3.utils.fromWei(res, "picoether"))
          setAmountNFTUser(Number(temp) / priceNFT)
        })
        .catch((err: any) => {
          setAmountNFTUser(0)
          console.log('err', err)
        })
    } catch (error) {
      setAmountNFTUser(0)
      console.log('error', error)
    }
  }

  useEffect(() => {
    if (address && WORK_CHAIN === selectedNetworkId && contractPTO) {
      getAmountNftUserByAddress()
    }
  }, [address, contractPTO, priceNFT, selectedNetworkId])




  const getContractDetails = async () => {
    try {
      let infoContract = await contractPTONotAddress?.methods?.getContractInfo().call()

      if (infoContract) {
        setPriceNFT(Number(web3.utils.fromWei(infoContract[0]?.nftPrice, "picoether")))
        setmaxNFTSupply(Number(infoContract[0]?.maxNFTSupply))
        settotalInvestPool(Number(web3.utils.fromWei(infoContract[2], "picoether")))
        setTotalLoanAmountRaised(Number(web3.utils.fromWei(infoContract[9], "picoether")))

        let result = caculaTimeLine(infoContract[7])


        setArrTimeline(result)
      }
    } catch (error) {
      console.log('error', error)
    }
  }


  useEffect(() => {
    if (contractPTONotAddress && scPTO) {
      getContractDetails()
    }
  }, [scPTO, statusPurchase])

  const [loadingRefund, setLoadingRefund]: any = useState(false)

  const handleRefund = async () => {
    try {
      setLoadingRefund(true)
      await contractPTO.refundLoan().then((res: any) => {
        saveLog(res.hash, 0, 11)
        res.wait().then((res1: any) => {
          if (res1?.status === 1) {
            setLoadingRefund(false)
            setAction("Refunded")
            setstatusPurchase(!statusPurchase)
            Noti('success', "Refund successfully", 3000)
          } else {
            setLoadingRefund(false)
            setstatusPurchase(!statusPurchase)
            Noti('error', "Unknown error (status hash fail)", 3000)
          }
        })
          .catch((err: any) => {
            setLoadingRefund(false)
            setstatusPurchase(!statusPurchase)
            Noti('error', err?.reason || err?.message || err, 3000)
          })
      })
        .catch((err: any) => {
          setLoadingRefund(false)
          setstatusPurchase(!statusPurchase)
          Noti('error', err?.reason || err?.message || err, 3000)
          console.log('err claim', err)
        })
    } catch (error: any) {
      setLoadingRefund(false)
      setstatusPurchase(!statusPurchase)
      Noti('error', error?.reason || error?.message || error, 3000)
      console.log('error claim', error)
    }
  }

  const [loadingClaim, setLoadingClaim]: any = useState(false)

  const claimNFT = async () => {
    try {
      setLoadingClaim(true)
      await contractPTO.claimNFTs().then(async (res: any) => {
        saveLog(res.hash, 0, 10)
        res.wait().then((res1: any) => {
          if (res1?.status === 1) {
            setLoadingClaim(false)
            setAction("Claimed")
            setstatusPurchase(!statusPurchase)
            Noti('success', "Claim successfully", 3000)
          } else {
            setLoadingClaim(false)
            setstatusPurchase(!statusPurchase)
            Noti('error', "Unknown error (status hash fail)", 3000)
          }
        })
          .catch((err: any) => {
            setLoadingClaim(false)
            setstatusPurchase(!statusPurchase)
            Noti('error', err?.reason || err?.message || err, 3000)
          })
      })
        .catch((err: any) => {
          setLoadingClaim(false)
          setstatusPurchase(!statusPurchase)
          Noti('error', err?.reason || err?.message || err, 3000)
          console.log('err claim', err)
        })
    } catch (error: any) {
      setLoadingClaim(false)
      setstatusPurchase(!statusPurchase)
      Noti('error', error?.reason || error?.message || error, 3000)
      console.log('error claim', error)
    }
  }

  const checkAmountStake = () => {
    let temp: any = arrTimeline
      .filter((e: any) => e.online === true
        && e.name !== TEXT_RANK.COMMUNITY
        && e.minStaked > 0)

    if (isActiveRound.toLowerCase() === TEXT_RANK.COMMUNITY) {
      setEnough(true)
    } else {
      if (temp.length > 0) {
        if (temp[temp.length - 1].minStaked > totalStakedFromSC) {
          setEnough(false)
        } else {
          setEnough(true)
        }
      } else {
        setEnough(true)
      }
    }
  }
  useEffect(() => {
    if (arrTimeline?.length > 0) {
      checkAmountStake()
    }
  }, [arrTimeline, isActiveRound, totalStakedFromSC])

  const getTotalClaimedNFT = async () => {
    try {
      await contractPTO.totalNFTsMinted().then((res: any) => {
        settotalClaimedNFT(Number(res))
      })
        .catch((err: any) => {
          settotalClaimedNFT(0)
          console.log('err', err)
        })
    } catch (error) {
      settotalClaimedNFT(0)
    }
  }

  useEffect(() => {
    if (contractPTO && (numStt === STATUS_PTO.END || numStt === STATUS_PTO.SOLD_OUT)) {
      getTotalClaimedNFT()
    } else {
      settotalClaimedNFT(0)
    }

  }, [contractPTO, numStt])

  let [com, setCom]: any = useState(0)
  let t: any = 0

  const renderDes = (text: any, num: any,
    decs: any, online: any, name: any,
    index: any, maxNFTsAllowed: any,
    nftsMinted: any, isActive: any) => {
    if (name.toLowerCase() !== TEXT_RANK.COMMUNITY && nftsMinted !== 0) {
      t = t + nftsMinted
    }
    setCom(t)

    if (online) {
      setNumSteps(index)
      setIsActiveRound(name)
      if (name.toLowerCase() !== TEXT_RANK.COMMUNITY) {
        setmaxBuyOnRound(maxNFTsAllowed)
      } else {
        setmaxBuyOnRound(t)
      }
      setnftMintedOnRound(nftsMinted)
    }

    return (
      <div className="dec-timeline-svip">
        <p>{text}</p>
        {maxNFTsAllowed !== 0 ? (
          <>
            {name.toLowerCase() === TEXT_RANK.COMMUNITY ? (
              <>
                {isActive ? (
                  <>
                    <div className="minted-nft">
                      <div className="minted-left">Total NFTs Round:</div>
                      <div className="minted-right">{nftsMinted || 0} / <span>{maxNFTSupply - com || 0}</span>
                      </div>
                    </div>
                  </>
                ) : ('')}

              </>

            ) : (
              <>
                <div className="minted-nft">
                  <div className="minted-left">Total NFTs Round:</div>
                  <div className="minted-right">{nftsMinted || 0} / <span>{maxNFTsAllowed || 0}</span></div>
                </div>
              </>

            )}
          </>

        ) : ('')}

        {online && num > 0 && num > totalStakedFromSC ? (
          <>
            {decs}
            <div className="btn-more-stake">
              <button onClick={goToStake} className="btn-stake-more">
                Stake Now
              </button>
            </div>
          </>
        ) : ('')}
      </div>
    )
  }


  const renderTimeStatus = (value: any, startTime: any, endTime: any) => {
    let valueLow = value.toLowerCase()
    switch (valueLow) {
      case TEXT_RANK.UPCOMING:
        setstartTime(endTime)
        return
      case TEXT_RANK.SVIP:
        setstartTimeSvip(startTime)
        setendTimeSvip(endTime)
        setendTime(endTime)
        return
      case TEXT_RANK.WHALE:
        setstartTimeWhale(startTime)
        setendTimeWhale(endTime)
        setendTime(endTime)
        return
      case TEXT_RANK.SHARK:
        setstartTimeShark(startTime)
        setendTimeShark(endTime)
        setendTime(endTime)
        return
      case TEXT_RANK.DOLPHIN:
        setstartTimeDolphin(startTime)
        setendTimeDolphin(endTime)
        setendTime(endTime)
        return
      case TEXT_RANK.NEXT3_RANK:
        setstartTimeDolphin(startTime)
        setendTimeDolphin(endTime)
        setstartTimeShark(startTime)
        setendTimeShark(endTime)
        setstartTimeWhale(startTime)
        setendTimeWhale(endTime)
        setendTime(endTime)
        return
      case 'whale, dolphin, shark':
        setstartTimeDolphin(startTime)
        setendTimeDolphin(endTime)
        setstartTimeShark(startTime)
        setendTimeShark(endTime)
        setstartTimeWhale(startTime)
        setendTimeWhale(endTime)
        setendTime(endTime)
        return
      case TEXT_RANK.NEXT32_RANK:
        setstartTimeShark(startTime)
        setendTimeShark(endTime)
        setstartTimeWhale(startTime)
        setendTimeWhale(endTime)
        setendTime(endTime)
        return
      case TEXT_RANK.NEXT21_RANK:
        setstartTimeDolphin(startTime)
        setendTimeDolphin(endTime)
        setstartTimeShark(startTime)
        setendTimeShark(endTime)
        setendTime(endTime)
        return

      case TEXT_RANK.COMMUNITY:
        setstartTimeCom(startTime)
        setendTimeCom(endTime)
        setendTime(endTime)
        return
      case TEXT_RANK.END:
        setstartTimeEnd(startTime)
        setendTimeEnd(endTime)
        return
      case TEXT_RANK.ACTION:
        setstartTimeClaim_Refund(startTime)
        setendTimeClaim_Refund(endTime)
        return
      default:
        break;
    }
  }

  const renderItemTimeLine = () => {
    const result = arrTimeline.reduce((acc: any, curr: any) => {
      const found = acc.find((item: any) => item.startTime === curr.startTime);
      if (found) {
        found.name += `, ${curr.name}`;
        found.maxNFTsAllowed += curr.maxNFTsAllowed;
        found.nftsMinted += curr.nftsMinted;
        found.minStaked = curr.minStaked;
      } else {
        acc.push({
          startTime: curr.startTime,
          name: curr.name,
          time_ui: curr.time_ui,
          minStaked: curr.minStaked,
          online: curr.online,
          desc: curr.desc,
          endTime: curr.endTime,
          isActive: curr.isActive,
          maxNFTsAllowed: curr.maxNFTsAllowed,
          nftsMinted: curr.nftsMinted
        });
      }
      return acc;
    }, []);

    for (let i = 0; i < result?.length; i++) {
      renderTimeStatus(result[i].name, result[i].startTime, result[i].endTime)
      let obj = {
        title: result[i].name,
        description: renderDes(
          result[i].time_ui,
          result[i].minStaked,
          result[i].desc,
          result[i].online,
          result[i].name,
          i,
          result[i].maxNFTsAllowed,
          result[i].nftsMinted,
          result[i].isActive
        )
      }
      arrItemTimeLine.push(obj)
    }
    setarrLine(arrItemTimeLine)
  }
  useEffect(() => {
    if (contractPTONotAddress && arrTimeline) {
      renderItemTimeLine()
    }
  }, [scPTO, dataDetail, isActiveRound, arrTimeline, enough, totalStakedFromSC])


  const getListHisSC = async () => {
    let temArrHis: any = []
    try {
      await contractPTO.getLenderHistory(address).then(async (res: any) => {
        let claimHis = res?.lenderClaimHistoryRecords
        let lendHis = res?.lenderLendHistoryRecords
        let refundHis = res?.lenderRefundHistoryRecords

        let amountClaimedTemp: any = 0
        for (let i = 0; i < claimHis?.length; i++) {
          amountClaimedTemp = amountClaimedTemp + Number(claimHis[i].amount)
          let obj = {
            type: 'Claim',
            value: `${formatNumber(Number(claimHis[i].amount) * priceNFT, 3)} USDC`,
            amount: `${formatNumber(Number(claimHis[i].amount), 3)} NFT`,
            date: convertDateToString(Number(claimHis[i].timestamp))
          }
          temArrHis.push(obj)
        }


        for (let i = 0; i < lendHis?.length; i++) {
          let obj = {
            type: 'Buy',
            value: `${formatNumber(Number(web3.utils.fromWei(lendHis[i].amount, "picoether")), 3)} USDC`,
            amount: `${formatNumber(Number(web3.utils.fromWei(lendHis[i].amount, "picoether")) / priceNFT, 3)} NFT`,
            date: convertDateToString(Number(lendHis[i].timestamp))
          }
          temArrHis.push(obj)
        }

        let amountRefundedTemp: any = 0
        for (let i = 0; i < refundHis?.length; i++) {
          amountRefundedTemp = amountRefundedTemp + Number(web3.utils.fromWei(refundHis[i].amount, "picoether"))
          let obj = {
            type: 'Refund',
            value: `${formatNumber(Number(web3.utils.fromWei(refundHis[i].amount, "picoether")), 3)} USDC`,
            amount: '--',
            date: convertDateToString(Number(refundHis[i].timestamp))
          }
          temArrHis.push(obj)
        }
        setAmountRefunded(amountRefundedTemp)
        setAmountClaimed(amountClaimedTemp)
        setarrHis(temArrHis)
      })
        .catch((err: any) => {
          setarrHis([])
          console.log('err', err)
        })
    } catch (error) {
      console.log('error', error)
      setarrHis([])
    }
  }

  useEffect(() => {
    if (contractPTO && address) {
      getListHisSC()
    } else {
      setarrHis([])
    }
  }, [contractPTO?.address, priceNFT, statusPurchase, address])

  const [amountNFTUserClaimed, setamountNFTUserClaimed]: any = useState(0)

  const getTotalClaimedNFTUser = async () => {
    try {
      await contractPTO.getLenderNFTIds(address).then((res: any) => {
        setamountNFTUserClaimed(res?.length)
      })
    } catch (error) {
      setamountNFTUserClaimed(0)
      console.log('error', error)
    }
  }

  useEffect(() => {
    if (address && contractPTO?.address && (numStt === STATUS_PTO.END || numStt === STATUS_PTO.SOLD_OUT)) {
      getTotalClaimedNFTUser()
    } else {
      setamountNFTUserClaimed(0)
    }
  }, [address, contractPTO?.address, numStt, statusPurchase])

  const [amountNFTUserBuyOnTier, setamountNFTUserBuyOnTier]: any = useState(0)
  const getamountNFTUserBuyOnTier = async () => {
    try {
      await contractPTO.lenderNFTsPurchased(address, iterID).then((res: any) => {
        setamountNFTUserBuyOnTier(Number(res))
      })
        .catch((err: any) => {
          console.log('err--', err)
        })
    } catch (error) {
      console.log('error--', error)
    }
  }

  useEffect(() => {
    if (address && contractPTO) {
      getamountNFTUserBuyOnTier()
    }
  }, [address, contractPTO, iterID])


  const [action, setAction]: any = useState('')
  const checkIsAction = async () => {
    try {
      await contractPTO.getLenderStatus(address).then((res: any) => {
        setAction(res)
      })
        .catch((err: any) => {
          console.log('err LenderStatus', err)
        })
    } catch (error) {
      console.log('error LenderStatus', error)
    }
  }

  useEffect(() => {
    if (address && contractPTO?.address) {
      checkIsAction()
    }
  }, [address, contractPTO?.address, numStt])

  let percent = (totalLoanAmountRaised / (maxNFTSupply * priceNFT)) * 100


  return (
    <>
      <div className="section-grow detail-pto font-radioCanada">
        {isMobile ? (
          <div className="breadcrum-line">
            <div className="item-bread">
              <Link to="/apps/pto">
                <img src="/images/menu/pto.png" alt="" />{" "}
                <span>PTO</span>
              </Link>
            </div>
            <div className="item-bread">
              <div className="vector-r">
                <img src="/images/vector-s.png" alt="" />
              </div>
            </div>
            <div className="item-bread active">
              <span>PTO Details</span>
            </div>
          </div>
        ) : ('')}
        <div className="grow-wrap">
          {dataDetail ? (
            <>
              <div className="wrap-left">
                <div className="left-details">
                  <div className="small-status">
                    <div className={renderClassStatus(numStt)} >
                      {renderStatus(numStt)}
                    </div>
                    {renderNumTimeAll(numStt, startTime, endTime) !== 0 ? (
                      <div className="cound-down-all" >
                        <img src="/images/d-all.png" alt="" />
                        <Countdown
                          date={renderNumTimeAll(numStt, startTime, endTime)}
                          renderer={rendererCountDownDetailAll}
                        />
                      </div>
                    ) : ('')}

                  </div>
                  {dataDetail?.thumbnails?.length > 0 ? (
                    <Carousel effect="fade" arrows infinite={true}>
                      {dataDetail?.thumbnails.map((item: any, index: any) => (
                        <div className="box-img">
                          <img src={item} alt="" />
                        </div>
                      ))}
                    </Carousel>
                  ) : ('')}
                  <div className="list-box-detail-pto">
                    <div className="content-box-info-pto">
                      {isMobile ? (
                        <>
                          <div className="content-box-info-pto-mo">
                            <div className="content-box-pto-item">
                              <div className="title-box-pto">
                                Funding Target
                              </div>
                              <div className="num-box-pto">
                                ${formatNumber(maxNFTSupply * priceNFT, 3)} USDC
                              </div>
                            </div>


                            <div className="content-box-pto-item">
                              <div className="title-box-pto">
                                NFTs Issued
                              </div>
                              <div className="num-box-pto">
                                {formatNumber(maxNFTSupply, 3)}
                              </div>
                            </div>
                          </div>
                          <div className="content-box-info-pto-mo">
                            <div className="content-box-pto-item">
                              <div className="title-box-pto">
                                Total NFTs Purchased
                              </div>
                              <div className="num-box-pto">
                                {formatNumber(totalLoanAmountRaised / priceNFT, 3)}
                              </div>
                            </div>

                            <div className="content-box-pto-item">
                              <div className="title-box-pto">
                                Total Claimed NFTs
                              </div>
                              <div className="num-box-pto">
                                {formatNumber(totalClaimedNFT, 3)}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="content-box-pto-item">
                            <div className="title-box-pto">
                              Funding Target
                            </div>
                            <div className="num-box-pto">
                              ${formatNumber(maxNFTSupply * priceNFT, 3)} USDC
                            </div>
                          </div>
                          <div className="line-height-box"></div>

                          <div className="content-box-pto-item">
                            <div className="title-box-pto">
                              NFTs Issued
                            </div>
                            <div className="num-box-pto">
                              {formatNumber(maxNFTSupply, 3)}
                            </div>
                          </div>
                          <div className="line-height-box"></div>
                          <div className="content-box-pto-item">
                            <div className="title-box-pto">
                              Total NFTs Purchased
                            </div>
                            <div className="num-box-pto">
                              {formatNumber(totalLoanAmountRaised / priceNFT, 3)}
                            </div>
                          </div>
                          <div className="line-height-box"></div>
                          <div className="content-box-pto-item">
                            <div className="title-box-pto">
                              Total Claimed NFTs
                            </div>
                            <div className="num-box-pto">
                              {formatNumber(totalClaimedNFT, 3)}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {isMobile ? ('') : (
                    <>
                      <div className="tabs-list">
                        <Tabs defaultActiveKey="1">
                          <Tabs.TabPane tab={tabProperty} key="1">
                            <PropertyDetailTab data={dataDetail?.properties} des={dataDetail?.description} />
                          </Tabs.TabPane>
                          <Tabs.TabPane tab={tabNFTDetail} key="2">
                            <NFTDetailTab data={dataDetail?.tokenDetails} />
                          </Tabs.TabPane>
                          <Tabs.TabPane tab={tabTimeLine} key="3">
                            <TimeLineTab data={dataDetail?.timeLineDoc} />
                          </Tabs.TabPane>
                          <Tabs.TabPane tab={tabDocument} key="4">
                            <DocumentDetail documentDetail={dataDetail?.documentDetail} />
                          </Tabs.TabPane>
                          <Tabs.TabPane tab={tabMarket} key="5">
                            <Market code={dataDetail?.code} />
                          </Tabs.TabPane>
                          <Tabs.TabPane tab={tabMap} key="6">
                            <Map data={dataDetail?.geographicCoordinateSystem} />
                          </Tabs.TabPane>
                        </Tabs>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="wrap-right">
                <div className="content-project">
                  <div className="location-page">
                    <div className={renderClassStatus(numStt)}>
                      {renderStatus(numStt)}
                    </div>
                    <div className="status-detail new">
                      Project Gross Yield {dataDetail?.projectedGrossYield}
                    </div>
                  </div>
                  <div className="name-detail">{dataDetail?.name}</div>
                  <div className="funding-target">
                    <div className="time-countdown">
                      <div className="time-l">
                        <img src="/images/lending/clock.png" alt="" />
                        {renderTextTime(numStt, isActiveRound)}
                      </div>
                      <div className="time-r">
                        {renderNumTime() !== 0 ? (
                          <Countdown
                            date={renderNumTime()}
                            // date={1727670627000}
                            renderer={rendererCountDownDetail}
                          />
                        ) : ('')}
                      </div>
                    </div>
                  </div>
                  <div className="funding-target">
                    <div className="funding-row">
                      <div className="name name-cus">
                        NFTs Available:
                        <span className="number number-cus">
                          Funding Target:
                        </span>
                      </div>
                      <div className="name-cus1">
                        {formatNumber(maxNFTSupply - (totalLoanAmountRaised / priceNFT) > 0 ? (maxNFTSupply - (totalLoanAmountRaised / priceNFT)) : (0), 3)}
                        <span className="number">
                          <span className="target1">
                            ${formatNumber((totalLoanAmountRaised), 3)}{" "}
                          </span>
                          <span className="target2">
                            /${formatNumber(maxNFTSupply * priceNFT, 3)}
                          </span>
                        </span>
                      </div>
                      <div className="bottom-process">
                        <Progress
                          strokeColor={{ from: '#108ee9', to: '#87d068' }}
                          percent={Number(percent.toFixed(2)) || 0}
                          status="active" />
                      </div>
                    </div>
                  </div>

                  {address ? (
                    <>
                      {numStt === STATUS_PTO.COMING ? (
                        <>
                          <div className="group-button">
                            <div className="btn-actions-pto">
                              UP COMING
                            </div>
                          </div>
                        </>
                      ) : ('')}

                      {numStt === STATUS_PTO.OPEN ? (
                        <>
                          <div className="row-purchase">
                            <div className="lends-page">
                              <div className="top">
                                <div className="left-lend">
                                  <div className="text">Price</div>
                                </div>
                                <div className="left-lend">
                                  <div className="balance-wallet">
                                    <div className="text">Quantity</div>
                                  </div>
                                </div>
                              </div>
                              <div className="bottom">
                                <div className="currentcy">
                                  {formatNumber(priceNFT, 2)} USDC
                                </div>
                                <div className="value-current">
                                  <div className="value-top">
                                    <input
                                      value={valueAmount}
                                      onChange={handleAmount}
                                      type="text"
                                    />
                                    <div onClick={handleClickPlusAmount} className="plus">
                                      +
                                    </div>
                                    <div
                                      onClick={handleClickMinusAmount}
                                      className="no-plus"
                                    >
                                      -
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                            <div className="wrap-before-btn">
                              <div className="investment-period">
                                <div className="title">Max Buy:</div>
                                <div className="flex gap-x-1 items-center">
                                  <div className="text-white text-[16px] font-medium">
                                    {isActiveRound.toLowerCase() === TEXT_RANK.COMMUNITY ? (
                                      <>
                                        {formatNumber(maxNFTSupply - (totalLoanAmountRaised / priceNFT), 3)} NFTs
                                        {/* {formatNumber(maxBuyOnRound - nftMintedOnRound, 3)} NFTs */}
                                      </>
                                    ) : (
                                      <>
                                        {maxBuyOnRound - nftMintedOnRound <= 0 ? (
                                          <>
                                            0 NFT
                                          </>
                                        ) : (
                                          <>
                                            {/* {formatNumber(maxBuyTier, 3)} NFTs */}
                                            {(maxNFTSupply - (totalLoanAmountRaised / priceNFT)) < (maxBuyTier - amountNFTUserBuyOnTier) ? (
                                              <>
                                                {formatNumber(maxNFTSupply - (totalLoanAmountRaised / priceNFT), 3)} NFTs
                                              </>
                                            ) : (
                                              <>
                                                {formatNumber((maxBuyTier - amountNFTUserBuyOnTier < 0 ? 0 : maxBuyTier - amountNFTUserBuyOnTier), 3)} NFTs
                                              </>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="investment-period">
                                <div className="title">Balance:</div>
                                <div className="flex gap-x-1 items-center">
                                  <div className="text-white text-[16px] font-medium">
                                    {formatNumber(amountTokenPTO, 3)}
                                  </div>
                                  <img alt="" src="/images/lending/usd-coin.png" className="usdc-child" />
                                </div>
                              </div>

                            </div>

                            <div className="group-button">
                              <>
                                {dataDetail?.userInfo?.isKyc ? (
                                  <>
                                    {enough ? (
                                      <>
                                        {
                                          !isActiveRound.toLowerCase().includes(curentTier.toLowerCase()) &&
                                            isActiveRound.toLowerCase() !== TEXT_RANK.COMMUNITY ? (
                                            <>
                                              <button className="btn-actions">
                                                Your tier doesn't match
                                              </button>
                                            </>
                                          ) : (
                                            <>
                                              {Number(valueAmount) <= 0 ? (
                                                <div
                                                  {...btnPurchasedDis()}
                                                >
                                                  Purchase
                                                </div>
                                              ) : (
                                                <>
                                                  {isActiveRound.toLowerCase() === TEXT_RANK.COMMUNITY ? (
                                                    <>
                                                      {/* {(Number(valueAmount) + nftMintedOnRound) > maxBuyOnRound ? ( */}

                                                      {(Number(valueAmount)) > (maxNFTSupply - (totalLoanAmountRaised / priceNFT)) ? (

                                                        <>
                                                          <div
                                                            {...btnPurchasedDis()}
                                                          >
                                                            Purchase limit reached
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <>
                                                          {(Number(priceNFT) * Number(valueAmount)) > Number(amountTokenPTO) ? (
                                                            <div
                                                              {...btnPurchased()}
                                                            >
                                                              Insufficient balance
                                                            </div>
                                                          ) : (
                                                            <>
                                                              {isApprove ? (
                                                                <div
                                                                  className="btn-actions-pto"
                                                                  onClick={showModal}
                                                                >
                                                                  Purchased
                                                                </div>
                                                              ) : (
                                                                <>
                                                                  {loadingApprove ? (
                                                                    <div
                                                                      {...btnApprove()}
                                                                    >
                                                                      Waiting{" "}
                                                                      <div className="loader"></div>
                                                                    </div>
                                                                  ) : (
                                                                    <div
                                                                      {...btnApprove()}
                                                                      onClick={handleApprove}
                                                                    >
                                                                      Approve
                                                                    </div>
                                                                  )}
                                                                </>
                                                              )}
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      {
                                                        (amountNFTUserBuyOnTier + Number(valueAmount)) > maxBuyTier ||
                                                          (Number(valueAmount) + nftMintedOnRound) > maxBuyOnRound ? (
                                                          <>
                                                            <div
                                                              {...btnPurchasedDis()}
                                                            >
                                                              Purchase limit reached
                                                            </div>
                                                          </>
                                                        ) : (
                                                          <>
                                                            {(Number(priceNFT) * Number(valueAmount)) > Number(amountTokenPTO) ? (
                                                              <div
                                                                {...btnPurchased()}
                                                              >
                                                                Insufficient balance
                                                              </div>
                                                            ) : (
                                                              <>
                                                                {isApprove ? (
                                                                  <div
                                                                    className="btn-actions-pto"
                                                                    onClick={showModal}
                                                                  >
                                                                    Purchased
                                                                  </div>
                                                                ) : (
                                                                  <>
                                                                    {loadingApprove ? (
                                                                      <div
                                                                        {...btnApprove()}
                                                                      >
                                                                        Waiting{" "}
                                                                        <div className="loader"></div>
                                                                      </div>
                                                                    ) : (
                                                                      <div
                                                                        {...btnApprove()}
                                                                        onClick={handleApprove}
                                                                      >
                                                                        Approve
                                                                      </div>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </>
                                                            )}
                                                          </>
                                                        )
                                                      }
                                                    </>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          )}
                                      </>
                                    ) : (
                                      <button className="btn-actions" onClick={goToStake}>
                                        {/* Go to Stake */}
                                        Your tier doesn't match
                                      </button>
                                    )}
                                  </>
                                ) : (
                                  <button className="btn-actions" onClick={goToKYC}>
                                    Start KYC
                                  </button>
                                )}
                              </>
                            </div>
                            <div className="investment-period">
                              <div className="title title-while">You Pay</div>
                              <div className="month">
                                {formatNumber((Number(priceNFT) * Number(valueAmount)), 3)} USDC
                                {/* <span>~$--</span> */}
                              </div>
                            </div>
                          </div>

                        </>
                      ) : ('')}

                      {numStt === STATUS_PTO.END || numStt === STATUS_PTO.SOLD_OUT ? (
                        <>
                          <div className="stt-complete">
                            <div className="stt-com-img">
                              <img src="/images/bg-end.png" alt="" />
                            </div>
                            <div className="stt-com-text">
                              <div className="stt-com-text1">{dataDetail?.name}</div>
                              {(totalLoanAmountRaised / priceNFT) >= maxNFTSupply ? (
                                <div>
                                  Sold Out - Thank You For Your Support!
                                </div>
                              ) : (
                                <div>
                                  Partially filled - Thank You For Your Support!
                                </div>
                              )}

                            </div>
                          </div>
                          {arrHis.length > 0 ? (
                            <>
                              <div className="group-claim">
                                {Date.now() >= endTimeEnd ? (
                                  <>
                                    {action === 'Claimable' ? (
                                      <>
                                        <div className="row-claim">
                                          <div className="title-claim">Claimable NFTs</div>
                                          <div className="num-claim">{formatNumber(amountNFTUser, 3)} <span>NFT</span></div>
                                        </div>
                                        {loadingClaim ? (
                                          <div
                                            {...btnClaimDis()}
                                          >
                                            <div className="loader"></div>
                                          </div>
                                        ) : (
                                          <>
                                            <div
                                              {...btnClaim()}
                                              onClick={claimNFT}>
                                              Claim
                                            </div>
                                          </>
                                        )}
                                      </>
                                    ) : ('')}

                                    {action === 'Claimed' ? (
                                      <>
                                        <div className="row-claim">
                                          <div className="num-claim">{formatNumber(amountNFTUserClaimed, 3)} <span>NFT</span></div>
                                        </div>
                                        <div
                                          {...btnClaimed()}
                                        >
                                          Claimed
                                        </div>
                                      </>
                                    ) : ('')}

                                    {action === 'Refundable' ? (
                                      <>
                                        <div className="row-claim">
                                          <div className="title-claim">Total Investments</div>
                                          <div className="num-claim">{formatNumber(amountNFTUser * priceNFT, 3)} <span>USDC</span></div>
                                        </div>
                                        {loadingRefund ? (
                                          <div
                                            {...btnClaimDis()}
                                          >
                                            <div className="loader"></div>
                                          </div>
                                        ) : (
                                          <>
                                            <div
                                              {...btnClaim()}
                                              onClick={handleRefund}>
                                              Refund
                                            </div>
                                          </>
                                        )}
                                      </>
                                    ) : ('')}

                                    {action === 'Refunded' ? (
                                      <>
                                        <div className="row-claim">
                                          <div className="num-claim">{formatNumber(amountRefunded, 3)} USDC</div>
                                        </div>
                                        <div
                                          {...btnClaimed()}
                                        >
                                          Refunded
                                        </div>
                                      </>
                                    ) : ('')}

                                    {action === 'Waiting' ? (
                                      <>
                                        <div className="row-claim">
                                          <div className="title-claim">Waiting...</div>
                                        </div>
                                      </>
                                    ) : ('')}
                                  </>
                                ) : (
                                  <>
                                    {(totalLoanAmountRaised / priceNFT) >= maxNFTSupply ? (
                                      <>
                                        <div className="coundown-claim">
                                          <div className="coundown-claim1">
                                            <div className="coundown-claim3">
                                              Time To Claim
                                            </div>
                                            <div className="coundown-claim4">
                                              <Countdown
                                                date={endTimeEnd + 10 * 1000}
                                                renderer={rendererCountDownDetailNew}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="coundown-claim">
                                          <div className="coundown-claim1">
                                            <div className="coundown-claim3">
                                              Time To Refund
                                            </div>
                                            <div className="coundown-claim4">
                                              <Countdown
                                                date={endTimeEnd + 10 * 1000}
                                                renderer={rendererCountDownDetailNew}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}

                              </div>
                            </>
                          ) : ('')}

                        </>
                      ) : ('')}
                    </>
                  ) : (
                    <button className="btn-actions-pto" onClick={handleConnect}>
                      Connect Wallet
                    </button>
                  )}

                  {arrHis.length > 0 ? (
                    <>
                      <div className="collapse-faq cus-his">
                        <Collapse accordion>
                          <Panel header="History" key="1">
                            <div className="history-purchase his-cus">
                              <div className="top-pur">
                                <div className="item">Type</div>
                                <div className="item">Amount</div>
                                <div className="item">Value</div>
                                <div className="item">Date</div>
                              </div>
                              {/* <div className="no-data-page">
                            <div className="box-img">
                              <img className='img-empty' src="/images/empty1.png" alt="" />
                            </div>
                          </div> */}
                              <div className="bottom-pur">

                                {arrHis.length > 0 ? (
                                  <>
                                    {arrHis.map((item: any, index: any) => (
                                      <div className="row-tsx">
                                        <div className="item">{item.type}</div>
                                        <div className="item">{item.amount}</div>
                                        <div className="item">{item.value}</div>
                                        <div className="item">{item.date}</div>
                                      </div>
                                    ))}
                                  </>
                                ) : ('')}
                              </div>
                            </div>
                          </Panel>

                        </Collapse>
                      </div>
                    </>
                  ) : ('')}

                  <div className="collapse-faq cus-his cus-time">
                    <Collapse>
                      <Panel header="Timeline" key="2">
                        <div className="time-line-pto">
                          <Steps
                            progressDot
                            current={numSteps}
                            direction="vertical"
                            className="timeline-detail-pto"
                            items={arrLine}
                          />
                        </div>
                      </Panel>
                    </Collapse>
                  </div>
                </div>
              </div>
              {!isMobile ? ('') : (
                <>
                  <div className="wrap-left">
                    <div className="left-details">
                      <div className="tabs-list">
                        <Tabs defaultActiveKey="1">
                          <Tabs.TabPane tab={tabProperty} key="1">
                            <PropertyDetailTab data={dataDetail?.properties} des={dataDetail?.description} />
                          </Tabs.TabPane>
                          <Tabs.TabPane tab={tabNFTDetail} key="2">
                            <NFTDetailTab data={dataDetail?.tokenDetails} />
                          </Tabs.TabPane>
                          <Tabs.TabPane tab={tabTimeLine} key="3">
                            <TimeLineTab data={dataDetail?.timeLineDoc} />
                          </Tabs.TabPane>
                          <Tabs.TabPane tab={tabDocument} key="4">
                            <DocumentDetail documentDetail={dataDetail?.documentDetail} />
                          </Tabs.TabPane>
                          <Tabs.TabPane tab={tabMarket} key="5">
                            <Market code={dataDetail?.code} />
                          </Tabs.TabPane>
                          <Tabs.TabPane tab={tabMap} key="6">
                            <Map data={dataDetail?.geographicCoordinateSystem} />
                          </Tabs.TabPane>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <div className="box-no-data-pto">
              <img className='img-empty' src="/images/empty1.png" alt="" />
            </div>
          )}
        </div>

        <Modal
          title="Approving"
          open={isModalOpenApprove}
          footer={false}
          wrapClassName="modal-confirm"
        >
          <p className="spin-stake">
            <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 60 }} spin />} />
          </p>
          <p className="modal-text1">Your request is being processed. Please wait...</p>
        </Modal>

        <Modal className="modal-purchase" maskClosable={false} title="Confirm Your Purchase" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          <div className="confirm-box">
            {hashLend !== "" ?
              <>
                <div className="box-img">
                  <img src="/images/lending/hash-success.png" alt="" />
                </div>
                <div className="title res-title">
                  Congrats! Your purchase is successful!
                </div>
              </>
              :
              <>
                <div className="box-img">
                  <img src="/images/lending/pur.png" alt="" />
                </div>
                <div className="title">
                  Confirm your purchase
                </div>
              </>
            }
            <div className="guide-confirm-project">
              <div className="name-pr">
                {dataDetail?.name}
              </div>
              <div className="item">
                <div className="txt-l">
                  Property Token Value:
                </div>
                <div className="txt-r">
                  ${formatNumber(priceNFT, 3)} USDC
                </div>
              </div>
              <div className="item">
                <div className="txt-l">
                  Your Bought:
                </div>
                <div className="txt-r">
                  {Number(valueAmount)} NFT
                </div>
              </div>
              <div className="item">
                <div className="txt-l">
                  Total value:
                </div>
                <div className="txt-r">
                  ${formatNumber((Number(priceNFT) * Number(valueAmount)), 3)} USDC
                </div>
              </div>
            </div>
            {hashLend !== "" ?
              <>
                <div className="box-hash-modal">
                  <div className="tsx-l">
                    Transaction Hash:
                  </div>
                  <div className="tsx-r">
                    <a className="mid-content3" target="_blank" rel="noreferrer" href={`${BASE_SCAN}/tx/${hashLend}`}>{hashLend?.slice(0, 4)}...{hashLend?.slice(-4)}</a>
                    <CopyToClipboard
                      text={`${hashLend}`}
                      onCopy={() => {
                        Noti('success', "Copied success", 3000)
                      }}
                    >
                      <img src="/images/user/copy-row.png" alt="" />
                    </CopyToClipboard>
                  </div>
                </div>
              </>
              :
              <>
                {""}
              </>
            }
            <div className="group-button">
              {hashLend !== "" ?
                <>
                  <button className="btn-actions" onClick={handleCancel}>
                    OK
                  </button>
                </>
                :
                <>
                  {(Number(priceNFT) * Number(valueAmount)) > Number(amountTokenPTO) ? (
                    <div
                      {...btnPurchased()}
                    >
                      Insufficient balance
                    </div>
                  ) : (
                    <>
                      {loadingPur ? (
                        <div
                          {...btnPurchased()}
                        >
                          Waiting{" "}
                          <div className="loader"></div>
                        </div>
                      ) : (
                        <div
                          {...btnPurchased()}
                          onClick={handlePurchased}
                        >
                          Purchased
                        </div>
                      )}
                    </>
                  )}
                </>
              }
            </div>
          </div>
        </Modal>
        <Faqs />
      </div>
    </>
  );
};
export default DetailsPto;
