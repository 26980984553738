/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { rendererCountDown, rendererCountDown2 } from "./utils/render-countdown";
import abiStake from "./utils/abiStake.json"
import web3 from "web3";
import { useNavigate } from "react-router-dom";
import {
    Modal,
    Spin,
    Tooltip
} from "antd";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { useHookStaked } from '../storeStaked'
import { useAccount } from "wagmi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import { useContract } from "../../../../hooks/useContract";
import Value from "../../../../components/Value";
import { calculateTimeRemaining, formatNumber } from "./utils";
import { BASE_SCAN, NUM_FORMAT, RPC_BASE, WORK_CHAIN } from "../../../../constants";
import { Noti } from "../../../../components/Noti/toast";
import { AbiItem } from "web3-utils";

const w3: any = new web3(RPC_BASE);

// const Action = React.memo((props: any) => {
const Action = React.memo(({ time, isSuccess, parentCallback, parentCallback1, stakeSc, tokenStake }: any) => {

    const { selectedNetworkId }: any = useWeb3ModalState();
    let scStake = stakeSc
    let endTime = time

    const { address } = useAccount();
    const [state, actions] = useHookStaked()
    const contractStake: any = useContract(scStake, abiStake);
    const [getUser, setGetUser] = useState<any>();
    const [timeUnstake, setTimeUnstake] = useState<any>();
    const [amountBscsPool, setAmountBscsPool] = useState<any>(0);
    const [amountReward, setAmountReward] = useState<any>(0);
    const [loadingStake, setloadingStake]: any = useState(false)
    const [loadingWithdraw, setloadingWithdraw]: any = useState(false)
    const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;
    const [isAction, setIsAction]: any = useState(false)
    const [isAction1, setIsAction1]: any = useState(false)
    const [timeLock, settimeLock]: any = useState(0)
    const [stakeTime, setstakeTime]: any = useState(0)
    const [lockTimeUnstake, setlockTimeUnstake]: any = useState(0)

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenW, setIsModalOpenW] = useState(false);

    const contractStakeNotAddress = new w3.eth.Contract(
        abiStake as unknown as AbiItem,
        scStake,
    );

    const sendData = (value: any) => {
        parentCallback(value)
        // props.parentCallback(value);
    };
    const sendData1 = (value: any) => {
        parentCallback1(value)
        //props.parentCallback1(value);
    };

    useEffect(() => {
        sendData1({ stakeTime, timeLock })
    }, [stakeTime, timeLock])


    const getBscsPool = async () => {
        if (contractStake) {
            await contractStake.balanceOf(address).then((amount: any) => {
                let balance: any = web3.utils.fromWei(amount, "ether");
                setAmountBscsPool(Number(balance));

            })
                .catch((err: any) => {
                    console.log('err getBscsPool', err)
                    setAmountBscsPool(0)
                })

        }
    };

    console.log('re-render')

    useEffect(() => {
        if (address && selectedNetworkId === WORK_CHAIN && contractStake?.address) {
            getBscsPool();
        } else {
            setAmountBscsPool(0)
        }
    }, [address, selectedNetworkId, isSuccess, isAction, contractStake?.address]);
    // }, [address, selectedNetworkId, props.isSuccess, props.tabBox, isAction, contractStake?.address]);


    const getRewardAmount = async () => {
        try {
            await contractStake.getEarnedRewardTokens(address).then((amountRW: any) => {
                setAmountReward(amountRW / 1e18 || 0)

            })
                .catch((err: any) => {
                    setAmountReward(0)
                })
        } catch (error) {
            setAmountReward(0)
        }
    }

    //------------


    //------------

    const getUserMap = async () => {
        if (contractStake && address && selectedNetworkId === WORK_CHAIN) {
            await contractStake.userMap(address).then((user: any) => {
                if (user) {
                    let balance: any = web3.utils.fromWei(user?.inUnStakeAmount, "ether");
                    setGetUser(Number(balance));
                    setTimeUnstake(Number(user?.unstakeTime))
                    setstakeTime(Number(user?.stakeTimeFee))
                }
            })
                .catch((err: any) => {
                    console.log('err userMap', err)
                })
        }
    };

    const getTimeLock = async () => {
        if (contractStake && address) {

            await contractStake.stakeLockPeriod().then((time: any) => {
                settimeLock(Number(time))
            })
            // await contractStake.stakeTimeFee(address).then((time1: any) => {
            //     setstakeTime(Number(time1))
            // })
            await contractStake.lockTimePeriod().then((time2: any) => {
                setlockTimeUnstake(Number(time2))
            })

        }
    }


    useEffect(() => {
        if (address && selectedNetworkId === WORK_CHAIN) {
            getUserMap();
            getTimeLock();
            getRewardAmount();
        } else {
            setGetUser(0)
            setTimeUnstake(0)
            setAmountReward(0)
        }
    }, [address, selectedNetworkId, isSuccess, isAction, contractStake?.address]);
    // }, [address, selectedNetworkId, props.isSuccess, props.tabBox, isAction, contractStake?.address]);

    // useEffect(() => {
    //     if (Number(amountBscsPool) > 0) {
    //         const intervalId = setInterval(() => getRewardAmount(), 10000);
    //         return () => {
    //             clearInterval(intervalId);
    //         };
    //     }


    // }, [address, amountBscsPool, scStake]);


    const saveLog = async (hash: any, amount: any, type: any) => {
        try {
            let params = {
                ownerAddress: address,
                contractAddress: scStake,
                txHash: hash,
                amount: amount,
                type: type,
                assetToken: 'Mey',
            }
            await actions.addHistory(params)
        } catch (error) {

        }
    }

    const handleStakeReward = async () => {
        try {

            setloadingStake(true)
            setIsModalOpen(true)
            if (selectedNetworkId !== WORK_CHAIN) {
                setloadingStake(false);
                setIsModalOpen(false)
                Noti('error', "Wrong network", 3000)
                return
            }
            await contractStake.stakeRewards().then(async (res: any) => {
                saveLog(res?.hash, amountReward, 3)
                // const timestamp = new Date().getTime();
                // const date1 = new Date(timestamp);
                // let timeStake = date1.toLocaleString('en-GB')
                // let paramNoti = {
                //     projectName: "BSCS",
                //     amount: amountReward,
                //     actionType: 1,
                //     userAddress: address,
                //     tokenName: "BSCS",
                //     actionTime: timeStake,
                //     hash: res?.hash,
                //     status: "Waiting"
                // }
                // await actions.callNoti(paramNoti)

                // let params = {
                //     ownerAddress: address,
                //     poolContract: scStake
                // }
                // let resapi = await actions.updateStaked(params)
                // if (resapi.data.data) {
                res.wait()
                    .then(async (resWait: any) => {
                        if (resWait?.status === 1) {
                            setloadingStake(false)
                            setIsModalOpen(false)
                            setIsAction(!isAction)
                            // setIsAction1(!isAction1)
                            sendData(!isAction1)

                            // const timestamp = new Date().getTime();
                            // const date1 = new Date(timestamp);
                            // let timeStake = date1.toLocaleString('en-GB')
                            // let paramNoti = {
                            //     projectName: "BSCS",
                            //     amount: amountReward,
                            //     actionType: 1,
                            //     userAddress: address,
                            //     tokenName: "BSCS",
                            //     actionTime: timeStake,
                            //     hash: resWait?.transactionHash,
                            //     status: "Success"
                            // }
                            // await actions.callNoti(paramNoti)

                            Noti('success', "Stake rewards successfully", 3000)
                        } else {
                            // const timestamp = new Date().getTime();
                            // const date1 = new Date(timestamp);
                            // let timeStake = date1.toLocaleString('en-GB')
                            // let paramNoti = {
                            //     projectName: "BSCS",
                            //     amount: amountReward,
                            //     actionType: 1,
                            //     userAddress: address,
                            //     tokenName: "BSCS",
                            //     actionTime: timeStake,
                            //     hash: resWait?.transactionHash,
                            //     status: "Status hash fail"
                            // }
                            // await actions.callNoti(paramNoti)

                            Noti('error', "Unknown error (status hash fail)", 3000)
                            setloadingStake(false)
                            setIsModalOpen(false)
                        }
                    }
                    )
                    .catch((err: any) => {
                        console.log('err', err)
                        Noti('error', "Unknown error", 3000)
                        setloadingStake(false)
                        setIsModalOpen(false)
                    })
                // } else {
                //     message.error({
                //         type: "error",
                //         content: "API update status stake fail",
                //         className: "custom-class",
                //         duration: 3,
                //     });
                //     setloadingStake(false)
                // }
            })
                .catch((err: any) => {
                    Noti('error', err.reason || err?.message, 3000)
                    setloadingStake(false)
                    setIsModalOpen(false)
                })
        } catch (error: any) {
            console.log('error stakeRewards', error)
            Noti('error', error, 3000)
            setloadingStake(false)
            setIsModalOpen(false)
        }
    }

    const handleWithdrawReward = async () => {
        try {
            setloadingWithdraw(true)
            setIsModalOpenW(true)
            if (selectedNetworkId !== WORK_CHAIN) {
                setloadingWithdraw(false);
                setIsModalOpenW(false)
                Noti('error', "Wrong network", 3000)
                return
            }
            await contractStake.claim().then(async (res: any) => {
                saveLog(res?.hash, amountReward, 4)
                // if (Number(amountReward) > 50000) {
                //     const timestamp = new Date().getTime();
                //     const date1 = new Date(timestamp);
                //     let timeStake = date1.toLocaleString('en-GB')
                //     let paramNoti = {
                //         projectName: "BSCS",
                //         amount: amountReward,
                //         actionType: 2,
                //         userAddress: address,
                //         tokenName: "BSCS",
                //         actionTime: timeStake,
                //         hash: res?.hash,
                //         status: "Waiting"
                //     }
                //     await actions.callNoti(paramNoti)
                // }
                res.wait()
                    .then(async (resWait: any) => {
                        if (resWait?.status === 1) {
                            setloadingWithdraw(false)
                            setIsModalOpenW(false)
                            setIsAction(!isAction)
                            // setIsAction1(!isAction1)
                            sendData(!isAction1)
                            // if (Number(amountReward) > 50000) {
                            //     const timestamp = new Date().getTime();
                            //     const date1 = new Date(timestamp);
                            //     let timeStake = date1.toLocaleString('en-GB')
                            //     let paramNoti = {
                            //         projectName: "BSCS",
                            //         amount: amountReward,
                            //         actionType: 2,
                            //         userAddress: address,
                            //         tokenName: "BSCS",
                            //         actionTime: timeStake,
                            //         hash: resWait?.transactionHash,
                            //         status: "Success"
                            //     }
                            //     await actions.callNoti(paramNoti)
                            // }
                            Noti('success', "Withdraw rewards successfully", 3000)
                        } else {
                            // if (Number(amountReward) > 50000) {
                            //     const timestamp = new Date().getTime();
                            //     const date1 = new Date(timestamp);
                            //     let timeStake = date1.toLocaleString('en-GB')
                            //     let paramNoti = {
                            //         projectName: "BSCS",
                            //         amount: amountReward,
                            //         actionType: 2,
                            //         userAddress: address,
                            //         tokenName: "BSCS",
                            //         actionTime: timeStake,
                            //         hash: resWait?.transactionHash,
                            //         status: "Status hash fail"
                            //     }
                            //     await actions.callNoti(paramNoti)
                            // }

                            Noti('error', "Unknown error (status hash fail)", 3000)
                            setloadingWithdraw(false)
                            setIsModalOpenW(false)
                        }
                    }
                    )
                    .catch((err: any) => {
                        console.log('err', err)
                        Noti('error', "Unknown error", 3000)
                        setloadingWithdraw(false)
                        setIsModalOpenW(false)
                    })

            })
                .catch((err: any) => {
                    console.log('err ', err)
                    Noti('error', err.reason || err?.message, 3000)
                    setloadingWithdraw(false)
                    setIsModalOpenW(false)
                })
        } catch (error: any) {
            console.log('error ', error)
            Noti('error', error, 3000)
            setloadingWithdraw(false)
            setIsModalOpenW(false)
        }
    }

    function gotoSc() {
        window.open(`${BASE_SCAN}/address/${scStake}`)
    }
    const naigation = useNavigate()
    function gotoHis() {
        naigation(`/apps/history`)
        sessionStorage.setItem("keyActiveMenu", "8")
        window.location.reload()
    }


    const [timeRemaining, setTimeRemaining]: any = useState(0);
    const getTimeRemaining = async () => {
        try {
            await contractStakeNotAddress.methods
                .stakeRewardEndTime()
                .call()
                .then((res: any) => {
                    setTimeRemaining(Number(res) * 1000);
                })
                .catch((err: any) => {
                    setTimeRemaining(0);
                });
        } catch (error) {
            setTimeRemaining(0);
        }
    };

    useEffect(() => {
        if (contractStakeNotAddress?._address) {
            getTimeRemaining()
        }
    }, [contractStakeNotAddress?._address, selectedNetworkId, isSuccess, isAction])
    // }, [contractStakeNotAddress?._address, selectedNetworkId,isSuccess, props.tabBox, isAction])

    const textHint = `You will continue to earn rewards for the next ${calculateTimeRemaining(timeRemaining / 1000)}. After this time, you can unstake for free without paying any early withdrawal fees.`

    return (
        <>
            <div className="action-pool">
                <div className="your-stake acc">
                    <div className="wrap-action-rw">
                        <div className="logo-reward">
                            <img src="/images/staking/total-stake-img.png" alt="" />
                        </div>
                        <div className="ac-reward-right">
                            <div className="title">Rewards</div>
                            <div className="num">
                                {Number(amountReward) > 0.000001 ? (
                                    <Value unit="" decimals={6} value={amountReward} />
                                ) : (0)} <span>MEY</span>

                            </div>
                        </div>
                    </div>
                    <div className="action-button">
                        {/* {amountReward > 0.000001 && getUser <= 0 ? ( */}
                        {amountReward > 0.000001 ? (
                            <>
                                <div className="btn-action">
                                    {loadingWithdraw ? (
                                        <button className="btn-stake-reward dis" >
                                            Stake Rewards
                                        </button>
                                    ) : (
                                        <>
                                            {loadingStake ? (
                                                <>
                                                    <button className="btn-stake-reward dis" >
                                                        Waiting{" "}
                                                        <Spin indicator={antIcon} />
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    {endTime ? (
                                                        <button className="btn-stake-reward dis">
                                                            Expired
                                                        </button>
                                                    ) : (
                                                        <>
                                                            <button className="btn-stake-reward" onClick={handleStakeReward}>
                                                                Stake Rewards
                                                            </button>
                                                        </>
                                                    )}

                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                                <div className="btn-action">
                                    {loadingStake ? (
                                        <button className="btn-withdraw-reward dis" >
                                            Withdraw Rewards
                                        </button>
                                    ) : (
                                        <>
                                            {loadingWithdraw ? (
                                                <>
                                                    <button className="btn-withdraw-reward dis" >
                                                        Waiting{" "}
                                                        <Spin indicator={antIcon} />
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    <button className="btn-withdraw-reward" onClick={handleWithdrawReward}>
                                                        Withdraw Rewards
                                                    </button>
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="btn-action">
                                    <button className="btn-stake-reward dis">Stake Rewards</button>
                                </div>
                                <div className="btn-action">
                                    <button className="btn-withdraw-reward dis">Withdraw Rewards</button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="your-stake">
                    <div className="title">Your Staked</div>
                    <div className="num">{formatNumber(amountBscsPool, NUM_FORMAT)} <span>MEY</span></div>
                    <div className="count-down-unstake">
                        <div className="time-down">
                            {timeLock > 0 && amountBscsPool > 0.0000000001 && stakeTime > 0 ? (
                                <>
                                    {(timeRemaining + 5000) > Date.now() ? (
                                        <>
                                            <div className="title">
                                                Rewards End In {" "}
                                                <Tooltip title={textHint}>
                                                    <InfoCircleOutlined />
                                                </Tooltip>
                                            </div>
                                            <Countdown
                                                date={timeRemaining + 5000}
                                                renderer={rendererCountDown2}
                                            />
                                        </>
                                    ) : ('Unstakeable Now')}
                                </>
                            ) : ('')}

                            {/* {timeLock > 0 && amountBscsPool > 0.0000000001 && stakeTime > 0 ? (
                                <>
                                    {(stakeTime * 1000 + timeLock * 1000 + 5000) > Date.now() ? (
                                        <>
                                            <div className="title"> Expired in:</div>
                                            <Countdown
                                                date={stakeTime * 1000 + timeLock * 1000 + 5000}
                                                renderer={rendererCountDown2}
                                            />
                                        </>
                                    ) : ('Unstakeable Now')}
                                </>
                            ) : ('')} */}
                        </div>
                    </div>
                </div>
                <div className="your-stake">
                    <div className="title">Unstaked</div>
                    <div className="num">{formatNumber(getUser, NUM_FORMAT)} <span>MEY</span></div>
                    {/* <div className="num">{getUser}</div> */}
                    <div className="count-down-unstake">

                        {timeUnstake > 0 && getUser > 0 && lockTimeUnstake > 0 ? (
                            <>
                                {(timeUnstake * 1000 + lockTimeUnstake * 1000) > Date.now() ? (<div className="title">Withdrawable in:</div>) : ('')}
                                <div className="time-down">
                                    <Countdown
                                        date={timeUnstake * 1000 + lockTimeUnstake * 1000}
                                        renderer={rendererCountDown}
                                    />
                                </div>
                            </>
                        ) : ''}
                        {/* {timeUnstake > 0 && getUser > 0 ? (
                        <>
                            {(timeUnstake * 1000 + timeDown) > Date.now() ? (<div className="title">Withdrawable in:</div>) : ('')}
                            <div className="time-down">
                                <Countdown
                                    date={timeUnstake * 1000 + timeDown}
                                    renderer={rendererCountDown}
                                />
                            </div>
                        </>
                    ) : ''} */}
                    </div>
                </div>
                <div className="stake-link">
                    <div className="link-sc" onClick={gotoSc}>
                        View pool contract
                        <img src="/images/staking/view.png" alt="" />
                    </div>
                    <div className="link-sc" onClick={gotoHis}>
                        View history
                        <img src="/images/staking/view.png" alt="" />
                    </div>
                </div>


            </div>

            {/* @ts-ignore this lib is incompatible with react18  */}
            <Modal
                title="Stake Rewards"
                visible={isModalOpen}
                // onOk={handleOk}
                // onCancel={handleCancel}
                footer={false}
                wrapClassName="modal-confirm"
            >
                <p className="modal-text1">Your request is being processed. Please wait...</p>
                <p className="spin-stake">
                    <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 55 }} spin />} />
                </p>
            </Modal>
            {/* @ts-ignore this lib is incompatible with react18  */}
            <Modal
                title="Withdraw Rewards"
                visible={isModalOpenW}
                // onOk={handleOk}
                // onCancel={handleCancel}
                footer={false}
                wrapClassName="modal-confirm"
            >
                <p className="modal-text1">Your request is being processed. Please wait...</p>
                <p className="spin-stake">
                    <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 55 }} spin />} />
                </p>
            </Modal>
        </>


    )
})

export default Action;


