import React from "react";
import { Col, Collapse, Row } from 'antd';

const Faqs = () => {

    const { Panel } = Collapse;

    return (
        <>
            <div className="collapse-faq">
                <div className="title">
                    Frequently Asked Questions
                </div>
                <Row gutter={16}>
                    <Col span={12}>
                        <Collapse accordion>
                            <Panel header="Why P2P lending?" key="1">
                                <p>Peer-to-peer (P2P) real estate lending provides a transparent process for both lenders and borrowers, thus preventing any money being created out of thin air, which in a broader context also contributes to preventing inflation. For lenders, it offers an attractive rewards program, while for borrowers, it offers flexibility to better meet the capital needs of their property owners.</p>
                            </Panel>
                            <Panel header="How can I use the borrowed funds?" key="2">
                                <p>You may use the funds for any purchase outside of the MeyFi platform. MeyFi P2P lets you borrow funds in the same way as a traditional loan service where you can use the funds to purchase a new computer, buy a holiday package, or finance a project.</p>
                            </Panel>
                            <Panel header="Do I need to be a verified user to borrow funds?" key="3">
                                <p>You must be a verified user to use the MeyFi service. Depending on the level of verification you have completed, different options will become available to you through MeyFi. </p>
                            </Panel>
                            <Panel header="Will I get penalized for early payments?" key="4">
                                <p>No, if you wish to settle your loan early, you simply move funds to your margin wallet and end the agreement. In the same way, you can also repay in part, which will then decrease your daily interest. However, if you use the loan for less than 1 hour, you have to pay interest for a minimum of one hour.</p>
                            </Panel>
                            <Panel header="What is the difference between a variable rate loan and a fixed rate loan?" key="5">
                                <p>With MeyFi, you can currently only take out a fixed-rate loan. The interest rate charged on a fixed-rate loan is fixed for the term of the loan. You will negotiate the interest rate and term of the loan with the lender through MeyFi. If you do not repay your fixed-rate loan by the due date, when your fixed-rate loan expires, MeyFi will work with you to liquidate the asset and proceed with the principal and interest repayment to the lender.</p>
                            </Panel>
                            <Panel header="Why are the amount of funds that can be borrowed and the margin limit different for each collateral?" key="6">
                                <p>The amount of the loan varies by type of real estate collateral, depending on a number of factors, such as the quality and liquidity of the collateral. This value is expressed in the initial margin requirement of the underlying margin pair. For example, if you use an apartment as collateral, you will be able to borrow up to 70% of the apartment's value. So, if your apartment is worth $100,000, you will be able to borrow up to $70,000 based on the collateral.</p>
                            </Panel>
                        </Collapse>
                    </Col>
                    <Col span={12}>
                        <Collapse accordion>
                            <Panel header="Is there a minimum and maximum amount that can be borrowed?" key="7">
                                <p>Yes. The amount of the loan is subject to a minimum limit of $10.000 equivalent for each collateral and borrowed real estate. The maximum amount you can borrow is $500,000 equivalent across all borrowing in all master and sub accounts.</p>
                            </Panel>
                            <Panel header="You mentioned about the risks that borrowers must manage, what are they?" key="8">
                                <p>One risk we are referring to is the risk of foreclosure if the borrower is unable to repay interest and principal when due. Borrowers need to consider the various factors that may affect the value of their collateral and take the necessary actions to mitigate their own risk and prevent foreclosure.</p>
                            </Panel>
                            <Panel header="When does my loan start?" key="9">
                                <p>If you are borrowing on a fixed rate loan, your loan starts as soon as you receive the borrowed funds. You’ll start paying interest right away, whether you leave your funds in your margin wallet or take them out.
                                    If you are borrowing on a variable rate loan, your loan does not start until you take funds out of your margin wallet. Until you actually remove funds from your margin wallet, the balance you see is a hypothetical amount that you could potentially borrow based on the collateral you’ve provided and not guaranteed to be the same as, or reflective of the actual amount you are able to borrow when you try to initiate a loan transaction by removing funds from your margin wallet.
                                    The amount of potential borrowing is provided for information purposes only and solely as a personal reference tool."</p>
                            </Panel>
                            <Panel header="Is the loan period fixed?" key="10">
                                <p>No, you can repay your loan at any time. Fixed rate loans will have an expiration date, based on what you agree with the lender, but if you choose not to repay the loan before the expiration date, MeyFi will still hold the position of that loan and you can repay the loan principal with the fixed interest rate at the expiration date.</p>
                            </Panel>
                            <Panel header="It looks quite similar to traditional borrowing finance. How is MeyFi different from traditional borrowing finance?" key="11">
                                <p>MeyFi will help approve loans quickly, the terms between the borrower and the lender are authenticated through smart contracts, ensuring transparency and safety. Moreover, MeyFi also helps attract lenders from all over the world.</p>
                            </Panel>
                            <Panel header="What if I'm unable to return the borrowed funds within the loan period?" key="12">
                                <p>If you fail to repay your loan on time, your loan will automatically be placed into monitoring and your collateral may be liquidated. We have a dedicated department to take care of and help borrowers who have payment problems.</p>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
            </div>
        </>
    )
}
export default Faqs