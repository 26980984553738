
export enum STATUS_PTO {
  SOLD_OUT = 3,
  COMING = 2,
  OPEN = 1,
  END = 4,
}

export enum NUM_RANK {
  SVIP = 100000,
  WHALE = 50000,
  DOLPHIN = 10000,
  SHARK = 25000,
}
export enum TEXT_RANK {
  UPCOMING='upcoming',
  SVIP = 'svip',
  WHALE = 'whale',
  DOLPHIN = 'dolphin',
  SHARK = 'shark',
  COMMUNITY='available for all users',
  NEXT3_RANK='whale, shark, dolphin',
  NEXT32_RANK='whale, shark',
  NEXT21_RANK='shark, dolphin',
  END = 'end',
  ACTION = 'nft claim & trading'
}
