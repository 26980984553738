import React from "react";
import ChartMarket from "../../p2p-lending/chartJs";

export const Market = (props: any) => {

    const code = props?.code
    return (
        <>
            <div className="content-details">
                <div className="list-detail-info mar-top-20 res-page">
                    <ChartMarket code={code} />
                    {/* <div className="row-info">
                        <div className="box-no-data-pto">
                            <img className='img-empty' src="/images/empty1.png" alt="" />
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
};
