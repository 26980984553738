import { Tooltip } from "antd";
import React from "react";

export const DocumentDetail = (data: any) => {
  return (
    <>
      <div className="content-details">
        {data && data?.documentDetail?.map((item: any, index: any) => (
          <>
            <div className="item-view">
              <div className="title-view">
                {item?.name}
              </div>
              <div className="button-view">
                {item?.link === "" ?
                  <>
                    <Tooltip title="Coming soon">
                      <button className="btn-view-page dis">
                        View
                      </button>
                    </Tooltip>
                  </>
                  :
                  <>
                    <button className="btn-view-page">
                      <a href={item?.link} target="blank">
                        View
                      </a>
                    </button>
                  </>
                }
              </div>
            </div>
          </>
        ))}
      </div>
      {/* <div className="content-details">
        <div className="list-detail-info mar-top-20 res-page">
          <div className="row-info">
            <div className="box-no-data-pto">
              <img className='img-empty' src="/images/empty1.png" alt="" />
            </div>
          </div>
        </div>
      </div> */}
    </>
  )
};