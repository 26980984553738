import {
    Button,
    Pagination,
    Popover,
    Progress,
    Segmented,
    Select,
    Slider,
    Tabs,
} from "antd";
import Web3 from "web3";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import "./style.scss";
import { useNavigate } from "react-router-dom";
import { CustomTab } from "../../../components/ui/CustomTab";
import { COUNTRY, DEFAULT_DATA_FILTER, PTO_TABS, STATUS_FILTER, TYPE_FILTER } from "../../../constants/pto";
import IconChevronDown from "../../../assets/icons/svg/icon-chevron-down.svg";
import { PTOFilterTypes } from "../../../types/pto";
import { useHookPTO } from './storePTO'
import { useAccount } from "wagmi";
import { formatNumber } from "../staking/details/utils";
import Countdown from "react-countdown";
import { AbiItem } from "web3-utils";
import { rendererCountDown2 } from "../p2p-lending/utils/render-countdown";
import Faqs from "./faqs";
import { RPC_BASE } from "../../../constants";
import abiPTO from "./utils/abiPTO.json"
import { renderClassStatusType, renderStatusList } from "./utils";
import { isMobile } from "react-device-detect";



const Item = ({ data }: any) => {
    let sc = data?.contractAddress
    const naigation = useNavigate()
    const [keyTabs, setKeyTabs] = useState(1)
    const [totalInvestPool, settotalInvestPool]: any = useState(0)
    const [maxNFTSupply, setmaxNFTSupply]: any = useState(0)
    const [priceNFT, setPriceNFT]: any = useState(0)
    const [isSoldOut, setIsSoldOut]: any = useState(false)

    useEffect(() => {
        if (totalInvestPool >= (priceNFT * maxNFTSupply)) {
            setIsSoldOut(true)
        } else {
            setIsSoldOut(false)
        }
    }, [totalInvestPool, priceNFT, maxNFTSupply])

    let web3: any = new Web3(RPC_BASE);
    const contractPTONotAddress = new web3.eth.Contract(
        abiPTO as unknown as AbiItem,
        sc
    );
    const handleOpenDetails = () => {
        naigation(`/apps/pto/details?id=${data?.code}`)
    }
    let percent = (totalInvestPool / (maxNFTSupply * priceNFT)) * 100

    const getContractDetails = async () => {
        try {
            let infoContract = await contractPTONotAddress?.methods?.getContractInfo().call()
            setPriceNFT(Number(web3.utils.fromWei(infoContract[0]?.nftPrice, "picoether")))
            setmaxNFTSupply(Number(infoContract[0]?.maxNFTSupply))
            settotalInvestPool(Number(web3.utils.fromWei(infoContract[9], "picoether")))
        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        if (contractPTONotAddress && sc) {
            getContractDetails()
        }
    }, [contractPTONotAddress, sc, data])

    return (
        <>
            <div className="colum w-3">
                <div className="guide-item"
                    onClick={handleOpenDetails}
                >
                    <div className="name-type res">
                        <div
                            className={renderClassStatusType(data?.status, isSoldOut)}
                        >
                            {renderStatusList(data?.status, isSoldOut)}
                        </div>
                        <div className="type-process">
                            Project Gross Yield {data?.projectedGrossYield || "--"}
                        </div>
                    </div>
                    <div className="box-img">
                        <img src={data?.thumbnail} alt="" />
                    </div>
                    <div className="bottom-item">
                        {data?.startTime * 1000 > Date.now() ? (
                            <>
                                <div className="time-countdown">
                                    <div className="time-l">
                                        <img src="/images/lending/clock.png" alt="" />Start in:
                                    </div>
                                    <div className="time-r">
                                        <Countdown
                                            date={data?.startTime * 1000}
                                            //date={Date.now() + 50000000}
                                            renderer={rendererCountDown2}
                                        />
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                {data?.startTime * 1000 < Date.now() && Date.now() < data?.endTime * 1000 ? (
                                    <>
                                        <div className="time-countdown">
                                            <div className="time-l">
                                                <img src="/images/lending/clock.png" alt="" />Ends in:
                                            </div>
                                            <div className="time-r">
                                                <Countdown
                                                    date={data?.endTime * 1000}
                                                    renderer={rendererCountDown2}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="time-countdown">
                                        </div>
                                    </>
                                )}
                            </>
                        )}

                        <div className="location-row">
                            <div className="title-row">
                                {data?.name}
                            </div>
                        </div>

                        <div className="location">
                            <div className="local-left">
                                <img src="/images/pto-location.png" alt="" />
                                <span>
                                    <span className="text-loca">Location:</span>
                                    {data?.country || '--'}
                                </span>
                            </div>
                            <div className="local-right">
                                <img src="/images/pto-type.png" alt="" />
                                <span>
                                    <span className="text-loca">Type:</span>
                                    {data?.type || "--"}
                                </span>
                            </div>
                        </div>

                        <div className="box-line1">
                            <div className="pto-line1">
                                <img className="icon-left" src="/images/pto-asset.png" alt="" />
                                Annual Asset Appreciation:
                            </div>
                            <div className="pto-line2">
                                {data?.annualAssetAppreciation || "--%"}
                            </div>
                        </div>

                        <div className="box-line1">
                            <div className="pto-line1">
                                <img className="icon-left" src="/images/pto-gross.png" alt="" />
                                Annual Gross Rental Yield:
                            </div>
                            <div className="pto-line2">
                                {data?.annualGrossRentalYield || "--%"}
                            </div>
                        </div>


                        <div className="row-line"></div>
                        <div className="pto-group">
                            <div className="pto-group-left">
                                <div className="pto-line1">
                                    <img className="icon-left" src="/images/pto-issua.png" alt="" />
                                    Tokens Issued Value:
                                </div>
                                <div className="pto-line2">
                                    ${formatNumber(maxNFTSupply * priceNFT, 3)} USDC
                                </div>
                            </div>
                            <div className="pto-group-right">
                                <div className="pto-line1">
                                    <img className="icon-left" src="/images/pto-asset.png" alt="" />
                                    Total Raised:
                                </div>
                                <div className="pto-line2">
                                    ${formatNumber(totalInvestPool, 3)}
                                </div>
                            </div>
                        </div>
                        <div className="pto-group">
                            <div className="pto-group-left">
                                <div className="pto-line1">
                                    <img className="icon-left" src="/images/pto-issua.png" alt="" />
                                    Tokens Issued:
                                </div>
                                <div className="pto-line2">
                                    {formatNumber(maxNFTSupply, 3)}
                                </div>
                            </div>
                            <div className="pto-group-right">
                                <div className="pto-line1">
                                    <img className="icon-left" src="/images/pto-property.png" alt="" />
                                    Property Token Value:
                                </div>
                                <div className="pto-line2">
                                    {formatNumber(priceNFT, 3)} USDC
                                </div>
                            </div>
                        </div>
                        <div className="raise-profit">
                            <div className="box-riase">
                                <div className="bottom-process">
                                    <div className="text-process">
                                        <div className="txt">
                                            Funding Taget
                                        </div>
                                        <div className="number-process">
                                            <div className="name">
                                                <span className="number">
                                                    <span className="target1">
                                                        ${formatNumber((totalInvestPool), 3)}{" "}
                                                    </span>
                                                    <span className="target2">
                                                        /${formatNumber(maxNFTSupply * priceNFT, 3)}
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <Progress
                                        strokeColor={{ from: '#108ee9', to: '#87d068' }}
                                        percent={Number(percent.toFixed(2))}
                                        status="active" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

const Pto = () => {
    const { address } = useAccount();
    const [state, actions] = useHookPTO()
    const [isFilter, setisFilter] = useState(false);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize]: any = useState(12)
    const [totalItem, setTotalItem]: any = useState(0)
    const [keyTabs, setKeyTabs] = useState(PTO_TABS[0].value);
    const [filterData, setFilterData] =
        useState<PTOFilterTypes>(DEFAULT_DATA_FILTER);

    const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);
    const [listItemPTO, setListItemPTO] = useState<any>([]);
    const onChange = async (key: any) => {
        setCurrent(key);
    };


    const checkIsFilter = () => {
        if (filterData?.minValue === 0 && filterData?.maxValue === 1000000
            && filterData?.type === "" && filterData?.status === ""
            && filterData?.country === "") {
            setisFilter(false)
        } else {
            setisFilter(true)
        }
    }
    useEffect(() => {
        checkIsFilter()
    }, [filterData])


    const getListPTO = async () => {
        try {
            let statusNew
            if (filterData?.status) {
                statusNew = Number(filterData?.status)
                setKeyTabs(filterData?.status)
            } else {
                statusNew = Number(keyTabs)
            }
            let params = {
                ownerAddress: address || "",
                status: Number(keyTabs),
                pageSize: pageSize,
                pageIndex: current - 1,
                country: filterData?.country,
                type: filterData?.type,
                price_range: [filterData?.minValue, filterData?.maxValue]
            }

            await actions.listPTO(params).then((res: any) => {
                setListItemPTO(res?.data || [])
                setTotalItem(res?.totalCount || 0)

            })
                .catch((err: any) => {
                    setListItemPTO([])
                    setTotalItem(0)
                    console.log('err', err)
                })
        } catch (error) {
            setListItemPTO([])
            setTotalItem(0)
            console.log('error', error)
        }
    }

    useEffect(() => {
        getListPTO()
    }, [keyTabs, current, filterData])

    return (
        <>
            <div className="section-grow pto">
                {isMobile ? (
                    <div className="brcum-pto">
                        Property Token Offering
                    </div>
                ) : ('')}

                <div className="grow-wrap">
                    <div className="wrap-left">
                        <div className="content-grow">
                            <div className="tabs-list">
                                <div className="flex items-center justify-between mobie-tab">
                                    <CustomTab
                                        tabs={PTO_TABS}
                                        value={keyTabs}
                                        onChange={setKeyTabs}
                                        className="mobie-tab-item"
                                    />
                                    <Popover
                                        trigger="click"
                                        placement="bottom"
                                        open={isOpenFilter}
                                        onOpenChange={setIsOpenFilter}
                                        content={
                                            <FilterPTO
                                                setIsOpenFilter={setIsOpenFilter}
                                                data={filterData}
                                                setData={setFilterData}
                                            />
                                        }
                                    >
                                        <div className={`${isFilter ? (
                                            "flex items-center rounded-[8px] px-4 py-1.5 bg-[#3B3F45] border border-[#3B3F45] text-sm text-white font-medium gap-x-2.5 hover:cursor-pointer transition hover:border-primary hover:bg-primary/20 text-[14px] filter-active"
                                        ) : (
                                            "flex items-center rounded-[8px] px-4 py-1.5 bg-[#3B3F45] border border-[#3B3F45] text-sm text-white font-medium gap-x-2.5 hover:cursor-pointer transition hover:border-primary hover:bg-primary/20 text-[14px]"
                                        )}`}
                                        >
                                            <img
                                                width={24}
                                                height={24}
                                                src="/images/marketplace/filter.png"
                                                alt=""
                                            />
                                            <span>Filters</span>
                                        </div>
                                    </Popover>
                                </div>
                                <div className="ant-item-wrap">
                                    {listItemPTO?.length > 0 ? (
                                        <>
                                            <div className="columns">
                                                {listItemPTO.map((item: any, index: any) => (

                                                    <Item key={index} data={item} />

                                                ))}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="list-no-data-pto">
                                                <img className='img-empty' src="/images/empty1.png" alt="" />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {listItemPTO?.length > 12 ? (
                    <div className="pagination-his">
                        <Pagination current={current} defaultPageSize={pageSize} onChange={onChange} total={totalItem} />
                    </div>
                ) : (
                    ""
                )}

                <Faqs />
            </div>
        </>
    );
};

const FilterPTO = ({
    data,
    setData,
    setIsOpenFilter,
}: {
    data: PTOFilterTypes;
    setData: Dispatch<SetStateAction<PTOFilterTypes>>;
    setIsOpenFilter: Dispatch<SetStateAction<boolean>>;
}) => {

    const [minValue, setMinValue]: any = useState(0)
    const [maxValue, setMaxValue]: any = useState(1000000)
    const [type, setType]: any = useState("")
    const [country, setCountry]: any = useState("")
    const [status, setStatus]: any = useState("")


    const handleChangeSlider = (value: any) => {
        setMinValue(value?.[0])
        setMaxValue(value?.[1])
        // setData((oldValue) => {
        //   return {
        //     ...oldValue,
        //     minValue: value?.[0],
        //     maxValue: value?.[1],
        //   };
        // });
    };

    const handleResetForm = () => {
        setData(DEFAULT_DATA_FILTER);
        setMinValue(0)
        setMaxValue(1000000)
        setType("")
        setCountry('')
        setStatus("")
    };

    const handleSaveForm = () => {
        setIsOpenFilter(false);
        setData((oldValue) => {
            return {
                ...oldValue,
                minValue,
                maxValue,
                type,
                country,
                status
            };
        });
    };


    function handleSelectFilterStatus(e: any) {
        setStatus(e)
        // setData((oldValue) => {
        //   return {
        //     ...oldValue,
        //     status: Number(e)
        //   };
        // });
    }
    function handleSelectFilterType(e: any) {
        setType(e)
        // setData((oldValue) => {
        //   return {
        //     ...oldValue,
        //     type: Number(e)
        //   };
        // });
    }
    function handleSelectFilterCountry(e: any) {
        setCountry(e)
        // setData((oldValue) => {
        //   return {
        //     ...oldValue,
        //     country: e
        //   };
        // });
    }


    return (
        <div className="w-[333px] font-radioCanada">
            <div className="p-3 flex gap-x-2.5 items-center border-b border-b-[#3B3F45]">
                <img
                    width={16}
                    height={16}
                    src="/images/marketplace/filter.png"
                    alt=""
                />
                <div className="text-white">Filter By</div>
            </div>
            <div className="p-3">
                <div className="flex flex-col gap-y-3">
                    <div className="flex flex-col gap-y-2">
                        <div className="text-white">Country</div>
                        <Select
                            suffixIcon={<img alt="" src={IconChevronDown} />}
                            placeholder="Select"
                            className="h-10 rounded-[8px]"
                            options={COUNTRY}
                            showSearch
                            value={country}
                            onSelect={(e) => handleSelectFilterCountry(e)}
                        />
                    </div>
                    <div className="flex flex-col gap-y-2">
                        <div className="text-white">Type</div>
                        <Select
                            suffixIcon={<img alt="" src={IconChevronDown} />}
                            placeholder="Select"
                            className="h-10 rounded-[8px]"
                            options={TYPE_FILTER}
                            value={type}
                            onSelect={(e) => handleSelectFilterType(e)}

                        />
                    </div>
                    <div className="flex flex-col gap-y-2">
                        <div className="text-white">Property Token Status</div>
                        <Select
                            suffixIcon={<img alt="" src={IconChevronDown} />}
                            placeholder="Select"
                            className="h-10 rounded-[8px]"
                            options={STATUS_FILTER}
                            value={status}
                            onSelect={(e) => handleSelectFilterStatus(e)}
                        />
                    </div>
                    <div className="flex flex-col gap-y-2">
                        <div className="text-white">Property Token Price Range</div>
                        <div className="p-2 bg-[#3b3f4566] flex flex-col gap-y-2.5 rounded-[8px]">
                            <div className="flex justify-between items-center text-[14px]">
                                <div className="text-white/60">Price Range</div>
                                <div className="text-white">
                                    ${data?.minValue} - ${data?.maxValue}
                                </div>
                            </div>
                            <Slider
                                onChange={handleChangeSlider}
                                value={[minValue || 0, maxValue || 1000000]}
                                className="!mt-0"
                                range={true}
                                min={0}
                                max={1000000}
                            />
                        </div>
                    </div>
                </div>
                <div className="mt-4 flex justify-end items-center gap-x-3">
                    <Button
                        onClick={handleResetForm}
                        size="small"
                        className="border border-primary !bg-transparent h-[31px] w-[91px] text-white font-radioCanada"
                    >
                        Reset
                    </Button>
                    <Button
                        onClick={handleSaveForm}
                        type="primary"
                        size="small"
                        className="h-[31px] w-[91px] text-white font-radioCanada"
                    >
                        Save
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Pto;

